.CheckOut__conatiner {
    height: 100%;
    background-image: url("../../../Assets/ThemePark/CheckOut.svg");
    background-position: center;
    border-radius: 17px;
    box-shadow: 0px 4px 39px 0px rgba(178, 178, 178, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .Ticket_conatiner {
        // width: 346px;
        width: 95%;
        max-width: 346px;
        height: 100%;
        margin: 2rem 0;
        background-color: #FFFFFF;
        border-radius: 8px;
        padding: 16px;
        position: relative;

        .tcart-header {
            display: flex;
            justify-content: space-between;

            >div {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
            }

            >div:first-child {
                font-size: 14px;
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                color: #3C3C3C;

                >.check-header-img {
                    margin-right: 8px;
                }

                >.check-header-img-arab {
                    margin-left: 8px;
                }
            }

            >div:nth-child(2) {
                color: #66686C;
                font-size: 12px;
                font-weight: 400;

                >.check-header-img-two {
                    margin-right: 6px;
                }

                >.check-header-img-two-arab {
                    margin-left: 6px;
                }
            }

            .location {
                max-width: 130px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        // .marginTop__ticket {
        //     // margin-top: 2rem !important;
        // }
    }
}

.TicketBottom__wapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    height: 40px !important;

    .lable {
        color: #3C3C3C;
        font-family: Inter500;
        font-size: 12px;
        line-height: 14.52px;
    }
}

.Ticket_datePicker {
    max-width: 160px !important;
    width: 127px;
    height: 40px !important;
    background-color: #F3F3F3 !important;
    border-radius: 6px;
    max-height: 40px !important;
    box-sizing: none !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        font-size: 12px !important;
        font-family: Inter500;
    }

    .css-1laqsz7-MuiInputAdornment-root {
        margin-left: initial !important;
    }

    .css-i4bv87-MuiSvgIcon-root {
        color: #04D6FB !important;
    }
}

/**Style For Selecetd Ticket type**/
.selected-text-style {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: Inter500;
    font-size: 12px;
    color: #4E4E4E;
}

.ticket-available_select {
    border: 2px solid #04D6FB !important;
}

/**For Ticket Editable **/
.ticket-available {
    display: flex;
    align-items: center;
    border: 1px solid #bdbbbb;
    height: 44px;
    margin-bottom: 0.4rem;
    border-radius: 58px;
    padding: 12px;
    justify-content: space-between;
    cursor: pointer;
    width: max-content;
    max-width: 100%;

    >img {
        margin-right: 0.4rem;
    }

    .ticket-available-img-arab {
        margin-right: initial !important;
        margin-left: 0.4rem !important;
    }

    .ticket-pass-type {
        margin-right: 0.4rem;
        margin-top: 0.9rem;
        font-size: 12px;
        font-family: Inter500;
        cursor: pointer;
        display: flex;
        align-items: center;

        .ticket-pass-type-img {
            margin-right: 5px;
        }

        .ticket-pass-type-img-arab {
            margin-left: 5px;
        }
    }

    .ticket-pass-type-arab {
        margin-right: initial !important;
        margin-left: 0.4rem !important;
    }

    .ticket-pass-type-arab {
        margin-right: initial !important;
        margin-left: 0.4rem !important;
    }

    .ticket-type-edite {
        margin-top: 1rem;
        font-size: 10px !important;
        background-color: #04D6FB !important;
        border-radius: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 10px;
        min-width: 68px;

        >span {
            margin-right: 0.4rem;
            color: white;
        }

        .ticket-type-edite-margin-arab {
            margin-right: initial !important;
            margin-left: 0.4rem !important;
        }
    }
}

//**For Ticket Un Editable**//
.ticket-not-available {
    width: 110px;
    height: 44px;
    border-radius: 58px;
    border: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px;
    font-family: Inter500;
    cursor: pointer;
}

.ticket-not-available-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

/**For Button**/
.BookBtn {
    color: #FFF;
    font-family: Inter600;
    font-size: 16px;
    width: 100%;
    // width: 309px;
    height: 46px;
    border-radius: 18px;
    background: #04D6FB;
    margin: 1rem 0;
    margin-bottom: 10;
    display: flex1;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    outline: none;
}

/**For Modal**/
.ticket-booking-model {

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
    .css-uhb5lp {
        max-width: 346px !important;
        margin-right: -58px !important;
        // margin-top: -78px !important;
        // max-width: 350px !important;

    }
}

/**For Modal**/
.ticket-order-summary-model {

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
    .css-uhb5lp {
        max-width: 450px !important;
        margin-right: -58px !important;
        margin-top: -78px !important;
    }
}

.modal-head-style {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 20px 20px 10px 20px !important;

    >div:nth-child(1) {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        // margin-left: -10px;

        >span {
            font-size: 16px;
            font-family: Inter600;
            color: #4E4E4E;
        }

        >img {
            width: 28px;
            height: 28px;
            margin-right: 5px;
        }

        .modal-head-style-img-arab {
            margin-right: initial !important;
            margin-left: 5px !important;
        }
    }

    >div:nth-child(2) {
        font-size: 16px !important;
        font-family: Inter600;
        color: #07ABC8;
        white-space: nowrap;
    }
}

.dialog-content {
    padding: 0px 20px !important;

    #alert-dialog-slide-description {
        // font-size: 12px !important;
        // font-family: Inter500 !important;
        // color: #848484 !important;
        // padding: 0px 40px 0px 6px !important;
        // margin-top: -15px;
        font-size: 12px !important;
        font-family: Inter500 !important;
        color: #848484 !important;
        /* padding: 0px 40px 0px 6px !important; */
        /* margin-top: -15px; */
        margin-top: 10px;
        word-break: break-all;
    }

    .summary__contant {
        display: flex;
        flex-direction: row;

        .checkbox {
            width: 16px;
            height: 16px;
            border-radius: 3px !important;
            border: 1px solid #04D6FB !important;
            background: rgba(255, 174, 79, 0.12) !important;
            cursor: pointer;
            margin-top: 10px;
        }

        .paratext {
            color: rgba(51, 51, 51, 0.75);
            font-size: 12px;
            font-weight: 500;
        }

        .terms__text {
            color: #04D6FB;
            font-size: 12px;
            font-weight: 600;
        }
    }
}

.model-date-picketr {
    // margin-top: 1rem !important;
    // position: relative;
    // background-color: white !important;
    // color: #333333;
    position: relative;
    background-color: white !important;
    display: flex;
    gap: 5px;
    margin-top: 25px !important;

    // .model-date-icon {
    //     position: absolute;
    //     left: 8px;
    //     z-index: 2;
    //     top: 17px;
    // }
    .date {
        font-size: 14px;
        color: #333333 !important;
        font-family: Inter500;
        font-size: 14px;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        background-color: white !important;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding-left: 28px !important;
        background-color: white !important;
        font-size: 12px !important;
        font-family: Inter500;
    }

    .model-Ticket_datePicker .css-1laqsz7-MuiInputAdornment-root {
        display: none;
        background-color: white !important;
    }
}

.model-inc-dec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 0.3rem 0rem 1rem 0rem !important; */
    /* padding: 0px 13px 0px 9px; */
    margin-top: 20px !important;

    img {
        cursor: pointer;
    }


    >p:nth-child(1) {
        font-family: Inter500;
        font-size: 14px;
        color: #333333;
        font-size: 14px;
    }

    >p:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;

        >img:nth-child(1),
        span {
            margin-right: 8px;
        }

        .modal-head-style-img-arab {
            margin-left: 8px !important;
        }
    }
}

/*For Model Button*/
.model-action-buttons {
    padding: 20px !important;
    justify-content: space-between !important;

    >Button {
        border: 1px solid #EEEEEE !important;
        width: 151px;
        height: 46px;
        border-radius: 16px;
        font-size: 14px;
        font-family: Inter500;
        text-transform: capitalize;
    }

    >Button:nth-child(1) {
        color: #333333;
    }

    >Button:nth-child(2) {
        background: #04D6FB;
        color: #FFFFFF;
    }

    .model-action-buttons-arab {
        margin-left: initial !important;
        margin-right: 8px !important;
    }

}


/*For Model Button*/
.proceed_buttons {
    margin: 15px !important;
    border: 1px solid #EEEEEE !important;
    width: 350px;
    height: 46px;
    border-radius: 16px;
    font-size: 14px;
    font-family: Inter500;
    text-transform: capitalize;
    background: #04D6FB;
    color: #FFFFFF;
}

.disable_btn {
    opacity: 0.5 !important;
    cursor: default !important;
}

.order_list_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.order_list_wapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between !important;

    .price_text {
        color: #3C3C3C;
        font-family: Manrope700;
        font-size: 18px;
        line-height: 14.52px;
    }

    .more_ticket_text {
        color: #04D6FB;
        font-family: Inter500;
        font-size: 14px;
    }

    .total_ticket_text {
        color: #3C3C3C;
        font-family: Inter500;
        font-size: 14px;
    }

    .plus_icon {
        color: #04D6FB;
        font-size: 20px !important;
        cursor: pointer;
    }
}

.summary-modal-for-ticket-order {

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
    .css-uhb5lp {
        max-width: 450px !important;
        margin-right: -58px !important;
        width: 100% !important;
        // margin-top: -78px !important;
    }

    .summary-modal-head-style {
        font-family: Manrope700;
        font-size: 14px;
        color: #333333;
        margin: 12px;
    }

    .summary-modal-hr {
        margin: 0rem 0rem !important;
    }

    .summary-dialog-content {
        .summary-model-inc-dec-one {
            .order_list_container {
                .order_list_wapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .sum-ticket-available {
                        display: flex;
                        align-items: center;
                        /* height: 35px; */
                        margin-bottom: 0.4rem;
                        border-radius: 58px;
                        padding: 8px 12px;
                        justify-content: space-between;
                        cursor: pointer;
                        margin: 4px 0px;
                        background-color: #04D6FB;

                        >img {
                            margin-right: 0.4rem;
                            // border: 1px solid #FFFFFF;
                            border-radius: 50%;
                            height: 16px;
                            width: 16px;
                        }

                        .sum-ticket-available-img-arab {
                            margin-right: initial !important;
                            margin-left: 0.4rem !important;
                        }

                        .sum-ticket-pass-type {
                            font-size: 12px;
                            font-family: Inter500;
                            cursor: pointer;
                            color: #FFFFFF;
                            max-width: 150px;
                            margin: 0;
                            padding-right: 10px;
                        }

                        .sum-ticket-pass-type-arab {
                            padding-right: initial !important;
                            padding-left: 10px !important;
                        }

                        .sum-ticket-type-edite {
                            margin-top: 1rem;
                            font-size: 10px !important;
                            background-color: #fff !important;
                            border-radius: 34px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 2px 10px;
                            height: 20px;
                            margin: 0;

                            >span {
                                margin-right: 0.4rem;
                                color: #04D6FB;
                            }
                        }
                    }

                    .price_text {
                        color: #333333;
                        font-family: Inter600;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }
        }

        .summary-model-inc-dec-two {
            >div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5.5px;

                .more_ticket_text {
                    color: #04D6FB;
                    font-family: Inter500;
                    font-size: 14px;
                }

                .plus_icon {
                    color: #04D6FB;
                    font-size: 20px !important;
                    cursor: pointer;
                }
            }
        }

        .summary-model-inc-dec-three {
            >div {
                margin: 9.5px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .total_ticket_text {
                    color: #3C3C3C;
                    font-family: Inter500;
                    font-size: 14px;
                }

                .more_ticket_text_three {
                    color: #04D6FB;
                    font-family: Inter500;
                    font-size: 14px;
                    margin-left: 5px;
                }
            }
        }

        .summary-model-inc-dec-four {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;

            >div {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                gap: 10px;
                width: 250px;

                .checkbox {
                    width: 16px;
                    height: 16px;
                    // border-radius: 3px !important;
                    border: 1px solid #04D6FB !important;
                    background: rgba(255, 174, 79, 0.12) !important;
                    cursor: pointer;
                    // margin-top: 2px;
                }

                .confirmation_text {
                    font-size: 12px;
                    font-family: Inter500;
                    line-height: 14.52px;
                    color: #4E4E4E;

                    span {
                        font-family: Manrope700;
                        color: #04D6FB;
                    }
                }

                // .paratext {
                //     color: rgba(51, 51, 51, 0.75);
                //     font-size: 12px;
                //     font-weight: 500;
                // }

                // .terms__text {
                //     color: #04D6FB;
                //     font-size: 12px;
                //     font-weight: 600;
                // }
            }
        }
    }

    .procees-button-wrap {
        overflow-x: hidden;
        display: flex;
        justify-content: center;

        .proceed_buttons {
            margin: 15px !important;
            border: 1px solid #EEEEEE !important;
            // width: 350px;
            width: 93%;
            height: 46px;
            border-radius: 16px;
            font-size: 14px;
            font-family: Inter500;
            text-transform: capitalize;
            background: #04D6FB;
            color: #FFFFFF;
        }

        .disable_btn {
            opacity: 0.5 !important;
            cursor: default !important;
            width: 93%;
        }
    }
}

.Themepark_checkout_page {
    word-break: break-word;

    .background_circle_1 {
        content: "";
        position: absolute;
        top: 0;
        right: 70%;
        bottom: 85%;
        left: 0%;
        z-index: 0;
        border-radius: 50%;
        opacity: 0.5;
        background: radial-gradient(circle, #04D6FB 0, #04D6FB 50%, #fff 100%);
        filter: blur(250px);
    }

    .background_circle_2 {
        content: "";
        position: absolute;
        top: 35%;
        right: 0%;
        bottom: 30%;
        left: 65%;
        z-index: 0;
        border-radius: 50%;
        opacity: 0.5;
        background: radial-gradient(circle, #04D6FB 0, #04D6FB 50%, #fff 100%);
        filter: blur(250px);
    }
}

@media screen and (max-width:992px) {
    .ticket-booking-model {

        .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
        .css-uhb5lp {
            margin-right: 32px !important;
        }
    }

    .summary-modal-for-ticket-order {

        .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
        .css-uhb5lp {
            margin-right: 32px !important;
        }
    }
}

@media screen and (max-width:420px) {
    .summary-dialog-content {
        .summary-model-inc-dec-one {
            .order_list_container {
                .order_list_wapper {
                    flex-direction: column !important;
                    align-items: flex-start !important;

                    .price_text {
                        margin: 10px 5px 0px 5px !important;
                    }
                }
            }
        }
    }
}