// .guest-user-login-model {
//     .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
//         height: auto;
//         width: 349px !important;
//         // height: 461px !important;
//         background-color: #FFFFFF !important;
//         border-radius: 18px !important;

//         >.guest-user-model-head {
//             color: #333333;
//             font-family: manrope700;
//             font-size: 14px;
//             border-bottom: 0.5px solid #D3D3D3;
//         }

//         .first-last-name-wrapper {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             gap: 15px;

//             >div:nth-child(1),
//             div:nth-child(2) {
//                 .user-input {
//                     width: 156px !important;
//                     height: 40px !important;
//                     border: 1px solid #E7E7E7 !important;
//                     border-radius: 9px !important;
//                     background-color: #E7E7E7;
//                     text-indent: 10px !important;
//                 }

//                 .user-input::placeholder {
//                     color: #888888;
//                     font-size: 12px;
//                     font-family: Inter400;
//                 }

//                 .user-input:focus-visible {
//                     outline: none !important;
//                 }

//                 .error-message {
//                     color: #ff0000d1;
//                     font-size: 12px;
//                     font-family: "Manrope600";
//                     margin-top: 6px;
//                     margin-left: 7px;
//                 }

//                 .input-name-label {
//                     font-family: Inter500;
//                     font-size: 12px;
//                     color: #333333;
//                     padding: 10px 0px;
//                 }
//             }
//         }

//         /**Style For Togle button head**/
//         .togle-button-head {
//             font-family: Inter500;
//             font-size: 12px;
//             color: #333333;
//             margin: 18px 0px 9px 0px;
//         }

//         /**Style For Togle Button**/
//         .email-phone-togle-button {
//             background-color: #F4F4F5;
//             height: 50px;
//             border-radius: 41.62px;
//             display: flex;
//             align-items: center;
//             justify-content: space-evenly;
//             text-transform: uppercase !important;
//             margin-bottom: 18px;

//             >Button {
//                 width: 150px;
//                 height: 40px;
//                 border-radius: 41.62px;
//                 color: #818181;
//                 text-transform: capitalize !important;
//                 font-size: 12px;
//             }

//             .phone-button,
//             .email-button {
//                 background-color: #04D6FB !important;
//                 color: #FCFBFF !important;
//                 font-size: 12px;
//                 font-family: Manrope600;
//             }
//         }

//         /**Style For Email Phone Input Wrap**/
//         .email-phone-input-wrap {
//             display: flex;
//             flex-direction: column;
//             margin-bottom: 28px;

//             .email-phone-input-field::placeholder {
//                 color: #888888;
//                 font-size: 12px;
//                 font-family: Inter400;
//             }

//             .email-phone-input-field:focus-visible {
//                 outline: none !important;
//             }

//             .error-message {
//                 color: #ff0000d1;
//                 font-size: 12px;
//                 font-family: "Manrope600";
//                 margin-top: 6px;
//                 margin-left: 7px;
//             }

//             .input-name-label {
//                 font-family: Inter500;
//                 font-size: 12px;
//                 color: #333333;
//                 padding: 10px 0px;
//             }

//             .email-phone-input-field {
//                 width: 325px;
//                 height: 40px;
//                 border-radius: 9px;
//                 border: 1px solid #E7E7E7;
//                 background: #F4F4F5;
//                 text-indent: 10px;
//             }
//         }

//         /**Stule for otp button**/
//         .guest-user-action-button {
//             display: flex;
//             flex-direction: column;

//             .send-otp-button {
//                 display: flex !important;
//                 align-items: center;
//                 justify-content: center;
//                 border-radius: 16px;
//                 background-color: #04D6FB;
//                 width: 325px;
//                 height: 46px;
//                 color: #FFFFFF;
//                 font-family: Inter700;
//                 font-size: 14px;
//                 margin-bottom: 8px;
//             }

//             >div {
//                 font-family: Inter400;
//                 font-size: 12px;
//                 color: #888888;

//                 >Button {
//                     padding: 6px 12px !important;
//                     border-radius: 19px;
//                     background-color: #04D6FB;
//                     color: #FFFFFF;
//                     text-transform: capitalize;
//                     width: 63px;
//                     height: 27px;
//                     margin-left: 3px
//                 }
//             }
//         }

//     }
// }


// /*overall model responsive*/
// @media only screen and (max-width: 900px) {
//     .user-login-model .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
//         width: 80%;
//         height: auto;
//     }
// }

// @media only screen and (max-width: 480px) {
//     .user-login-model .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
//         width: 90%;
//         height: auto;
//     }
// }

.guest-user-login-model {
    // width: 350px;

    .title {
        color: #333333;
        font-family: manrope700;
        font-size: 14px;
        border-bottom: 0.5px solid #D3D3D3;
    }

    form {
        padding: 10px;
    }

    label {
        font-family: Inter500;
        font-size: 12px;
        color: #333333;
        margin-bottom: 5px;
    }

    input {
        width: 100%;
        border: 1px solid #E7E7E7;
        border-radius: 9px;
        outline: none;
        padding: 10px 13px;
        background: #F4F4F5;
        font-size: 12px;
    }

    .custom-form-group {
        margin-bottom: 20px;
    }

    .submit-button {
        width: 100%;
        border-radius: 16px;
        background-color: #04D6FB;
        color: #FFFFFF;
        font-family: Inter700;
        font-size: 14px;
        text-align: center;
        padding: 10px;
        margin-bottom: 20px;
    }

    .signup-button {
        padding: 8px 15px 10px 15px;
        border-radius: 19px;
        background-color: #04D6FB;
        color: #FFFFFF;
        margin-left: 5px;
        line-height: 14.52px;
    }

    .error-message {
        color: rgba(255, 0, 0, 0.8196078431);
        font-size: 12px;
        font-family: "Manrope600";
        margin-top: 5px;
        margin-left: 7px;
    }
    .MuiDialog-paper{
        width: 350px;
    }
}
/*For language based style*/
.guest-user-login-model-arabic{
    .MuiDialog-paper{
        .skip_button{
            right: 307px !important;
        }
    }
}