.University__dashboard__container {
  font-family: Manrope600 !important;
  margin-top: 1rem;

  .title_container {
    display: flex;
    margin-bottom: 15px;
    gap: 10px;
  }

  .UniversityCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 60px;

    img {
      width: 23.122px;
      height: 19.818px;
    }

    .TutorImg__text {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }
  }

  .Dashboard__UniversityCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    width: 181px;
    padding: 0 15px;
    height: 60px;

    img {
      width: 23.122px;
      height: 19.818px;
    }

    .text {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }
  }

  .search_field_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    max-width: 582px;
  }

  .search_field:focus {
    outline: none;
  }

  .search_field_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 14px;
  }

  .TutorCard_Filter {
    width: 46px;
    height: 46px;
    border-radius: 8px;
    border: 1px solid #f4f2f9;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search_field {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    font-size: 14px;
    width: 100%;
    height: 46px;
    padding: 14px 15px;
  }

  .search_field::placeholder {
    color: #adb5be;
    font-size: 14px;
    font-family: Inter500;
  }

  .search_button {
    padding: 16px 12px;
    cursor: pointer;
  }

  .search_button_icon_wrapper {
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 48px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .search__autocomplete {
    width: 100% !important;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    position: relative;

    input::placeholder {
      font-size: 14px;
      font-family: Inter500;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 5px !important;
      border-radius: 15px !important;
    }

    .search_button {
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 0;
    }
  }

  .University_map_view {
    border-radius: 12px;
    background: #fff;
    width: 100%;
    height: 330px;
    padding: 10px;
    position: relative;
    margin-top: 1rem;

    .University__viewMap {
      position: absolute;
      right: 18px;
      top: 33px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.45);
      box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
      backdrop-filter: blur(2.5px);
      padding: 10px;
      cursor: pointer;
      z-index: 501;

      span {
        color: #333;
        font-size: 13px;
        font-family: Manrope700;
      }
    }
  }

  //mobileView app download
  @media (max-width: 1080px) {
    .university__list__wapper {
      display: flex;
      flex-direction: column !important;
    }
  }

  /* width */
  .university__list__container::-webkit-scrollbar {
    width: 0px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .university__list__container {
    overflow: auto;
    overflow-y: scroll;
    // max-height: 330px;
    max-height: 356px;
  }

  @media screen and (min-width:992px) {
    
  }

  .university__list__wapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 96%;
    padding: 12px;
    border-radius: 14px;
    background: #fff;
    margin-bottom: 10px;


    .university__leftside {
      img {
        width: 200px;
        height: 160px;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .university__rightside {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .university__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between !important;

        .name {
          color: #000;
          font-size: 16px;
          font-family: Manrope700;
        }

        .imageIcon {
          display: flex;
          flex-direction: row;
          align-items: center !important;
          gap: 10px;

          img {
            width: 18px;
            height: 20px;
            cursor: pointer;
          }
        }
      }

      .university__location {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        img {
          width: 12px;
        }

        .location {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
          max-width: 330px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .marginTop {
        margin-top: 15px !important;
      }

      .university__ranking {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .text__black {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }

        .text__green {
          color: #12cc92;
          font-size: 12px;
          font-family: Inter500;
        }
      }

      .university__rating {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        cursor: pointer;

        .year {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }

        .StarIcon {
          color: #fcb040 !important;
          font-size: 14px !important;
        }

        .points {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }
      }
    }
  }
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 3px 4px !important;
}

// * Filter__drp   /*
.Filter__drp_top_wapper__university {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  margin: 7px;
  gap: 5px;

  .left__icon {
    background-color: rgba(18, 204, 146, 0.12);
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }

  .title {
    color: #333;
    font-size: 14px;
    font-family: Inter600;
  }
}

.Filter__drp__bottom_btn__university {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 5px;

  .resetBtn {
    border-radius: 29px;
    border: 1px solid #12cc92 !important;
    background-color: #fbfbfb;
    color: #12cc92;
    font-size: 12px;
    font-family: Inter400;
    width: 89px;
    height: 36px;
  }

  .applyBtn {
    border-radius: 29px;
    background: #12cc92;
    width: 89px;
    height: 36px;
    color: #fdfeff;
    font-size: 12px;
    font-family: Inter500;
  }
}

.filter_university_rightIcon {
  color: #b6b6b6 !important;
  font-size: 12px !important;
  height: 14px !important;
}

.filterActive_university_rightIcon {
  color: #12cc92 !important;
  font-size: 12px !important;
  height: 14px !important;
}

.filterActive_university {
  color: #12cc92 !important;
}

.No__data__contant {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  gap: 10px;

  img {
    // width: 208.503px;
    // height: 116.288px;
    width: 0;
    height: 0;
    object-fit: contain;
  }

  .text {
    color: #333;
    font-family: Manrope700;
    font-size: 16px;
  }
}

.css-1c35hjw-MuiPaper-root-MuiAccordion-root:first-of-type {
  box-shadow: none !important;
}

.css-1wz20g3 {
  box-shadow: none !important;
}

@media (max-width: 990px) {
  .university__list__container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 100% !important;
    height: 100% !important;

    .university__list__wapper {
      margin: 10px !important;
      width: 98% !important;
    }
  }
}

@media screen and (max-width: 1330px) and (min-width: 1080px) {
  .University__dashboard__container {
    .university__rightside .university__location .location {
      max-width: 180px !important;
    }
  }
}

@media (min-width: 1420px) {
  .University__dashboard__container {

    .university__list__wapper {

      .university__rightside .university__location .location {
        max-width: 400px !important;
      }
    }
  }
}

//mobileView app download
@media (max-width: 500px) {
  .University__dashboard__container {

    .university__list__container {
      overflow: auto;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 100% !important;
      height: 100% !important;
    }

    .university__list__wapper {
      display: flex;
      flex-direction: row !important;
      gap: 10px;
      width: 97% !important;
      // margin: 10px;
      padding: 12px;
      border-radius: 14px;
      background: #fff;
      margin-bottom: 10px;

      .university__leftside {
        img {
          width: 100px !important;
          height: 100% !important;
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
      }

      .university__rightside {
        display: flex;
        flex-direction: column;
        gap: 5px !important;
        width: 100%;

        .university__name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between !important;

          .name {
            color: #000;
            font-size: 14px;
            font-family: Manrope700;
            cursor: pointer;
          }

          .imageIcon {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-right: 7px;

            img {
              width: 20px;
              height: 17px;
              cursor: pointer;
            }
          }
        }

        .university__location {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            width: 12px;
          }

          .location {
            color: #333;
            font-size: 12px;
            font-family: Inter500;
            max-width: 180px !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .marginTop {
          margin-top: 0px !important;
        }

        .university__ranking {
          display: none !important;
        }

        // .university__ranking {
        //   display: flex;
        //   flex-direction: row;
        //   flex-wrap: wrap;
        //   align-items: center;
        //   gap: 10px;
        //   cursor: pointer;

        //   .text__black {
        //     color: #333;
        //     font-size: 12px;
        //     font-family: Inter500;
        //   }

        //   .text__green {
        //     color: #12cc92;
        //     font-size: 12px;
        //     font-family: Inter500;
        //   }
        // }

        .university__rating {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: space-between;
          cursor: pointer;

          .year {
            color: #333;
            font-size: 12px;
            font-family: Inter500;
          }

          .StarIcon {
            color: #fcb040 !important;
            font-size: 14px !important;
          }

          .points {
            color: #333;
            font-size: 12px;
            font-family: Inter500;
          }
        }
      }
    }
  }
}

//****//
@media screen and (max-width:991px) {
  .row-override-school{
    padding-right: initial !important;
    .input-style-phone{
      margin-bottom: 0.8rem !important;
    }
  }
  .row-override-school_arab{
    .input-style-phone{
      margin-bottom: 0.8rem !important;
    }
  }
}

/*Reduce the input width*/
@media screen and (min-width:400px) {
  .row-override-school{
    .input-style-phone{
      max-width: 60%;
    }
  }
}