.carousel_image_wrapper {
  // background: rgb(231, 112, 253);
  // background: linear-gradient(180deg,
  //     rgba(231, 112, 253, 1) 0%,
  //     rgba(175, 123, 232, 1) 35%,
  //     rgba(117, 79, 212, 1) 100%);
  width: 100% !important;
  position: relative;
  border-radius: 14px !important;

  .image_one_container {
    background: rgb(231, 112, 253);
    background: linear-gradient(180deg,
        rgba(231, 112, 253, 1) 0%,
        rgba(175, 123, 232, 1) 35%,
        rgba(117, 79, 212, 1) 100%);
    animation: firstToSecondImage 0.8s linear;
  }

  .image_two_container {
    animation: secondToThirdImage 0.8s linear;
  }

  .image_three_container {
    animation: thirdToFourth 0.8s linear;
  }

  .image_four_container {
    animation: fourthToFirst 0.8s linear;
  }

  //carousel background animation keyframes
  @keyframes firstToSecondImage {
    0% {
      background: rgb(231, 112, 253);
      background: linear-gradient(180deg,
          rgba(231, 112, 253, 1) 0%,
          rgba(175, 123, 232, 1) 35%,
          rgba(117, 79, 212, 1) 100%);
    }

    100% {
      background: #82e2cf;
      background: linear-gradient(167deg,
          #53d1b8 4.83%,
          #53d1b8 64.23%,
          #5d72cf 99.9%);
    }
  }

  @keyframes secondToThirdImage {
    0% {
      background: #82e2cf;
      background: linear-gradient(167deg,
          #53d1b8 4.83%,
          #53d1b8 64.23%,
          #5d72cf 99.9%);
    }

    100% {
      background: #19acff;
      background: linear-gradient(201deg,
          #19acff 7.77%,
          #2a98ff 66.68%,
          #1128f6 97.4%);
    }
  }

  @keyframes thirdToFourth {
    0% {
      background: #19acff;
      background: linear-gradient(201deg,
          #19acff 7.77%,
          #2a98ff 66.68%,
          #1128f6 97.4%);
    }

    100% {
      background: #f8cb69;
      background: linear-gradient(201deg,
          #ff9b30 7.77%,
          #ff9a30 66.68%,
          #ff9b31 97.4%);
    }
  }

  @keyframes fourthToFirst {
    0% {
      background: #f8cb69;
      background: linear-gradient(201deg,
          #ff9b30 7.77%,
          #ff9a30 66.68%,
          #ff9b31 97.4%);
    }

    100% {
      background: rgb(231, 112, 253);
      background: linear-gradient(180deg,
          rgba(231, 112, 253, 1) 0%,
          rgba(175, 123, 232, 1) 35%,
          rgba(117, 79, 212, 1) 100%);
    }
  }

  //active carousel dot
  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #ffff !important;
    width: 12px !important;
    height: 12px !important;
  }

  //inactive carousel dot
  .alice-carousel__dots-item:not(.__custom) {
    background-color: #ffff !important;
    width: 8px !important;
    height: 8px !important;
  }

  //carousel dots alignment
  .alice-carousel__dots {
    // margin: 0px 44% 36px 32px !important;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 1000;
    left: 25px;
    bottom: 5px !important;
  }

  //carousel navigation buttons
  .alice-carousel__prev-btn {
    width: unset !important;
    position: absolute;
    z-index: 1000;
    right: 18%;
    bottom: 0;
    padding: 0 !important;
  }

  .alice-carousel__next-btn {
    width: unset !important;
    position: absolute;
    z-index: 1000;
    right: 8%;
    bottom: 0;
    padding: 0 !important;
  }

  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    // background-color: #8752ca5c !important;
    border-radius: 50% !important;
  }

  .alice-carousel__next-btn-item {
    padding: 7px 9px 4px 11px !important;
  }

  .alice-carousel__prev-btn-item {
    padding: 7px 11px 4px 9px !important;
  }

  .alice-carousel__dots-item:not(.__custom):last-child {
    margin-left: auto;
  }

  //carousel navigation icons
  .alice-carousel__prev-btn [data-area]::after {
    content: url("../../Assets/Authentication/arrow left.svg") !important;
  }

  .alice-carousel__next-btn [data-area]::after {
    content: url("../../Assets/Authentication/arrow right.svg") !important;
  }

  //carousel image width
  .alice-carousel__stage-item {
    width: 100% !important;
    min-width: 278px;
  }

  //Carousel image height
  // .alice-carousel__wrapper {
  //   height: 74vh !important;
  // }

  .my_carousel_item{
  }
  
  .carousel_content{
    border-radius: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 32px;
  }


  .carousel_content_title {
    color: #fff;
    font-size: 18px;
    font-family: Manrope700;
    margin-bottom: 4px;
  }

  .carousel_content_description {
    color: #fff;
    font-size: 13px;
    font-family: Inter400;
    padding-bottom: 20px;
  }

}

@media screen and (max-width:785px) {
  .carousel_image_wrapper {
    .alice-carousel__stage-item{
        min-width: initial !important;
    }
    .carousel_content_title {
      font-size: 15px !important;
    }
    .carousel_content_description {
      font-size: 10px !important;
    }
  }
}

@media screen and (max-width:458px) {
  .carousel_image_wrapper {
    .carousel_content_title {
      font-size: 14px !important;
    }
    .carousel_content_description {
      font-size: 9px !important;
    }
  }
}

// @media screen and (min-width:431px) and (max-width:500px) {
//   .carousel_image_wrapper {
//     .alice-carousel__prev-btn,
//     .alice-carousel__next-btn {
//       display: none !important;
//     }
//   }
// }