.preference_dialog_wrapper {
  .MuiDialogContent-root {
    padding: 0 !important;
  }

  .skip_button_preference {
    position: absolute;
    right: 20px;
    top: 20px;
    font-family: Inter500;
    font-size: 14px;
    cursor: pointer;
    z-index: 2000;
    color: #FFFFFF;
  }

  .preference_dialog_contents_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;

    .preference_title_container {
      position: relative;
      background: linear-gradient(180deg,
          #51ABFF 0%,
          #9290FF 42.89%,
          #9B80FF 100%);
      padding: 40px 30px;
    }

    .preference_bg_curve {
      position: absolute;
      top: 40px;
      right: 30%;
    }

    .bg_circle_small {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.57);
      mix-blend-mode: soft-light;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 80px;
      right: 35%;
    }

    .bg_circle_large {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.57);
      mix-blend-mode: soft-light;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      top: 86px;
      right: 10%;
    }

    .preference_title {
      color: #F5F2FF;
      font-family: Manrope700;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .preference_description_info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
    }

    .preference_description {
      color: #F5F2FF;
      font-family: Inter400;
      font-size: 12px;
      font-weight: 400;
    }

    .preference_chips_wrapper {
      width: 100%;
      height: auto;
      min-width: 500px;
      max-height: 290px;
      overflow: auto;
      overflow-y: auto;
      border-radius: 17px;
      padding: 0px 20px 20px 20px;

      .pref-body-wrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 20px;

        .pref_title_text {
          color: #333;
          font-family: Inter500;
          font-size: 14px;
        }

        .pref-item-list-wrap {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 10px;
          align-items: center;
        }
      }
    }

    .preference_chips {
      border-radius: 13px;
      background: #F2F2F2;
      color: #333;
      font-family: Manrope600;
      font-size: 12px;
      padding: 9px 15px;
      cursor: pointer;
      border: 1px solid #CBDCE1;
    }

    .preference_chips__active {
      border-radius: 13px;
      background: #333;
      color: #F2F2F2;
      font-family: Manrope600;
      font-size: 12px;
      padding: 9px 15px;
      cursor: pointer;
    }

    .home_nav {
      border-radius: 50px;
      background: #744FFF;
      color: #fff;
      font-family: Manrope700;
      font-size: 12px;
      padding: 16px 40px;
      align-self: center;
      margin-bottom: 32px;
    }

    .home_nav__disable {
      border-radius: 50px;
      background: #744FFF;
      color: #fff;
      font-family: Manrope700;
      font-size: 12px;
      padding: 16px 40px;
      align-self: center;
      margin-bottom: 32px;
      opacity: 0.5;
      cursor: default !important;
    }

    .info_icon {
      cursor: pointer;
    }

    //Tooltip
    .tooltip_title {
      color: #333;
      font-family: Manrope600;
      font-size: 12px;
      font-weight: 600;
    }

    .tooltip_description {
      color: rgba(51, 51, 51, 0.66);
      font-family: Inter400;
      font-size: 10px;
      font-weight: 400;
    }

    .tooltip_title_close_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .tooltip_contents_wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .css-kudwh-MuiTooltip-arrow {
      color: #F8F7FF !important;
    }
  }
}