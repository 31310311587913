.overal-signup-modal-container{

.signin_signup_container {
  display: flex;
  flex-direction: column;
}

.signin_signup_title {
  color: #333;
  font-family: Manrope700;
  font-size: 28px;
  margin-bottom: 8px;
  margin-top: 1.6rem;
}

.signin_signup_description {
  color: rgba(51, 51, 51, 0.69);
  font-family: Inter400;
  font-size: 14px;
  margin-bottom: 20px;
}

.fields_label {
  color: #333;
  font-family: Manrope600;
  font-size: 12px;
  margin-bottom: 12px;
  margin-left: 5px;
}

.send_otp_button {
  position: absolute;
  right: 0;
  top: 0;
  color: #744fff;
  font-family: Manrope600;
  font-size: 12px;
  border-radius: 12px;
  background: #dfd7ff;
  box-sizing: border-box;
  padding: 11px 20px;
}
.send_otp_button_ar {
  position: absolute;
  left: 0;
  top: 0;
  color: #744fff;
  font-family: Manrope600;
  font-size: 12px;
  border-radius: 12px;
  background: #dfd7ff;
  box-sizing: border-box;
  padding: 11px;
}

.email_field_wrapper {
  height: 40px;
  position: relative;

  .login-email-input {
    border-radius: 12px;
    background: #f4f3f8;
    height: 40px;
    color: #888;
    font-family: Inter400;
    font-size: 12px;
    border: unset;
    padding: 12px 97px 12px 18px;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: #888;
      font-family: Inter400;
      font-size: 12px;
    }

    &:focus {
      outline: unset !important;
      border: unset !important;
    }
  }
  .login-email-input-ar {
    border-radius: 12px;
    background: #f4f3f8;
    height: 40px;
    color: #888;
    font-family: Inter400;
    font-size: 12px;
    border: unset;
    padding: 12px 18px 12px 135px;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: #888;
      font-family: Inter400;
      font-size: 12px;
    }

    &:focus {
      outline: unset !important;
      border: unset !important;
    }
  }
}

.errorMessage {
  color: #ff0000d1;
  font-size: 14px;
  font-family: "Manrope600";
  margin-top: 8px;
  margin-left: 7px;
}

.phone_field_wrapper {
  height: 40px;
  position: relative;

  .login-phone-input {
    border-radius: 20px;
    background: #f4f3f8;
    height: 40px;
    color: #888;
    font-family: Inter400;
    font-size: 14px;
    border: unset;
    padding: 12px 97px 12px 46px;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: #888;
      font-family: Inter400;
      font-size: 14px;
    }

    &:focus {
      outline: unset !important;
      border: unset !important;
    }
  }
  
  .login-phone-input-ar {
    border-radius: 20px;
    background: #f4f3f8;
    height: 40px;
    color: #888;
    font-family: Inter400;
    font-size: 14px;
    border: unset;
    padding: 12px 44px 12px 97px;
    width: 100%;
    box-sizing: border-box;
    direction: rtl;

    .flag-dropdown {
      right: 25px !important;
    }
    &::placeholder {
      color: #888;
      font-family: Inter400;
      font-size: 14px;
    }

    &:focus {
      outline: unset !important;
      border: unset !important;
    }
  }
}

.google_login_wrapper {
  border-radius: 50px;
  background: #eeecf9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  align-self: center;
  height: 48px !important;
  width: 100%;
  max-width: 245px;
}

.google_login_label {
  color: #333;
  font-family: Manrope600;
  font-size: 14px;
}

.seperator_for_social_login {
  color: #91929b;
  font-family: Inter500;
  font-size: 12px;
  align-self: center;
  margin: 12px 0;
}

.signup_login_navigation_wrapper {
  margin-top: 20px;
  color: #4F505A;
  font-family: Inter500;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
}

.signup_login_navigation_button {
  color: #744fff;
  font-family: Inter600;
  font-size: 14px;
  background-color: unset !important;
  padding: 0px 4px !important;
}

.email_login_form_wrapper {
  margin-top: 13px;
  // margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}

}


// @media screen and (max-width:785px) {
  @media screen and (max-width:636px) {
  .overal-signup-modal-container{
    .carousel_login_navigations_wrapper{
        flex-direction: row !important;
    }
    .google_login_wrapper {
      height: 40px !important;
    }
    .signin_signup_title {
      font-size: 25px !important;
      margin-top: 1rem !important;
      margin-bottom: 6px !important;
    }
    .signin_signup_description {
      font-size: 11px !important;
      margin-bottom: 14px !important;

    }
    .fields_label {
      font-size: 9px !important;
      margin-bottom: 9px !important;
    }
    .send_otp_button,.send_otp_button_ar {
      padding: 12.5px 13px !important;
      font-size: 9px !important;
    }
    .send_otp_button,.send_otp_button_ar{
      font-size: 9px !important;
    }
    .email_field_wrapper {
      input {
        font-size: 9px;
        &::placeholder {
          font-size: 9px;
        }
      }
    }
    .errorMessage {
      font-size: 11px !important;
    }
    .phone_field_wrapper {
      input {
        font-size: 11px !important;
    
        &::placeholder {
          font-size: 11px !important;
        }
      }
    }
    .google_login_label {
      font-size: 11px !important;
    }
    .seperator_for_social_login {
      font-size: 9px !important;
      margin: 9px 0px !important;
      height: 13px !important;
    }
    .signup_login_navigation_wrapper {
      font-size: 10px !important;
    }
    .signup_login_navigation_button {
      font-size: 10px !important;
    }

   .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
   .css-1q2h7u5.Mui-selected,
   .css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
   .css-1q2h7u5{
        font-size: 11px !important;
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
      padding: 9px 10px !important;
    }

  }
}


@media screen and (min-width:431px) and (max-width:495px) {
  .overal-signup-modal-container{
  .LoginCarousel__img{
    min-height: 339px !important;
  }
  .signin_signup_description {
    margin-bottom: 10px !important;
  }
  .email_login_form_wrapper{
    margin-top: 9px !important;
  }
.signup_login_navigation_wrapper {
    margin-top: 14px !important;
  }
}
}

@media screen and (max-width:430px) {
  .overal-signup-modal-container{
    .carousel_login_navigations_wrapper{
        flex-direction: column!important;
    }
    .LoginCarousel__img{
      max-height: 200px !important;
    }
  }
}

//****For Carosal icon hide***//
@media screen and (min-width:431px) and (max-width:500px) {
  .overal-signup-modal-container{
  .carousel_image_wrapper {
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      display: none !important;
    }
  }
}
}

@media screen and (min-width:430px) and (max-width:452px) {
  .carousel_image_wrapper .alice-carousel__dots{
    left: 9px !important;
  }
}

.arab_input{
  .flag-dropdown{
   right: 25px !important;
  }
.selected-flag{
  width: unset !important;
}
}