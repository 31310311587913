.top_tutors_card_wrapper {
    // max-width: 325px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: #FFF;
    padding: 10px;

    .top_tutors_title {
        color: #333;
        font-size: 16px;
        font-family: Manrope600;
        cursor: pointer;
    }

    .top_tutors_card {
        border-radius: 7px;
        border: 1px solid #EEE;
        background: #FFF;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 13px;
        cursor: pointer;

        .tutor_img {
            width: 45px;
            height: 45px;
            border: 2px solid #f9f9f9;
            border-radius: 50%;
            object-fit: cover !important;
        }

        .card__title {
            color: #000;
            font-size: 12px;
            font-family: Manrope600;
        }

        .badge {
            border-radius: 4px;
            background: #E8F4FF;
            color: #178FFF;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-top: 7px;
        }

        .BadgeIcon {
            width: 10px;
            height: 12px;
        }

        .StarIcon {
            color: #FCB040 !important;
            font-size: 14px !important;
        }
    }

    .scroll__wapper {
        max-height: 300px;
        overflow: auto;
        overflow-y: scroll;
    }

    /* width */
    .scroll__wapper::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .empty__data {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5rem;

        .text {
            color: #333;
            font-family: Manrope700;
            font-size: 16px;
        }
    }
}

@media screen and (max-width:460px) {
    .top_tutors_card_wrapper {
        .top_tutors_title {
            font-size: 14px;
        }
        .top_tutors_card{
          .card__title,.badge{
            font-size: 10px;
        }
        }
    }
}

@media screen and (min-width:993px) {
    .top_tutors_card_wrapper {
        width: 97% !important;
    }
}