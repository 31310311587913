.header_wrapper__blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(5px);

  .header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 20px;
    background: rgba(255, 255, 255, 0.77);
    box-shadow: 7.000000476837158px 4px 26.000001907348633px 0px rgba(144, 144, 144, 0.25);
    backdrop-filter: blur(8.500000953674316px);
  }

  .Hamber__icon {
    display: none;
  }

  .main_navigation_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  .main_navigation {
    color: #636363;
    font-family: Manrope600;
    font-size: 12px;
    cursor: pointer;
  }

  .main_navigation__inactive {
    color: #636363 !important;
    font-family: Manrope400 !important;
    font-size: 12px !important;
    cursor: pointer;
  }

  .main_navigation_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .active_indicator {
    border-radius: 29px;
    width: 19px;
    height: 3px;
    position: absolute;
    top: 32px;
  }

  .home_indicator {
    background: #744fff !important;
  }

  .school_indicator {
    background: #ffc812;
  }

  .university_indicator {
    background: #12cc92;
  }

  .tutor_indicator {
    background: #178fff;
  }

  .activity_indicator {
    background: #f0962c;
  }

  .themePark_indicator {
    background: #04d6fb;
  }

  .navigation_icons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 17px;

    img {
      cursor: pointer;
      width: 19px;
      height: 19px;
      object-fit: contain;
    }

    .applications__icon {
      background-color: #f5f1fa;
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }
}

.header_wrapper {
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(8.5px);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

  .header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 20px;
    background: rgba(255, 255, 255, 0.77);
    box-shadow: 7.000000476837158px 4px 26.000001907348633px 0px rgba(144, 144, 144, 0.25);
    backdrop-filter: blur(8.500000953674316px);

    .logo__icon {
      cursor: pointer;
    }
  }

  .Hamber__icon {
    display: none;
  }

  .main_navigation_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  .main_navigation {
    color: #636363;
    font-family: Manrope600;
    font-size: 12px;
    cursor: pointer;
  }

  .main_navigation__inactive {
    color: #636363 !important;
    font-family: Manrope400 !important;
    font-size: 12px !important;
    cursor: pointer;
  }

  .main_navigation_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .active_indicator {
    border-radius: 29px;
    width: 19px;
    height: 3px;
    position: absolute;
    top: 32px;
  }

  .school_indicator {
    background: #ffc812;
  }

  .home_indicator {
    background: #744fff !important;
  }

  .university_indicator {
    background: #12cc92;
  }

  .tutor_indicator {
    background: #178fff;
  }

  .activity_indicator {
    background: #f0962c;
  }

  .themePark_indicator {
    background: #04d6fb;
  }

  .navigation_icons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 17px;

    img {
      cursor: pointer;
      width: 19px;
      height: 19px;
      object-fit: contain;
    }

    .applications__icon {
      background-color: #f5f1fa;
      width: 30px;
      height: 30px;
      padding: 5px;
      object-fit: contain;
      border-radius: 50%;
    }

    .icon__translate {
      cursor: pointer;
      width: 26x;
      height: 26px;
      margin-right: 23px;
    }
  }
}

.header_location_dropdown {
  background-color: #EFEFEF;
  border-radius: 22PX;
}

.header__profile_picture {
  width: 24px !important;
  height: 24px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
}

.header__profile_picture__pop {
  width: 37px !important;
  height: 37px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
}

.swiper-button-next:after {
  font-size: 14px !important;
  margin-left: 15px !important;
  color: #744fff !important;
  font-weight: 700 !important;
  font-size: 8px !important;
}

.swiper-button-prev:after {
  font-size: 14px !important;
  margin-right: 20px !important;
  color: #744fff !important;
  font-weight: 700 !important;
  font-size: 8px !important;
}

.Profile_select_item_conatiner {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 300px;

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, -6px) !important;
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
  }

  .profile__card__conatiner {
    width: 100%;
    height: auto;
    border-radius: 12px;
    border: 1px solid #e6deff;
    background: #f7f4ff;
    backdrop-filter: blur(5.5px);
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px 13px;
    margin-bottom: 15px;

    .contant__wapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }

    .primary_badge {
      border-radius: 27px;
      background: #744fff;
      width: fit-content;
      padding: 4px 10px;
      color: #fff;
      font-family: Manrope700;
      font-size: 10px;
    }

    .select_badge {
      border-radius: 27px;
      background: rgba(116, 79, 255, 0.06);
      width: fit-content;
      padding: 4px 10px;
      color: #3d3d3d;
      font-family: Manrope700;
      font-size: 10px;
      cursor: pointer;
    }

    .profile_Img {
      width: 46.396px;
      height: 46.396px;
      border-radius: 50%;
      object-fit: cover;
    }

    .profile_Name {
      color: #000;
      font-family: Manrope700;
      font-size: 12px;
      text-align: center;
      text-wrap: pretty;
    }

    .role {
      color: #744fff;
      font-family: Inter700;
      font-size: 11px;
    }
  }
}

.accounts__wapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .accounts__wapper__add {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .account_text {
    color: #505867;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter500;
    font-size: 14px;
  }

  .add_text {
    color: #744fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Manrope600;
    font-size: 14px;
  }
}

.profile_arrow_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.profile_name_switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.profile_name_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.account_contents_logo_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.logout_logo_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.divider {
  background-color: #ebecf1 !important;
  height: 1px;
  margin: 0px 16px !important;
  padding: unset !important;
}

.name_label {
  color: #505867;
  font-family: Manrope600;
  font-size: 14px;
}

.switch_profile_button {
  color: #724ef9;
  font-family: Manrope600;
  font-size: 14px;
  cursor: pointer;
}

.account_contents_lave {
  color: #505867;
  font-family: Inter500;
  font-size: 12px;
}

.logout_labe {
  color: #f26a55;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter500;
  font-size: 12px;
}

.back_button_wrapper {
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
  }
}

.avatar_name_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.avatar_name_active_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.profile_name_role {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.avatar_pic {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.active_icon_wrapper {
  img {
    background-color: #724ef9;
    border-radius: 50%;
    padding: 7px 6px;
  }
}

.switch_account_label {
  color: #505867;
  font-family: Manrope600;
  font-size: 14px;
}

.profile_name {
  color: #505867;
  font-family: Manrope600;
  font-size: 14px;
}

.role {
  color: #a2aab6;
  font-family: Manrope600;
  font-size: 12px;
}

.avatar_profile_button {
  display: block !important;
}

.profile_switch_container {
  padding: 6px 16px;
  white-space: nowrap;
}

.add_profile_label {
  color: #505867;
  font-family: Manrope600;
  font-size: 12px;
}

.add_profile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.add_icon {
  background-color: #f0f3f8;
  padding: 8px;
  border-radius: 50%;
}

.header_location_icon {
  position: absolute;
  left: 11px;
  top: 13px;
  width: 10.001px !important;
  height: 12.306px !important;
  object-fit: contain;
}

.google__search__autocomplete {
  width: 100% !important;
  max-width: 202px !important;
  height: 32px;
  padding: 10px;
  padding-left: 30px;
  border-radius: 100px;
  background: #efefef;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04) inset;
  margin-top: 3px;
  border: none;
  outline: none;
  color: #717171;
  font-family: Manrope500 !important;
  font-size: 12px;
}

.google__search__autocomplete::placeholder {
  color: #717171;
  font-family: Manrope400 !important;
  font-size: 12px;
}

.Pos__relative {
  position: relative;

  .notifybadeg__pos {
    position: absolute;
    top: 2px;
    right: 1px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: #ff4f4f;
  }
}

.search__autocomplete__header {
  width: 100% !important;
  max-width: 300px !important;
  // background: #FFF;
  // box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);
  border: none;
  margin-left: 30px;
  position: relative;

  // input {
  //   min-width: 250px !important;
  // }

  // input::placeholder {
  //   // color: #ADB5BE;
  //   font-size: 14px;
  //   font-family: Inter500;
  // }

  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 5px !important;
    border-radius: 15px !important;
  }

  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7.5px 8px 7.5px 0px !important;
  }
}

.ChildSelectBtn__wapper__calendar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 2rem;

  .BackBtn {
    width: 133px;
    height: 46px;
    border-radius: 26px;
    background-color: #fff;
    border: 1px solid #744fff !important;
    color: #744fff;
    font-size: 12px;
    font-weight: 600;
  }

  .OkayBtn {
    width: 133px;
    height: 46px;
    border-radius: 26px;
    background: #744fff;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
}

.Header__gird_Menu__conatiner {
  width: 263px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 20px;
  padding-top: 10px;

  .module__wapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;

    img {
      width: 22.4px;
      height: 22.4px;
      object-fit: contain;
    }

    .center__lable {
      display: flex;
      flex-direction: column;

      span {
        color: #333;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Manrope600;
        font-size: 12px;
      }

      p {
        color: #636363;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Manrope500;
        font-size: 8px;
      }
    }

    .right__arrow {
      color: rgba(51, 51, 51, 1) !important;
      font-size: 18px;
    }
  }
}

/* 1100 Tap View */

@media (max-width: 1118px) {
  .header_wrapper {
    .main_navigation {
      color: #636363;
      font-family: Manrope600;
      font-size: 10px !important;
      cursor: pointer;
    }

    .main_navigation__inactive {
      color: #636363 !important;
      font-family: Manrope400 !important;
      font-size: 10px !important;
      cursor: pointer;
    }

    .navigation_icons_wrapper {
      img {
        cursor: pointer;
        width: 17px;
        height: 17px;
        object-fit: contain;
      }

      .applications__icon {
        background-color: #f5f1fa;
        width: 15px;
        height: 15px;
        padding: 5px;
        object-fit: contain;
        border-radius: 50%;
      }

      .icon__translate {
        cursor: pointer;
        width: 20x;
        height: 20px;
        margin-right: 23px;
      }
    }

    .header_container {
      .logo__icon {
        width: 50x;
        height: 50px;
        object-fit: contain;
      }
    }
  }

  .search__autocomplete__header {
    width: 100% !important;
    max-width: 300px !important;
    border: none;
    margin-left: 0px !important;
    position: relative;

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 1px !important;
      border-radius: 15px !important;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
      padding: 7.5px 8px 7.5px 0px !important;
    }

    .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      font-size: 10px !important;
    }

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
      font-size: 10px !important;
    }
  }
}

.nav-options__Header {
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  .navigation_contant_title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin: 10px 0;
  }

  .navigation_icons_MobileView {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 17px;

    img {
      cursor: pointer;
      width: 20px;
      height: 20px;
      object-fit: contain;
    }

    .applications__icon {
      background-color: #f5f1fa;
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }

  .main_navigation_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .active_indicator {
    border-radius: 29px;
    width: 19px;
    height: 3px;
    position: absolute;
    top: 18px;
  }
}

/* 992px Tap View */

@media (max-width: 992px) {
  .header_wrapper {
    .main_navigation_wrapper {
      display: none;
    }

    .search__autocomplete__header {
      display: none;
    }

    .header_container {
      padding-right: 40px;

      .navigation_icons_wrapper {
        img {
          display: none;
        }

        .web_view {
          display: none;
        }
      }

      .Hamber__icon {
        display: block;
      }
    }

    .header_container_arab {
      padding-right: 20px !important;
      padding-left: 40px !important;
    }

    .main_navigation_wrapper__mobileView {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 14px;

      .mobile_view {
        display: block;
      }
    }
  }

  .search__autocomplete__header__mobile {
    width: 100% !important;
    max-width: 300px !important;
    border: none;
    margin-left: 0px !important;
    position: relative;

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 1px !important;
      border-radius: 15px !important;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
      padding: 7.5px 8px 7.5px 0px !important;
    }

    .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      font-size: 10px !important;
    }

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
      font-size: 10px !important;
    }

    .location-dropdown-wrap {
      margin-bottom: 10px;

      .css-b62m3t-container {
        font-size: 12px !important;
      }

      .my-country-dropdown-two {
        width: initial !important;
        font-size: 12px !important;
      }
    }
  }

  .header_wrapper__blur {
    .main_navigation_wrapper {
      display: none;
    }

    .search__autocomplete__header {
      display: none;
    }

    .header_container {
      padding-right: 40px;

      .navigation_icons_wrapper {
        img {
          display: none;
        }
      }

      .Hamber__icon {
        display: block;
      }
    }

    .header_container_arab {
      padding-right: initial !important;
      padding-left: 40px !important;
    }

    .main_navigation_wrapper__mobileView {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 14px;
    }
  }
}

@media (min-width: 1420px) {
  .header_wrapper {
    .header_container {
      padding: 10px 20px;
    }

    .main_navigation {
      font-size: 16px !important;
    }

    .navigation_icons_wrapper {
      gap: 25px;
    }

    .main_navigation_wrapper {
      gap: 35px;
    }

    .search__autocomplete__header {
      margin-left: 150px;
    }

    .main_navigation__inactive {
      font-size: 16px !important;
    }

    .active_indicator {
      top: 39px;
    }

  }

  .header_wrapper__blur {
    .header_container {
      padding: 10px 20px;
    }

    .main_navigation {
      font-size: 16px !important;
    }

    .navigation_icons_wrapper {
      gap: 25px;
    }

    .main_navigation_wrapper {
      gap: 35px;
    }

    .search__autocomplete__header {
      margin-left: 150px;
    }

    .main_navigation__inactive {
      font-size: 16px !important;
    }

    .active_indicator {
      top: 39px;
    }
  }
}

/*For Region DropDown*/
@media screen and (max-width:700px) {
  .search__autocomplete__header__mobile
   .location-dropdown-wrap
    .my-country-dropdown-two{
      height: 30px !important;
    }
}