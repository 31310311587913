.TutorHubChat__container {
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    // background: #F4F3F9;
    // padding: 14px;
    //
    background-color: #f9f9f9;
    border-radius: 10px;
    // height: calc(100vh - 160px);
    // height: calc(100vh - 345px);
    height: calc(100vh - 404px);
    // padding: 20px;
    padding: 10px;
    position: relative;

    .Header__wapper {
        border-radius: 10px 10px 0px 0px;
        background: #F4F3F9;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        
        // background-color: #f9f9f9;
        // border-radius: 10px;
        // height: calc(100vh - 160px);
        // padding: 20px;
        // position: relative;

        img {
            width: 22px;
            height: 22px;
            object-fit: contain;
        }

        .title {
            color: #2B2451;
            font-family: Manrope600;
            font-size: 20px;
        }
    }

    .chat__container {
        // display: flex;
        // flex-direction: column;
        // max-height: 400px;
        // min-height: 200px;
        // height: calc(100vh - 380px);
        // overflow: auto;
        // overflow-y: scroll;
        // padding-bottom: 26px;
        
        //

        height: 90%;
        margin-bottom: 10px;
        overflow: hidden;
        overflow-y: auto;
    }

    /* width */
    .chat__container::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .chat__start {
        justify-content: flex-start;
        text-align: -webkit-left !important;
        margin: 5px 0;

        .time {
            color: #a4a3a9;
            font-family: Inter400;
            font-size: 10px;
        }
    }

    .chat__center {
        justify-content: center !important;
        text-align: -webkit-center !important;
        margin: 5px 0;
    }

    .chat__end {
        justify-content: flex-end;
        text-align: -webkit-right !important;
        margin: 5px 0;

        .time {
            color: #a4a3a9;
            font-family: Inter400;
            font-size: 10px;
        }
    }

        /*Change thhe message position*/
        .user_chat_arab{
            border-radius: 20px 20px 20px 0px !important;
        }
    
        .chat_end_arab{
        text-align: -webkit-left !important;
        }

    .chat-image {
        max-height: 160px;
        max-width: 130px;
        object-fit: contain;
        cursor: pointer;
    }

    .chat-document {
        height: 60px;
        width: 70px;
        object-fit: contain;
        cursor: pointer;
    }

    .show__time {
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.50);
        width: 120.089px;
        height: 24px;
        color: rgba(51, 51, 51, 0.72);
        font-family: Inter500;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .user__chat {
        display: flex;
        max-width: 326px;
        width: fit-content;
        padding: 10px 22px;
        align-items: center;
        word-break: break-all !important;
        text-align: justify;
        gap: 10px;
        border-radius: 20px 20px 0px 20px;
        background: #744FFF;
        box-shadow: 3px 7px 26px 0px rgba(173, 211, 255, 0.27);
        color: #FFF;
        font-family: Inter500;
        font-size: 12px;
    }

    .Pdf__container {
        border-radius: 7px;
        background: #fff;
        padding: 10px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        max-width: 340px;

        .left__contant {
            display: flex;
            align-items: center;
            gap: 10px;

            .Black__text {
                color: #333;
                font-family: Inter600;
                font-size: 14px;
                text-align: start;
                text-wrap: wrap;
                max-width: 200px;
                word-wrap: break-word;
            }

            .name__text {
                color: #8d8d8d;
                font-family: Inter500;
                font-size: 14px;
                text-transform: uppercase;
                text-align: start;
            }
        }

        .DownloadImg {
            width: 30px;
            height: 30.085px;
            object-fit: contain;
            object-position: center;
            cursor: pointer;
        }
    }

    .tutor__chat {
        display: flex;
        max-width: 337px;
        width: fit-content;
        padding: 10px 22px;
        text-align: justify;
        align-items: center;
        word-break: break-all !important;
        gap: 10px;
        border-radius: 20px 20px 20px 0px;
        background: #FFF;
        box-shadow: 3px 7px 26px 0px rgba(173, 211, 255, 0.27);
        color: #545454;
        font-family: Inter500;
        font-size: 12px;
    }

    .Activity__chat {
        display: flex;
        max-width: 337px;
        width: fit-content;
        padding: 10px 22px;
        text-align: justify;
        align-items: center;
        word-break: break-all !important;
        gap: 10px;
        border-radius: 20px 20px 20px 0px;
        background: rgba(255, 242, 227, 1);
        box-shadow: 3px 7px 26px 0px rgba(173, 211, 255, 0.27);
        color: #545454;
        font-family: Inter500;
        font-size: 12px;
    }

    .type__box__container {
        // // position: fixed;
        // position: absolute;
        // bottom: 0px;
        // // bottom: 61px;
        // // width: 67.3%;
        // // width: calc(70vw - 20px);
        // width: calc(71vw - 20px);
        // margin-left: -14px;
        // background-color: #F4F3F9;
        // // padding: 6px 0px;
        // padding: 6px 0px 10px 0px;

        //
        // bottom: 5px;
        bottom: 15px;
    left: 20px;
    // position: absolute;
    right: 20px;

        .file_input {
            display: none !important;
        }
        .type_box_container_wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            //
            // left: 20px;
            // right: 20px;
        }

        .type__box__input {
            width: 100%;
            // min-height: 50px;
            border-radius: 52px;
            border: none;
            outline: none;
            padding-top: 15px;
            padding-bottom: 0px;
            padding-left: 22px;
            padding-right: 79px !important;
            background: #FFF;
            box-shadow: 0px 0px 18px 0px #D8D3F3;
            margin-top: 10px;
            color: #545454;
            font-family: Inter500;
            font-size: 12px;
            resize: none;
            width: 97%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .TypeBoxInput_arab{
            padding-right: 20px !important;
        }

        .type__box__input::placeholder {
            color: #808080;
            font-family: Manrope500;
            font-size: 12px;
        }

        .AttachFileIcon {
            position: absolute;
            // right: 45px;
            // bottom: 19px;
            // right: 86px;
            right: 75px;
            // bottom: -64px;
            cursor: pointer;
            rotate: 30deg;
        }
        .AttachFileIcon_arab{
            right: initial !important;
            left: 65px !important;
            transform: scaleX(-1);
        }

        .sendIcon {
            position: absolute;
            // right: 24px;
            // bottom: 20.5px;
            right: 35px;
            cursor: pointer;
        }

        .sendIcon__disable {
            position: absolute;
            // right: 10px;
            // bottom: 15px;
            // right: 44px;
            right: 35px;
            // bottom: -67px;
            opacity: 0.5;
            cursor: default !important;
        }
        .sendIcon_disable_arab{
            right: initial !important;
            left: 24px !important;
            rotate: 180deg;
        }
    }
    // .type__box__container_arab{
    //     right: 282px;
    // }
    .type__box__container_arab{
        margin-left: initial  !important;
        margin-right: -14px;
    }
    @media screen and (max-width:882px) {
        .type__box__container_arab{
        width: calc(67.7vw - 20px);
    }
}
}
