.background_circles_container {
  position: relative;
  height: 100%;
}

.background_circles_wrapper {
  position: absolute;
  overflow: hidden;
  min-height: calc(100vh - 64px);
  height: 100%;
  width: 100%;
  background-color: transparent;
  // background: #FFFBF6;
  z-index: -1;
}

.background_circle_left__profile {
  border-radius: 527px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(116, 79, 255, 0.18) 0%,
      rgba(116, 79, 255, 0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -50%;
}

.background_circle_right__profile {
  border-radius: 971px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(116, 79, 255, 0.18) 0%,
      rgba(116, 79, 255, 0) 100%);
  min-height: calc(100vh - 64px);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: -50%;
}

.background_circle_left__tutor {
  border-radius: 527px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(23, 143, 255, 0.21) 0%,
      rgba(208, 220, 255, 0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -50%;
}

.background_circle_right__tutor {
  border-radius: 971px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(23, 143, 255, 0.21) 0%,
      rgba(208, 220, 255, 0) 100%);
  min-height: calc(100vh - 64px);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: -50%;
}

// .background_circle_left__Activities {
//   border-radius: 527px;
//   background: radial-gradient(50% 50% at 50% 50%,
//       rgba(255, 249, 237, 1) 0%,
//       rgba(252, 193, 106, 0) 100%);
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: -50%;
// }

// .background_circle_right__Activities {
//   border-radius: 971px;
//   background: radial-gradient(50% 50% at 50% 50%,
//       rgba(255, 249, 237, 1) 0%,
//       rgba(252, 193, 106, 0) 100%);
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   top: 0;
//   right: -50%;
// }

.background_circle_left__Activities {
  content: "";
  position: absolute;
  top: 0;
  right: 75%;
  bottom: 50%;
  left: 0%;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.5;
  background: radial-gradient(circle, #FCC16A 0, #FFC812 19%);
  filter: blur(250px);
}

.background_circle_right__Activities {
  content: "";
  position: absolute;
  top: 25%;
  right: 0%;
  bottom: 25%;
  left: 80%;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.5;
  background: radial-gradient(circle, #FFC812 0, #FFC812 19%);
  filter: blur(200px);
}

.background_circle_left__University {
  border-radius: 527px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(18, 204, 146, 0.17) 0%,
      rgba(252, 193, 106, 0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -50%;
}

.background_circle_right__University {
  border-radius: 971px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(18, 204, 146, 0.17) 0%,
      rgba(252, 193, 106, 0) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: -50%;
}

.background_circle_left__School {
  border-radius: 527px;
  border-radius: 529px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(255, 207, 48, 0.21) 0%,
      rgba(208, 220, 255, 0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -50%;
}

.background_circle_right__School {
  border-radius: 971px;
  border-radius: 529px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(255, 207, 48, 0.21) 0%,
      rgba(208, 220, 255, 0) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: -50%;
}

.background_circle_left__themePark {
  border-radius: 527px;
  border-radius: 547px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(4, 214, 251, 0.06) 0%,
      rgba(4, 214, 251, 0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -50%;
}

.background_circle_right__themePark {
  border-radius: 971px;
  border-radius: 529px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(4, 214, 251, 0.06) 0%,
      rgba(4, 214, 251, 0) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: -50%;
}

.sidebar_outlet_container {
  padding-top: 64px;
  display: flex;
  z-index: 700;
  // align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  min-height: calc(100vh - 64px);
  height: 100%;
  position: relative;
  gap: 16px;
  overflow: hidden;
  overflow-x: hidden;
}

.sidebar_outlet_container_english{
  margin-left: 12px;
}
.sidebar_outlet_container_arabic{
  margin-left: initial;
  margin-right: 12px;
}

.main_outlet_container {
  margin-left: 70px;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.main_outlet_container_arab{
  margin-left: initial !important;
  overflow-y: auto;
  overflow-x: hidden !important;
  margin-right: 70px !important;
}

//mobileView app download
@media only screen and (max-width: 990px) {
  .sidebar_outlet_container {
    margin-left: initial;
  }
  .sidebar_outlet_container_arabic{
    margin-left: initial;
    margin-right: initial !important;
  }
  .main_outlet_container_arab{
    margin-right: initial !important;
    padding: 0px 10px 0px 10px !important;
  }
}

@media (max-width: 992px) {
  .main_outlet_container {
    padding: 0px 10px 0px 10px !important;
    margin-left: 0px;
    margin-right: 0px !important;
    .homePage__footer__container{
        width: 100vw !important;
        margin-left: -10px !important;
        overflow-x: hidden;
    }
  }

  .sidebar_outlet_container.full-width .main_outlet_container {
    padding: 0px !important;
  }

  .sidebar_outlet__visble {
    display: none !important;
  }
}

//mobileView app download
@media (max-width: 576px) {
  // .main_outlet_container {
  //   margin-left: 0px !important;
  //   margin: 8px !important;
  // }

  .sidebar_outlet_container {
    margin-left: 0px;
  }

  .sidebar_outlet__visble {
    display: none !important;
  }
}

/*🤖Below responsive for  1920×1080 & 1680x1050 & 1600×900 
  mainly for height🤖   
*/

@media screen and (min-height:860px) {
  .main_outlet_container{
       height: 100vh !important;
       /**Activity**/
       .activities__container{
        height: 88.8vh;
        .body_scroll_container{
          max-height: 75vh !important;
        }
        .Activities__Detaile__view {
          height: 86.9vh;
        }

       }
       /**Tutor**/
       .tutor__container{
          height: 92.9vh !important;
          .scroll__container{
            height: 60vh !important;
          }
       }
       .Tutor__Detaile__view{
        height: 90.5vh;
       }
       .TutorsMapsView__container{
        height: 92.9vh !important;
       }
       .Tutors_Calender_View_respon{ 
        height: 86.7vh !important;
       }
       /**University&university**/
       .University__dashboard__container{
        height: 92.9vh !important;
       }
       .University__Detaile__view{
             height: 88.4vh !important;
       }
       .overall-chat-university-container .university-chat-wrap{
        height: 93.6vh;
       }
       .University__dashboard__container .University_map_view{
        height: 78vh !important;
       }
  }
}