
.Addaddress-container {
  // background-color: aqua;
  width: 100%;
  margin-bottom: 2rem;
  padding: 10px;
  .address_container{
  
    input {
      border-radius: 12px;
      background: #f4f3f8;
      height: 40px;
      color: #888;
      font-family: Inter400;
      font-size: 14px;
      border: unset !important;
      padding: 12px 18px;
      box-sizing: border-box;
      width: 100%;
  
      &::placeholder {
        color: #888;
        font-family: Inter400;
        font-size: 14px;
      }
  
      &:focus {
        outline: unset !important;
      }
    }
    
  
}
.submit_container{
  display: flex;
  justify-content: center;
  button{
  min-width: 200px !important;
  }
}
  .row {
    .row {
        word-wrap: break-word;
        white-space: normal;
        font-size: 14px;
      font-weight: 500;
      .card_container {
        background: #fff;
        box-shadow: 0px 4px 5px 0px rgba(210, 210, 210, 0.25);
        padding: 10px;
        border-radius: 10px;
        min-height: 100px;
        // margin: auto;
       .Delete-btn{
        background-color: transparent;
        .red_icon{
          fill: red;
         }
       }
       
      }
      .selected_btn_container {
        // display: flex;
        // justify-content: center;
        // margin: auto;
      }
      .Selected-btn {
        background-color: #744fff;
        font-size: 14px;
        color: white;
        border-radius: 20px;
        padding: 2px 5px 2px 5px;
        font-weight: 500;
      }
      .not-activated {
        background-color: white;
        font-size: 14px;
        border: 1px solid #744fff !important;
        padding: 2px 5px 2px 5px;
        border-radius: 20px;
        font-weight: 500;
        color: #744fff;
      }
    }
  }
}
