.sidebar_wrapper_Service,.sidebar_wrapper_Service_lang {
  border-radius: 41.5px;
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 7px 4px 26px 0px rgba(144, 144, 144, 0.25);
  -webkit-backdrop-filter: blur(8.5px);
  backdrop-filter: blur(8.5px);
  display: flex;
  flex-direction: column;
  align-items: center !important;
  padding: 16px 20px;
  height: 350px;
  box-sizing: border-box;
  position: fixed;
  // left: 13px;
  top: 30%;

  .sidebar_nav_container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .active_indicator {
    position: absolute;
    left: -20px;
    width: 4px;
    top: 27px;
    height: 46%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .tutor_indicator {
    background: #178FFF;
  }

  .themePark_indicator {
    background: rgba(4, 214, 251, 1);
  }

  .activity_indicator {
    background: #F0962C;
  }

  .university_indicator {
    background: #12CC92;
  }

  .school_indicator {
    background: #FFC812;
  }

  .sidebar_logo_container {
    background-color: transparent;
    padding: 20px 0px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .tutor_active {
    background-color: rgba(23, 143, 255, 0.12);
    border-radius: 50%;
    width: 35px;
    margin-top: 20px;
    height: 35px;
    margin-left: -5px;
    text-align: center;
    padding-top: 4px;
  }

  .themePark_active {
    background-color: rgba(4, 214, 251, 0.12);
    border-radius: 50%;
    width: 35px;
    margin-top: 20px;
    height: 35px;
    margin-left: -5px;
    text-align: center;
    padding-top: 4px;
  }

  .activities_active {
    background-color: rgba(255, 174, 79, 0.12);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-top: 20px;
    margin-left: -5px;
    text-align: center;
    padding-top: 4px;
  }

  .university_active {
    background-color: rgba(18, 204, 146, 0.12);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-top: 20px;
    margin-left: -5px;
    text-align: center;
    padding-top: 4px;
  }

  .school_active {
    background-color: rgba(255, 200, 18, 0.12);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-top: 20px;
    margin-left: -5px;
    text-align: center;
    padding-top: 4px;
  }
}

//**For Arabic Strytle**//
.sidebar_wrapper_Service_english{
  left: 13px;
}
.sidebar_wrapper_Service_arabic{
  left: initial;
  right: 13px;
}

.sidebar_outlet_container.full-width {
  gap: 0px ;
  margin-left: 0px;
  padding-top: 0px;
  .sidebar_wrapper_Service{
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
  }
  .main_outlet_container{
    margin-left: 0px;
  }
}