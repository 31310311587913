.otp-verify-modal-container{
.otp_verification_wrapper {
  display: flex;
  flex-direction: column;
}

.otp_verificatin_title {
  color: #333;
  font-family: Manrope700;
  font-size: 28px;
  margin-bottom: 8px;
  margin-top: 10px;
}

.otp_sent_to_wrapper {
  color: rgba(51, 51, 51, 0.69);
  font-family: Inter500;
  font-size: 14px;
  margin-bottom: 52px;
}

.email_phone {
  color: #744fff;
  font-family: Inter500;
  font-size: 14px;
}

.resend_timer {
  color: #744fff;
  font-family: Inter500;
  font-size: 14px;
  align-self: center;
}

.verfiy_submit_button__wapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verfiy_submit_button {
  color: #fff;
  font-family: Manrope600;
  font-size: 14px;
  border-radius: 50px;
  background: #744fff;
  // padding: 18px 72px;
  margin-top: 36px;
  margin-bottom: 20px;
  width: 245px;
  height: 50px;
}

.resend_otp_button {
  color: #1f2027;
  font-family: Inter400;
  font-size: 14px;
  align-self: center;
}

.resend_otp {
  color: #744fff;
  font-family: Inter600;
  font-size: 14px;
  cursor: pointer;
}

.otp_verification_wrapper input {
  height: 67px !important;
  width: 50px !important;
  font-size: 28px !important;
  font-family: "Manrope600" !important;
  color: #333 !important;
  border-radius: 14px !important;
  border: 2px solid #e7e7e7 !important;

  &:focus {
    border: 2px solid #744fff !important;
    outline: unset !important;
  }
}

.otp_container {
  justify-content: center;
  gap: 5px;
  margin-bottom: 20px;
}

.errorMessageOtp {
  color: rgba(255, 0, 0, 0.8196078431);
  font-size: 14px;
  font-family: "Manrope600";
  margin-bottom: 12px;
}
}


@media screen and (max-width:636px) {
      .otp-verify-modal-container{
        .LoginCarousel__img{
          max-height: 200px !important;
        }
        .skip_button {
          border: 1px solid cornsilk;
          border-radius: 10px;
          padding: 0px 10px;
          top: 8px;
        }
        .otp_verificatin_title,
        .otp_verification_wrapper input  {
          font-size: 25px !important;
        }
        .otp_sent_to_wrapper,
        .email_phone,
        .resend_timer,
        .verfiy_submit_button,
        .resend_otp_button,
        .resend_otp,
        .errorMessageOtp {
          font-size: 11px !important;
        }
        //
        .otp_sent_to_wrapper{
          margin-bottom: 30px !important;
        }
        .otp_container {
          margin-bottom: 10px !important;
        }
        .verfiy_submit_button {
          margin-top: 20px !important;
        }
      }
}