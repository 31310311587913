.Tutor_hup_container_wraper{
.Tutor_hup__container {
  padding: 13px;
  width: 100%;
  margin-top: 1rem;
  background: #fff;
  backdrop-filter: blur(6px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header__section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .left__contant {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .left__contant_two{
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .tutor__active {
      color: #fff;
      font-family: Manrope700;
      font-size: 16px;
      background-color: #178fff;
      padding: 10px;
      border-radius: 12px;
      // text-wrap: nowrap;
    }

    .Activity__active {
      color: #fff;
      font-family: Manrope700;
      font-size: 16px;
      background-color: #ffae4f;
      padding: 10px;
      border-radius: 12px;
    }

    .title {
      border-radius: 12px;
      border: 1px solid #e0daf1;
      background: rgba(224, 222, 232, 0.34);
      color: #a6a0c2;
      font-family: Manrope600;
      font-size: 16px;
      padding: 10px;
      cursor: pointer;
      // text-wrap: nowrap;
    }

    .ParentTag {
      position: relative;
      display: flex;
      align-items: center !important;
    }
    .css-lh3xye-MuiFormControl-root{
      position: relative !important;
      text-align: end;
      .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
        position: absolute !important;
        left: 110px !important;
      }
    }

    .searchInput {
      width: 100% !important;
      max-width: 250px !important;
      min-width: 195px;
      border-radius: 10px;
      border: none;
      outline: none;
      padding: 15px 17px;
      // padding-right: 32px !important;
      font-size: 14px;
      font-family: Inter400;
      background-color:rgba(224, 222, 232, 0.34);
    }
    .searchInput-eng{
     padding-right: 32px !important;
    }
    .searchInput-arab{
      padding-right: 10px !important;
    }

    .searchInput::placeholder {
      color: #000;
      font-family: Inter400;
      font-size: 14px;
    }

    .searchIcon {
      position: absolute;
      right: 12px;
      top: 16px;
    }
    .searchIcon-arab{
      right: initial !important;
      left: 12px !important;
    }
  }

  /* width */
  .tutor_hub__card__container::-webkit-scrollbar {
    width: 0px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .tutor_hub__card__container {
    border-radius: 10px;
    background: #f4f3f9;
    margin-top: 1rem;
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    max-height: 550px;
    overflow-x: hidden;

    .is_today {
      border-radius: 29px;
      border: 1px solid #e0daf1;
      background: rgba(224, 222, 232, 0.34);
      color: #a6a0c2;
      font-family: Manrope600;
      font-size: 16px;
      padding: 10px 20px;
      cursor: pointer;
      width: fit-content !important;
      margin: 10px;
    }

    .is_today__tutor__active {
      color: #fff;
      font-family: Manrope700;
      font-size: 16px;
      background-color: #178fff;
      padding: 10px 20px;
      border-radius: 29px;
      width: fit-content !important;
      cursor: pointer;
      margin: 10px;
    }

    .is_today__Activity__active {
      color: #fff;
      font-family: Manrope700;
      font-size: 16px;
      background-color: #ffae4f;
      padding: 10px 20px;
      border-radius: 29px;
      cursor: pointer;
      margin: 10px;
      width: fit-content !important;
    }

    .card_wapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 9px;
      background: #fff;
      padding: 17px;
      margin: 10px 0;
      cursor: pointer;

      .Profile_contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .profile__Img {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          object-fit: cover;
        }

        .right_options {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;

          .message__wapeer {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 34.622px;
            background: #f1ecff;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            position: relative;
            cursor: pointer;

            img {
              width: 15.002px;
              height: 15.777px;
              object-fit: contain;
            }

            .badeg__pos {
              position: absolute;
              right: 3px;
              top: 2px;
            }
          }

          .video_call__wapeer {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 34.622px;
            background: #f1ecff;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            position: relative;
            cursor: pointer;

            img {
              width: 19.002px;
              height: 19.777px;
              object-fit: contain;
            }

            .badeg__pos {
              position: absolute;
              right: 3px;
              top: 2px;
            }
          }
        }
      }

      .tutor__contant {
        display: flex;
        align-items: center;
        gap: 10px;

        .Black__text {
          color: #000;
          font-family: Inter500;
          font-size: 16px;
        }

        .Profile__name__text {
          color: #000;
          font-family: Manrope700;
          font-size: 14px;
        }

        .name__text {
          color: #656565;
          font-family: Inter400;
          font-size: 12px;
        }
      }

      .tutor__img__wapper {
        width: 38px;
        height: 100%;
        min-height: 52px;
        border-radius: 5px;
        background: rgba(209, 233, 255, 0.61);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 23px;
          min-width: 20px;
          height: 17px;
          object-fit: contain;
        }
      }

      .activity__img__wapper {
        width: 38px;
        height: 100%;
        min-height: 52px;
        border-radius: 5px;
        background: rgba(255, 236, 214, 1);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 23px;
          height: 17px;
          object-fit: contain;
        }
      }

      .row__contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 7px 0;
      }

      .left__light__text {
        color: #8a8a8a;
        font-family: Inter400;
        font-size: 14px;
      }

      .right__black__text {
        color: #333;
        font-family: Inter500;
        font-size: 14px;
      }

      .inprogressBtn {
        width: 116px;
        height: 40px;
        border-radius: 8px;
        background: #f9ce5f;
        color: #000;
        font-family: Manrope700;
        font-size: 14px;
      }

      .CompletedBtn {
        width: 116px;
        height: 40px;
        border-radius: 8px;
        background: #12cc92;
        color: #fff;
        font-family: Manrope700;
        font-size: 14px;
      }

      .child_profile_img {
        width: 18.698px;
        height: 18.698px;
        border-radius: 50%;
        object-fit: cover;
      }

      .createdBy {
        color: #000;
        font-family: Manrope700;
        font-size: 11px;
      }
      .me-arab{
        margin-right: .5rem !important;
        margin-left: initial !important;
    }
    }
  }
}
}
.badgeIcon {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #ff4f4f;
}

@media (min-width: 1420px) {
  .Tutor_hup__container {
    .tutor_hub__card__container {
      max-height: 70vh !important;
    }
  }
}


  @media screen and (max-width: 700px) {
  .Tutor_hup_container_wraper {
    .css-1vzbb9p-MuiContainer-root {
      padding-left: 10px !important;
      padding-right: 0px !important;
    }

    .Tutor_hup__container {
      padding: 17px 7px 7px 7px !important;
      border-radius: 10px !important;

      .tutor_hub__card__container {
        .is_today {
          font-size: 81.25% !important;
          padding: 5px 20px !important;
        }

        .card_wapper {
          .tutor__contant {
            .Profile__name__text {
              font-size: 81.25% !important;
            }

            .name__text {
              font-size: 56.25% !important;
            }

            .Black__text{
              font-size: 81.25% !important;
              width: 60%;
              text-wrap: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
      
          }
         .left__light__text{
          font-size: 68.75% !important;
         }
         .right__black__text{
          font-size: 68.75% !important;
          margin-left: 20px;
          text-align: right;
         }
         .RightBlackTextLang_Arab{
          margin-left: initial !important;
          margin-right: 20px !important;
          text-align: left !important;
         }
         .inprogressBtn{
          width: 80px !important;
          height: 35px !important;
          font-size: 68.75% !important;
         }
         .user-text-icon{
          margin-left: 30px !important;
          .createdBy{
            font-size: 9px !important;
          }
         }
         .CompletedBtn{
          width: 80px !important;
          height: 35px !important;
          font-size: 68.75% !important;
         }
        }
      }
    }
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
      font-size: 7px !important;
    }
  }
  .menu_itemses{
    font-size: 12px !important;
    font-family: Inter400 !important;
    min-height: 33px !important;
  }
}


///*******Below for header style *********//

@media screen and (max-width: 1086px) {
  .header__section {
  position: relative !important;
  margin-bottom: 77px !important;
  .ParentTag {
    position: absolute !important;
    top: 67px;
    left: 2px;
  }
  .ParentTagLang_Arab{
    left: initial !important;
    right: 2px !important;
  }
}
}

@media screen and (max-width: 950px) {
  .Tutor_hup_container_wraper {
    .Tutor_hup__container {
  .header__section {
    .Activity__active{
      font-size: 81.25% !important;
      padding: 10px !important;
    }
    .tutor__active,
    .title {
      font-size: 81.25% !important;
      padding: 10px !important;
    }
  }
}
  }
}


@media screen and (max-width: 662px) {
  .header__section {
    // margin-bottom: 54px !important;
    margin-bottom: 77px !important;
     .left__contant_two{
      .two_form_control{
       margin-top: 0px !important;
       .form_control_select{
        font-size: 75% !important;
       }

      }
      .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
        padding: 0px 2px !important;
      }
     }
     .ParentTag {
      position: absolute !important;
      top: 65px;
      left: 2px;
    }
    .ParentTagLang_Arab{
      left: initial !important;
      right: 2px !important;
    }
  }
}

@media screen and (max-width: 517px) {
  .Tutor_hup_container_wraper {
    .Tutor_hup__container {
  .header__section {
    display: initial !important;
    margin-bottom: 78px !important;
    .left__contant_two{
      .two_form_control{
        // border: 2px solid green !important;
      //  margin-top: 7px !important;
       margin-top: 14px !important;
       margin-left: 6px;
      }
      .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        padding: 0px  !important;
        padding-right: 32px !important;
      }
    }
    .Activity__active{
      font-size: 68.75% !important;
    }
    .tutor__active,
    .title {
      font-size: 68.75% !important;
    }
    .ParentTag {
      position: absolute !important;
      top: 80px;
      left: 2px;
    }
    .ParentTagLang_Arab{
      left: initial !important;
      right: 2px !important;
    }
  }
}
  }
}



