.sticky_wrapper {
  position: fixed;
  width: calc(100% - 70px);
  top: 80px;
  z-index: 1000;


  .TutorCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #f9f9fb;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    width: 131px;
    height: 60px;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 12px;
    margin-bottom: 15px;

    img {
      width: 23.122px;
      height: 19.818px;
    }

    .TutorImg__text {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }
  }


  .search_field_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    max-width: 582px;
  }

  .search_field:focus {
    outline: none;
  }

  .search_field_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .search__dropdown {
    border-radius: 15px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 46px;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 13px !important;
    padding-bottom: 11px !important;
    padding-right: 32px;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input .search_field {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    font-size: 14px;
    width: 100%;
    height: 46px;
    padding: 14px 15px;
  }

  .search_field::placeholder {
    color: #adb5be;
    font-size: 14px;
    font-family: Inter500;
  }

  .search_button {
    padding: 16px 12px;
    cursor: pointer;
  }

  .search_button_icon_wrapper {
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 48px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .search__autocomplete {
    width: 100% !important;
    max-width: 482px !important;
    border-radius: 15px;
    height: 46px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);
    border: none;
    position: relative;

    input::placeholder {
      // color: #ADB5BE;
      font-size: 14px;
      font-family: Inter500;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 5px !important;
      border-radius: 15px !important;
    }


    .search_button {
      // padding: 8px 12px;
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 0;
    }

    .search_button_arabic {
      cursor: pointer;
      position: absolute;
      right: initial;
      left: 14px;
      top: 16px;
    }
  }
}

.tutor__container {
  margin-top: 1rem;
  // width: 97%;
  // height: 100%;
  width: 96vw;
  overflow: auto;
  overflow-x: hidden;
  font-family: Manrope600 !important;
  position: relative;

  .header__position__stricky {
    position: sticky;
  }

  .tutors-head-row {
    align-items: end;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1%;

    .tutors-search-part {
      width: 24%;
    }

    .tutors-filter-wrap {
      // padding-right: 1.6rem;
      width: 74%;

      .filter_container {
        .filter_item {
          text-wrap: nowrap;

          .item_button {
            gap: 7px;
          }
        }
      }
    }
  }

  .tutors-filter-wrap-english {
    padding-right: 1.6rem;
  }

  .tutors-filter-wrap_arabic {
    padding-right: initial;
    padding-left: 1.6rem;
  }

  .header__position__fixed {
    position: fixed;
    z-index: 10000;
  }

  .scroll__container {
    // max-height: 52vh;
    // max-height: 45vh;
    // overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  /* width */
  .scroll__container::-webkit-scrollbar {
    width: 0px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .TutorCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #f9f9fb;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    width: 131px;
    height: 60px;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 12px;
    position: absolute;
    top: 2px;
    // left: 0px;

    img {
      width: 23.122px;
      height: 19.818px;
    }

    .TutorImg__text {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }
  }

  //**Togle Style for Languages**//
  .TutorCard_Img_wapper-english {
    left: 0px;
  }

  .TutorCard_Img_wapper_arabic {
    left: initial;
    right: 12px;
  }

  .search_field_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    max-width: 582px;
  }

  .search_field:focus {
    outline: none;
  }

  .search_field_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 4.5rem !important;
  }

  .search__dropdown {
    border-radius: 15px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 46px;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 13px !important;
    padding-bottom: 11px !important;
    padding-right: 32px;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input .search_field {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    font-size: 14px;
    width: 100%;
    height: 46px;
    padding: 14px 15px;
  }

  .search_field::placeholder {
    color: #adb5be;
    font-size: 14px;
    font-family: Inter500;
  }

  .search_button {
    padding: 16px 12px;
    cursor: pointer;
  }

  .search_button_icon_wrapper {
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 48px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .search__autocomplete {
    width: 100% !important;
    max-width: 482px !important;
    border-radius: 15px;
    height: 46px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);
    border: none;
    position: relative;

    input::placeholder {
      // color: #ADB5BE;
      font-size: 14px;
      font-family: Inter500;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 5px !important;
      border-radius: 15px !important;
    }

    .search_button {
      // padding: 8px 12px;
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 0;
    }

    .search_button_arabic {
      cursor: pointer;
      position: absolute;
      right: initial;
      left: 14px;
      top: 16px;
    }
  }

  .TutorCard_Filter_wapper {
    width: 100px;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    gap: 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);

    .FilterIcon {
      width: 13px;
      height: 13.949px;
      color: #333333;
      object-fit: contain;
    }

    .Filter__text {
      color: #333;
      font-size: 16px;
      font-family: Inter500;
    }
  }

  .TutorSubject__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    // margin-top: 1.5rem;

    .Show__more {
      color: #116bbf;
      font-size: 12.24px;
      font-family: Manrope600;
      line-height: 98.1%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .TutorSubject_Img_wapper {
    border-radius: 17px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(216, 216, 216, 0.25);
    width: auto;
    padding: 10px 15px;
    cursor: pointer;
    height: 50px;

    img {
      width: 32.16px;
      height: 32.002px;
    }

    span {
      color: #333;
      font-size: 12px;
      font-family: Manrope600;
    }

    .TutorSubject_Img_wapper_span_english {
      margin-left: 5px;
    }

    .TutorSubject_Img_wapper_span_arabic {
      margin-left: initial;
      margin-right: 5px;
    }
  }

  //////********Style For********//////////
  /////***********Scroll First Content ****//////
  .tutors-scroll-content-one {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1%;
    margin-bottom: 1%;
    // padding-right: 2% !important;

    /**  Text Content **/
    .Tutor__text__contant {
      min-width: 15.7%;
      width: calc(15.7% - 20px);
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 274px;
      // margin-right: 1%;
      // padding-right: 1.5%;

      h1 {
        // font-size: 190%;
        font-size: 38.49px;
        color: #178fff;
        font-family: Manrope800;
      }

      p {
        color: #000;
        // font-size: 110%;
        font-size: 16px;
        font-family: Inter400;
        margin-top: 10px;
        max-width: 200px;
        text-align: justify;
      }
    }

    .Tutor_text_contant_english {
      margin-right: 1%;
      padding-right: 1.5%;
    }

    .Tutor_text_contant_arabic {
      margin-right: initial;
      padding-right: initial;
      margin-left: 1%;
      padding-left: 1.5%;
    }

    /** Three Carts No:1**/
    .Middle__leftside_wapper {
      min-width: 15.7%;
      width: calc(15.7% - 20px);
      display: flex;
      flex-direction: column;
      gap: 5.5px;
      height: 274px;

      .Middle__leftside__img {
        border-radius: 10px;
        width: 100%;
        height: 134px;
      }
    }

    /** Three Carts No:2 **/
    .tutorImg1 {
      min-width: 15.7%;
      width: calc(15.7% - 20px);
      height: 274px;

      .Middle__rightside_img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }

    /**Three cart No:3 **/
    .tutorImg2 {
      min-width: 15.7%;
      width: calc(15.7% - 20px);
      height: 274px;

      .Middle__rightside_img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    /** Map View **/
    .TutorMaps__view {
      width: 30%;
      height: 277px;
      border-radius: 12px;
      background: #fff;
      // height: 300px;
      padding: 10px;
      position: relative;

      .tutor__viewMap {
        position: absolute;
        right: 18px;
        bottom: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.45);
        box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
        backdrop-filter: blur(2.5px);
        padding: 10px;
        cursor: pointer;

        span {
          color: #333;
          font-size: 12.24px;
          font-family: Manrope700;
        }
      }
    }

    //** For Language Based **//
    .TutorMaps__view_english {
      margin-left: 0.9%;
    }

    .TutorMaps__view_arabic {
      margin-left: initial;
      margin-right: 0.9%;
    }

    /**Common Style For Card Content, Image Style **/
    .tutorImg1,
    .tutorImg2,
    .tutorImg4,
    .tutorImg3 {
      position: relative;
      cursor: pointer;

      .discount__wapper {
        // max-width: 190px;
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        border-radius: 3.729px;
        background: rgba(40, 40, 40, 0.49);
        backdrop-filter: blur(4.351078987121582px);
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .discount__flex__column {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .discount__flex__row {
          display: flex;
          flex-direction: row;
          gap: 4px;
        }

        .free_badge {
          color: #339cfe;
          font-family: Manrope600;
          font-size: 62.5%;
          padding: 0px 5px !important;
          padding-bottom: 0 !important;
          border-radius: 8px;
          background: #fefeff;
          height: 15px;
        }

        .discount__text__contant {
          color: #fefeff;
          font-family: Manrope700;
          // font-size: 75%;
          font-size: 12px;
        }

        .discount__smalltext__contant {
          color: #fefeff;
          font-family: Manrope600;
          font-size: 62.5%;
        }

        .Discount_Symbole {
          width: 17.997px;
          height: 17.995px;
          object-fit: contain;
        }
      }
    }

  }


  //******** Below Style For Scroll Content Two *********//
  .tutors_scroll_content_two {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1%;
    width: 100%;
  }

  .tutors_scroll_content_two_english {
    margin-right: 2%;
  }

  .tutors_scroll_content_two_arabic {
    margin-right: initial;
    margin-left: 2%;
  }

  .top_tutors_container_scroll {
    width: 67%;
  }

  .top__tutors__container_list {
    width: 31%;
  }

  //**Style Based On The Language***//
  .top__tutors__container_list_english {
    margin-left: 0.5%;
  }

  .top__tutors__container_list_arabic {
    margin-left: initial;
    margin-right: 0.5%;
  }
}

.filter_Tutor_rightIcon {
  color: #b6b6b6 !important;
  font-size: 12px !important;
  height: 14px !important;
}

.filterActive_Tutor_rightIcon {
  color: rgba(23, 143, 255, 1) !important;
  font-size: 12px !important;
  height: 14px !important;
}

@media only screen and (max-width: 760px) {
  .Middle__leftside {
    display: flex !important;
    flex-direction: column !important;

    .Middle__leftside__img {
      border-radius: 10px;
      max-width: 200px;
      max-height: 200px;
    }
  }

  .Middle__rightside {
    margin-top: 15px;
  }
}

.Filter__course__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  span {
    text-align: left !important;
  }
}

.Filter__Price__container {
  display: flex;
  flex-direction: column;
  justify-content: center !important;

  .title {
    text-align: center;
    color: #333;
    font-size: 16px;
    font-family: Inter500;
  }

  .Price__range {
    display: flex;
    flex-direction: row;
    justify-content: space-around !important;

    .Price__text {
      color: #333;
      font-size: 14px;
      font-family: Inter500;
    }
  }

  .Rating__contant {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}

.filterActive {
  color: rgba(23, 143, 255, 1) !important;
}

// * Filter__drp   /*
.Filter__drp_top_wapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  margin: 7px;
  gap: 5px;

  .left__icon {
    background-color: #f2f0f9;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }

  .title {
    color: #333;
    font-size: 14px;
    font-family: Inter600;
  }
}

.Filter__drp__bottom_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 5px;

  .resetBtn {
    border-radius: 29px;
    border: 1px solid #178fff !important;
    background-color: #fbfbfb;
    color: #178fff;
    font-size: 12px;
    font-family: Inter400;
    width: 89px;
    height: 36px;
  }

  .applyBtn {
    border-radius: 29px;
    background: #178fff;
    width: 89px;
    height: 36px;
    color: #fdfeff;
    font-size: 12px;
    font-family: Inter500;
  }
}

// //mobileView app download
// @media (max-width: 436px) {
//   .tutor__container {
//     width: 100% !important;

//     .top__tutors__container {
//       // width: 100%;
//       margin-bottom: 1.5rem;
//     }
//   }
// }

// @media (min-width: 1420px) {
//   .tutor__container {
//     width: 98%;

//     .scroll__container {
//       max-height: 60vh !important;
//     }

//     .Middle__leftside .Middle__leftside_wapper .Middle__leftside__img {
//       max-width: 100% !important;
//     }

//     .Middle__leftside .Tutor__text__contant {
//       h1 {
//         font-size: 46px;
//       }

//       p {
//         color: #000;
//         font-size: 26px;
//         font-family: Inter400;
//         margin-top: 10px;
//         text-align: justify;
//         max-width: 80% !important;
//       }

//     }

//     .Middle__rightside .Middle__rightside_img {
//       max-width: 100% !important;
//     }

//     .discount__wapper {
//       max-width: 100% !important;
//     }
//   }
// }



///******** Media Query Scroll Content One ************///
@media screen and (max-width: 992px) {
  .tutors-scroll-content-one {
    align-items: center;
    justify-content: center;
  }

  .tutor__container {
    width: 100vw !important;
    align-items: center;
    justify-content: center;
  }

  .Tutor__text__contant,
  .Middle__leftside_wapper,
  .tutorImg1,
  .tutorImg2 {
    width: 23% !important;
  }

  .TutorMaps__view {
    width: 99% !important;
    margin-top: 3% !important;
    margin-right: 1%;
  }
}

@media screen and (max-width: 700px) {
  .tutor__container {
    .tutors-scroll-content-one {
      gap: 2% !important;
    }

    .Tutor__text__contant,
    .Middle__leftside_wapper {
      width: 45% !important;
      margin-bottom: 10px;
      height: 300px !important;
    }

    .tutorImg1,
    .tutorImg2 {
      width: 45.5% !important;
    }

    .Middle__leftside__img {
      height: 156px !important;
    }

    .TutorMaps__view {
      margin-left: 2.9% !important;
      margin-right: 2% !important;
      margin-bottom: 2% !important;
    }
  }
}

// *************Media Quer For Scroll Content Two************//
@media screen and (max-width: 992px) {
  .tutors_scroll_content_two {
    align-items: center;
    justify-content: center;


    .top_tutors_container_scroll {
      width: 98% !important;
      margin-top: 3%;
      margin-left: initial;
    }

    .top__tutors__container_list {
      margin-top: 3%;
      width: 99% !important;
      margin-right: 1%;
    }
  }
}

@media screen and (max-width: 700px) {
  .tutors_scroll_content_two {
    .top_tutors_container_scroll {
      width: 92.5% !important;
      margin: 3% 0% !important;
      margin-left: 3.5% !important;
      margin-right: 4% !important;
    }

    .top__tutors__container_list {
      width: 100% !important;
      margin-left: 1% !important;
      margin-right: 2% !important;
    }
  }
}

//**Responsive form mobile**//
// @media screen and (max-width:460px) {
@media screen and (max-width:576px) {
  .TutorCard_Filter_wapper .Filter__text {
    font-size: 14px !important;
  }

  //
  .TutorCard_Img_wapper {
    height: 50px !important;

    .TutorImg__text {
      font-size: 18px !important;
    }
  }

  //
  .search_field_wrapper {
    // margin-top: 4.5rem !important;
    margin-top: 0.1rem !important;
  }

  //
  .tutor__container {

    .tutorImg1,
    .tutorImg2 {
      width: 49% !important;

      .Middle__rightside_img {
        object-fit: initial !important;
      }
    }

    //
    .Tutor__text__contant,
    .Middle__leftside_wapper {
      width: 48% !important;
    }

    //
    .TutorMaps__view {
      margin-left: 0% !important;
      margin-right: 0% !important;
    }

    //
    .tutors_scroll_content_two {
      gap: 0% !important;
      margin-right: 0% !important;

      .top_tutors_container_scroll {
        width: 100% !important;
        margin-top: 13% !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
      }

      .top__tutors__container_list {
        margin-left: 0% !important;
        margin-right: 0% !important;
        margin-top: 0% !important;
        padding-bottom: 2rem;
      }
    }

    //
    .tutors-scroll-content-one .TutorMaps__view {
      padding: 0px !important;
    }

    //
    .tutors-scroll-content-one .Tutor__text__contant h1 {
      font-size: 36.49px;
    }

    .discount__text__contant {
      font-size: 10px;
    }

    .tutor_carousel_title {
      font-size: 14px;
    }

    .left_arrow,
    .right_arrow,
    .disable_arrow {
      width: 7px !important;
    }

    .tutor__viewMap {
      font-size: 10.24px;
    }

    .TutorSubject_Img_wapper {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 12px !important;
    }

    .TutorSubject_Img_wapper {
      img {
        width: 27.16px !important;
        height: 27.002px !important;
        border-radius: 4px !important;
      }

      span {
        font-size: 10px !important;
      }
    }

    .TutorSubject__container {
      gap: 4px !important;
    }

    .scroll__container {
      margin-top: 2rem !important;
      //
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    .top_tutors_card_wrapper {
      // padding: 0px !important;
    }
  }
}

//**Below for bottom scroll isuue**//
@media screen and (min-width:993px) {
  .tutor__container {
    .top_tutors_container_scroll {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }

    .top__tutors__container_list {
      height: 100%;
    }
  }
}

//**header content text responsive**//
@media screen and (min-width:701px) and (max-width:840px) {
  .tutor__container {
    .tutors-scroll-content-one {
      .Tutor__text__contant {
        h1 {
          font-size: 36.49px !important;
        }

        p {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media screen and (min-width:993px) and (max-width:1256px) {
  .tutor__container {
    .tutors-scroll-content-one {
      .Tutor__text__contant {
        h1 {
          font-size: 36.49px !important;
        }

        p {
          font-size: 14px !important;
        }
      }
    }
  }
}

//**Search filter wrap responsive**//
@media screen and (max-width:991px) {
  .tutor__container {
    .tutors-head-row {
      .tutors-search-part {
        width: 50% !important;
      }

      .tutors-filter-wrap {
        margin-top: 1.5rem;
        padding-right: 0rem !important;
        width: 100% !important;

        .filter_container {
          width: 100% !important;
        }
      }
    }
  }
}

//**Filter-Scroll responsive**//
//   @media screen and (max-width:650px) {
//     .tutor__container{
//     .tutors-head-row{
//       .tutors-filter-wrap{
//         position: relative !important;
//         .filter_container{
//           overflow-y:scroll !important;
//           width: 79% !important;
//           border-radius: 10px 0px 10px 10px;
//           div:last-child{
//             position: absolute !important;
//             left: 79%;
//             top: 0px;
//             text-wrap: wrap !important;
//             background-color: #fff;
//             border-radius: 0px 10px 10px 0px;
//             border: 1px solid lightblue;
//           }
//         }
//       }
//     }
//   }
// }

// //**Search filter wrap responsive**//
// @media screen and (max-width:991px) {
//   .tutor__container {
//     .tutors-head-row {
//       .tutors-search-part {
//         width: 50% !important;
//       }

//       .tutors-filter-wrap {
//         margin-top: 1.5rem;
//         width: 100% !important;
//       }
//     }
//   }

// }