.fc-daygrid-day-number {
  text-decoration: none;
}

.fc .fc-scrollgrid-liquid {
  border: none;
}

.fc-col-header-cell-cushion {
  text-decoration: none;
  color: #6E6B7B;
  font-size: 12px;
  font-family: Manrope600;

}

.fc-toolbar-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  text-align: left;
}

.fc-toolbar-chunk div {
  display: flex !important;
  align-items: center;
}

.fc-toolbar-chunk div>button {
  background: none !important;
  border: none !important;
  outline: none !important;
}

.fc-toolbar-chunk div>button:focus {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.fc-prev-button .fc-button .fc-button-primary {
  background-color: none;
}

.fc-next-button {
  background: none;
}

.fc-icon-chevron-right {
  color: #000;
}

.fc-icon-chevron-left {
  color: #000;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 3rem !important;
}


.CalendarView__container {
  padding: 20px 25px;
  height: 100%;
  box-shadow: 0px 4px 39px 0px #b2b2b240;
  border-radius: 17px;
  background: #fff;
  margin-bottom: 4rem;

  // .fc .fc-view-harness {
  //   max-height: 450px !important;
  // }

  // .fc .fc-scrollgrid-section-body table {
  //   border: 1px solid #D2D2D2 !important;
  // }

  .Calendar__discount__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 17px;
    margin-bottom: 0.7rem;

    .discount__card {
      height: 40px;
      border-radius: 48px;
      background: #e6f3fe;
      backdrop-filter: blur(5px);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
      padding: 0 17px;
      cursor: pointer;

      .discount__Img {
        width: 21.993px;
        height: 21.99px;
        object-fit: contain;
      }

      .Black_text {
        color: #333;
        font-family: Manrope600;
        font-size: 10px;
      }

      .blue__text {
        color: #339cfe;
        font-family: Manrope600;
        font-size: 10px;
      }

      .badge_blue {
        color: #fff;
        font-family: Manrope600;
        font-size: 10px;
        padding: 0px 5px !important;
        padding-bottom: 0 !important;
        border-radius: 8px;
        background: #339cfe;
      }
    }

    .discount__card__active {
      height: 40px;
      border-radius: 48px;
      background: #339cfe;
      backdrop-filter: blur(5px);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
      padding: 0 17px;
      cursor: pointer;

      .discount__Img {
        width: 21.993px;
        height: 21.99px;
        object-fit: contain;
      }

      .Black_text {
        color: #fff;
        font-family: Manrope600;
        font-size: 10px;
      }

      .blue__text {
        color: #fff;
        font-family: Manrope600;
        font-size: 10px;
      }

      .badge_blue {
        color: #339cfe;
        font-family: Manrope600;
        font-size: 10px;
        padding: 0px 5px !important;
        padding-bottom: 0 !important;
        border-radius: 8px;
        background: #fff;
      }
    }
  }

  // .CalendarView__child__select {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;

  //   .tittle-div {
  //     height: 40px;
  //     padding: 10px;
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     gap: 7px;

  //     .back-img {
  //       cursor: pointer;
  //       height: 25px;
  //     }

  //     .text-tittle {
  //       font-family: Manrope700;
  //       font-size: 16px;
  //       line-height: 28px;
  //       letter-spacing: 0em;
  //       text-align: left;
  //     }
  //   }
  // }

  .CalendarView__child__select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .CalendarView__child__select_arb {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .CalendarView__weeklyIcon {
    position: relative;
  }

  // .CalendarView__weeklyIcon_arab {
  //   .fc .fc-toolbar {
  //     justify-content: flex-end !important;
  //   }
  // }

  .weeklyIcon {
    position: absolute;
    top: -34px;
    right: 36%;
    width: 83px;
    height: 26px;
  }

  .slot_wapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    margin-top: 15px;
    margin-bottom: -65px;

    .slot__item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;

      span {
        font-size: 12px;
        font-weight: 600;
        color: #6E6B7B;
      }
    }
  }
}

/* Custom styling for the cells of the specific date's column */
// .custom-day-cell {
//     font-size: 14px;
//     font-weight: 600;
//     // border: 1px solid #F8F7FE;
//     background-color: #178FFF;
//     color: #178FFF !important;
//     height: 20px;
//     width: 100%;
// }

.custom-day-cell:hover {
  font-size: 14px;
  font-weight: 600;
  // border: 1px solid #F8F7FE;
  background: #178fff;
  height: 20px;
  width: 100%;
}

.custom-background_tutor {
  // background-color: #116BBF;
  border-radius: 4px !important;
  border: 1px solid #178fff !important;
  background: #eff7ff !important;
  cursor: pointer;
}

.custom-background_selected_tutor {
  border-radius: 4px !important;
  border: 1px solid #eff7ff !important;
  background: #178fff !important;
}

/* Default styling for other cells */
.default-day-cell {
  // width: 100px !important;
  // height: 100px !important;
  margin: 0;
  padding: 0;
  background-color: aqua !important;
}

.CalendarView__rightside_tutor {
  padding: 20px 25px;
  height: 100%;
  box-shadow: 0px 4px 39px 0px #b2b2b240;
  border-radius: 17px;
  background: #fff;

  .CalendarView__datePicker {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;

    .datePicker {
      max-width: 180px !important;
      background-color: rgba(237, 243, 249, 1) !important;
      border-radius: 28px;
      max-height: 40px !important;
      box-sizing: none !important;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
      background-color: rgba(237, 243, 249, 1) !important;
      border-radius: 28px;
      max-height: 40px !important;
      box-sizing: none !important;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
    }

    .chosse_time_Conatiner_Tutor_Active {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 35px;
      background: rgba(23, 143, 255, 1);
      cursor: pointer;

      img {
        width: 13px;
        height: 13px;
        object-fit: cover;
      }

      span {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .chosse_time_Tutor_Conatiner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 35px;
      background: #edf3f9;
      cursor: pointer;

      img {
        width: 13px;
        height: 13px;
        object-fit: cover;
      }

      span {
        color: #333;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .Timeline__Calendar_container {
    margin-top: 2rem;

    .fc-direction-ltr {
      min-height: 450px;
      height: 100%;
    }
  }

  .Bottom__book__slot__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px -1px 13px 0px rgba(169, 169, 169, 0.25);
    width: 100%;
    height: 100px;
    //
    margin-top: 1rem;

    .Book__slot__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left !important;

      .Selected__text {
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-weight: 500;
      }

      .count__text {
        color: rgba(23, 143, 255, 1);
        font-size: 18px;
        font-weight: 500;
      }
    }

    .Book__slot__right {
      .Book__slot_btnActivie {
        height: 50px;
        width: 100%;
        min-width: 150px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: 600;
        border-radius: 50px;
        background: #178fff;
        opacity: 1;
      }

      .Book__slot_btnInActive {
        height: 50px;
        width: 100%;
        min-width: 150px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: 600;
        border-radius: 50px;
        background: #178fff;
        opacity: 0.5;
        cursor: default !important;
      }
    }
  }

  .rightSide__top__Date {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
  }

  .rightSide_slot_container_wapper {
    max-height: 400px;
    overflow: auto;
    margin-top: 1rem;
  }

  .slot_container_Position {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .DotedLine__wapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      height: 100%;
      margin: 5px 3px;

      .time {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }

      .dotted-line {
        width: 1px;
        height: 56px;
        border-left: 2px dotted #b8b8b8;
        stroke-width: 1px;
        stroke: #b8b8b8;
        margin-left: 10px;
      }
    }

    .slot_container_wraping {
      width: 100%;
    }

    .Slot_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      // padding: 4px;
      padding: 4px 15px;
      margin: 0px 3px;
      border-radius: 8px;
      background-color: rgba(209, 233, 255, 0.42) !important;
      cursor: pointer;

      .Slot__tutor_img_wapper {
        width: 36.932px;
        height: 61px;
        border-radius: 9.017px;
        background: #fbfdff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .Slot__tutor_contant_wapper {
        display: flex;
        flex-direction: column;
        width: 90%;

        .Slot_course_name {
          color: #333;
          font-size: 12px;
          font-family: Inter600;
          //
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .tutor-createTime-wrap {
          display: flex;
          justify-content: space-between;
        }

        .Slot_badge {
          color: #fbfdff;
          font-size: 10px;
          font-weight: 500;
          border-radius: 5px;
          background: #178fff;
          padding: 1px 3px;
          width: 100%;
          max-width: 76px;
        }

        .createdBy_Tutor {
          font-size: 10px;
          font-weight: 500;
          color: #178fff;
        }
      }
    }
  }
}

.dayCellContent__selected {
  // color: #FFF;
  // font-size: 14px;
  // font-weight: 600;
  // background-color: #178FFF;
  // padding: 0px 5px;
  // border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.tutor_calendar_head_breadcam {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px;
  gap: 8px;

  .light {
    color: #8d8d8d;
    font-family: Manrope600;
    font-size: 14px;
    font-weight: 600;
  }

  .light:hover {
    color: #000;
    font-family: Manrope600;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .dark {
    color: #000;
    font-family: Manrope600;
    font-size: 14px;
    font-weight: 600;
  }

  .icon {
    font-size: 14px !important;
  }
}

.TutorFulCalender {
  .fc-highlight {
    background-color: rgba(23, 143, 255, 1) !important;
    // color: white !important;
  }
}

.dayCellContent__Unselected {
  color: #333;
  font-size: 12px;
  font-weight: 500;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  display: none !important;
}

.disabled-date {
  pointer-events: none;
  opacity: 0.5;
  // background-color: #dee2e6;
  border: 1px solid #D2D2D2 !important;
}

/* MyCalendar.css */
.highlighted-day {
  border: 2px solid red;
  height: 20px !important;
  width: 20px !important;
  /* You can adjust the border style as needed */
}

.child_select__dropdown {
  border-radius: 46px !important;
  border: 1px solid #efefef !important;
  padding: 2px 0px !important;
  margin: 0px 0px !important;
  height: 52px !important;
}

.backToNavigate {
  width: 133px;
  height: 46px;
  border-radius: 26px;
  background: #178fff;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.ChildSelect__container {
  max-width: 500px;
  width: 100%;
  padding: 20px;

  .discount__title {
    color: #333;
    font-family: Manrope600;
    font-size: 20px;
    // padding: 5px;
  }

  .discount__body {
    color: #393535;
    font-family: Inter500;
    font-size: 14px;
    text-align: start;
    padding: 10px 0px;
    margin-bottom: 1rem;
    max-width: 470px;
  }

  .Calendar__discount__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 17px;
    margin-bottom: 0.7rem;

    .discount__card {
      height: 56px;
      border-radius: 48px;
      background: #e6f3fe;
      backdrop-filter: blur(5px);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
      padding: 0 17px;
      cursor: pointer;

      .discount__Img {
        width: 25.993px;
        height: 25.99px;
        object-fit: contain;
      }

      .Black_text {
        color: #333;
        font-family: Manrope600;
        font-size: 12px;
      }

      .blue__text {
        color: #339cfe;
        font-family: Manrope600;
        font-size: 12px;
      }

      .badge_blue {
        color: #fff;
        font-family: Manrope600;
        font-size: 10px;
        padding: 0px 5px !important;
        padding-bottom: 0 !important;
        border-radius: 8px;
        background: #339cfe;
      }
    }

    .discount__card__active {
      height: 56px;
      border-radius: 48px;
      background: #339cfe;
      backdrop-filter: blur(5px);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
      padding: 0 17px;
      cursor: pointer;

      .discount__Img {
        width: 25.993px;
        height: 25.99px;
        object-fit: contain;
      }

      .Black_text {
        color: #fff;
        font-family: Manrope600;
        font-size: 12px;
      }

      .blue__text {
        color: #fff;
        font-family: Manrope600;
        font-size: 12px;
      }

      .badge_blue {
        color: #339cfe;
        font-family: Manrope600;
        font-size: 10px;
        padding: 0px 5px !important;
        padding-bottom: 0 !important;
        border-radius: 8px;
        background: #fff;
      }
    }
  }

  .text__contant {
    color: #333;
    font-size: 18px;
    font-family: Manrope600;
    text-align: center;
  }

  .ChildSelectBtn__wapper__Tutor {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 2rem;

    .BackBtn {
      width: 133px;
      height: 40px;
      border-radius: 26px;
      background-color: #fff;
      border: 1px solid #178fff !important;
      color: #178fff;
      font-size: 12px;
      font-weight: 600;
    }

    .OkayBtn {
      width: 133px;
      height: 40px;
      border-radius: 26px;
      background: #178fff;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.tutors-custom-day-header {
  color: #333;
  font-size: 12px;
  font-weight: 500;
}

@media (min-width: 1420px) {
  .fc .fc-view-harness {
    max-height: 70% !important;
  }

  .CalendarView__rightside_tutor .rightSide_slot_container_wapper {
    max-height: 60vh !important;
  }

  .tutor_calendar_head_breadcam {
    margin-top: 2rem;
    margin-left: 10px;
  }
}

@media (max-width: 600px) {
  .child_select__dropdown {
    height: 45px !important;
  }

  .CalendarView__container {
    .CalendarView__weeklyIcon_arab {
      .fc .fc-toolbar {
        justify-content: normal !important;
        direction: rtl;

        // .fc .fc-button:not(:disabled),
        // .fc .fc-button-primary:hover,
        // .fc .fc-button-primary:hover{
        //   rotate: 180deg !important;
        // }
        .fc-button-primary {
          rotate: 180deg !important;
        }
      }
    }
  }
}

@media screen and (min-width:992px) {
  .s-cource-titile {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}