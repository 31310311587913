.home_page_wrapper {
  margin-top: 58px;
  background: #f9f9f9;
  user-select: none !important;

  .banner_image {
    // width: calc(100vw - 10px);
    width: 100%;
    object-fit: cover;
    height: 330px;
    // height: 55vh;
  }

  .search_field,
  .search_field-arab {
    border-radius: 88px;
    border: 1px solid #f4f2f9;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);
    padding: 16px 60px 16px 20px;
    font-size: 14px;
    font-family: Manrope600;
    // width: 30vw;
    min-width: 507px;
    max-width: 1000px;
    height: 50px;
    color: #f4f2f9;

    &::placeholder {
      color: #f4f2f9;
      font-family: Manrope400;
      font-size: 12px;
    }

    &:focus {
      outline: unset !important;
    }
  }

  .search_field-arab {
    padding: 16px 28px 16px 20px !important;
  }

  .Autocompleted__data_wapper {
    min-width: 507px;
    max-width: 1000px;
    max-height: 200px;
    height: 100%;
    overflow: auto;
    overflow-y: scroll;
    border-radius: 20px;
    z-index: 1000;

    .data_wapper__box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(255, 255, 255, 1);
      padding: 10px 20px;
      width: 100%;
    }

    .data_wapper__box:hover {
      background-color: #f4f2f9;
      cursor: pointer;
    }

    .data_wapper__box_hilight {
      background-color: #f4f2f9 !important;
      cursor: pointer;
    }

    .no__result_wapper__box {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 1);
      padding: 10px 20px;
      width: 100%;
    }

    .no__result {
      color: gray;
      font-family: Manrope700;
      font-size: 14px;
      text-transform: capitalize;
      margin: 1.2rem 0;
    }

    .box {
      display: flex;
      align-items: center;
      gap: 10px;

      .tag {
        color: #333;
        font-family: Manrope700;
        font-size: 16px;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }

      .addon {
        color: gray;
        text-align: center;
        font-family: Manrope600;
        font-size: 12px;
      }
    }

    .provider {
      color: gray;
      text-align: center;
      font-family: Manrope600;
      font-size: 14px;
    }
  }

  .homePage__footer__container {
    background-color: #F5F4F8;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .footer__nav_links {
      display: flex;
      flex-direction: row;
      align-items: center;

      .link_text {
        color: #636363;
        font-family: Manrope600;
        font-size: 14px;
        cursor: pointer;
        margin-right: 27px;
      }
    }

    .footer__text {
      color: #636363;
      font-family: Manrope600;
      font-size: 14px;
      margin-left: 20px;
    }
  }

  /* width */
  .Autocompleted__data_wapper::-webkit-scrollbar {
    width: 0px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .search_button {
    background-color: rgba(255, 255, 255, 1);
    padding: 6px 15px;
    border-radius: 88px;
    position: absolute;
    right: 12px;
    cursor: pointer;
  }

  .search_button-arab {
    background-color: rgba(255, 255, 255, 1);
    padding: 6px 15px;
    border-radius: 88px;
    position: absolute;
    right: 445px;
    cursor: pointer;
  }

  .search_field_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    margin-top: 2rem;
  }

  .search_banner_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .swiper {
      width: 100% !important;
    }
  }

  .search_field_wrapper {
    position: absolute;
    top: 20%;
    z-index: 1;

    .top__banner__contant_whiteText {
      color: #f4f2f9;
      text-align: center;
      font-family: Manrope800;
      font-size: 34px;
    }

    .top__banner__contant_blackText {
      color: #f4f2f9;
      text-align: center;
      font-family: Inter400;
      font-size: 18px;
    }
  }

  .know_more_contents_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 12px 12px 12px;
  }

  .know_more_contents {
    color: #404246;
    text-align: center;
    font-family: Manrope600;
    font-size: 24px;
    width: 100%;
    max-width: 500px;
  }

  .know_more_description {
    color: rgba(51, 51, 51, 0.52);
    text-align: center;
    font-family: Inter400;
    font-size: 18px;
  }

  .know_more {
    color: #f9f9f9;
    text-align: center;
    font-family: Inter500;
    font-size: 14px;
    border-radius: 8px;
    background: #178fff;
    padding: 12px 18px;
  }

  .know_more_button_container {
    position: relative;
    margin-top: 24px;
    margin-bottom: 30px;
  }

  .know_more_arrow {
    position: absolute;
    right: -13px;
    top: -12px;
  }

  .service_card_title_container {
    margin: 45px 40px;
    margin-right: 5px !important;
  }

  .service_card_title_container-arab {
    margin: 45px 5px !important;
    margin-right: 40px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .service_chip_container {
    border-radius: 51px;
    background: #f9f9fb;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 16px 18px;
    color: #333;
    font-family: Manrope700;
    font-size: 20px;
    cursor: pointer;
  }

  .service_title {
    color: #444a53;
    font-family: Manrope700;
    font-size: 38px;
    margin-top: 20px;
  }

  .school_background_circle_container {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(255, 207, 48, 0.21) 0%,
        rgba(208, 220, 255, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    right: 70%;
  }

  .school_background_circle_container-arab {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(255, 207, 48, 0.21) 0%,
        rgba(208, 220, 255, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    // right: 70%;
  }

  .university_background_circle_container {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(19, 203, 146, 0.21) 0%,
        rgba(20, 204, 147, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    right: 70%;
  }

  .university_background_circle_container-arab {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(19, 203, 146, 0.21) 0%,
        rgba(20, 204, 147, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  /*Circle theme parent relative*/
  .service_card_title_wrapper,
  .top_service_card_title_wrapper {
    position: relative;
    margin: 45px 0px;
  }

  .activities_background_circle_container {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(255, 174, 79, 0.28) 0%,
        rgba(255, 174, 79, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    right: 70%;
  }

  .activities_background_circle_container-arab {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(255, 174, 79, 0.28) 0%,
        rgba(255, 174, 79, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    // right: 70%;
  }

  .theme_park_background_circle_container {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(111, 229, 251, 0.28) 0%,
        rgba(111, 229, 251, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    right: 70%;
  }

  .theme_park_background_circle_container-arab {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(111, 229, 251, 0.28) 0%,
        rgba(111, 229, 251, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .download_app {
    color: #9d6cfb;
    font-family: Manrope700;
    font-size: 16px;
    border-radius: 33.489px;
    background: rgba(157, 108, 251, 0.1);
    padding: 8px 18px;
    margin-top: 32px;
  }

  .mobile_app_ad_title {
    color: #444a53;
    font-family: Inter700;
    font-size: 48px;
    width: 310px;
    margin-top: 20px;
    margin-bottom: 8px;
  }

  .mobie_app_ad_description {
    color: #969ca6;
    font-family: Inter500;
    font-size: 20px;
    width: 360px;
    margin-bottom: 60px;
  }

  .mobile_app_ad_wrapp {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;
    // margin: 60px;
    margin: 60px 60px 0px 60px;
  }

  .mobile_app_links_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .tutor_background_circle_container {
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(25, 144, 255, 0.21) 0%,
        rgba(208, 220, 255, 0) 100%);
    width: 100%;
    height: 50%;
    position: absolute;
    right: 70%;
  }

  .top_serive_title {
    color: #444a53;
    font-family: Manrope700;
    font-size: 38px;
    min-height: 100px;
    line-height: 51.91px;
    margin: 30px 0px 22px 0px;
  }

  .top_serive_description {
    color: #444a53;
    font-family: Inter400;
    font-size: 18px;
    line-height: 26.64px;
    width: 85%;
    text-align: justify;
  }

  .top_service_card_title_container {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 5px;
    margin-left: 42px !important;
    position: relative;
  }

  .top_service_card_title_container-arab {
    margin-left: 0px !important;
  }

  .image_content_overlap {
    opacity: 0;
  }

  .top_service_title_wrapper {
    width: 30%;
    transition: opacity 0.8s ease;
  }

  .top_service_title_wrapper-arab {
    width: 30%;
    transition: opacity 0.8s ease;
    margin-right: 42px !important;
  }

  .playStore,
  .appStore {
    cursor: pointer;
  }

  .service_navigation_card_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border-radius: 17px;
    background: #fff;
    padding: 12px;
    width: 100%;
    max-width: 250px;
    flex: 1 1 100%;
    cursor: pointer;
  }

  .school_nav_icon_wrapper {
    background: rgba(255, 200, 18, 1);
    // background: linear-gradient(0deg,
    //     rgba(255, 251, 243, 1) 100%,
    //     rgba(254, 247, 230, 1) 100%);
    border-radius: 50%;
    padding: 18px;
  }

  .university_nav_icon_wrapper {
    background: rgba(18, 204, 146, 1);
    // background: linear-gradient(0deg,
    //     rgba(241, 252, 246, 1) 100%,
    //     rgba(209, 246, 226, 1) 100%);
    border-radius: 50%;
    padding: 18px;
  }

  .tutor_nav_icon_wrapper {
    background: rgba(89, 173, 251, 1);
    // background: linear-gradient(0deg,
    //     rgba(242, 244, 255, 1) 100%,
    //     rgba(210, 218, 255, 1) 100%);
    border-radius: 50%;
    padding: 18px;
  }

  .activities_nav_icon_wrapper {
    background: rgba(255, 174, 79, 1);
    // background: linear-gradient(0deg,
    //     rgba(255, 247, 232, 1) 100%,
    //     rgba(254, 233, 197, 1) 100%);
    border-radius: 50%;
    padding: 18px;
  }

  .theme_park_nav_icon_wrapper {
    background: rgba(4, 214, 251, 1);
    // background: linear-gradient(0deg,
    //     rgba(242, 253, 255, 1) 100%,
    //     rgba(194, 245, 254, 1) 100%);
    border-radius: 50%;
    padding: 18px;
  }

  .service_nav_title_descriptions_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
  }

  .service_nav_title {
    color: #333;
    font-family: Manrope600;
    font-size: 16px;
    white-space: nowrap;
  }

  .service_nav_description {
    color: #838383;
    font-family: Manrope500;
    font-size: 12px;
  }

  .service_navigation_card_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 30px 50px;
  }

  .service_nav_icon {
    // width: 60px;
    // height: 60px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }

  .top_activities_service_cards_wrapper {
    transition: all 0.8s ease;
    width: 70%;
    position: absolute;
    left: 30%;
    top: 0;
  }

  .top_activities_service_cards_wrapper-arab {
    position: initial;
    transition: all 0.8s ease;
    width: 70%;
    left: initial !important;
    right: 30% !important;
  }

  .tutor_content_overlap {
    left: 0% !important;
    width: 100% !important;
  }

  .top_activities_service_cards_wrapper {
    cursor: grab;
    padding: 0px 10px;

    &:hover {
      cursor: grab;
    }

    // .custom_cursor {
    //   display: flex;
    //   position: absolute;
    //   top: 0px;
    //   left: 0px;
    //   width: 50px;
    //   height: 50px;
    //   background: rgba(255, 255, 255, 0.2);
    //   backdrop-filter: blur(14.5217px);
    //   border: 1px solid rgb(255, 255, 255);
    //   pointer-events: none;
    //   border-radius: 50%;
    //   z-index: 1500;
    //   opacity: 1;
    //   transition: opacity 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s,
    //     background 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s;
    //   -webkit-box-align: center;
    //   align-items: center;
    //   -webkit-box-pack: center;
    //   justify-content: center;
    // }
  }

  .school_service_cards_wrapper,
  .university_service_cards_wrapper,
  .activities_service_cards_wrapper,
  .theme_park_service_cards_wrapper {
    &:hover {
      cursor: grab;

      // .custom_cursor {
      //   display: flex !important;
      //   opacity: 1 !important;
      //   transition: opacity 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0.2s,
      //     background 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s !important;
      // }
    }
  }

  // .custom_cursor {
  //   display: none;
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   width: 50px;
  //   height: 50px;
  //   background: rgba(255, 255, 255, 0.2);
  //   backdrop-filter: blur(14.5217px);
  //   border: 1px solid rgb(255, 255, 255);
  //   pointer-events: none;
  //   border-radius: 50%;
  //   z-index: 1500;
  //   opacity: 0;
  //   transition: opacity 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s,
  //     background 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s;
  //   -webkit-box-align: center;
  //   align-items: center;
  //   -webkit-box-pack: center;
  //   justify-content: center;
  // }

  //service nav
  @media only screen and (max-width: 1160px) {
    .service_navigation_card_container {
      flex-direction: column;
    }

    .service_nav_title_descriptions_container {
      align-items: center;
    }

    .service_nav_description {
      display: none !important;
    }

    .service_nav_title {
      text-align: center;
    }
  }

  //mobile app download
  @media only screen and (max-width: 950px) {
    .mobile_app_ad_wrapp {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 992px) {
  .home_page_wrapper {
    margin-top: 29px !important;
  }
}

//mobileView app download
@media (max-width: 890px) {
  .home_page_wrapper {
    .search_field_wrapper {
      top: 13% !important;

      .top__banner__contant_whiteText {
        font-size: 26px !important;
      }

      .top__banner__contant_blackText {
        font-size: 12px !important;
      }
    }

    .search_field_container {
      margin-top: 1rem;
    }

    .search_field,
    .search_field-arab {
      border-radius: 88px;
      border: 1px solid #f4f2f9;
      background: rgba(255, 255, 255, 0.2);
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      padding: 16px 60px 16px 20px;
      font-size: 14px;
      font-family: Manrope600;
      min-width: 337px;
      max-width: 500px;
      color: #f4f2f9;
    }

    .search_field-arab {
      padding: 16px 20px 16px 60px !important;
    }

    .Autocompleted__data_wapper {
      min-width: 337px;
      max-width: 500px;
      max-height: 120px;
      height: 100%;
      overflow: auto;
      overflow-y: scroll;
      border-radius: 20px;
      z-index: 1000;
    }

    .tag {
      color: #333;
      font-family: Manrope700;
      font-size: 16px;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 60px !important;
    }
  }
}

@media (max-width: 630px) {
  .home_page_wrapper {
    .top_service_card_title_container {
      display: initial !important;
    }

    .service_navigation_card_wrapper {
      flex-wrap: wrap;
    }

    .service_navigation_card_container {
      max-width: 100%;
    }

    .top_activities_service_cards_wrapper {
      transition: all 0.8s ease;
      width: 20%;
      width: 94%;
      // position: absolute;
      position: relative;
      // left: 80%;
      left: 6%;
      top: 17px;
    }

    .top_activities_service_cards_wrapper-arab {
      transition: all 0.8s ease;
      width: 94%;
      // position: absolute;
      position: relative;
      // left: 80%;
      left: initial !important;
      right: 6% !important;
      top: 17px !important;
    }

    .top_service_title_wrapper {
      width: 70%;
      margin-left: 42px;

      .top_serive_title {
        min-height: 0px !important;
        font-size: 28px;
      }

      .top_serive_description {
        font-size: 14px;
      }
    }

    //
    .top_service_title_wrapper-arab {
      width: 70%;
      margin-left: initial !important;
      margin-right: 42px !important;

      .top_serive_title {
        min-height: 0px !important;
        font-size: 28px;
      }

      .top_serive_description {
        font-size: 14px;
      }
    }


  }
}

//mobileView app download
@media (max-width: 550px) {
  .home_page_wrapper {
    .banner_image {
      object-fit: cover !important;
      height: 180px !important;
      overflow: hidden;
    }

    .service_chip_container {
      padding: 12px 18px !important;
      font-size: 17px !important;
    }

    .service_title {
      font-size: 28px !important;
    }

    .search_field_wrapper {
      // top: 5% !important;
      top: -7% !important;

      .top__banner__contant_whiteText {
        font-size: 16px !important;
      }

      .top__banner__contant_blackText {
        font-size: 10px !important;
      }
    }

    .search_field_container {
      margin-top: 10px;
    }

    .mobile_app_links_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center !important;
      width: 100% !important;
    }

    .mobile_app_ad_title {
      font-size: 34px !important;
      width: 100% !important;
      text-align: center;
    }

    .mobie_app_ad_description {
      font-size: 14px !important;
      width: 100% !important;
      text-align: center;
    }

    .mobile_app_download_links {
      text-align: center;
    }

    .search_field,
    .search_field-arab {
      border-radius: 35px;
      padding: 2px 50px 2px 13px;
      font-size: 14px;
      min-width: 217px;
      max-width: 300px;
    }

    .search_field-arab {
      padding: 2px 13px 2px 50px !important;
    }


    .Autocompleted__data_wapper {
      min-width: 217px;
      max-width: 300px;
      max-height: 120px;
    }

    .no__result {
      font-size: 12px !important;
    }

    .addon {
      font-size: 10px !important;
    }

    .data_wapper__box {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 5px 12px;
      width: 100%;
    }

    .tag {
      max-width: 80px !important;
      font-size: 12px !important;
    }

    .provider {
      display: none;
    }

    .search_button {
      // padding: 6px 10px;
      padding: 5px 13px;
      border-radius: 88px;
      position: absolute;
      // right: 37px;
      right: 28px;
      cursor: pointer;
    }

    .search_button-arab {
      padding: 5px 13px;
      border-radius: 88px;
      position: absolute;
      right: initial !important;
      left: 16px !important;
      cursor: pointer;
    }

    .mobile_app_ad_wrapp {
      flex-direction: column;
      align-items: center;

      .mobile_view_img {
        width: 100%;
      }
    }

    .service_card_title_container {
      // margin: 40px 15px;
      margin: 27px 15px 0px 15px !important;
      margin-right: 15px !important;
    }

    .service_card_title_container-arab {
      // margin: 40px 15px !important;
      margin: 27px 15px 0px 15px !important;
      margin-left: 15px !important;
    }

    .top_serive_title {
      line-height: 38.91px;
      margin: 22px 0px 22px 0px;
    }

    .top_service_title_wrapper {
      width: 85%;
      margin-left: 15px;
    }

    .top_service_title_wrapper-arab {
      width: 85%;
      margin-left: initial !important;
      margin-right: 15px !important;
    }
  }
}

@media (max-width: 890px) {
  .home_page_wrapper {
    .search_button-arab {
      right: initial !important;
      left: 16px !important;
    }
  }
}

@media (min-width: 1420px) {
  .home_page_wrapper {
    margin-top: 77px;

    .top_serive_title {
      min-height: 90px;
    }

    .top_serive_description {
      font-size: 20px;
      text-align: justify;
      width: 90%;
    }

  }
}

@media screen and (max-width:750px) {
  .home_page_wrapper {
    .swiper-button-next {
      left: 195px !important;
    }
  }
}