.TutorHubFeedback__container {
    border-radius: 10px;
    background: #F4F3F9;
    padding: 10px;

    .Header__wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 5px 8px;

        img {
            width: 22px;
            height: 22px;
            object-fit: contain;
        }

        .title {
            color: #2B2451;
            font-family: Manrope600;
            font-size: 20px;
        }
    }

    .feedback__card__conatiner {
        max-height: 550px;
        overflow: auto;
        overflow-y: scroll;
    }

    /* width */
    .feedback__card__conatiner::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .Profile_contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 5px 8px;

        .profile__Img {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            object-fit: cover;
        }

        .right_options {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 4px;

            .rating__starIcon {
                color: #F7C376 !important;
                font-size: 14px;
            }

            .rating__value {
                color: #656565;
                font-family: Inter500;
                font-size: 12px;
            }

            .editBtn {
                color: #744FFF;
                font-family: Inter500;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }

    .top__contant__wapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tutor__contant {
        display: flex;
        align-items: center;

        gap: 10px;

        .Black__text {
            color: #000;
            font-family: Inter500;
            font-size: 16px;
        }

        .name__text {
            color: #656565;
            font-family: Inter400;
            font-size: 12px;
        }
    }

    .tutor__img__wapper {
        width: 38px;
        height: 100%;
        min-height: 52px;
        border-radius: 5px;
        background: rgba(209, 233, 255, 0.61);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 23px;
            height: 17px;
            object-fit: contain;
        }
    }

    .activity__img__wapper {
        width: 38px;
        height: 100%;
        min-height: 52px;
        border-radius: 0px;
        background: rgba(255, 242, 227, 1);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 23px;
            height: 17px;
            object-fit: contain;
        }
    }

    .feedback__card__wapper {
        border-radius: 9px;
        background: #FFF;
        padding: 10px;
        margin: 10px 0;

        .completedBtn {
            width: 116px;
            height: 40px;
            border-radius: 8px;
            background: #ECF9F3;
            color: #11BE6B;
            font-family: Manrope700;
            font-size: 14px;
        }

        .row__contant {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;
        }

        .left__light__text {
            color: #8A8A8A;
            font-family: Inter400;
            font-size: 14px;
        }

        .right__black__text {
            color: #333;
            font-family: Inter500;
            font-size: 14px;
        }

        .feedbackBtn {
            display: flex;
            width: 100%;
            height: 39px;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 31px;
            background: #F1EDFF;
            color: #744FFF;
            font-family: Manrope700;
            font-size: 14px;
            margin-top: 2rem;
            margin-bottom: 5px;
        }
    }
}


.FeedBack__dailog__box {
    border-radius: 16px;

    .DialogTitle__wapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            color: #000;
            font-family: Manrope600;
            font-size: 16px;
        }

        .closeBtn {
            color: #C3C3C3;
            font-family: Manrope600;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .DialogContent___wapper {
        display: flex;
        flex-direction: column;
        min-width: 300px;
        width: 100%;

        .tutor__contant {
            display: flex;
            align-items: center;
            gap: 10px;

            .Black__text {
                color: #000;
                font-family: Inter500;
                font-size: 16px;
            }

            .name__text {
                color: #656565;
                font-family: Inter400;
                font-size: 12px;
            }
        }

        .tutor__img__wapper {
            width: 38px;
            height: 100%;
            min-height: 52px;
            border-radius: 5px;
            background: rgba(209, 233, 255, 0.61);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 23px;
                height: 17px;
                object-fit: contain;
            }
        }

        .activity__img__wapper {
            width: 38px;
            height: 100%;
            min-height: 52px;
            border-radius: 0px;
            background: rgba(255, 242, 227, 1);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 23px;
                height: 17px;
                object-fit: contain;
            }
        }

        .row__contant {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;
        }

        .left__light__text {
            color: #8A8A8A;
            font-family: Inter400;
            font-size: 14px;
        }

        .right__black__text {
            color: #333;
            font-family: Inter500;
            font-size: 14px;
        }

        .rating__container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            justify-content: center;

            .rating__icon {
                font-size: 40px !important;
            }

            .lable__text {
                color: #000;
                font-family: Manrope600;
                font-size: 14px;
                text-wrap: wrap;
                max-width: 300px;
            }

            .textarea__input {
                border-radius: 16px;
                background: #F4F4F5;
                width: 100%;
                height: 102px;
                padding: 10px;
                outline: none;
                border: none;
                color: #000;
                font-size: 12px;
                font-family: Manrope500;
            }

            .textarea__input::placeholder {
                color: #B5B5CF;
                font-family: Manrope500;
                font-size: 12px;
            }

            .submitBtn {
                width: 100%;
                height: 50px;
                border-radius: 16px;
                background: #744FFF;
                color: #FFF;
                font-family: Manrope600;
                font-size: 14px;
                border: none;
                outline: none;
            }

            .submitBtn__disable {
                width: 100%;
                height: 50px;
                border-radius: 16px;
                background: #744FFF;
                color: #FFF;
                font-family: Manrope600;
                font-size: 14px;
                border: none;
                outline: none;
                cursor: default !important;
                opacity: 0.5;
            }
        }
    }

}