.Category__activitiesSports_Img_wapper {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(216, 216, 216, 0.25);
    width: auto;
    padding: 0px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 145px;
    height: 60px;

    img {
        width: 34.16px;
        height: 32.002px;
    }

    span {
        margin-left: 5px;
        color: #333;
        font-size: 12px;
        font-family: Inter600;
    }
}

.activity_container {
    z-index: 1;
    position: relative;

    .background_circle_1 {

        content: "";
        position: absolute;
        top: 0;
        right: 75%;
        bottom: 50%;
        left: 0%;
        z-index: -1;
        border-radius: 50%;
        opacity: 0.5;
        background: radial-gradient(circle, #FCC16A 0, #FFC812 19%);
        filter: blur(100px);
    }
}