button {
  border: unset !important;
  cursor: pointer !important;
}

//Common for all authentication pages
.carousel_login_navigations_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .carousel_wrapper {
    width: 40%;
  }

  .login_signup_navigations_wrapper {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 42px;
  }
}

.skip_button {
  position: absolute;
  right: 20px;
  top: 2px;
  color: #bdbdbd;
  font-family: Inter500;
  font-size: 14px;
  cursor: pointer;
  z-index: 2000;

  .close__icon {
    color: #bdbdbd !important;
  }
}

.skip_button_ar {
  position: absolute;
  left: 10px;
  top: 2px;
  color: #bdbdbd;
  font-family: Inter500;
  font-size: 14px;
  cursor: pointer;
  z-index: 2000;

  .close__icon {
    color: #bdbdbd !important;
  }
}

.skip_button:hover {
  color: rgba(51, 51, 51, 0.69);

  .close__icon {
    color: rgba(51, 51, 51, 0.69) !important;
  }
}

.back_button:hover {
  color: rgba(51, 51, 51, 0.69);
}

.back_button {
  position: absolute;
  right: 350px;
  top: 20px;
  color: #bdbdbd;
  font-family: Inter500;
  font-size: 14px;
  cursor: pointer;
  z-index: 2000;
}

//React mobile number input dropdown
.react-tel-input .country-list .search-box {
  width: 88% !important;
}

.react-tel-input .flag-dropdown {
  top: 0px !important;
  height: 40px !important;
  border: unset !important;
  outline: unset !important;
  font-size: 14px !important;
  box-sizing: border-box !important;
  background: #f4f3f8 !important;
  border-radius: 20px !important;
  min-width: unset !important;
  width: unset !important;
  padding: unset !important;
}

.react-tel-input .selected-flag:hover {
  background: #f4f3f8 !important;
  border-radius: 20px !important;
}

//Tabs
.MuiTabs-root {
  .css-heg063-MuiTabs-flexContainer,
  .css-k008qs {
    border-radius: 53px !important;
    // background: #f4f3f8 !important;
    padding: 6px !important;
    justify-content: space-around;
  }
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-1q2h7u5.Mui-selected {
  border-radius: 53px !important;
  background: #744fff !important;
  color: #fcfbff !important;
  font-family: Manrope600 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  width: 50% !important;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  white-space: nowrap !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
.css-1q2h7u5 {
  color: #818181 !important;
  border-radius: 53px !important;
  font-family: Manrope600 !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  width: 50% !important;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  min-height: unset !important;
  max-height: 36px !important;
  white-space: nowrap !important;
}

.css-1aquho2-MuiTabs-indicator,
.css-ttwr4n {
  display: none !important;
}

//Toast
.toastContainer {
  z-index: 2000 !important;
  font-family: Manrope600;
}

.LoginCarousel__img {
  max-height: 395px !important;
  width: 100% !important;
  object-fit: contain !important;
  border-radius: 14px !important;
  // border-top-right-radius: 14px !important;
}

.LoginCarousel__img_bg1 {
  background: linear-gradient(180deg, #d872f7 0%, #ac78e7 100%);
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.LoginCarousel__img_bg2 {
  background: linear-gradient(180deg, #82ebdc 0%, #53d1b8 100%);
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.LoginCarousel__img_bg3 {
  background: linear-gradient(180deg, #25bcff 3.93%, #2b9fff 99.56%);
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.LoginCarousel__img_bg4 {
  background: linear-gradient(180deg, #ffca3e 0%, #ffba4a 100%);
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

#google-map-script {
  border-radius: 12px !important;
}

.gmnoprint {
  display: none;
}

.gmnoprint {
  display: none;
}

.gmnoscreen {
  display: none;
}

.gm-style-cc {
  display: none;
}

body,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: none !important;
  border-width: 0;
}

// .offer_cards_wrapper {
//   .__target {
//     margin-right: 10px !important;

//     .offer_card {
//       width: 450px !important;
//     }

//     .offer_card_container {
//       width: 450px !important;
//     }
//   }
// }

.error-popup {
  .MuiPaper-root {
    width: 300px !important;
    max-width: 300px !important; 
    padding: 15px;
    border-radius: 16px !important;
    background: #fff !important;
    max-height: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;

    .dialog-text {
      color: #333;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      max-width: 100%;
      margin: 14px 0;
    }
  
  }
}

.close-btn {
  width: 133px;
  height: 46px;
  border-radius: 26px !important;
  background: #744FFF !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;

  &:hover {
    width: 133px;
    height: 46px;
    border-radius: 26px !important;
    background: #178fff !important;
    color: #fff  !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
}
/* Add this to your CSS file */
.error-popup {
  .MuiDialog-container {
    backdrop-filter: blur(9px) !important;
  }
}

//mobileView app download
@media (max-width: 636px) {
  .carousel_login_navigations_wrapper {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    // position: relative;

    .carousel_wrapper {
      width: 100%;
    }

    .login_signup_navigations_wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 16px;
      position: relative;
    }
  }
}

@media (max-width: 990px) {
  .ViewMoreLess_description {
    .description__contant {
      width: 100% !important;
    }
  }
}

.ViewMoreLess_description {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .description__contant {
    color: #333;
    font-family: Inter400;
    font-size: 14px;
    width: 90%;
    text-align: justify;
    word-break: break-all;
    margin-top: 2px;
  }

  .description__Btn {
    color: #333;
    font-family: Inter600;
    font-size: 14px;
    cursor: pointer;
  }

  .description__Btn_viewless {
    margin-left: 7px;
  }
}

.Pdf__title {
  font-family: Manrope700;
  color: #333;
  font-size: 16px;
  text-align: left;
}

.PdfDownloadFile__container {
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .contant__wapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;

    img {
      width: 80px;
      height: 70px;
      object-fit: contain;
    }

    .name {
      color: #333;
      font-family: Inter600;
      font-size: 14px;
    }
  }
}

.css-1ty026z {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 15px !important;
}

.Model__Dialog__container {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-height: 85vh !important;
  }
}

//  Google translate css
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important; // add !important if required
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

#goog-gt-tt {
  display: none !important;
}

#google_translate_element {
  margin-top: 12px !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none !important;
}

//*Non't Need Transaltion for Promotional ALL Page Content*//
.notranslate {
  unicode-bidi: plaintext;
}
