.activities__container {
    margin-top: 1rem;
    font-family: Manrope600 !important;
    width: 100%;
    // height: 100%;
    overflow: auto;
    overflow-x: hidden;
    max-width: 15s00px;
    margin: 30px auto;
    padding: 0px 10px;
    margin-bottom: 1rem;

    /* below for hide the scroll */
    .body_scroll_container::-webkit-scrollbar {
        width: 0px !important;
    }

    .body_scroll_container {
        overflow: auto;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 64vh;

        // /* below for hide the scroll */
        // .body_scroll_container::-webkit-scrollbar {
        //     width: 0px !important;
        // }
    }

    .background_circle_1 {
        content: "";
        position: absolute;
        top: 0;
        right: 70%;
        bottom: 85%;
        left: 0%;
        z-index: 0;
        border-radius: 50%;
        opacity: 0.5;
        background: radial-gradient(circle, #FCC16A 0, #FFC812 50%, #fff 100%);
        filter: blur(250px);
    }

    .background_circle_2 {
        content: "";
        position: absolute;
        top: 35%;
        right: 0%;
        bottom: 30%;
        left: 65%;
        z-index: 0;
        border-radius: 50%;
        opacity: 0.5;
        background: radial-gradient(circle, #FFC812 0, #FFC812 50%, #fff 100%);
        filter: blur(250px);
    }

    .activities_Img_wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 160px;
        height: 60px;
        border-radius: 15px;
        background: #fff;
        padding: 5px 15px;
        cursor: pointer;

        .activities__text {
            color: #000;
            font-size: 20px;
            font-family: Manrope700;
        }
    }

    .scroll__container {
        height: 100%;
        width: 100%;
        max-height: calc(100vh - 220px);
        overflow: auto;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    // /* width */
    // .scroll__container::-webkit-scrollbar {
    //     width: 0px !important;
    // }

    // /* Track */
    // ::-webkit-scrollbar-track {
    //     background: transparent;
    // }

    // /* Handle */
    // ::-webkit-scrollbar-thumb {
    //     background: transparent;
    // }

    .search_field_wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 14px;

        .activitiesCard_Filter {
            width: 100px;
            height: 46px;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            padding: 10px;
            gap: 10px;
            border-radius: 15px;
            background: #FFF;
            box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);

            .FilterIcon {
                width: 13px;
                height: 13.949px;
                color: #333333;
                object-fit: contain;
            }

            .Filter__text {
                color: #333;
                font-size: 16px;
                font-family: Inter500;
            }
        }

        .search__autocomplete {
            width: 100% !important;
            max-width: 482px !important;
            border-radius: 15px;
            background: #FFF;
            box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
            border: none;
            position: relative;

            input::placeholder {
                // color: #ADB5BE;
                font-size: 14px;
                font-family: Inter500;
            }

            .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
                padding: 5px !important;
                border-radius: 15px !important;
            }

            .search_button_eng {
                // padding: 8px 12px;
                cursor: pointer;
                position: absolute;
                right: 17px;
                top: 15px;
            }

            .search_button_arb {
                // padding: 8px 12px;
                cursor: pointer;
                position: absolute;
                left: 17px;
                top: 15px;
            }

        }
    }

    .activitiesSports__container {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        // background: #FFFBF6;

        .activitiesSports {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;
            // margin-top: 1.5rem;


            .activitiesSports_Img_wapper {
                border-radius: 12px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(216, 216, 216, 0.25);
                width: auto;
                padding: 0px 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                width: fit-content;
                height: 60px;
                gap: 10px;

                img {
                    width: 34.16px;
                    height: 32.002px;
                }

                span {
                    margin-left: 5px;
                    color: #333;
                    font-size: 12px;
                    font-family: Inter600;
                }
            }

            .show_more_category {
                background-color: rgb(255, 242, 227);
                color: rgb(240, 150, 44);
                border-radius: 12px;
                cursor: pointer;
                width: 150px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .activitiesSports__contants {
            padding-bottom: 20px;

            .text_wapper {

                margin: 20px;

                .Sweat {
                    color: #F0962C;
                    font-size: 24px;
                    font-family: Manrope800;
                    margin-right: 5px;
                }

                .out {
                    color: #333;
                    font-size: 24px;
                    font-family: Manrope600;
                }
            }

            .leftImg {
                border-radius: 14px;
                width: 100%;
            }

            .rightImg {
                height: 100%;
                width: 100%;
                border-radius: 14px;
            }

            .flex-column {
                justify-content: space-between;
            }
        }

    }

    .map__ShowMore_btn {
        color: #FFAE4F;
        font-size: 16px;
        font-family: Inter600;
        text-align: end;
        width: 100%;
        cursor: pointer;
        padding-right: 30px;
    }

    .ActivitiesMaps__container {
        padding: 10px;
        background: #FFF;
        height: 100%;

    }

    .ActivitiesMaps__view {
        border-radius: 12px;
        background: #FFF;
        // padding: 10px;
        // width: 99%;
        // min-height: 350px;
        // margin-top: 30px;
        text-align: -webkit-center;
        position: relative;
        height: 100%;

        .activity__viewMap {
            position: absolute;
            right: 15px;
            bottom: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            border-radius: 15px;
            background: rgba(255, 255, 255, 0.45);
            box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
            backdrop-filter: blur(2.5px);
            padding: 10px;
            cursor: pointer;

            span {
                color: #333;
                font-size: 13px;
                font-family: Manrope700;
            }
        }

    }

    .Activities__Near_sports {
        // width: 100%;
        width: 99%;
        margin-top: 2rem;

        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        ::-webkit-scrollbar-thumb {
            // background: radial-gradient(circle, #FFED99 10%, #FFE7B3 0%);
            background: radial-gradient(circle, #FFC812 10%, #FCC16A 0%);
            border-radius: 7px;
        }
    }

    .css-oggqqf-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
        border-radius: 24px !important;
    }

    .main_content {

        position: relative;
        cursor: pointer;
        height: 100%;
        // .discount_wrap_one{
        //     bottom: 14px !important;
        // }
        //  .discount_wrap_two{
        //     bottom: -10px !important;
        //  }
        .discount__wapper {
            // max-width: 190px;
            width: 100%;
            height: 60px;
            position: absolute;
            bottom: 0;
            border-radius: 3.729px;
            background: rgba(40, 40, 40, 0.49);
            backdrop-filter: blur(4.351078987121582px);
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .discount__flex__column {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            .discount__flex__row {
                display: flex;
                flex-direction: row;
                gap: 4px;
            }

            .free_badge {
                color: #339cfe;
                font-family: Manrope600;
                font-size: 62.5%;
                padding: 0px 5px !important;
                padding-bottom: 0 !important;
                border-radius: 8px;
                background: #fefeff;
                height: 15px;
            }

            .discount__text__contant {
                color: #fefeff;
                font-family: Manrope700;
                font-size: 75%;
            }

            .discount__smalltext__contant {
                color: #fefeff;
                font-family: Manrope600;
                font-size: 62.5%;
            }

            .Discount_Symbole {
                width: 17.997px;
                height: 17.995px;
                object-fit: contain;
            }
        }
    }
}

@media only screen and (max-width: 760px) {

    // .activitiesSports__contants {
    //     img {
    //         max-width: 250px;
    //         max-height: 250px;
    //         margin: 10px 0;
    //         border-radius: 14px;
    //     }
    // }
}

@media only screen and (max-width: 991px) {

    .activitiesSports__contants {
        img {
            margin: 10px 0;
            border-radius: 14px;
        }
    }
    .map__ShowMore_btn {
        margin-top: 15px;
    }
    .row-overide-activity{
        // padding-right: initial !important;
        .input-style-resp{
            margin-bottom: 0.8rem !important;
        }
        .activity-filter-respons{
            .filter_container{
                padding-top: initial !important;
            }
            ::-webkit-scrollbar {
                height: 0px !important;
                width: 0px !important;
             }
        }
    }
    .RowOverideActivityLang_Eng{
        padding-right: initial !important;
    }
    .RowOverideActivityLang_Arab{
        padding-left: initial !important;
    }
    //
    // .row-overide-activiList,.row-overide-activiList-chield{
    //     padding-right: initial !important;
    // }
    .RowOverideActivity_Eng{
        padding-right: initial !important;
    }
    // .RowOverideActivity_Arab{
    //     margin-left: initial !important;
    // }
    //
    .activities__container {
        .main_content {
            .discount_wrap_one {
                bottom: 14px !important;
            }
            .discount_wrap_two {
                bottom: -10px !important;
            }
        }
    }
}


.filterActive_Activities {
    color: #FFAE4F !important;
}

.filter_Activities_rightIcon {
    color: #B6B6B6 !important;
    font-size: 12px !important;
    height: 14px !important;
}

.filterActive_Activities_rightIcon {
    color: #FFAE4F !important;
    font-size: 12px !important;
    height: 14px !important;
}

// * Filter__drp   /*
.Filter__drp_top_wapper_Activities {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    margin: 7px;
    gap: 5px;

    .left__icon {
        background-color: rgba(255, 174, 79, 0.12);
        color: #FFAE4F !important;
        padding: 4px;
        border-radius: 4px;
        cursor: pointer;
    }

    .title {
        color: #333;
        font-size: 14px;
        font-family: Inter600;
    }

}


.Filter__drp__bottom_btn_Activities {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 5px;

    .resetBtn {
        border-radius: 29px;
        border: 1px solid #FFAE4F !important;
        background-color: #fbfbfb;
        color: #FFAE4F;
        font-size: 12px;
        font-family: Inter400;
        width: 89px;
        height: 36px;
    }

    .applyBtn {
        border-radius: 29px;
        background: #FFAE4F;
        width: 89px;
        height: 36px;
        color: #FDFEFF;
        font-size: 12px;
        font-family: Inter500;
    }
}

@media (min-width: 1420px) {
    .activities__container {
        .scroll__container {
            max-height: 70vh !important;
        }
    }
}

//************For Mobile Responsive*********//
@media screen and (max-width: 576px) {
    .rightImg-two {
        margin-top: 4%;
    }
    .rightImg{
        padding: 10px 0px;
    }
    .activities__container
    .activitiesSports__container
    .activitiesSports
    .activitiesSports_Img_wapper{
        height:  auto !important;
        padding: 0px 10px;
         img{
          padding: 3px;
    }
    span {
        font-size: 10px;
    }
}
.show_more_category{
    width: 135px !important;
    height: 30px !important;
    font-size: 14px;
}
}

@media screen and (max-width: 991px) {
    .flex-column__text {
        width: 100%;
    }
}

@media screen and (max-width: 720px) {
    .activities__container {
        overflow: hidden;
        margin: 0 !important;


    }

    .activitiesSports_Img_wapper {
        width: auto !important;
    }

    .ActivitiesMaps__container {
        margin-top: 10px;
        height: 400px !important;
    }

    .activity_top {
        gap: 7px !important;
    }

    .StarIcon {
        font-size: 12px !important;
        color: #FCB040 !important;
    }

    .activities__camp_dates {
        display: none !important;
    }

    .Activities__Near_sports {
        margin-top: 0px !important;
    }
}

@media (min-width: 720px) {
    .StarIcon {
        font-size: 18px !important;
        color: #FCB040 !important;
    }
}

@media screen and (max-width: 991px) {
    .ActivitiesMaps__container {
        margin-top: 10px;
        height: 400px !important;
    }
}