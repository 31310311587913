.offer_cards_wrapper_arab {
  margin-left: initial !important;
  margin-right: 16px !important;
}

.offer_cards_wrapper {
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 45px;

  .scroll__wapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  /* width */
  .scroll__wapper::-webkit-scrollbar {
    width: 0px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }


  .offer_title {
    color: #fefeff;
    font-family: Manrope700;
    font-size: 14px;
  }

  .offer_sub_title {
    color: #fefeff;
    font-family: Manrope600;
    font-size: 10px;
  }

  .view_button {
    border-radius: 10px;
    border: 0.5px solid #fff;
    background: rgba(255, 255, 255, 0.18);
    color: #fff;
    font-family: Manrope600;
    font-size: 12px;
    padding: 4px 10px;

    &:hover {
      cursor: pointer !important;
      z-index: 1000 !important;
    }
  }

  .offer_card_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100% !important;
    height: 300px;
  }

  .offer_card_container.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 360px;
    height: 300px;
    transform: scale(1.1);
    transition: all 1s linear;
  }

  .offer_details_container {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 100px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: linear-gradient(37deg,
        rgba(4, 120, 239, 0.6) 0%,
        rgba(21, 227, 255, 0.6) 100%);
    backdrop-filter: blur(14px);
    padding: 20px;
    box-sizing: border-box;
  }

  .offer_title_description_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .offers_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .offer_card {
    margin-right: 12px;
    width: calc(100% / 4);
    transition: width 750ms ease-in-out;
    transition-delay: 250ms;
    height: 300px;
    cursor: pointer;
  }

  .offer_card_arab {
    margin-right: initial !important;
    margin-left: 12px !important;
  }

  .offer_card:hover {
    width: 31%;
  }

}

@media (max-width: 990px) {
  .offer_cards_wrapper {
    .scroll__wapper {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px 0;
    }

    .offer_card {
      margin-right: 12px;
      width: 45% !important;
      height: 300px;
    }

    .offer_card_arab {
      margin-right: initial !important;
      margin-left: 12px !important;
    }
  }
}

@media (max-width: 520px) {
  .offer_cards_wrapper {
    .scroll__wapper {
      flex-direction: column !important;
      flex-wrap: wrap;
      gap: 15px 0;
    }

    .offer_card {
      margin-right: 12px;
      width: 95% !important;
      height: 300px;
    }

    .offer_card_arab {
      margin-right: initial !important;
      margin-left: 12px !important;
    }
  }
}