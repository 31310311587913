.ActivitiesMaps__view {
    position: relative;
    width: 100%;
    // height: 100%;

    .Activities__card__Position {
        position: absolute;
        top: 90px;
        left: 110px;
        width: 80%;

        .activities_Img_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            border-radius: 15px;
            background: #F9F9FB;
            box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
            width: 172px;
            height: 60px;
            padding: 10px 20px;
            cursor: pointer;
            // margin-left: 56px;

            .activities__text {
                color: #000;
                font-size: 20px;
                font-weight: 700;
            }
        }

        .search_field_wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 14px;

            .activitiesCard_Filter {
                width: 100px;
                height: 46px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                padding: 10px;
                gap: 10px;
                border-radius: 15px;
                background: #FFF;
                box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);

                .FilterIcon {
                    width: 13px;
                    height: 13.949px;
                    color: #333333;
                    object-fit: contain;
                }

                .Filter__text {
                    color: #333;
                    font-size: 16px;
                    font-family: Inter500;
                }
            }

            .search__autocomplete {
                // width: 100% !important;
                min-width: 282px;
                max-width: 482px !important;
                border-radius: 15px;
                background: #FFF;
                box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
                border: none;
                position: relative;

                input::placeholder {
                    // color: #ADB5BE;
                    font-size: 14px;
                    font-weight: 500;
                }

                .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
                    padding: 5px !important;
                    border-radius: 15px !important;
                }

                .css-18nc3u2 .MuiOutlinedInput-root {
                    padding: 5px 9px !important;
                    height: 45px !important;
                }

                .search_button_eng {
                    cursor: pointer;
                    position: absolute;
                    right: 17px;
                    top: 15px;
                }

                .search_button_arb {
                    cursor: pointer;
                    position: absolute;
                    left: 17px;
                    top: 15px;
                }
            }
        }
    }

    .Activity__RadiusMapKm {
        display: flex;
        flex-direction: row;
        gap: 14px;
        margin-top: 10px;

        .RadiusMapKm__wapper {
            background-color: rgba(255, 255, 255, 1);
            border-radius: 50px;
            padding: 10px;
            display: flex;
            gap: 10px;
            cursor: pointer;

            span {
                color: #4E4E4E;
                font-family: Inter500;
                font-size: 14px;
            }
        }

        .RadiusMapKm__wapper__active {
            background-color: rgba(240, 150, 44, 1);
            border-radius: 50px;
            padding: 10px;
            display: flex;
            gap: 10px;
            cursor: pointer;

            span {
                color: #FFF;
                font-family: Inter500;
                font-size: 14px;
            }
        }
    }

}

@media (max-width: 990px) {
    .ActivitiesMaps__view {
        .Activities__card__Position {
            position: absolute;
            top: 90px;
            left: 10px;
        }
    }
}

@media screen and (max-width: 720px) {
    .Activities__card__Position {
        .search_field_wrapper {
            .search__autocomplete {
                min-width: 230px !important;
            }
        }
    }
}

@media screen and (max-width:991px) {
        .ActivitiesMaps__view {
        .search_field_wrapper{
             display: initial !important;
        }
        .search__autocomplete{
          margin-bottom: 1rem;
          max-width: 400px;
        }
        .filter_container{
          padding-top: initial !important;
        }
        ::-webkit-scrollbar{
          height: 0px !important;
        }
      }
      .Activities__card__Position
       .activities_Img_wapper{
           margin-bottom: 10px;
           height: 50px !important;
      }
      }