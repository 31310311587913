.University__Detailes__rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
    gap: 10px;

    .Detailes__rating__contant {
        display: flex;
        gap: 6px;

        span {
            color: #333;
            font-size: 14px;
            font-family: Inter500;
        }

        div {
            color: #12CC92;
            font-size: 14px;
            font-family: Inter500;
        }
    }
}

.profile_image__map_university {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 7px;
}

// .StarIcon {
//     color: rgba(252, 176, 64, 1) !important;
//     font-size: 18px !important;
// }

.info_location {
    max-width: 190px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gm-style-iw {
    max-width: 100% !important;
}