.TutorListView__rightSide__Pop {
  overflow: auto;
  // overflow-y: scroll;
  // max-height: 450px;
  border-radius: 16px;
  background: #fff;
  padding: 20px;
  max-width: 594px;
}


.Tutor__list__wapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 96%;
  // margin: 20px 0;
  padding: 14px;
  border-radius: 14px;
  background: #fff;
  cursor: pointer;
  margin-bottom: 20px;

  .Tutor__leftside {
    img {
      width: 200px;
      height: 180px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .Tutor__rightside {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    .Tutor__name {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between !important;

      .name {
        color: #000;
        font-size: 16px;
        font-family: Manrope700;
      }

      .imageIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    .Tutor__location {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      img {
        width: 12px;
      }

      .location {
        color: #333;
        font-size: 12px !important;
        font-family: Inter500;
      }
    }

    .Slots_Tutor {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .lable {
        border-radius: 5px;
        background: #faf3ec !important;
        padding: 6px 10px;
        color: #fcb040;
        font-size: 12px;
        font-weight: 500;
      }

      .Slots {
        color: #4e4e4e;
        font-size: 12px;
        font-weight: 500;
      }

      .SlotsYes {
        color: #04c270;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .Tutor__ranking {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .text__black {
        color: #333;
        font-size: 12px;
        font-family: Inter500;
      }

      .text__green {
        color: #f0962c;
        font-size: 12px;
        font-family: Inter500;
      }
    }

    .user__Detailes__Curriculam {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;

      .black__text {
        color: #4e4e4e;
        font-family: Inter500;
        font-size: 12px;
      }

      .blue__text {
        color: #168efe;
        font-family: Inter500;
        font-size: 12px;
      }
    }

    .user__Detailes__Course {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      background: #e8f4ff;
      padding: 4px 8px;
      width: fit-content;

      .Course {
        color: #178fff;
        font-size: 12px;
        font-family: Inter500;
      }

      .lable {
        color: #4e4e4e;
        font-size: 12px;
        font-family: Inter500;
      }

      img {
        width: 12px;
        height: 13px;
      }
    }

    .user__Detailes__location {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .grayIcons {
        width: 10px;
        height: 12px;
      }

      .graytext {
        color: #178fff;
        font-size: 12px;
        font-family: Inter500;
      }
    }

    .Tutor__rating {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .text__contant {
        color: #178fff;
        font-size: 16px;
        font-family: Inter500;
      }

      .points {
        color: #333;
        font-family: Inter500;
        font-size: 12px;
      }
    }
  }
}

.TutorCard_Img_wapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 18px;
  border: 1px solid #f4f2f9;
  background: #fff;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  width: fit-content;
  padding: 10px 20px;
  cursor: pointer;

  h5 {
    color: #000;
    font-family: Manrope700;
    font-size: 20px;
  }
}

.TutorCard_Filter_wapper {
  width: 100px;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  gap: 10px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);

  .FilterIcon {
    width: 13px;
    height: 13.949px;
    color: #333333;
    object-fit: contain;
  }

  .Filter__text {
    color: #333;
    font-size: 16px;
    font-family: Inter500;
  }
}

.Maps__view__listPage {
  border-radius: 12px;
  background: #fff;
  width: 100%;
  height: 320px;
  padding: 10px;
  position: relative;
  margin-top: 10px;

  .viewMap {
    position: absolute;
    right: 18px;
    bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
    backdrop-filter: blur(2.5px);
    padding: 10px;
    cursor: pointer;

    span {
      color: #333;
      font-size: 13px;
      font-family: Manrope700;
    }
  }
}

.List__topView__wapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .List__topView__onMap {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // gap: 10px;
    // border-radius: 15px;
    // background: #f9f9fb;
    // box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    // padding: 10px 20px;
    // cursor: pointer;
    // margin-right: 45px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: fit-content;
    height: 60px;
    border-radius: 15px;
    background: #fff;
    padding: 5px 15px;
    cursor: pointer;
    margin-right: 10px;
    border: 2px solid #fff;

    span {
      color: #000;
      font-size: 16px;
      font-family: Manrope700;
    }
  }
}

/*Below for language based style*/
.tutor-view-map-english {
  width: 160px;
}

.tutor-view-map-arab {
  width: 162px;
}

.tutorList_selectcourse-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  span {
    color: #000;
    font-size: 16px;
    font-family: Manrope700;
    margin: 14px 0;
  }

  .Icon {
    color: #000 !important;
    cursor: pointer;
  }

  .IconInActive {
    color: #000 !important;
    cursor: pointer;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.Tutor__listView__Active {
  cursor: pointer;
  border: 2px solid #178fff !important;
}

.Tutor__listView__container {
  overflow-y: auto;
}

.top__contant__wapper {
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  .top__PageBtn__icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;

    .prev__icons {
      color: rgba(23, 143, 255, 1) !important;
      background-color: #F0F0F0;
      border-radius: 8px;
    }

    .icons__disable {
      color: rgba(23, 143, 255, 1) !important;
      opacity: 0.5;
      cursor: default !important;
    }

    .text__disable {
      color: #333;
      font-size: 14px;
      font-family: Manrope700;
      opacity: 0.5;
      cursor: default !important;
    }

    .next__icons {
      color: rgba(23, 143, 255, 1) !important;
      background-color: #F0F0F0;
      border-radius: 8px;
    }

    .prev__text {
      color: #333;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .top__PageBtn__icons-english {
    flex-direction: row;
  }

  .top__PageBtn__icons-arab {
    flex-direction: row-reverse;
  }

  .top__close__icons {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .expanded__icons {
      cursor: pointer;
      color: #333333 !important;
      font-size: 16px;
    }

    .close__icons {
      cursor: pointer;
      font-size: 16px;
      color: #333333 !important;
    }
  }
}

.top_cover_img_wrapper {
  position: relative;
  max-width: 530px !important;

  .card_bg_img {
    width: 269px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    padding: 0 5px;
  }

  .viewCalendar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 159px;
    height: 39px;
    border-radius: 9px;
    background: #fff;
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 1000;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }

    span {
      color: #000;
      font-family: Roboto, Arial, sans-serif !important;
      font-size: 14px;
    }
  }

  .profileImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 1);
    object-fit: cover;
    position: absolute;
    left: 20px;
    bottom: -30px;
  }

  .left_arrow {
    position: absolute;
    left: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }

  .right_arrow {
    position: absolute;
    right: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }
}

.top_cover_img_wrapper_arb {
  position: relative;
  max-width: 530px !important;

  .card_bg_img {
    width: 269px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    padding: 0 5px;
  }

  .viewCalendar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 159px;
    height: 39px;
    border-radius: 9px;
    background: #fff;
    position: absolute;
    left: 20px;
    top: 15px;
    z-index: 1000;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }

    span {
      color: #000;
      font-family: Roboto, Arial, sans-serif !important;
      font-size: 14px;
    }
  }

  .profileImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 1);
    object-fit: cover;
    position: absolute;
    right: 20px;
    bottom: -30px;
  }

  .left_arrow {
    position: absolute;
    left: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }

  .right_arrow {
    position: absolute;
    right: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }
}

.card__container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  .user__Detailes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 0px;

    .user__name {
      color: #000;
      font-size: 20px;
      font-weight: 700;
    }

    .user__icon__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center !important;
      gap: 10px;

      img {
        height: 14px;
        width: 15px;
        cursor: pointer;
      }
    }
  }

  .description__contant {
    color: rgb(51, 51, 51);
    font-size: 14px;
    font-weight: Inter500 !important;
  }

  .tutor__user__Detailes__Education {
    margin-top: 1rem;

    .grayIcons {
      width: 17px;
      height: 13px;
      object-fit: contain;
    }

    .Bluetext {
      color: #178fff;
      font-size: 14px;
      font-family: Inter600 !important;
      max-width: 115px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .graytext {
      color: #333;
      font-size: 14px;
      font-family: Inter500 !important;
    }

    .StarIcon {
      color: #fcb040 !important;
      width: 18px;
      height: 17px;
    }

    .flexDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
    }

    .detailed__container__wapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 18px;

      .lable {
        font-size: 14px;
        font-family: Inter500;
      }
    }
  }

  .user__Detailes__Grade {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .Bluetext {
      color: #2396f2;
      font-size: 14px;
      font-weight: 600;
    }

    .lable {
      color: #4e4e4e;
      font-size: 14px;
      font-weight: 500;
    }

    img {
      width: 12px;
      height: 16px;
    }
  }

  .user__Detailes__fess {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0px;
    margin-top: 12px;

    .Avg__Fees {
      color: #333333 !important;
      // font-size: 16px;
      // font-weight: Manrope700;
      font-size: 14px !important;
      font-family: Inter500 !important;
    }

    .title {
      color: #178fff;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .user__Detailes__Courses {
    padding: 7px 10px;

    .title {
      color: #000;
      font-size: 16px;
      font-weight: Manrope700;
    }

    .badge_wrapeer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 6px;
      max-width: 400px;

      .badge__container {
        border: 1px solid #d8d8d8;
        padding: 2px 8px;
        border-radius: 3px;
        margin: 0;
        cursor: pointer;

        span {
          color: #178fff;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .badge__container-Active {
        border: 1px solid #178fff;
        padding: 2px 8px;
        border-radius: 3px;
        margin: 0;
        cursor: pointer;
        background-color: #178fff;

        span {
          color: #fff;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  .display-container2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 14px;

    .courses-div {
      border: 0.4px solid #d8d8d8;
      background: #fafcff;
      padding: 10px;
      border-radius: 7px;
      margin: 0;
      cursor: pointer;

      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
      }

      .coursetxt {
        color: #178fff;
        font-size: 12px;
        font-family: Inter600;
      }
    }

    .courses-Active {
      border: 1px solid #178fff;
      padding: 5px 10px;
      border-radius: 7px;
      margin: 0;
      background-color: #178fff;

      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
      }

      .coursetxt {
        color: #fff;
        font-size: 12px;
        font-family: Inter600;
      }
    }
  }


  .bottom__contant__wapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    div {
      color: rgb(51, 51, 51);
      font-size: 14px;
      font-family: Manrope700 !important;
    }

    img {
      width: 21px;
      height: 20px;
      object-fit: cover;
    }

    .bottom__contant__left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 14px;

      .pdfIcon {
        cursor: pointer;
      }

      div {
        font-size: 14px;
        font-family: Manrope500 !important;
        text-align: left;
        color: #000000;
      }
    }
  }

  .Description_contant_tutor {
    .title {
      color: #000 !important;
      font-size: 18px !important;
      font-family: Manrope700 !important;
    }

    .para-content {
      color: rgb(51, 51, 51);
      font-size: 14px;
      font-family: Inter400;
    }
  }

  .model__diloge__bookSlot_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .Book-Slots-btn {
      background: linear-gradient(0deg, #178fff, #178fff);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 359px;
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 26px;
      font-family: Inter600;
      font-size: 16px;
    }

    .free_session_Btn {
      max-width: 359px;
      width: 100%;
      height: 56px;
      border-radius: 26px;
      border: 1px solid #b0cce7 !important;
      background: #fff;
      color: #178fff;
      font-family: Inter600;
      font-size: 16px;
    }
  }
}

.TutorsMapsView__container {
  ::-webkit-scrollbar {
    width: 0px;
  }

  .tutors-list-view-body {
    max-height: 61vh;
    min-height: 56vh; //for empty valuve space//
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 5rem;

    .tutors-list-view-body-two,
    .tutors-list-view-body-one {

      .TutorListView__rightSide__Pop,
      .Tutor__listView__container {
        max-height: 400px;
        overflow-y: auto;
      }

      ::-webkit-scrollbar {
        width: 6px !important;
      }

      ::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #0e69cc 0%, #adc6ff 0%);
        border-radius: 7px;
      }

    }
  }
}



@media (max-width: 990px) {
  .TutorListView__rightSide__Pop {
    max-width: 100% !important;
  }

  .top_cover_img_wrapper {
    position: relative;
    max-width: 100% !important;
  }

  .Tutor__listView__container {
    max-height: 100% !important;
  }

  .Tutor__list__wapper {
    max-width: 100% !important;
    gap: 15px;
  }

  .TutorListView__rightSide__Pop {
    max-height: 100% !important;
  }
}

//mobileView app download
@media (max-width: 636px) {
  .TutorsMapsView__container .search_field_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  //   /*subject view response*/
  //   .tutor-row-paddins-head{
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         .tutor-row-paddins{
  //         .tutors-list-view-body-one {
  //             padding-right: initial !important;
  //         }
  //   }
  // }
}

@media (max-width: 991px) {

  /*subject view response*/
  .tutor-row-paddins-head {
    display: flex;
    align-items: center;
    justify-content: center;

    .tutor-row-paddins {
      gap: 15px;

      // .tutors-list-view-body-one {
      //     padding-right: initial !important;
      // }
      .tutorsListViewBodyOneArab {
        padding-left: initial !important;
      }
    }
  }
}

@media (min-width: 1420px) {
  .Tutor__listView__container {
    max-height: 65vh !important;
  }

  .TutorListView__rightSide__Pop {
    max-height: 65vh !important;
    // margin-top: 1rem;
    max-width: 100% !important;
  }

  .top_cover_img_wrapper {
    width: 100% !important;
    max-width: 100% !important;
  }

  .Maps__view__listPage {
    height: 55vh !important;
  }

  // .TutorsMapsView__container {
  //   margin-top: 1.5rem;
  // }

  .tutor__model_pop_width {
    max-width: 98% !important;
  }
}

@media (max-width: 635px) {
  .Tutor__listView__container {
    .Tutor__list__wapper {
      display: flex;
      flex-direction: row !important;
      gap: 10px;
      width: 97%;
      margin: 20px 0;
      padding: 14px;
      border-radius: 14px;
      background: #fff;
      cursor: pointer;

      .Tutor__leftside {
        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .Tutor__rightside {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        .Tutor__name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between !important;

          .name {
            color: #000;
            font-size: 14px;
            font-family: Manrope700;
          }

          .imageIcon {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            img {
              width: 15px;
              height: 15px;
            }
          }
        }

        .Tutor__location {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            width: 12px;
          }

          .location {
            color: #333;
            font-size: 12px !important;
            font-family: Inter500;
          }
        }

        .Slots_Tutor {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .lable {
            border-radius: 5px;
            background: #faf3ec !important;
            padding: 6px 10px;
            color: #fcb040;
            font-size: 12px;
            font-weight: 500;
          }

          .Slots {
            color: #4e4e4e;
            font-size: 12px;
            font-weight: 500;
          }

          .SlotsYes {
            color: #04c270;
            font-size: 12px;
            font-weight: 500;
          }
        }

        .Tutor__ranking {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .text__black {
            color: #333;
            font-size: 12px;
            font-family: Inter500;
          }

          .text__green {
            color: #f0962c;
            font-size: 12px;
            font-family: Inter500;
          }
        }

        .user__Detailes__Curriculam {
          display: none;
        }

        .user__Detailes__Course {
          display: none;
        }

        .user__Detailes__location {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .grayIcons {
            width: 10px;
            height: 12px;
          }

          .graytext {
            color: #178fff;
            font-size: 12px;
            font-family: Inter500;
          }
        }

        .Tutor__rating {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .text__contant {
            color: #178fff;
            font-size: 16px;
            font-family: Inter500;
          }

          .points {
            color: #333;
            font-family: Inter500;
            font-size: 12px;
          }
        }
      }
    }
  }
}

//**Responsive For Extra Large Screen**//
@media screen and (min-height:860px) {
  .TutorsMapsView__container {
    .tutors-list-view-body {
      max-height: initial !important;
      margin-bottom: 8rem;
    }
  }

  .TutorListView__rightSide__Pop {
    max-height: 100vh !important;
  }

}