.TutorFilterPop_conatiner {
    width: 100%;
    // padding: 0 20px;

    .Filter_header__wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        margin-bottom: 1rem;

        .header_title_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
        }

        .header_title {
            color: #333;
            font-family: Manrope600;
            font-size: 14px;
            cursor: pointer;
            padding: 5px 14px;
        }

        .header_title_active {
            color: #FFF;
            font-family: Manrope600;
            font-size: 14px;
            cursor: pointer;
            padding: 5px 14px;
            border-radius: 29px;
        }

        .close__text {
            color: #BBBECC;
            font-family: Manrope500;
            font-size: 14px;
            cursor: pointer;
        }

        .close__text:hover {
            color: rgba(51, 51, 51, 0.69) !important;
        }
    }

    .Filter__body__contant {
        width: 100%;
        height: auto;
        min-width: 500px;
        max-height: 290px;
        overflow: auto;
        overflow-y: auto;
        border-radius: 17px;
        // background: #F5F5F5;
        padding: 20px;

        .contant__wapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .sorting_wapper {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
        }

        .sorting_text_ {
            color: #333;
            font-family: Inter400;
            font-size: 14px;
        }

        .bobules__wapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
        }

        .title_text {
            color: #333;
            font-family: Inter500;
            font-size: 14px;
        }

        .price_range {
            color: #333;
            font-family: Manrope500;
            font-size: 12px;
        }

        .blue_text {
            color: #339CFE;
            font-family: Inter500;
            font-size: 14px;
        }

        .contant_text {
            display: inline-flex;
            height: 40px;
            padding: 10px 14px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border-radius: 29px;
            cursor: pointer;
            font-family: Manrope500;
            font-size: 12px;
            border: 1px solid #CBDCE1;
            background: #FBFBFB;
            color: #333;
            min-width: 65px;

        }

        // .school_active{
        //     background: #178FFF;
        //     color: #FFF;
        //     border: 1px solid #178FFF;
        // }
    }

    .Filter__footer__wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        margin-top: 1rem;

        .applyBtn {
            width: 95px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 26px;
            // background: #178FFF;
            color: #FFF;
            font-family: Inter500;
            font-size: 14px;
        }

        .resetBtn {
            width: 95px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 26px;
            border: 1px solid #CBDCE1;
            color: #333;
            font-family: Inter500;
            font-size: 14px;
        }
    }
}

@media screen and (max-width:490px) {
    .TutorFilterPop_conatiner
    .Filter__body__contant{
         min-width: initial !important;
    }
}