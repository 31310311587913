.guest-user-login-otp-model {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        width: 349px !important;
        height: auto !important;
        border-radius: 18px;
    }

    .user-content-container {
        margin: 1rem;
        position: relative;

        .guest-user-otp-head {
            display: flex;
            align-items: baseline;
            margin-bottom: -8px;
            >img {
                margin: 0px 10px 0px 0px;
            }

            >p {
                font-family: Manrope700;
                font-size: 14px;
                color:#333333;
            }
        }
        .guest-user-otp-body{
              display: flex;
              flex-direction: column;
              align-items: center;
              align-content: flex-start;
              margin: 40px 0px;
              >div:first-child{
                font-family: Inter400;
                font-size: 14px;
                color: #333333;
                margin-left: -23px;
                margin-bottom: 27px;
                >span{
                    font-family: Inter700;
                }
              }
        }
    }
}

.guest-user-model-timing{
    font-family: Inter500 !important;
    font-size: 12px !important;
    color:#04D6FB;
    margin-top: 15px;
}

.guest-user-verify-button{
    display: flex;
    align-items: center;
    justify-content: center;
>Button{
    width: 325px;
    height: 46px;
    background-color: #04D6FB;
    font-size: 14px;
    font-family: Inter700;
    text-transform: capitalize;
    border-radius: 16px;
}
}


.guest-usert-otp-input {
    width: 50px  !important;
    height: 60px;
    margin-left: 10px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #E7E7E7;
    border-radius: 9px;
    background-color: #F4F4F5;


    &:focus {
        border: 2px solid #04D6FB !important;
        outline: unset !important;
    }
}

.resend_otp_button {
    color: #1f2027;
    font-family: Inter400;
    font-size: 14px;
    text-align: center;
}

