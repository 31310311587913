.UniversityMapsView__container {
  position: relative;
  width: 100%;
  height: 100%;

  .UniversityCard_position {
    position: absolute;
    top: 90px;
    left: 110px;
    width: 80%;
  }

  .UniversityCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0 12px;
    width: fit-content;
    height: 60px;
    border-radius: 15px;
    background: #fff;
    margin-bottom: 10px;
    cursor: pointer;

    img {
      width: 23px;
      height: 19px;
    }

    .University__text {
      color: #000;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .search_field:focus {
    outline: none;
  }

  .search_field_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 14px;
  }

  .UniversityCard_Filter {
    width: 46px;
    height: 46px;
    border-radius: 8px;
    border: 1px solid #f4f2f9;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search_field {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    font-size: 14px;
    width: 100%;
    height: 46px;
    padding: 14px 15px;
  }

  .search_field::placeholder {
    color: #adb5be;
    font-size: 14px;
    font-weight: 500;
  }

  .search_button {
    padding: 16px 12px;
    cursor: pointer;
  }

  .search_button_icon_wrapper {
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 48px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .search__autocomplete {
    // width: 100% !important;
    min-width: 270px !important;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    position: relative;

    input::placeholder {
      font-size: 14px;
      font-weight: 500;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 5px !important;
      border-radius: 15px !important;
    }

    .search_button_eng {
      cursor: pointer;
      position: absolute;
      right: 17px;
      top: 15px;
    }

    .search_button_arb {
      cursor: pointer;
      position: absolute;
      left: 17px;
      top: 15px;
    }

    .Ssearch_button_arab {
      right: initial;
      left: 4px;
    }
  }

  .TutorCard__RadiusMapKm {
    display: flex;
    flex-direction: row;
    gap: 14px;
    margin-top: 10px;

    .RadiusMapKm__wapper {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 50px;
      padding: 10px;
      display: flex;
      gap: 10px;
      cursor: pointer;

      span {
        color: #4e4e4e;
        font-family: Inter500;
        font-size: 14px;
      }
    }

    .RadiusMapKm__wapper__active {
      background-color: rgba(18, 204, 146, 1);
      border-radius: 50px;
      padding: 10px;
      display: flex;
      gap: 10px;
      cursor: pointer;

      span {
        color: #fff;
        font-family: Inter500;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width:600px) {
  .UniversityMapsView__container {
    .UniversityCard_Img_wapper {
      height: 50px;
      .University__text {
        font-size: 18px;
      }
    }
    .search_field {
      font-size: 12px;
    }
    .search_field::placeholder {
      font-size: 12px;
    }
    .search_button {
      padding: 16px 12px;
    }
    .search__autocomplete {
      input::placeholder {
        font-size: 12px;
      }
    }
    .TutorCard__RadiusMapKm {
      .RadiusMapKm__wapper {
        span {
          font-size: 12px;
        }
      }
      .RadiusMapKm__wapper__active {
        span {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 636px) {
  .UniversityMapsView__container .search__autocomplete {
    min-width: 150px !important;
  }
}

@media (max-width: 990px) {
  .UniversityMapsView__container {
    .UniversityCard_position {
      position: absolute;
      top: 90px;
      left: 10px;
    }
  }
}

@media screen and (max-width:991px) {
  .UniversityMapsView__container{
  .search_field_wrapper{
       display: initial !important;
  }
  .search__autocomplete{
    margin-bottom: 1rem;
    max-width: 400px;
  }
  .filter_container{
    padding-top: initial !important;
  }
  ::-webkit-scrollbar{
    height: 0px !important;
  }
}
}

