.TutorsMapsView__container {
    position: relative;
    width: 100%;
    // height: 100%;


    .TutorCard_position {
        position: absolute;
        top: 90px;
        left: 110px;
        width: 80%;
    }

    .TutorCard_Img_wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        border-radius: 18px;
        border: 1px solid #f4f2f9;
        background: #fff;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        width: fit-content;
        padding: 10px 20px;
        cursor: pointer;
        margin-bottom: 10px;

        h5 {
            color: #000;
            font-family: Manrope700;
            font-size: 20px;
        }
    }

    .TutorCard_Filter_wapper {
        width: 100px;
        height: 46px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        gap: 10px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);

        .FilterIcon {
            width: 13px;
            height: 13.949px;
            color: #333333;
            object-fit: contain;
        }

        .Filter__text {
            color: #333;
            font-size: 16px;
            font-family: Inter500;
        }

    }

    .search_field_wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20px;
        // margin-top: 10px;

        .search_field:focus {
            outline: none;
        }

        .search_button {
            padding: 8px 12px;
            position: absolute;
            right: 12px;
            cursor: pointer;
        }

        .search__autocomplete {
            // width: 100% !important;
            // min-width: 282px;
            // max-width: 482px !important;
            border-radius: 15px;
            background: #FFF;
            box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
            border: none;
            position: relative;
            min-width: 270px !important;

            input::placeholder {
                // color: #ADB5BE;
                font-size: 14px;
                font-family: Inter500;
            }

            .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
                padding: 5px !important;
                border-radius: 15px !important;
            }

            .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
                min-width: 230px !important;
            }

            .css-18nc3u2 .MuiOutlinedInput-root {
                padding: 5px 9px !important;
                height: 45px !important;
            }

            .search_button_eng {
                cursor: pointer;
                position: absolute;
                right: 17px;
                top: 15px;
            }

            .search_button_arb {
                cursor: pointer;
                position: absolute;
                left: 17px;
                top: 15px;
            }

            .tsearch_button_english {
                right: 4px;
                top: 5px;
            }

            .tsearch_button_arab {
                right: initial;
                left: 8px;
                top: 8px;
            }
        }

    }

    .ViewMore__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 89%;
        gap: 8px;

        .ViewMore__text {
            color: #333;
            font-size: 14px;
            font-family: Inter700;
            cursor: pointer;
        }

        img {
            height: 12px;
            cursor: pointer;
        }
    }

    .TutorCard__RadiusMapKm {
        display: flex;
        flex-direction: row;
        gap: 14px;
        margin-top: 10px;

        .RadiusMapKm__wapper {
            background-color: rgba(255, 255, 255, 1);
            border-radius: 50px;
            padding: 10px;
            display: flex;
            gap: 10px;
            cursor: pointer;

            span {
                color: #4E4E4E;
                font-family: Inter500;
                font-size: 14px;
            }
        }

        .RadiusMapKm__wapper__active {
            background-color: rgba(23, 143, 255, 1);
            border-radius: 50px;
            padding: 10px;
            display: flex;
            gap: 10px;
            cursor: pointer;

            span {
                color: #FFF;
                font-family: Inter500;
                font-size: 14px;
            }
        }
    }

}

@media (max-width: 990px) {
    .TutorsMapsView__container {
        .TutorCard_position {
            position: absolute;
            top: 90px;
            left: 10px;
        }
    }
}

@media screen and (max-width:991px) {
    .TutorsMapsView__container {
        .search_field_wrapper {
            display: initial !important;
        }

        .search__autocomplete {
            margin-bottom: 1rem;
            max-width: 400px;
        }

        .filter_container {
            padding-top: initial !important;
        }

        ::-webkit-scrollbar {
            height: 0px !important;
        }
    }
}

@media screen and (max-width:600px) {
    .TutorsMapsView__container {
        .TutorCard_Img_wapper {
            h5 {
                font-size: 18px;
            }
        }

        .TutorCard_Filter_wapper {
            .Filter__text {
                font-size: 14px;
            }
        }

        .search_field_wrapper {
            .search__autocomplete {
                input::placeholder {
                    font-size: 12px;
                }
            }
        }

        .ViewMore__wrapper {
            .ViewMore__text {
                font-size: 12px;
            }
        }

        .TutorCard__RadiusMapKm {
            .RadiusMapKm__wapper {
                span {
                    font-size: 12px;
                }
            }

            .RadiusMapKm__wapper__active {
                span {
                    font-size: 12px;
                }
            }
        }
    }
}