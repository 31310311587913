.profile_pic_fields_wrapper {
  border-radius: 24px;
  background: #f9f9f9;
  backdrop-filter: blur(6px);
  padding: 24px;
  display: flex;
  flex-direction: row;
  // gap: 20px;
  gap: 30px;
  width: 100%;

  .profile_upload_container {
    position: relative;
    cursor: pointer;
  }

  .upload_arrow_wrapper {
    position: absolute;
    bottom: 8px;
    right: 10px;
  }

  .remove_arrow_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    cursor: pointer;
    margin-top: 12px;
  }

  .remove_arrow_text {
    color: #67a6ed !important;
    font-family: Inter400;
    font-size: 12px;
  }

  .remove_arrow_icon {
    color: #67a6ed !important;
    font-size: 16px;
  }

  .upload_arrow_container {
    background-color: #fff;
    border: 1px solid #dedede;
    padding: 8px;
    border-radius: 50%;
    width: 20px;
    height: 21px;
  }

  .upload_arrow {
    background-color: #67a6ed;
    padding: 5px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    right: 1px;
  }

  .file_input {
    display: none !important;
  }

  .profile_pic_tabs_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 30%;
    .MuiTabs-root .css-heg063-MuiTabs-flexContainer{
      background: #F4F3F8 !important;
    }
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    border-radius: 12px;
    background: #f4f3f8 !important;
    height: 40px;
    color: #888 !important;
    font-family: Inter400;
    font-size: 14px;
    border: unset !important;
    padding: 12px 18px;
    box-sizing: border-box;
    width: 100%;
  }

  .Account__drpdown {
    border-radius: 12px !important;
    background: #f4f3f8 !important;
    height: 40px !important;
    color: #888 !important;
    font-family: Inter400;
    font-size: 14px;
    border: unset !important;
    padding: 12px 18px !important;
    box-sizing: border-box;
    width: 100%;
  }

  .profile_fields_wrapper {
    width: 100%;
  }

  .cancel_button {
    border-radius: 50px;
    border: 1px solid #744fff;
    color: #744fff;
    font-family: Manrope600;
    font-size: 14px;
    padding: 18px 80px;
    margin-right: 12px;
  }

  .accounts_label_field_error_messages_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
  }

  .action_buttons_wrapper {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
  }

  .accounts_label_date_picker_field_error_messages_container {
    width: 100% !important;
  }

  .add_profile_sign_up_button {
    color: #fff;
    font-family: Manrope700;
    font-size: 14px;
    border-radius: 50px;
    background: #744fff;
    padding: 18px 80px;
    box-sizing: border-box;
    align-self: center;
  }
}


@media screen and (max-width:970px) {
  .profile_pic_fields_wrapper {
    flex-direction: column !important;
    .profile_pic_tabs_container {
      width: 100% !important;
      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
        font-size: 0.75rem !important;
        padding: 9px 14px !important;
      }
    }
    .action_buttons_wrapper {
      width: 100% !important;
      justify-content: flex-end !important;
    }
    .cancel_button,
    .add_profile_sign_up_button{
      padding: 13px 64px !important;
    }
  }
}

@media screen and (max-width:600px) {
  .profile_pic_fields_wrapper {
    margin-left: 9px !important;
    width: 96% !important;
    .remove_arrow_text{
      font-size: 0.625rem;
    }
    .remove_arrow_icon {
      font-size: 1rem !important;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
    .Account__drpdown,
    .cancel_button,
    .add_profile_sign_up_button {
      font-size: 0.75rem;
    }
    //
    .cancel_button,
    .add_profile_sign_up_button{
      padding: 8px 23px !important;
    }
  }
}

//**Below for Editicon size reduce**//
@media screen and (max-width:600px) {
.pnumber-edite-icon{
          .css-i4bv87-MuiSvgIcon-root{
              width: 0.6em !important;
              height: 0.6em !important;
          }
      }
.email-edite-icon{
  .css-i4bv87-MuiSvgIcon-root{
    width: 0.6em !important;
    height: 0.6em !important;
  }
//  .ExitEmail_icon{
//     bottom: 13px !important;
//     right: 4px !important;

//     // left: 2px;
//     // bottom: 11px;
//   }
      }
//
.profile_pic_fields_wrapper {
  .upload_arrow_wrapper {
    bottom: 0px !important;
  }
}

  }