.top_service_cards_wrapper {
  .top_service_card {
    margin-right: 10px;
    width: 382px;
    height: 100%;
    cursor: pointer;
  }

  .top_service_card_container {
    width: 290px;
    height: 100%;
    border-radius: 33px;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
  }

  .tutor_type_chip {
    border-radius: 29px;
    background: rgba(23, 143, 255, 0.1);
    color: #178FFF;
    font-family: Manrope700;
    font-size: 14px;
    padding: 8px 18px;
    text-transform: capitalize;
  }

  .activities_type_chip {
    border-radius: 29px;
    background: rgba(255, 174, 79, 0.17);
    color: #ffae4f;
    font-family: Manrope700;
    font-size: 14px;
    padding: 8px 18px;
  }

  .top_service_name {
    color: #000;
    font-family: Inter600;
    font-size: 14px;
    margin: 8px 0px 6px 0px;
  }

  .top_service_description {
    color: #545454;
    font-family: Inter500;
    font-size: 12px;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ratings {
    color: #000;
    font-family: Inter600;
    font-size: 12px;
  }

  .type_content_profile_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }

  .type_contents_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .service_profile {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer !important;
  }

  .star_group_wrapper {
    display: flex;
    flex-direction: column;
  }

  .star_group_highlighted_wrapper {
    margin-top: 15px;
    width: 100%;
  }

  .swiper-wrapper {
    padding-bottom: 30px !important;
  }
}

.tutor_explore-all__wapper {
  width: 280px;
  height: 100%;
  min-height: 266px;
  border-radius: 33px;
  padding: 44px 32px;
  background: #178FFF;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .text {
    color: #FFF;
    text-align: center;
    font-family: Manrope500;
    font-size: 20.409px;
  }

  .title {
    color: #FFF;
    text-align: center;
    font-family: Manrope600;
    font-size: 40.017px;
  }
}

.activity_find_all_explore-all__wapper {
  width: 280px;
  height: 100%;
  min-height: 266px;
  border-radius: 33px;
  padding: 44px 32px;
  background: #FFAE4F;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .text {
    color: #FFFF97;
    text-align: center;
    font-family: Manrope500;
    font-size: 20.409px;
  }

  .title {
    color: #FFFF97;
    text-align: center;
    font-family: Manrope600;
    font-size: 40.017px;
  }
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  width: auto !important;
  padding: 0px 5px !important;
}

.home_swiper {
  .swiper-wrapper {
    padding-bottom: 30px !important;
  }
 
  //
  .swiper-pagination {
    text-align: start !important;
    width: auto !important;
    left: 15px !important;
  }

  .swiper-pagination-bullet-active {
    width: 20px !important;
    border-radius: 10px !important;
  }

  .swiper-control {
    position: relative;
    bottom: 18px;
    width: 100%;
  }

  .swiper-button-prev {
    left: 0px;
  }
  .swiper-button-prev-arab{
    left: initial !important;
    right: 0px !important;
  }

  .swiper-button-next:after {
    font-size: 14px !important;
    margin-left: 15px !important;
    color: #8C92A3 !important;
    font-weight: 700 !important;
  }

  .swiper-button-prev:after {
    font-size: 14px !important;
    margin-right: 20px !important;
    color: #8C92A3 !important;
    font-weight: 700 !important;
  }
}

.home_swiper_arab{
  .swiper-wrapper {
    padding-bottom: 30px !important;
  }
 
  //
  .swiper-pagination {
    text-align: start !important;
    // width: auto !important;
    // left: 15px !important;
    left: -50px !important;
  }

  .swiper-pagination-bullet-active {
    width: 20px !important;
    border-radius: 10px !important;
  }

  .swiper-control {
    position: relative;
    bottom: 18px;
    width: 100%;
  }

  // .swiper-button-prev, .swiper-button-next {
  //   // left: 0px;
  //   left: 729px !important;
  // }

  .swiper-button-next-arab{
    right: 196px !important;
    left: initial !important;
  }

  .swiper-button-next:after {
    font-size: 14px !important;
    // margin-left: 15px !important;
    margin-left: -33px !important;
    color: #8C92A3 !important;
    font-weight: 700 !important;
  }

  .swiper-button-prev:after {
    font-size: 14px !important;
    margin-right: 20px !important;
    color: #8C92A3 !important;
    font-weight: 700 !important;
  }
}

@media screen and (max-width: 750px) {
  .home_swiper_arab{
  .swiper-button-next-arab{
    right: 230px !important;
    left: initial !important;
  }
}
}