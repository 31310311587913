// Tutor__Detaile__view

.Tutor__Detaile__view {
  margin: 0 auto;
  margin-top: 10px;
  display: block;
  margin-bottom: 5rem;

  .card_bg_img {
    width: 269px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    padding: 0 5px;
    user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
  }

  .tittle-div {
    border-radius: 15px;
    background: #f9f9fb;
    width: auto;
    max-width: 155px;
    height: 60px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .user__Detailes__Education {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0px;
    gap: 10px;

    .grayIcons {
      width: 12px;
      height: 14px;
    }

    .Bluetext {
      color: #178fff;
      font-size: 14px;
      font-family: Inter600;
    }

    .graytext {
      color: #333;
      font-size: 14px;
      font-family: Inter500;
    }

    .StarIcon {
      color: #fcb040 !important;
      width: 18px;
      height: 17px;
    }
  }

  .user__Detailes__Grade {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    .Bluetext {
      color: #2396f2;
      font-size: 14px;
      font-family: Inter500;
    }

    .lable {
      color: #4e4e4e;
      font-size: 14px;
      font-family: Inter400;
    }

    img {
      width: 14px;
      height: 16px;
    }
  }

  .text-tittle {
    font-family: Manrope700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  .back-img {
    margin-right: 10px;
    cursor: pointer;
  }

  .dback-img-arab {
    margin-right: initial;
    margin-left: 10px;
    rotate: 180deg;
  }

  .tutor-icon {
    margin-right: 5px;
  }

  .dtutor-icon-arab {
    margin-right: initial;
    margin-left: 5px;
  }

  .user__Detailes__rating_detailes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .graytext {
      color: #333;
      font-family: Inter400;
      font-size: 14px;
    }
  }

  .tutorlist-conatiner {
    padding: 15px 25px;
    height: 100%;
    box-shadow: 0px 4px 39px 0px rgba(178, 178, 178, 0.25);
    border-radius: 17px;
    background: #fff;

    .display-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .iconBtn__wapper {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .iconBtn {
          background-color: transparent;
          border: 1px solid rgba(23, 143, 255, 1) !important;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 28px;
          gap: 3px;
          padding: 10px 17px;
          outline: none !important;
        }

        span {
          font-size: 12px;
          font-family: Manrope600;
          color: rgba(23, 143, 255, 1) !important;
        }
      }

      .Book-Slots-btn {
        background: linear-gradient(0deg, #178fff, #178fff);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 290px;
        border: none;
        outline: none;
        border-radius: 28px;
        height: 50px;
        margin: 10px 0px;
        font-family: Inter600;
      }

      .free_session_Btn {
        max-width: 290px;
        width: 100%;
        height: 45px;
        border-radius: 26px;
        border: 1px solid #b0cce7 !important;
        background: #fff;
        color: #178fff;
        font-family: Inter600;
        font-size: 14px;
        margin-top: 10px;
      }
    }

    .courses-div {
      border: 0.4px solid #d8d8d8;
      background: #fafcff;
      padding: 10px;
      border-radius: 7px;
      margin: 0;
      cursor: pointer;

      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
      }

      .coursetxt {
        color: #178fff;
        font-size: 12px;
        font-family: Inter600;
      }
    }

    .courses-Active {
      border: 1px solid #178fff;
      padding: 5px 10px;
      border-radius: 7px;
      margin: 0;
      background-color: #178fff;

      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
      }

      .coursetxt {
        color: #fff;
        font-size: 12px;
        font-family: Inter600;
      }
    }

    .display-div1 {
      width: 45%;
      height: 66px;
    }

    .display-div2 {
      width: 45%;
      height: 66px;
    }

    .slider-img {
      width: 100%;
      height: 140px !important;
      object-fit: cover;
      border-radius: 5px;
    }

    .tutor-image {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
    }

    .img-size {
      width: 14%;
    }

    .text14 {
      color: #000000;
      font-size: 20px;
      font-family: Manrope600;
    }

    .text10 {
      // color: rgb(51, 51, 51);
      color: #929292;
      font-size: 12px;
      font-family: Inter500;
      text-align: center;
    }

    .text11 {
      color: #2396f2;
      font-size: 17px;
      font-family: Manrope700;
    }

    .text12 {
      margin-left: 11px;
      font-family: Inter600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
    }

    .text13 {
      font-family: Inter600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #2396f2;
      white-space: nowrap;
    }

    .select-css {
      border: 0;
    }

    .display-container2 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 20px;
      gap: 14px;
    }

    .userdetails-div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .userdetails-div2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }

    .img-size5 {
      width: 25px;
      height: 17px;
    }

    .img-overall {
      padding: 4px;
      display: flex;
      flex-direction: row;
      gap: 7px;
    }

    .selectcourse-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      span {
        color: #000;
        font-size: 16px;
        font-family: Manrope700;
        margin: 5px 0;
      }

      .Icon {
        color: #000 !important;
        cursor: pointer;
      }

      .IconInActive {
        color: #000 !important;
        cursor: pointer;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .alignment1 {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }

    .display-container5 {
      cursor: grab;
      margin-bottom: 12px;
      width: 100%;
      display: flex;
    }

    .slider-container {
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    /* Make the first slide visible */
    .slide:first-child {
      display: block;
    }

    .title {
      color: #333;
      font-size: 16px;
      font-family: Manrope700;
    }

    .para-content {
      // color: rgb(51, 51, 51);
      color: #929292;
      font-size: 14px;
      font-family: Inter500;
    }

    .custom_cursor {
      display: flex;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 50px;
      height: 50px;
      background: rgba(255, 255, 255, 0);
      backdrop-filter: blur(14.5217px);
      border: 1px solid rgb(255, 255, 255);
      pointer-events: none;
      border-radius: 50%;
      z-index: 1500;
      opacity: 1;
      transition: opacity 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s,
        background 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }

    .alice-carousel__stage {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      transform-style: flat;
      -webkit-transform-style: flat;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }

    .service_card_container {
      width: 250px;
      height: 100%;
    }

    .service_card {
      width: 262px;
      height: 100%;
    }

    .dums-css {
      width: 35%;
    }

    .overall-details {
      display: flex;
      flex-direction: row;
      width: 78%;
      gap: 20%;
      flex-wrap: wrap;
    }
  }

  .bottom__contant__wapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    div {
      color: rgb(51, 51, 51);
      font-size: 14px;
      font-family: Inter700;
    }

    img {
      width: 21px;
      height: 20px;
      object-fit: cover;
    }

    .pdf__name {
      font-size: 14px;
      font-family: Manrope700;
      text-align: left;
      color: #333;
    }

    .bottom__contant__left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 14px;

      div {
        font-size: 14px;
        font-family: Manrope500;
        text-align: left;
        color: #000000;
      }
    }

  }
}

.tutor_detail_notfound_style{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
  
.pdfIcon {
  cursor: pointer;
}

.detailes__scroll__container {
  overflow: auto;
  overflow-y: scroll;
  // max-height: 400px;
  height: 100%;
  // margin-left: 15px;
  margin-left: 15px !important;
}
.DetailesScrollContainerArab{
  margin-right: 15px !important;
}

/* width */
.detailes__scroll__container::-webkit-scrollbar {
  width: 0px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

//mobileView app download
@media (max-width: 436px) {
  .Tutor__Detaile__view .tutorlist-conatiner .display-container .iconBtn__wapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 1199px) {
  .detailes__scroll__container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 100% !important;
    height: 100% !important;
    margin: 10px 0px;
  }
}

// @media (min-width: 1420px) {

//   .detailes__scroll__container {
//     max-height: 75vh !important;
//   }
// }

@media screen and (max-width:600px) {
  .Tutor__Detaile__view {
    .text14 {
      font-size: 18px;
    }

    .Bluetext,
    .graytext,
    .lable,
    .text-tittle,
    .free_session_Btn {
      font-size: 12px;
    }

    .iconBtn__wapper {
      span {
        font-size: 10px;
      }
    }

    .coursetxt,
    .text10 {
      font-size: 10px;
    }

    .text11 {
      font-size: 15px;
    }

    .text12 {
      font-size: 14px;
    }

    .text13 {
      font-size: 12px;
    }

    .selectcourse-div {
      span {
        font: 14px;
      }
    }

    .title {
      font-size: 14px;
    }

    .para-content {
      font-size: 12px;
    }

    //
    .bottom__contant__wapper {
      div {
        font-size: 12px;
      }

      .pdf__name {
        font-size: 12px;
      }

      .bottom__contant__left {
        div {
          font-size: 12px;
        }
      }
    }
  }
}