.MyWallet__container {
    backdrop-filter: blur(6px);
    margin-top: 2rem;
    width: 100%;
    margin-bottom: 2rem;

    .container__wapper {
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
        overflow-y: scroll;
        max-height: 570px;

        .title {
            color: #000;
            font-family: Manrope700;
            font-size: 18px;
        }

        .walletCard__wapper {
            position: relative;
            width: 319px !important;

            .walletCard_img {
                max-width: 319px;
                max-height: 181px;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: contain;
            }

            .walletCard__Name {
                position: absolute;
                color: #F9F8FE;
                font-family: Manrope700;
                font-size: 16px;
                left: 30px;
                top: 33px;
            }

            .walletCard__total_count {
                position: absolute;
                color: #F9F8FE;
                font-family: Manrope600;
                font-size: 16px;
                bottom: 40px;
                right: 30px;
            }

            .walletCard__total_text {
                position: absolute;
                color: #D2B1FE;
                font-family: Manrope600;
                font-size: 12px;
                bottom: 20px;
                right: 30px;
            }
        }


        .detailed__card__wapper {
            border-radius: 19px;
            background: #F7F6FC;
            padding: 15px 25px;
            display: flex;
            justify-content: space-between;

            .name__text {
                color: #000;
                font-family: Manrope700;
                font-size: 14px;
            }

            .date__text {
                color: #000;
                font-family: Manrope500;
                font-size: 14px;
                margin-top: 10px;
            }

            .amount {
                color: #000;
                font-family: Manrope700;
                font-size: 14px;
            }
        }
    }

    /* width */
    .container__wapper::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
}

/* ------> Pagination  <-------- */

.pagination__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    .custom_pagination button.Mui-selected {
        background: #744FFF;
        color: #FFF;
    }

    .CustomPagination-Arab {
        direction: ltr;

        .css-nhb8h9 {
            direction: ltr !important;
        }
    }
}

@media (max-width: 495px) {
    .MyWallet__container {
        .container__wapper {
            .title {
                font-size: 0.9rem !important;
            }

            .walletCard__Name {
                font-size: 0.9375rem !important;
                left: 21px !important;
                top: 14px !important;
            }

            .walletCard__total_count {
                font-size: 0.9rem !important;
            }

            .walletCard__total_text {
                font-size: 0.5rem !important;
            }

            .walletCard__total_text,
            .walletCard__total_count {
                right: 20px !important;
            }

            .walletCard__wapper {
                position: relative;
                width: 100% !important;

                .walletCard_img {
                    max-width: 100% !important;
                }
            }

            .detailed__card__wapper {

                .name__text,
                .date__text {
                    font-size: 0.6875rem !important;
                }

                .amount {
                    font-size: 0.6875rem !important;
                    margin-left: 15px !important;
                }
            }
        }

    }
}