.my-tickets-wrapper {
    padding: 20px;

    .card-wrapper {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        cursor: pointer;

        .ticket-card {
            //  box-shadow: none;
            padding: 20px;
            width: 45%;
            margin: 10px;
            min-height: 300px;
        }

        .image-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h5 {
                margin-bottom: 0px;
            }

            .ticket-name-location {
                margin-top: 10px !important;
            }
        }

        .theme-image {
            // height: 100px;
            // width: 100px;
            width: 100%;
            height: 150px;
            border-radius: 10px;
            object-fit: cover;

            p {
                font-size: 14px;
            }
        }

        .theme-title {
            color: #8a8a8a;
            font-family: Inter400;
            font-size: 14px;
        }

        .theme-value {
            color: #333;
            font-size: 14px;
            font-family: Inter500;
        }

        .location-icon {
            color: gray;
            font-size: 16px;
        }
    }
}

///******Responsive for ticket page******////
@media screen and (max-width: 700px) {
    .my-tickets-wrapper {
        padding: 0px !important;

        .card-wrapper {
            gap: 10px !important;
            padding: 0px !important;

            .ticket-card {
                width: 100% !important;
                margin: 0px !important;
                margin-left: 10px !important;
            }
        }

        .theme-title {
            margin-bottom: 0.5rem !important;
        }
    }
}

@media screen and (min-width: 700px) and (max-width: 950px) {
    .my-tickets-wrapper {
        padding: 0px !important;

        .card-wrapper {
            padding: 0px !important;

            .ticket-card {
                width: 46% !important;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .card-wrapper {
        .theme-image {
            height: 100px !important;
        }
    }
}

@media screen and (min-width:380px) and (max-width: 576px) {
    .theme-details {
        .row {
            div {
                width: 50% !important;
            }
        }
    }
}