.tutorcheckout-container {
  margin: 20px 15px;

  .tutoImg_wapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 4px;

    .checkout-txt {
      font-size: 20px;
      font-family: Manrope700;
      color: #000;
    }
  }

  .billingtext {
    color: #178fff;
    font-size: 18px;
    font-family: Manrope700;
    padding-left: 12px;
  }

  .billing-container {
    padding: 12px;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0px 4px 15px 0px #b4b4b440;
    background: #fff;

    .inputfieldss {
      padding: 10px 14px;

      .emailtext {
        font-size: 16px;
        font-family: Manrope500;
        text-align: left;
        color: #000;
        width: 100%;
        max-width: 200px;
      }

      .billng_contact_fields {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        .inputdesign1 {
          width: 100%;
          height: 40px;
          border-radius: 10px;
          background: #f1f5fd;
          border: 0 !important;
          padding: 3px 15px;
          outline: none;
          color: #333;
          font-family: Inter500;
          font-size: 14px;
        }

        .inputdesign1::placeholder {
          color: #9ba9c5;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .phone-num {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        input {
          border-radius: 12px;
          background: #f1f5fd;
          height: 40px;
          color: #888;
          font-size: 14px;
          border: unset;
          padding: 12px 12px 12px 44px;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }

  .OrderDetails__contants {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;

    .Order__Details {
      color: #2396f2;
      font-family: Manrope700;
      font-size: 18px;
    }

    .Order__amount {
      color: #333;
      font-family: Manrope800;
      font-size: 16px;
    }

    .left__contant {
      display: flex;
      flex-direction: column;

      .date {
        color: #178fff;
        font-family: Manrope600;
        font-size: 12px;
      }

      .course {
        color: #3c3c3c;
        font-family: Inter600;
        font-size: 14px;
        margin-top: 7px;
        margin-bottom: 3px;
      }

      .session__count {
        color: rgba(51, 51, 51, 0.75);
        font-size: 12px;
        font-family: Manrope500;
      }
    }

    .right__contant {
      color: rgba(51, 51, 51, 0.75);
      font-size: 12px;
      font-family: Manrope600;
    }
  }

  .OrderDetails__view__more {
    color: #4faaff;
    font-size: 14px;
    font-weight: 700;
    text-align: end;
    padding-right: 20px;
    cursor: pointer;
  }

  .Course__Details__bottom__contant {
    margin: 15px 0px;

    .title__wapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 5px;
    }

    .Icon {
      color: #656566 !important;
      cursor: pointer;
    }

    .IconInActive {
      color: #656566 !important;
      cursor: pointer;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .title {
      color: #333;
      font-size: 12px;
      font-family: Manrope600;
      padding-left: 20px;
    }

    .bottom__contant__wapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;

      .Spfont {
        font-size: 13px;
        color: #333;
        font-family: Manrope600;
      }
      .text-font {
        font-size: 14px;
      }
      .bottom__contant {
        display: flex;
        align-items: center;
        gap: 10px;
        .Address_select__dropdown {
          border-radius: 46px !important;
          border: 1px solid #efefef !important;
          padding: 2px 0px !important;
          margin: 0px 0px !important;
          height: 52px !important;
          max-width: 190px !important;
        }
        .text-font {
          font-size: 14px;
        }
        img {
          width: 33.228px;
          height: 31.615px;
          border-radius: 50%;
          object-fit: cover;
        }

        .name {
          color: #333;
          font-family: Manrope700;
          font-size: 14px;
        }
        .MuiSvgIcon-fontSizeMedium {
          width: 10px !important;
          height: 10px !important;
        }
      }
    }
  }

  .summary {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #fff !important;
    box-shadow: 0px 4px 15px 0px rgba(180, 180, 180, 0.25) !important;
    padding: 10px;

    .CheckOut__discount__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 0.7rem;

      .discount__card {
        height: 56px;
        border-radius: 48px;
        background: #e6f3fe;
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 0 12px;
        cursor: pointer;

        .card_flex_column {
          display: flex;
          flex-direction: column;
        }

        .card_flex_row {
          display: flex;
          flex-direction: row;
          align-items: center !important;
          gap: 8px;
        }

        .discount__Img {
          width: 25.993px;
          height: 25.99px;
          object-fit: contain;
        }

        .Black_text {
          color: #333;
          font-family: Manrope600;
          font-size: 12px;
        }

        .blue__text {
          color: #339cfe;
          font-family: Manrope600;
          font-size: 12px;
        }

        .badge_blue {
          color: #fff;
          font-family: Manrope600;
          font-size: 10px;
          padding: 0px 5px !important;
          padding-bottom: 0 !important;
          border-radius: 8px;
          background: #339cfe;
        }
      }

      .discount__card__active {
        height: 56px;
        border-radius: 48px;
        background: #339cfe;
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 0 12px;
        cursor: pointer;

        .card_flex_column {
          display: flex;
          flex-direction: column;
        }

        .card_flex_row {
          display: flex;
          flex-direction: row;
          align-items: center !important;
          gap: 8px;
        }

        .discount__Img {
          width: 25.993px;
          height: 25.99px;
          object-fit: contain;
        }

        .Black_text {
          color: #fff;
          font-family: Manrope600;
          font-size: 12px;
        }

        .blue__text {
          color: #fff;
          font-family: Manrope600;
          font-size: 12px;
        }

        .badge_blue {
          color: #339cfe;
          font-family: Manrope600;
          font-size: 10px;
          padding: 0px 5px !important;
          padding-bottom: 0 !important;
          border-radius: 8px;
          background: #fff;
        }
      }
    }

    .Price__detailes__wapper {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 1rem;

      .original-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 14px;

        .original__price__text {
          color: rgba(51, 51, 51, 0.75);
          font-family: Inter500;
          font-size: 14px;
        }

        .discount-text {
          color: #178fff;
          font-family: Inter500;
          font-size: 14px;
        }

        .total__small {
          color: rgba(0, 0, 0, 0.75);
          font-family: Manrope700;
          font-size: 14px;
        }

        .total__large {
          color: #333;
          font-family: Manrope700;
          font-size: 18px;
        }
      }
    }

    .summary-btndiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .summary__contant {
        display: flex;
        flex-direction: row;
        margin-top: 2.5rem;

        .checkbox {
          width: 16px;
          height: 16px;
          border-radius: 3px;
          border: 1px solid #178fff !important;
          background: #f7f5ff !important;
          cursor: pointer;
        }

        .paratext {
          color: rgba(51, 51, 51, 0.75);
          font-family: Manrope500;
          font-size: 12px;
        }

        .terms__text {
          color: #178fff;
          font-family: Manrope600;
          font-size: 12px;
        }
      }

      .proceedBtn {
        width: 100%;
        max-width: 359px;
        height: 56px;
        margin-top: 2rem;
        border-radius: 26px;
        background: #178fff !important;
        outline: none;
        color: #fff;
        font-size: 16px;
        font-family: Inter600;
      }

      .proceedBtn__disable {
        width: 100%;
        max-width: 359px;
        height: 56px;
        margin-top: 2rem;
        border-radius: 26px;
        background: #178fff !important;
        outline: none;
        color: #fff;
        font-size: 16px;
        font-family: Inter600;
        opacity: 0.5;
        cursor: default !important;
      }
    }
  }
}

.Tutor__OrderDetails__Dailoge {
  width: 100% !important;
  min-width: 470px !important;
  height: 100%;
  max-height: 250px;
  overflow: auto;
  overflow-y: scroll;

  .contant__wapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .Order__Details {
      color: #2396f2;
      font-family: Manrope700;
      font-size: 18px;
    }

    .Order__amount {
      color: #333;
      font-family: Manrope800;
      font-size: 16px;
    }

    .left__contant {
      display: flex;
      flex-direction: column;

      .date {
        color: #178fff;
        font-family: Manrope600;
        font-size: 12px;
      }

      .course {
        color: #3c3c3c;
        font-family: Inter600;
        font-size: 14px;
        margin-top: 7px;
        margin-bottom: 3px;
      }

      .session__count {
        color: rgba(51, 51, 51, 0.75);
        font-size: 12px;
        font-family: Manrope500;
      }
    }

    .right__contant {
      color: rgba(51, 51, 51, 0.75);
      font-size: 12px;
      font-family: Manrope600;
    }
  }
}

.Tutor__bottom__btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .backBtn {
    width: 100%;
    max-width: 319px;
    height: 50px;
    border-radius: 23px;
    background: #e8f4ff;
    color: #178fff;
    font-size: 14px;
    font-family: Inter600;
  }

  .paymentBtn {
    width: 100%;
    max-width: 319px;
    height: 50px;
    border-radius: 23px;
    background: #178fff;
    color: #fff;
    font-size: 14px;
    font-family: Inter600;
  }
}

.hr-tag {
  margin: 1rem 43px !important;
}

.mobile__input {
  outline: none;
  padding: 3px 15px;
}

//mobileView app download
@media (max-width: 476px) {
  .tutorcheckout-container {
    .OrderDetails__contants {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    .Course__Details__bottom__contant .bottom__contant__wapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .bottom__contant {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .Address_select__dropdown {
          border-radius: 46px !important;
          border: 1px solid #efefef !important;
          padding: 2px 0px !important;
          margin: 0px 0px !important;
          height: 52px !important;
          min-width: 50px;
        }
      }
    }
  }
  .Tutor__OrderDetails__Dailoge {
    min-width: 100% !important;
    .contant__wapper {
      display: flex;
      flex-wrap: wrap !important;
      align-items: flex-start;
    }
  }
}
