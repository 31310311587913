.university__list__view {
  .Accordion__title {
    color: #000 !important;
    font-family: Manrope700 !important;
    font-size: 16px !important;
  }

  .Accordion__body_text {
    color: #000 !important;
    font-family: Manrope600 !important;
    font-size: 12px !important;
  }

  .university__listView__Active {
    cursor: pointer;
    border: 2px solid #12cc92 !important;
  }

  .university__rating__list__view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .rating__wapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    .year {
      color: #333;
      font-size: 12px;
      font-family: Inter500;
    }

    .StarIcon {
      color: #fcb040 !important;
      font-size: 14px !important;
    }

    .points {
      color: #333;
      font-size: 12px;
      font-family: Inter500;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .UniversityCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 60px;
    max-width: 180px;
    // padding-left: 63px;

    img {
      width: 23.122px;
      height: 19.818px;
    }

    .TutorImg__text {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }
  }

  .search_field_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    max-width: 582px;
  }

  .search_field:focus {
    outline: none;
  }

  .search_field_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 14px;
  }

  .TutorCard_Filter {
    width: 46px;
    height: 46px;
    border-radius: 8px;
    border: 1px solid #f4f2f9;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search_field {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    font-size: 14px;
    width: 100%;
    height: 46px;
    padding: 14px 15px;
  }

  .search_field::placeholder {
    color: #adb5be;
    font-size: 14px;
    font-family: Inter500;
  }

  .search_button {
    padding: 16px 12px;
    cursor: pointer;
  }

  .search_button_icon_wrapper {
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 48px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .search__autocomplete {
    width: 74% !important;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    position: relative;
    flex: 1;

    input::placeholder {
      font-size: 14px;
      font-family: Inter500;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 5px !important;
      border-radius: 15px !important;
    }

    .search_button_eng {
      cursor: pointer;
      position: absolute;
      right: 17px;
      top: 15px;
    }

    .search_button_arb {
      cursor: pointer;
      position: absolute;
      left: 17px;
      top: 15px;
    }
  }

  .Maps__view__listPage {
    border-radius: 12px;
    background: #fff;
    width: 93%;
    height: 320px;
    padding: 10px;
    position: relative;
    // margin-top: 2rem;
    margin-top: 10px;

    .viewMap {
      position: absolute;
      right: 18px;
      bottom: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.45);
      box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
      backdrop-filter: blur(2.5px);
      padding: 10px;
      cursor: pointer;

      span {
        color: #333;
        font-size: 13px;
        font-family: Manrope700;
      }
    }
  }

  .University__listView__container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 350px;
  }

  .TutorCard_Filter_wapper {
    width: 100px;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    gap: 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);

    .FilterIcon {
      width: 13px;
      height: 13.949px;
      color: #333333;
      object-fit: contain;
    }

    .Filter__text {
      color: #333;
      font-size: 16px;
      font-family: Inter500;
    }
  }

  .List__topView__wapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .List__topView__onMap {
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // gap: 10px;
      // border-radius: 15px;
      // background: #f9f9fb;
      // box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
      // padding: 10px 20px;
      // cursor: pointer;
      // margin-right: 45px;
      // width: fit-content;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: fit-content;
      height: 60px;
      border-radius: 15px;
      background: #fff;
      padding: 5px 15px;
      cursor: pointer;
      margin-right: 10px;
      border: 2px solid #fff;

      span {
        color: #000;
        font-size: 16px;
        font-family: Manrope700;
      }
    }
  }

  .school__list__wapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 96%;
    margin: 10px 0;
    padding: 12px;
    border-radius: 14px;
    background: #fff;

    .university__leftside {
      img {
        width: 200px;
        height: 160px;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .university__rightside {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .university__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between !important;

        .name {
          color: #000;
          font-size: 16px;
          font-family: Manrope700;
          cursor: pointer;
        }

        .imageIcon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            height: 20px;
            width: 18px;
            cursor: pointer;
          }
        }
      }

      .university__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }

        .location {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
          max-width: 330px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .university__ranking {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .text__black {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }

        .text__green {
          color: #12cc92;
          font-size: 12px;
          font-family: Inter500;
        }
      }

      .university__rating {
        display: flex;
        flex-direction: row;

        .text__contant {
          color: #eebb12;
          font-size: 16px;
          font-family: Inter500;
        }
      }
    }
  }

  .top__contant__wapper__university {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .top__PageBtn__icons {
      display: flex;
      flex-direction: row;
      align-items: end;
      gap: 10px;
      cursor: pointer;

      .prev__icons {
        color: rgba(18, 204, 146, 1) !important;
        background: #e8f5f1;
        border-radius: 8px;
      }

      .next__icons {
        color: rgba(18, 204, 146, 1) !important;
        background: #e8f5f1;
        border-radius: 8px;
      }

      .icons__disable {
        color: rgba(18, 204, 146, 1) !important;
        opacity: 0.5;
        cursor: default !important;
      }

      .text__disable {
        color: #333;
        font-size: 14px;
        font-weight: 700;
        opacity: 0.5;
        cursor: default !important;
      }

      .prev__text {
        color: #333;
        font-size: 14px;
        font-family: Manrope700;
      }
    }

    .top__close__icons {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .expanded__icons {
        cursor: pointer;
        color: #333333 !important;
        font-size: 16px;
      }

      .close__icons {
        cursor: pointer;
        font-size: 16px;
        color: #333333 !important;
      }
    }
  }

  .top_cover_img_wrapper {
    position: relative;
    max-width: 530px !important;

    .card_bg_img {
      width: 269px;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
      padding: 0 5px;
    }

    .profileImg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 1);
      object-fit: cover;
      position: absolute;
      left: 20px;
      bottom: -30px;
    }

    .left_arrow {
      position: absolute;
      left: 9px;
      top: 63px;
      z-index: 1000;
      border-radius: 24px;
      background: rgba(240, 240, 240, 0.27);
      backdrop-filter: blur(1.3333333730697632px);
      color: #f0f0f045 !important;
    }

    .right_arrow {
      position: absolute;
      right: 9px;
      top: 63px;
      z-index: 1000;
      border-radius: 24px;
      background: rgba(240, 240, 240, 0.27);
      backdrop-filter: blur(1.3333333730697632px);
      color: #f0f0f045 !important;
    }

    .alice-carousel {
      direction: ltr !important;
    }
  }

  .top_cover_img_wrapper_arb {
    position: relative;
    max-width: 530px !important;

    .card_bg_img {
      width: 269px;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
      padding: 0 5px;
    }

    .profileImg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 1);
      object-fit: cover;
      position: absolute;
      right: 20px;
      bottom: -30px;
    }

    .left_arrow {
      position: absolute;
      left: 9px;
      top: 63px;
      z-index: 1000;
      border-radius: 24px;
      background: rgba(240, 240, 240, 0.27);
      backdrop-filter: blur(1.3333333730697632px);
      color: #f0f0f045 !important;
    }

    .right_arrow {
      position: absolute;
      right: 9px;
      top: 63px;
      z-index: 1000;
      border-radius: 24px;
      background: rgba(240, 240, 240, 0.27);
      backdrop-filter: blur(1.3333333730697632px);
      color: #f0f0f045 !important;
    }

    .alice-carousel {
      direction: rtl !important;
    }
  }

  .user__Detailes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
    padding: 3px 10px;
    gap: 15px;

    .user__name {
      color: #000;
      font-size: 16px;
      font-family: Manrope700;
    }

    .user__icon__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center !important;
      gap: 10px;

      img {
        height: 14px;
        width: 15px;
      }
    }
  }

  .card__container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    .user__Detailes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 7px 0px;

      .user__name {
        color: #000;
        font-size: 18px;
        font-family: Manrope700;
      }

      .user__icon__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center !important;
        gap: 10px;

        img {
          height: 20px;
          width: 18px;
        }
      }
    }

    .description__contant {
      color: rgb(51, 51, 51);
      font-size: 14px;
      font-family: Inter400;
      text-align: justify;
    }

    .user__Detailes__Education {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 0px;
      gap: 10px;

      .grayIcons {
        width: 12px;
        height: 14px;
      }

      .graytext {
        color: #333;
        font-size: 14px;
        font-family: Inter500;
      }

      .StarIcon {
        color: #fcb040 !important;
        width: 18px;
        height: 17px;
      }
    }

    .user__Detailes__Grade {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .lable {
        color: #4e4e4e;
        font-size: 14px;
        font-family: Inter500;
      }

      img {
        width: 12px;
        height: 16px;
      }
    }

    .user__Detailes__fess {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 7px 0px;
      margin-top: 6px;

      .Avg__Fees {
        color: #333;
        font-size: 14px;
        font-family: Inter500;
      }

      .title {
        color: #178fff;
        font-size: 16px;
        font-family: Manrope600;
      }
    }

    .user__Detailes__Courses {
      padding: 7px 0px;

      .title {
        color: #000;
        font-size: 18px;
        font-family: Manrope700;
      }

      .badge_wrapeer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 6px;
        max-width: 400px;

        .badge__container {
          border: 1px solid #d8d8d8;
          padding: 2px 8px;
          border-radius: 3px;
          margin: 0;
          cursor: pointer;

          span {
            color: #178fff;
            font-size: 12px;
            font-family: Inter600;
          }
        }

        .badge__container-Active {
          border: 1px solid #178fff;
          padding: 2px 8px;
          border-radius: 3px;
          margin: 0;
          cursor: pointer;
          background-color: #178fff;

          span {
            color: #fff;
            font-size: 12px;
            font-family: Inter600;
          }
        }
      }
    }
  }

  .View__on__map__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    padding: 7px 0px;

    .wapperDiv {
      border-radius: 7px;
      background: #e8f5f1;
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      img {
        width: 16.217px;
        height: 13.79px;
      }

      span {
        color: #0a8962;
        font-size: 12px;
        font-family: Inter600;
      }
    }
  }

  .University__detailed__container__ {

    .flexDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }

    .detailed__container__wapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 18px;
    }

    img {
      width: 20px;
      height: 20px;
    }

    span {
      color: #333;
      font-size: 14px;
      font-family: Inter500;
    }

    .number {
      color: #12cc92;
      font-size: 16px;
      font-family: Inter600;
    }
  }

  .university__description__container {
    padding: 4px 0px;

    .title {
      color: #333;
      font-size: 16px;
      font-family: Manrope700;
    }

    .contants_listpage {
      color: #333;
      font-size: 14px;
      font-family: Inter400;
      text-align: justify;
      margin-top: 10px;
    }
  }

  .university__bottom__contant {
    display: flex;
    flex-direction: column;

    .bottom__contant__wapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      span {
        color: #333;
        font-size: 16px;
        font-family: Manrope700;
      }

      div {
        color: #333;
        font-size: 14px;
        font-family: Inter400;
      }
    }
  }

  .university__tap__contant {


    ul {
      list-style: none;
      margin-top: 1rem;
    }

    ul li::before {
      content: "\25A0";
      color: #12cc92;
      font-weight: 800;
      font-size: 0.7em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: -2em;
      margin-right: 1em;
    }

    li {
      font-size: 14px;
      font-family: Inter400;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      margin-top: 7px;
    }
  }

  .university__tap__contant_arb {
    display: flex;

    span {
      color: #333;
      font-size: 14px;
      font-family: Inter600;
    }

    ul {
      list-style: none;
      margin-top: 1rem;
    }

    ul li::before {
      content: "\25A0";
      color: #12cc92;
      font-weight: 800;
      font-size: 0.7em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: 1em;
      margin-right: 1em;
    }

    li {
      font-size: 14px;
      font-family: Inter400;
      text-align: right;
      color: rgba(51, 51, 51, 1);
      margin-top: 7px;
    }
  }

  .MuiTabs-root .css-heg063-MuiTabs-flexContainer {
    background-color: none !important;
  }

  .Uiversity__Taps {
    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #333 !important;
      text-transform: capitalize !important;
      font-family: Manrope700 !important;
      font-size: 16px !important;
    }

    .css-1mrn996.Mui-selected {
      color: #333 !important;
      text-transform: capitalize !important;
      font-family: Manrope700 !important;
      font-size: 16px !important;
    }

    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
      text-transform: capitalize !important;
      color: #949494 !important;
      font-family: Manrope600 !important;
      font-size: 16px !important;
    }

    .css-axpu1l {
      background-color: #12cc92 !important;
      border-radius: 7px 11px 0px 0px !important;
      height: 5px;
    }

    .css-11yukd5-MuiTabs-indicator {
      background-color: #12cc92 !important;
      border-radius: 7px 11px 0px 0px !important;
      height: 5px;
    }
  }

  .Uiversity__accordien .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
  }

  .Uiversity__accordien .css-67l5gl {
    box-shadow: none !important;
  }

  .Uiversity__accordien .css-1aj41gs {
    box-shadow: none !important;
  }

  .Uiversity__accordien .css-1086bdv-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
  }

  .Uiversity__Programs__text {
    color: #333;
    font-family: Manrope700;
    font-size: 16px;
  }

  .Uiversity__accordien__head {
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root:hover:not(.Mui-disabled) {
      cursor: pointer;
      width: 96% !important;
      margin-left: 14px !important;
    }

    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
      width: 96% !important;
      margin-left: 14px !important;
    }
  }

  .Uiversity__accordien__body_head {
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root:hover:not(.Mui-disabled) {
      cursor: pointer;
      width: 100% !important;
      margin-left: 0px !important;
    }

    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
      width: 100% !important;
      margin-left: 0px !important;
    }
  }



  .Uiversity__accordien {

    .css-17o5nyn.Mui-expanded {
      margin: 0 !important;
    }

    .css-17o5nyn {
      margin: 0 !important;
    }

    .css-1iji0d4 {
      padding: 0 4px !important;
    }

    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
      margin: 0 !important;
    }

    .title {
      color: #000;
      font-family: Manrope700;
      font-size: 14px;
    }

    .text {
      color: #000;
      font-family: Manrope600;
      font-size: 12px;
    }

    .detailes__wapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .left__side {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 15px;

        img {
          width: 18px;
          height: 18.001px;
          object-fit: contain;
        }

        span {
          color: #7d7d7d;
          font-family: Manrope400;
          font-size: 14px;
        }
      }

      .link {
        color: #0285bd;
        font-family: Inter500;
        font-size: 14px;
        text-decoration-line: underline;
      }

      .text {
        color: #333;
        font-family: Inter500;
        font-size: 14px;
      }

      .number {
        color: #0285bd;
        font-family: Inter500;
        font-size: 14px;
      }
    }
  }

  .chat__consultant__btn__wapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;

    .chat__consultant__btn {
      width: 100%;
      max-width: 312px;
      height: 60px;
      border-radius: 52px;
      border: 2px solid rgba(23, 143, 255, 0.1);
      background: #12cc92;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }

      span {
        color: #f9f9fb;
        font-family: Inter600;
        font-size: 16px;
        font-weight: 600;
        line-height: 136.523%;
      }
    }
  }

  .schoolListView__rightSide__Pop {
    overflow: auto;
    overflow-y: scroll;
    max-height: 450px;
    border-radius: 16px;
    background: #fff;
    padding: 20px;
    max-width: 594px;
  }
}

@media (max-width: 990px) {
  .university__list__view {
    .schoolListView__rightSide__Pop {
      width: 100% !important;
      max-width: 100% !important;
      max-height: 100% !important;
    }

    .top_cover_img_wrapper {
      position: relative;
      max-width: 100% !important;
    }

    .Maps__view__listPage {
      width: 98%;
    }
  }

  .University__listView__container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 100% !important;
    height: 100% !important;
  }
}

@media (max-width: 436px) {
  .university__list__view .schoolListView__rightSide__Pop {
    padding: 10px;
  }
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 4px 16px 8px !important;
}

@media (min-width: 1420px) {
  .university__list__view {
    margin-top: 2rem;

    .University__listView__container {
      max-height: 66vh !important;
    }

    .schoolListView__rightSide__Pop {
      max-height: 66vh !important;
      max-width: 100% !important;
    }

  }

  .top_cover_img_wrapper {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1330px) and (min-width: 1080px) {
  .university__list__view {
    .school__list__wapper .university__rightside .university__location .location {
      max-width: 180px !important;
    }
  }
}

//mobileView app download
@media (max-width: 500px) {
  .school__listView__container {
    .school__list__wapper {
      display: flex;
      flex-direction: row !important;
      gap: 10px;
      width: 100% !important;
      margin: 10px 0;
      padding: 12px;
      border-radius: 14px;
      background: #fff;

      .university__leftside {
        img {
          width: 100px !important;
          height: 100% !important;
          max-height: 100px !important;
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
      }

      .university__rightside {
        display: flex;
        flex-direction: column;
        gap: 5px !important;
        width: 100%;

        .university__name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between !important;

          .name {
            color: #000;
            font-size: 14px;
            font-family: Manrope700;
            cursor: pointer;
          }

          .imageIcon {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-right: 7px;

            img {
              width: 20px;
              height: 17px;
              cursor: pointer;
            }
          }
        }

        .university__location {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            width: 12px;
          }

          .location {
            color: #333;
            font-size: 12px;
            font-family: Inter500;
            max-width: 180px !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .marginTop {
          margin-top: 0px !important;
        }

        .university__ranking {
          display: none !important;
        }

        .university__rating {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          cursor: pointer;
          gap: 12px;

          .text__green {
            color: #12cc92;
            font-size: 12px;
            font-family: Inter600;
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .text__black {
            color: #333333;
            font-size: 12px;
            font-family: Inter500;
          }
        }
      }
    }
  }
}