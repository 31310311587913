.spinner_container {
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2000;
  background-color: #00000014;
  pointer-events: none;

  .loader {
    position: relative;
    display: block;
    width: 44px;
    height: 44px;
    font-size: 15px;
    box-shadow: 9px 9px 33px #d1d1d1, -9px -9px 33px #ffffff;
  }

  .loader::before,
  .loader::after {
    content: '';
    position: absolute;
    display: block;
  }

  .loader_bubble::before {
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
  }

  .loader_bubble {
    background: linear-gradient(180deg, #744FFF 10%, #744FFF 100%);
    border-radius: 50%;
    transform-origin: 50% 50%;
    animation: bubble7234 1200ms cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  }

  @keyframes bubble7234 {
    0% {
      transform: translate3d(0, 0, 0) rotate(0);
    }

    100% {
      transform: translate3d(0, 0, 0) rotate(360deg);
    }
  }
}