.location-dropdown-wrap {
    display: flex;
    gap: 10px;

    .my-country-dropdown-one {
        width: 100%;
        font-family: Manrope400 !important;
        font-size: 14px !important;
        height: 36px;
        border: 1px solid hsl(0, 0%, 70%) !important;
        border-radius: 3px !important;
        padding: 0px 5px;
    }

    .my-country-dropdown-two {
        width: 110px;
        font-family: Manrope400 !important;
        font-size: 14px !important;
        height: 36px;
        border: 1px solid hsl(0, 0%, 70%) !important;
        border-radius: 3px !important;
        padding: 0px 2px;

        // outline: none !important;
    }

    .my-country-dropdown-two:focus-visible {
        // outline: none !important;
        outline: 1px solid #007aff;
    }

    .my-country-dropdown-one:focus-visible {
        outline: 1px solid #007aff;
    }

    .css-b62m3t-container {
        width: 250px;
    }
}
.pac-container{
    top: 50px !important;
    position: fixed !important;
}
@media screen and (max-width:700px) {
    .location-dropdown-wrap {

        .css-13cymwt-control,
        .css-1hb7zxy-IndicatorsContainer {
            height: 30px !important;
            min-height: 30px !important;
        }

        //
        .css-t3ipsp-control {
            height: 30px !important;
            min-height: 30px !important;
        }

    }
}