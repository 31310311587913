.TutorHubDetailes__container {
    // padding: 13px;
    // padding: 13px 0px 13px 13px;
    padding: 13px 13px 13px 13px;
    width: 100%;
    background: #f9f9f9;
    height: 100%;

    .header_section {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Profile_contant {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .profile__Img {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                object-fit: cover;
            }

            .right_options {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;

                .message__wapeer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 34.622px;
                    background: #F1ECFF;
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;

                    img {
                        width: 15.002px;
                        height: 15.777px;
                        object-fit: contain;
                    }
                }

                .video_call__wapeer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 34.622px;
                    background: #F1ECFF;
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                    position: relative;
                    cursor: pointer;

                    img {
                        width: 19.002px;
                        height: 19.777px;
                        object-fit: contain;
                    }

                    .badeg__pos {
                        position: absolute;
                        right: 3px;
                        top: 2px;
                    }
                }
            }
        }

        .tutor__contant {
            display: flex;
            align-items: center;
            gap: 10px;

            .Black__text {
                color: #000;
                font-family: Inter500;
                font-size: 16px;
            }

            .name__text {
                color: #656565;
                font-family: Inter400;
                font-size: 12px;
            }
        }
    }

    .TutorHubList__wappper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        margin: 1rem 0;
        width: 100%;
       overflow-x: scroll;
      position: relative;
        // flex-wrap: wrap;
    }

    .List__data {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;

        img {
            width: 16px;
            height: 16px;
            object-fit: contain;
        }

        .text {
            color: #797979;
            font-family: Manrope600;
            font-size: 14px;
        }
    }

    .List__data_active {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        border-radius: 152px;
        background: #744FFF;
        padding: 4px 10px;

        img {
            width: 16px;
            height: 16px;
            object-fit: contain;
        }

        .text {
            color: #FFF;
            font-family: Manrope600;
            font-size: 14px;
        }
    }
}

//***Responsive Font Size**//
@media screen and (max-width:600px) {
    .TutorHubDetailes__container {
        .header_section {
            .tutor__contant {
                .Black__text {
                    font-size: 13px;
                }
    
                .name__text {
                    font-size: 9px;
                }
            }     
        }
        .List__data_active,.List__data {
            .text {
                font-size: 11px !important;
            }
        }
    }
}