//Inter 400
@font-face {
  font-family: Inter400;
  src: url(./Assets/Fonts/Inter/static/Inter-Regular.ttf);
}

//Inter 500
@font-face {
  font-family: Inter500;
  src: url(./Assets/Fonts/Inter/static/Inter-Medium.ttf);
}

//Inter 600
@font-face {
  font-family: Inter600;
  src: url(./Assets/Fonts/Inter/static/Inter-SemiBold.ttf);
}

//Inter 700
@font-face {
  font-family: Inter700;
  src: url(./Assets/Fonts/Inter/static/Inter-Bold.ttf);
}

//Inter 800
@font-face {
  font-family: Inter800;
  src: url(./Assets/Fonts/Inter/static/Inter-ExtraBold.ttf);
}

//Inter 900
@font-face {
  font-family: Inter900;
  src: url(./Assets/Fonts/Inter/static/Inter-Black.ttf);
}

//Manrope 400
@font-face {
  font-family: Manrope400;
  src: url(./Assets/Fonts/Manrope/static/Manrope-Regular.ttf);
}

//Manrope 500
@font-face {
  font-family: Manrope500;
  src: url(./Assets/Fonts/Manrope/static/Manrope-SemiBold.ttf);
}

//Manrope 600
@font-face {
  font-family: Manrope600;
  src: url(./Assets/Fonts/Manrope/static/Manrope-SemiBold.ttf);
}

//Manrope 700
@font-face {
  font-family: Manrope700;
  src: url(./Assets/Fonts/Manrope/static/Manrope-Bold.ttf);
}

//Manrope 800
@font-face {
  font-family: Manrope800;
  src: url(./Assets/Fonts/Manrope/static/Manrope-ExtraBold.ttf);
}

//Noto Sans Arabic 400
@font-face {
  font-family: NotoSansArabic400;
  src: url(./Assets/Fonts/Noto_Sans_Arabic/static/NotoSansArabic-Regular.ttf);
}

//Noto Sans Arabic 500
@font-face {
  font-family: NotoSansArabic500;
  src: url(./Assets/Fonts/Noto_Sans_Arabic/static/NotoSansArabic-Medium.ttf);
}

//Noto Sans Arabic 600
@font-face {
  font-family: NotoSansArabic600;
  src: url(./Assets/Fonts/Noto_Sans_Arabic/static/NotoSansArabic-SemiBold.ttf);
}

//Noto Sans Arabic 700
@font-face {
  font-family: NotoSansArabic700;
  src: url(./Assets/Fonts/Noto_Sans_Arabic/static/NotoSansArabic-Bold.ttf);
}

//Noto Sans Arabic 800
@font-face {
  font-family: NotoSansArabic800;
  src: url(./Assets/Fonts/Noto_Sans_Arabic/static/NotoSansArabic-ExtraBold.ttf);
}

//Noto Sans Arabic 900
@font-face {
  font-family: NotoSansArabic900;
  src: url(./Assets/Fonts/Noto_Sans_Arabic/static/NotoSansArabic-Black.ttf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 0px !important;
}