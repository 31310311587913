.overall-chat-university-container{
        margin-bottom: 0px !important;
.university-chat-wrap{
    position: relative;
.UniversityChatExpert__container {
    border-radius: 10px;
    background: #F4F3F9;
    padding: 14px;
    //
    // position: fixed;
    // width: 70%;
    // bottom: 0px;
    position: relative;
    height: calc(100vh - 250px);

    .Header__wapper {
        border-radius: 10px 10px 0px 0px;
        background: #F4F3F9;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
            width: 22px;
            height: 22px;
            object-fit: contain;
        }

        .title {
            color: #2B2451;
            font-family: Manrope600;
            font-size: 20px;
        }
    }

    .chat__container {
        display: flex;
        flex-direction: column;
        // max-height: 300px;
        // min-height: 280px;
        // height: calc(100vh - 286.5px);
        // height: calc(100vh - 205px);
        // height: calc(91vh - 205px);
        // height: calc(81vh - 138px);
        height: calc(89vh - 265px);
        overflow: auto;
        overflow-y: scroll;
    }

    /* width */
    .chat__container::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .chat__start {
        justify-content: flex-start;
        text-align: -webkit-left !important;
        margin: 5px 0;

        .time {
            color: #a4a3a9;
            font-family: Inter400;
            font-size: 10px;
        }
    }

    .chat__center {
        justify-content: center !important;
        text-align: -webkit-center !important;
        margin: 5px 0;
    }

    .chat__end {
        justify-content: flex-end;
        text-align: -webkit-right !important;
        margin: 5px 0;

        .time {
            color: #a4a3a9;
            font-family: Inter400;
            font-size: 10px;
        }
    }
    /*Change thhe message position*/
    .chat_end_arab{
        border-radius: 20px 20px 20px 0px !important;
    }

    .chat_end_arab{
    text-align: -webkit-left !important;
    }

    .show__time {
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.50);
        width: 120.089px;
        height: 24px;
        color: rgba(51, 51, 51, 0.72);
        font-family: Inter500;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .user__chat {
        display: flex;
        max-width: 326px;
        width: fit-content;
        padding: 10px 22px;
        align-items: center;
        word-break: break-all !important;
        text-align: justify;
        gap: 10px;
        border-radius: 20px 20px 0px 20px;
        background: #12CC92;
        box-shadow: 3px 7px 26px 0px rgba(173, 211, 255, 0.27);
        color: #FFF;
        font-family: Inter500;
        font-size: 12px;
    }
    .user_chat_arab{
        border-radius: 20px 20px 20px 0px !important;
    }

    .chat-image {
        max-height: 160px;
        max-width: 130px;
        object-fit: contain;
        cursor: pointer;
    }

    .chat-document {
        height: 60px;
        width: 70px;
        object-fit: contain;
        cursor: pointer;
    }


    .tutor__chat {
        display: flex;
        max-width: 337px;
        width: fit-content;
        padding: 10px 22px;
        text-align: justify;
        align-items: center;
        word-break: break-all !important;
        gap: 10px;
        border-radius: 20px 20px 20px 0px;
        background: #FFF;
        box-shadow: 3px 7px 26px 0px rgba(173, 211, 255, 0.27);
        color: #545454;
        font-family: Inter500;
        font-size: 12px;
    }

    .Pdf__container {
        border-radius: 7px;
        background: #fff;
        padding: 10px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        max-width: 340px;

        .left__contant {
            display: flex;
            align-items: center;
            gap: 10px;

            .Black__text {
                color: #333;
                font-family: Inter600;
                font-size: 14px;
                text-align: start;
                text-wrap: wrap;
                max-width: 200px;
                word-wrap: break-word;
            }

            .name__text {
                color: #8d8d8d;
                font-family: Inter500;
                font-size: 14px;
                text-transform: uppercase;
                text-align: start;
            }
        }

        .DownloadImg {
            width: 30px;
            height: 30.085px;
            object-fit: contain;
            object-position: center;
            cursor: pointer;
        }
    }

    .type__box__container {
        // position: relative;
        // position: fixed;
        position: absolute;
        // bottom: 0px;
        // bottom: -61px;
        bottom: -67px;
        // width: 62.3%;
        width: 99.8%;
        // margin-left: -13px;
        background-color: #F4F3F9;
        padding: 6px 0px 10px 0px;

        .type__box__input {
            width: 95%;
            margin: 0 auto;
            height: 50px;
            border-radius: 52px;
            border: none;
            outline: none;
            padding-top: 0px;
            padding-bottom: 4px;
            padding-left: 22px;
            // padding-right: 79px !important;
            background: #FFF;
            box-shadow: 0px 0px 18px 0px #D8D3F3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            color: #545454;
            font-family: Inter500;
            font-size: 12px;
        }
        .type_box_input_english{
            padding-right: 79px !important;
        }
        .type_box_input_arab{
          padding-right: 17px !important;
        }

        .file_input {
            display: none !important;
        }

        .AttachFileIcon {
            position: absolute;
            right: 70px;
            bottom: 21px;
            cursor: pointer;
            rotate: 30deg;
        }
        .AttachFileIcon_arab{
            right: initial !important;
            left: 70px !important;
            transform: scaleX(-1);
        }

        .type__box__input::placeholder {
            color: #808080;
            font-family: Manrope500;
            font-size: 12px;
        }

        .sendIcon {
            position: absolute;
            right: 31px;
            bottom: 16px;
            cursor: pointer;
            bottom: 20px;
        }
        .sendIcon_arab{
            right: initial !important;
            left: 31px !important;
            rotate: 180deg;
        }

        .sendIcon__disable {
            position: absolute;
            right: 31px;
            bottom: 20px;
            opacity: 0.5;
            cursor: default !important;
        }
        .sendIcon_disable_arab{
            right: initial !important;
            left: 31px !important;
            rotate: 180deg;
        }
    }
    .type_box_container_english{
        margin-left: -13px;
    }
    .type_box_container_arab{
        margin-left: initial;
        margin-right: -13px;
    }

}
}
}

.chat_with_university_tittle-div {
    border-radius: 15px;
    background: #fff;
    width: auto;
    max-width: 210px;
    height: 40px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .back-img {
        margin-right: 10px;
        cursor: pointer;
    }
    .back-img-arab{
        margin-right: initial !important;
        margin-left: 10px !important;
        rotate: 180deg !important;
    }

    .unversity-icon {
        margin-right: 5px;
        width: 22px;
        height: 22px;
    }
    .unversity-icon-arab{
         margin-right: initial !important;
         margin-left: 5px;
    }

    .text-tittle {
        color: #000;
        font-size: 20px;
        font-family: Manrope700;
        margin-left: 6px;
    }
    .text-tittle-arab{
        margin-left: initial !important;
        margin-right: 6px !important;
    }
}

@media screen and (max-width:600px) {
  .overall-chat-university-container{
     .university-chat-wrap{
        .UniversityChatExpert__container {
            .Header__wapper {
                .title {
                    font-size: 17px !important;
                }
            }
            .chat__start {
                .time {
                     font-family: 7px !important;
                }
            }
            .chat__end {
                .time {
                    font-family: 7px !important;
               }
            }
            .show__time {
                font-size: 7px !important;
            }
            .user__chat {
                font-size: 9px !important;
                padding: 7px 22px;
            }
            .tutor__chat {
                font-size: 9px !important;
            }
            .Pdf__container {
                .left__contant{
                    .Black__text,
                    .name__text {
                        font-size: 11px !important;
                    }
                }
            }
            .type__box__container {
                .type__box__input,
                .type__box__input::placeholder{
                    font-size: 9px !important;
                }
                .AttachFileIcon{
                    right: 60px;
                }
                .sendIcon__disable,
                .sendIcon {
                    right: 19px;
                }
            }
         }
     }
  }
  //
  .chat_with_university_tittle-div {
    .text-tittle {
        font-size: 17px !important;
    }
  }
}