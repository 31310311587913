.AboutUs__container {
    padding: 30px;

    .container__wapper {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
        overflow-y: scroll;
        max-height: 570px;
        padding-right: 20px;

        .AboutImg {
            height: 200px;
            width: 100%;
            max-width: 431px;
            object-fit: contain;
            border-radius: 20px;
            object-fit:none !important;
            margin-bottom: 20px;
        }

        .title {
            color: #333;
            font-family: Manrope700;
            font-size: 18px;
        }

        .pragrap {
            color: #333;
            font-family: Inter500;
            font-size: 12px;
            text-align: justify;
        }
    }

    /* width */
    .container__wapper::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
}

.AboutUs__container__without_token {
    padding: 30px 70px;
    margin: 3rem 1rem;

    .title_head {
        color: #333;
        font-family: Manrope700;
        font-size: 24px;
        margin: 1rem 0;
    }

    .container__wapper {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
        overflow-y: scroll;
        height: 100%;

        .AboutImg {
            height: 200px;
            width: 100%;
            object-fit: contain;
            border-radius: 20px;
            margin: 1rem 0;
            object-fit:none !important;
            margin-bottom: 20px;
        }

        .title {
            color: #333;
            font-family: Manrope700;
            font-size: 18px;
        }

        .pragrap {
            color: #333;
            font-family: Inter500;
            font-size: 12px;
            text-align: justify;
        }
    }

    /* width */
    .container__wapper::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
}

@media (min-width: 1420px) {
    .AboutUs__container {
        .container__wapper {
            max-height: 70vh !important;
        }
    }
}

@media screen and (max-width: 400px) {
    .AboutUs__container {
        padding: 15px 0px 15px 25px !important;
        .container__wapper {
            // .AboutImg {
            //     object-fit:none !important
            // }
            .title {
                font-size: 93.75% !important;
            }
            .pragrap {
                font-size: 57.25% !important;
            }
        }
        .AboutUs__container__without_token {
            .title_head {
                 font-size: 21px !important;
            }
            .container__wapper {
                // .AboutImg {
                //     object-fit:none !important
                // }
                .title {
                font-size: 93.75% !important;
                }
                .pragrap {
                  font-size: 57.25% !important;
                }
            }
        }
    }
}