//Background color
.avatar_selection_dialog_wrapper {
  .MuiDialogContent-root {
    position: relative;
    overflow-y: hidden;
  }

  .css-ypiqx9-MuiDialogContent-root {
    padding: 20px !important;
    max-width: 600px !important;
  }

  .background_color_wrapper_left {
    position: absolute;
    top: -14px;
    left: -84px;
    width: 245px;
    height: 248px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(116, 79, 255, 0.15) 0%,
      rgba(116, 79, 255, 0) 100%
    ) !important;
  }

  .background_color_wrapper_right {
    position: absolute;
    bottom: -52px;
    right: 0;
    width: 245px;
    height: 248px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(116, 79, 255, 0.15) 0%,
      rgba(116, 79, 255, 0) 100%
    ) !important;
  }

  .profile_title_description_container {
    margin-bottom: 40px;
  }

  .profile_avatar_wrapper {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .file_input {
    display: none !important;
  }

  .profile_upload_seperator_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 38px;
    align-self: flex-end;
  }

  .profile_upload_container {
    position: relative;
    cursor: pointer;
  }

  .upload_arrow_wrapper {
    position: absolute;
    // bottom: -10px;
    // right: -10px;
    bottom: 5px;
    right: 1px;
  }

  .upload_arrow_container {
    background-color: #fff;
    border: 1px solid #dedede;
    padding: 8px;
    border-radius: 50%;
    width: 20px;
    height: 21px;
  }

  .upload_arrow {
    background-color: #67a6ed;
    padding: 5px;
    border-radius: 50%;
  }

  .profile_upload_avatar_carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .avatar_image {
    width: 76px;
    padding: 4px;
    border: 1px solid transparent;
    border-radius: 50%;
  }

  .profile_upload_wrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .profile_avatars_carousel_wrapper {
    width: 70%;

    .alice-carousel__stage-item {
      margin: 10px !important;
    }

    .alice-carousel__wrapper {
      padding-left: 8px !important;
    }
  }

  .avatar_navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-left: 8px;
  }

  .profile_title {
    color: #333;
    font-family: Manrope700;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .profile_description {
    color: rgba(51, 51, 51, 0.69);
    font-family: Inter400;
    font-size: 16px;
    font-weight: 400;
  }

  .or_seperator {
    color: rgba(51, 51, 51, 0.69);
    font-family: Inter600;
    font-size: 14px;
    font-weight: 600;
    margin-right: 12px;
  }

  .upload_button {
    color: #67a6ed;
    font-family: Inter600;
    font-size: 12px;
    font-weight: 600;
    background-color: unset !important;
    margin-top: 12px;
  }

  .profile_picture_label {
    color: #333;
    font-family: Inter600;
    font-size: 12px;
    font-weight: 600;
  }

  .next_button {
    border-radius: 50px;
    background: #744fff;
    color: #fff;
    font-family: Manrope700;
    font-size: 12px;
    font-weight: 700;
    padding: 12px 85px;
    align-self: center;
    margin-top: 32px;
  }

  .profile_picture {
    width: 76px;
    border-radius: 50%;
    object-fit: cover;
    height: 76px;
  }

  //carousel
  .profile_avatars_carousel_wrapper {
    // .alice-carousel__stage-item {
    //   // width: unset !important;
    // }

    //carousel navigation icons
    .alice-carousel__prev-btn [data-area]::after {
      content: url("../../Assets/Authentication/left_arrow.svg") !important;
    }

    .alice-carousel__next-btn [data-area]::after {
      content: url("../../Assets/Authentication/right_arrow.svg") !important;
    }

    .alice-carousel__prev-btn-item {
      background-color: #f5f5f5;
      padding: 4px 8px;
      border-radius: 50% 2px 2px 50%;
      cursor: pointer;
    }

    .alice-carousel__next-btn-item {
      background-color: #f5f5f5;
      padding: 4px 8px;
      border-radius: 2px 50% 50% 2px;
      cursor: pointer;
    }

    // .alice-carousel__stage .__target img {
    //   border: 1px solid #744fff !important;
    // }
    // .alice-carousel__stage .__target img {
    //   border: none !important; /* Default no border */
    // }

    // .alice-carousel__stage .__target.active img {
    //   border: 1px solid #744fff !important;
    // }

    .avatar_image {
      width: 76px;
      padding: 4px;
      border-radius: 50%;
      border: none !important; /* Default: No border */
    }

    .avatar_image.active {
      width: 76px;
      padding: 4px;
      border-radius: 50%;
      border: 1px solid #744fff !important; /* Border for active image */
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      width: unset !important;
    }

    .alice-carousel__prev-btn {
      margin-left: 50px;
    }
  }
}

////********Style For Responsive**********////

@media screen and (max-width: 560px) {
  .avatar_selection_dialog_wrapper {
    .profile_upload_avatar_carousel {
      flex-direction: column !important;
    }
    .or_seperator {
      display: none !important;
    }
    .profile_upload_seperator_container {
      align-self: center !important;
    }
    .MuiDialogContent-root {
      overflow-y: scroll !important;
    }
    //
    .profile_avatars_carousel_wrapper {
      position: relative;
      .alice-carousel__prev-btn {
        margin-left: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85% !important;
      }
      .alice-carousel__next-btn {
        position: absolute !important;
        top: 75%;
        left: 55%;
      }
    }
    //
    .alice-carousel__stage-item {
      width: 80px !important;
    }
    .profile_avatars_carousel_wrapper {
      width: 90% !important;
    }
    .profile_picture_label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
    }
    .profile_upload_wrapper {
      width: 70% !important;
    }
  }
}

@media screen and (max-width: 680px) {
  .avatar_selection_dialog_wrapper {
    .profile_title {
      font-size: 22px !important;
    }
    .profile_description {
      font-size: 13px !important;
    }
    .or_seperator {
      font-size: 12px !important;
    }
    .upload_button,
    .profile_picture_label,
    .next_button {
      font-size: 10px !important;
    }
    //
    .profile_avatar_wrapper {
      .skip_button {
        font-size: 13px !important;
        margin-top: 5px;
      }
    }
  }
}
