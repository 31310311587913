.payment-success-animation {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 70px;
    height: 70px;

    .checkmark-circle {
      fill: #FFFFFF;
    }

    .checkmark-check {
      fill: none;
      // stroke: #178FFF;
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 22;
      stroke-dashoffset: 22;
      animation: drawCheck 0.6s ease-out 0.3s forwards;
      transform-origin: center; 
      transform: scale(0.8);

      @media (max-width: 468px) {
        stroke-width: 1; 
      }
    }
  }
}

@keyframes drawCheck {
  from {
    stroke-dashoffset: 22;
  }
  to {
    stroke-dashoffset: 0;
  }
}
