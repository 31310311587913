//Dialog styling
.css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.css-uhb5lp {
  // width: 80vw !important;
  max-width: 720px !important;
  border-radius: 16px !important;
  background: #fff !important;
  // max-height: 485px !important;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background: rgba(218, 218, 218, 0.68) !important;
  backdrop-filter: blur(9px) !important;
}

.login_signup_navigations_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  gap: 20px;
  margin-top: 30px;
}

.create_account_button {
  border-radius: 50px;
  background: #744fff;
  color: #fff;
  font-family: Manrope600;
  font-size: 12px;
  padding: 17px;
}

.login_button {
  border-radius: 50px;
  background: #eeecf9;
  color: #333;
  font-family: Manrope600;
  font-size: 12px;
  padding: 17px;
}

.brand_logo {
  width: 154px;
  align-self: center;
  margin-bottom: 3.4rem;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 12px !important;
}

@media (min-width: 1420px) {

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
  .css-uhb5lp {
    max-height: 90% !important;
  }
}

@media screen and (max-width:431px) {
   .loginSignup-dialog-container{
    .brand_logo {
      margin-bottom: .4px !important;
    }
    .LoginCarousel__img{
      max-height: 200px !important;
    }
   }
}