.user__Detailes__Slots_activities {
    // padding: 2px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;


    .lable {
        flex: 1;

        span {
            border-radius: 5px;
            background: #FAF3EC !important;
            padding: 6px 10px;
            color: #FCB040;
            font-size: 12px;
            font-weight: 500;
        }

    }

    .Slots {
        color: #4E4E4E;
        font-size: 12px;
        font-weight: 500;
        flex: 1;

    }

    .SlotsYes {
        color: #04C270;
        font-size: 12px;
        font-weight: 500;
        margin-left: 5px;
    }

}


.user__Detailes__rating_Activities {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // padding: 5px 10px;
    margin-top: 10px;

    .title {
        color: #FFAE4F;
        font-size: 12px;
        font-weight: 600;
    }

    .StarIcon {
        color: #FCB040 !important;
        width: 18px;
        height: 17px;
    }

    .card__title {
        color: #333;
        font-size: 12px;
        font-weight: 500;
    }
}

.profile_image__container__Activities {
    position: relative;

    .EducationIcon {
        position: absolute;
        top: -1px;
        left: -1px;
    }

    .profile_image {
        width: 80px !important;
        height: 80px !important;
        border-radius: 6px;
        object-fit: cover;
    }
}

.Hover__Card {
    display: block !important;
    max-width: 480px !important;
    max-height: 135px !important;
    border-radius: 9px !important;
    background: #FFF !important;
    cursor: pointer;
    padding: 5px;
    min-width: 200px;

    .user__Detailes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center !important;
        padding: 0px;
        gap: 30px;
        margin-bottom: 10px;
    }
}