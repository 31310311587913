.Themepark_list_page {
    .background_linear_1 {
        content: "";
        position: absolute;
        z-index: 1;
        opacity: 0.5;
        background: linear-gradient(to bottom, #E7FBFF 0%, #fff 100%);
        filter: blur(250px);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.ThemePark__dashboard__container {
    margin-top: 1.5rem;
    position: relative;
    z-index: 1;

    
    .header_wapper {
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .ThemeParkCard_Img_wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 100%;
        max-width: 201px;
        height: 46px;
        border-radius: 15px;
        background: #fff;
        padding: 5px 10px;
        cursor: pointer;

        img {
            width: 23.122px;
            height: 19.818px;
        }

        .TutorImg__text {
            color: #000;
            font-size: 20px;
            font-family: Manrope700;
        }
    }

    .search_field_container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        max-width: 582px;
    }

    .search_field:focus {
        outline: none;
    }

    .search_field_wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 14px;
        width: 100%;
        max-width: 600px;
        position: relative;
        bottom: 21px;

        .search_box_container {
            position: absolute;
            top: 10%;

            .auto-complete-container {
                position: relative;
                z-index: 1111111;
                min-width: 516px;
                // max-width: 1000px;
                max-height: 200px;
                height: 100%;
                overflow: auto;
                overflow-y: scroll;
                border-radius: 20px;

                // p {
                //     min-height: 48px;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                // }
            }

            .search_box {
                width: 516px;

                .search-field {
                    width: 100%
                }
            }
            .search_box-arab{
                .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
                    padding-left: 14px !important;
                    // padding-right: 5px !important;
                }
                .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
                    padding-right: 2px !important;
                }
            }

            .css-1uwgr7b-MuiTypography-root {
                padding: 10px;
            }
        }
    }

    .TutorCard_Filter {
        width: 46px;
        height: 46px;
        border-radius: 8px;
        border: 1px solid #f4f2f9;
        background: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .search_field {
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
        border: none;
        font-size: 14px;
        width: 100%;
        height: 46px;
        padding: 14px 15px;
    }

    .search_field::placeholder {
        color: #ADB5BE;
        font-size: 14px;
        font-family: Inter500;
    }

    .search_button {
        padding: 16px 12px;
        cursor: pointer;
    }

    .search_button_icon_wrapper {
        background: #FFF;
        box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
        border: none;
        height: 48px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .search__autocomplete {
        width: 100% !important;
        max-width: 400px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
        border: none;
        position: relative;

        input::placeholder {
            font-size: 14px;
            font-family: Inter500;
        }

        .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
            padding: 5px !important;
            border-radius: 15px !important;
        }

        .search_button {
            cursor: pointer;
            position: absolute;
            right: 4px;
            top: 0;
        }

    }

    .ThemePark__list__wapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 90%;
        margin: 7px 0;
        padding: 14px;
        border-radius: 14px;
        background: #FFF;

        .ThemePark__leftside {
            img {
                width: 200px;
                height: 160px;
                object-fit: cover;
                border-radius: 10px;
                cursor: pointer;
            }
        }

        .ThemePark__rightside {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;

            .ThemePark__name {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between !important;

                .name {
                    color: #000;
                    font-size: 16px;
                    font-family: Manrope700;
                }

                .imageIcon {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    img {
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                    }
                }
            }

            .ThemePark__location {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                img {
                    width: 12px;
                }

                .location {
                    color: #333;
                    font-size: 12px;
                    font-family: Inter500;
                }
            }

            .ThemePark__description {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                .text__black {
                    color: #333;
                    font-size: 12px;
                    font-family: Inter400;
                    text-align: justify;
                }
            }

            .ThemePark__rating {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                cursor: pointer;

                .ticket_text {
                    color: #333;
                    font-family: Inter500;
                    font-size: 16px;
                }

                .available_text {
                    color: #04C270;
                    font-family: Inter500;
                    font-size: 16px;
                }

                .sold_text {
                    color: rgba(255, 0, 0, 1);
                    font-family: Inter500;
                    font-size: 16px;
                }

                .StarIcon {
                    color: #FCB040 !important;
                    font-size: 14px !important;
                }

                .points {
                    color: #333;
                    font-size: 12px;
                    font-family: Inter500;
                }
            }
        }
    }

    .action-container{
        text-align: center;
        margin-bottom: 30px;
        .view_more{
            border-radius: 52px;
            border: 2px solid rgba(23, 143, 255, 0.10);
            background: #04D6FB;
            width: fit-content;
            color: #F9F9FB;
            font-family: Inter600;
            font-size: 16px;
            outline: none;
            width: 230px;
            height: 50px;
        }
    }
}

/*For Card*/

.theme-park-list-container {
    // margin: 0px 40px 0px 0px !important;
    margin: auto;
    max-width: 1300px;
}

@media only screen and (max-width: 990px) {
    .theme-park-list-container {
        margin: initial !important;

        .list-data__conatiner {
            justify-content: center !important;
        }
    }
}

/** For Card List Style **/
.list-data__conatiner {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin-top: 50px;
    justify-content: start;
    margin-bottom: 30px;
}

/** CSS Grid **/
.list-theme_three_card {
    width: calc(32.33% - 20px);
    height: 540px;
    position: relative;
    border-radius: 13px !important;

    .css-o69gx8-MuiCardMedia-root {
        height: 100% !important;
    }


    @media only screen and (max-width: 768px) {
        width: calc(50% - 20px);
    }

    @media only screen and (max-width: 480px) {
        width: calc(100% - 20px);
    }

    .css-19abcwk-MuiCardContent-root {
        padding: 16px 16px 8px 16px !important;
    }
}

.discount {
    position: absolute;
    top: 0;
    width: 0;
    right: 0;
    height: 0;
    border-left: 100px solid transparent;
    border-bottom: 100px solid #fff;
    transform: rotate(270deg);
}

.discount-label {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #FF4D57;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;

    img {
        width: 14px;
        position: relative;
        bottom: 1px;
        margin-left: 2px;
    }

    span {
        display: block;
        text-align: end;
    }
}

.hover_content {
    font-size: 14px;
    font-family: Inter400;

    .booking_button {
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        background-color: rgba(178, 180, 181, 0.5);
        width: 100%;
    }


    span {
        margin-right: 5px;
        color: #B2B4B5;
    }

    .showmore {
        display: inline-block;
        // margin-left: 5px;

        font-weight: 600;
    }

}


// .list-theme-three-card-img {
//     width: 100%;
//     height: auto;
// }


.list-theme_rating_inc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: Inter500;

    .css-15befwp-MuiRating-root {
        color: #FCB040 !important;
    }

    .css-1c99szj-MuiRating-icon {
        color: rgba(255, 255, 255, 0.3);
    }

    // svg {
    //     width: 18px;
    //     position: relative;
    //     bottom: 1px;
    //     margin-right: 5px;
    // }
}

.list-theme_card_head {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 2px;
}

.list-theme-share-like {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    img {
        width: 30px;
        height: 30px;
        cursor: pointer;
        padding: 5px;
        border-radius: 50%;
        background-color: rgba(178, 180, 181, 0.3);
    }

}

.rating_count {
    position: relative;
    bottom: 5px;
}

.list-card_map_icon {
    // margin-top: -6px;
    font-family: Inter500;
    font-size: 16px;
    color: #B2B4B5;
    margin-bottom: 5px;
    line-height: 25px;

    margin-left: -4px;
    //  max-width: 150px;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    svg {
        width: 16px;
        position: relative;
        bottom: 3px;
        margin-right: 5px;
        color: #B2B4B5 !important;
        left: 3px;
    }
}


@media only screen and (max-width: 700px) {
    .theme-park-list-container {
        .list-data__conatiner {
            display: flex;
        }

        .list-theme_three_card {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 576px) {
    .ThemePark__dashboard__container {
        margin-top: 0px;

        .header_wapper {
            display: block;
            // margin-bottom: 0px;
            margin-bottom: -18px;
        }

        .search_field_wrapper {
            // justify-content: center;
            justify-content: initial !important;
            bottom: 45px;
            .search_box_container{
                width: 100%;
                // position: initial !important;
                .search_box {
                    width: 100% !important;
                    .search-field {
                        width: 70% !important
                        ;
                    }
                }
                .auto-complete-container{
                    min-width: 30% !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 741px) {
    .ThemePark__dashboard__container {
        .search_field_wrapper {
            // justify-content: center;
            justify-content: initial !important;
            .search_box_container{
                width: 100%;
                // position: initial !important;
                .search_box {
                    width: 100% !important;
                    .search-field {
                        width: 70% !important
                        ;
                    }
                }
                .auto-complete-container{
                    min-width: 30% !important;
                }
            }
        }
    }
}