.MySessions_container_parent {
  width: 100%;
  overflow-x: hidden !important;

  .css-1fynrr1-MuiContainer-root {
    padding-left: 0px !important;
    margin-left: 0px !important;
    max-width: 100% !important;
  }

  .MySessions__container {
    width: 100%;
    border-radius: 24px;
    //   background: #fff;
    backdrop-filter: blur(6px) !important;

    .session__wapper .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
      box-shadow: none !important;
    }

    .session__wapper .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
      padding: 0 !important;
    }

    .session__wapper .css-15v22id-MuiAccordionDetails-root {
      padding: 0 !important;
    }

    .session__wapper .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
      margin-bottom: 0 !important;
    }

    .css-67l5gl {
      box-shadow: none !important;
    }

    .css-u7qq7e {
      padding: 0 !important;
    }

    .css-1iji0d4 {
      padding: 0 !important;
    }

    .Detaile_slot_scroll_container {
      width: 100%;
      height: 100%;
      max-height: 580px;
      overflow: auto;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    /* width */
    .Detaile_slot_scroll_container::-webkit-scrollbar {
      width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    .session__wapper {
      border-radius: 9px;
      background: #fff;
      padding: 20px;
      width: 100%;
      margin-top: 10px;
      //Hoide the boyttom line
      .css-1086bdv-MuiPaper-root-MuiAccordion-root,.css-1aj41gs{
        box-shadow: initial !important;
      }

      .row__contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
      }

      .left__contant {
        display: flex;
        align-items: center;
        gap: 10px;

        .Black__text {
          color: #000;
          font-family: Inter500;
          font-size: 18px;
        }

        .name__text {
          color: #7e7e7e;
          font-family: Inter400;
          font-size: 12px;
        }
      }

      .tutor__img__wapper {
        width: 38px;
        height: 100%;
        min-height: 52px;
        border-radius: 3px;
        background: rgba(209, 233, 255, 0.61);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 23px;
          height: 17px;
          object-fit: contain;
        }
      }

      .activity__img__wapper {
        width: 38px;
        height: 100%;
        min-height: 52px;
        border-radius: 0px;
        background: rgba(255, 242, 227, 1);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 23px;
          height: 17px;
          object-fit: contain;
        }
      }

      .left__light__text {
        color: #8a8a8a;
        font-family: Inter400;
        font-size: 14px;
      }

      .right__black__text {
        color: #333;
        font-family: Inter500;
        font-size: 14px;
      }

      .inprogressBtn {
        width: 116px;
        height: 40px;
        border-radius: 8px;
        background: #f9ce5f;
        color: #000;
        font-family: Manrope700;
        font-size: 14px;
      }

      .CompletedBtn {
        width: 116px;
        height: 40px;
        border-radius: 8px;
        background: #12cc92;
        color: #fff;
        font-family: Manrope700;
        font-size: 14px;
      }

      .child_profile_img {
        width: 18.698px;
        height: 18.698px;
        border-radius: 50%;
        object-fit: cover;
      }

      .createdBy {
        color: #000;
        font-family: Manrope700;
        font-size: 11px;
      }
    }

    .sort__wapper__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .css-lh3xye-MuiFormControl-root {
        min-width: 0px !important;
      }

      .lable {
        color: #333;
        font-family: Manrope700;
        font-size: 16px;
      }
    }

    .Header__top__wapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .left__contant {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 14px;
    }

    .is_today {
      border-radius: 29px;
      border: 1px solid #e0daf1;
      background: rgba(224, 222, 232, 0.34);
      color: #a6a0c2;
      font-family: Manrope600;
      font-size: 16px;
      padding: 10px 20px;
      cursor: pointer;
      width: fit-content !important;
      margin: 10px;
    }

    .is_today__active {
      color: #fff;
      font-family: Manrope700;
      font-size: 16px;
      background-color: #178fff;
      padding: 10px 20px;
      border-radius: 29px;
      width: fit-content !important;
      cursor: pointer;
      margin: 10px;
    }

    .ParentTag {
      position: relative;
      display: flex;
      align-items: center !important;
    }

    .searchInput {
      width: 100% !important;
      max-width: 250px !important;
      border-radius: 10px;
      border: 1px solid #e0daf1;
      outline: none;
      padding: 10px 17px;
      padding-right: 32px !important;
      font-size: 14px;
      font-family: Inter400;
    }

    .searchInput::placeholder {
      color: #000;
      font-family: Inter400;
      font-size: 14px;
    }

    .searchIcon {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}

@media (min-width: 1420px) {
  .MySessions__container {
    .Detaile_slot_scroll_container {
      max-height: 70vh !important;
    }
  }
}

///**********Media Query For Respomnsive*********///
@media (max-width: 600px) {
  .MySessions_container_parent {
    overflow-x: hidden !important;

    .css-1fynrr1-MuiContainer-root {
      margin-left: 16px !important;
      padding-right: 0px !important;
    }

    .MySessions__container {
      .session__wapper {
        width: 94% !important;
        margin-top: 20px !important;

        .css-yw020d-MuiAccordionSummary-expandIconWrapper {
          position: absolute !important;
          left: 80% !important;
        }

        //
        .left__light__text,
        .right__black__text {
          font-size: 68.75% !important;
        }

        .inprogressBtn {
          font-size: 68.75% !important;
          width: 70px !important;
          height: 31px !important;
          margin-bottom: 4px !important;
        }

        .createdBy {
          font-size: 56.25% !important;
        }

        .row__contant {
          align-items: flex-start !important;
          gap: 4px !important;
        }

        .left__contant {
          .name__text {
            font-size: 62.5% !important;
          }

          .Black__text {
            font-size: 14px !important;
            width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 23px;
            display: flex;
            align-items: flex-start;
          }
        }

        //
        .CompletedBtn {
          font-size: 68.75% !important;
          width: 70px !important;
          height: 31px !important;
          margin-bottom: 4px !important;
        }
      }
      .SessionWapperLang_Arab{
        .css-yw020d-MuiAccordionSummary-expandIconWrapper {
          position: absolute !important;
          left: initial !important;
          right: 90% !important;
          top: 50%;
        }
      }

      //**For Header input fields**//
      .sort__wapper__container {
        position: absolute;
        top: 40px;
        left: 0px;

        .lable {
          font-size: 81.25% !important;
        }

        .css-lh3xye-MuiFormControl-root {
          min-width: 0px !important;
        }

        .select_list_item * {
          font-size: 13px !important;
        }
      }

      //
      .Header__top__wapper {
        // margin-bottom: 2rem !important;
        margin-bottom: 3.2rem !important;

        .is_today {
          padding: 8px 10px !important;
          font-size: 81.25% !important;
          margin: 0px !important;
          border-radius: 10px !important;
        }
      }

      //
      .searchInput {
        max-width: 165px !important;
      }

    }
  }
}

//**Style For lg-992 **//
@media (min-width: 1200px) {
  .MySessions_container_parent {
    .MySessions__container {
      .session__wapper {
        width: 95%;

        .Black__text {
          width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 30px;
        }
      }

      .Detaile_slot_scroll_container {
        display: flex !important;
        flex-wrap: wrap !important;
        // justify-content: center !important;
      }
    }
  }
}

@media (max-width: 462px) {
  .Black__text {
    width: 163px !important;
  }
}

@media (max-width: 380px) {
  .MySessions_container_parent {
    .MySessions__container {
      .session__wapper {
        .row__contant {
          flex-direction: column !important;
        }
      }
    }
  }



}