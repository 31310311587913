.CampBookSession_wrapper {
    width: 100%;
    padding: 20px;
    position: relative;

    .cursor_defalut {
        cursor: default !important;
    }

    .leftside__contant {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        min-width: 370px;

        .freeTrial_img {
            width: 300px;
            height: 150px;
            object-fit: cover;
            border-radius: 8px;
        }

        .age_text {
            color: #f0962c;
            font-family: Inter500;
            font-size: 14px;
            text-align: left;
        }

        .contant_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between !important;
            gap: 14px;
        }

        .title {
            color: #333;
            font-family: Manrope700;
            font-size: 18px;
            text-align: left;
            max-width: 180px;
            text-wrap: wrap;
            word-break: break-all;
        }

        .price_text {
            color: #178fff;
            font-family: Manrope700;
            font-size: 18px;
            overflow: hidden;
            white-space: nowrap;
        }

        .descrption {
            color: #333;
            font-family: Inter400;
            font-size: 14px;
            text-align: justify;
            max-width: 300px;
        }

        .date__filed_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            img {
                width: 24px;
                height: 24px;
                object-fit: contain;
                padding: 2px;
                background: rgb(250, 244, 236);
            }

            span {
                color: #333;
                font-family: Inter400;
                font-size: 14px;
                text-align: left;
            }
        }
    }

    .rightSide__Card {
        width: 100%;
        padding: 20px;
        border-radius: 13px;
        background: rgb(250, 244, 236);
        display: flex;
        flex-direction: column;
        gap: 10px;

        .lable_title {
            color: #333;
            font-family: Manrope700;
            font-size: 18px;
        }

        .scrool__conatiner {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 260px;
            overflow: auto;
            overflow-y: scroll;
        }

        /* width */
        .scrool__conatiner::-webkit-scrollbar {
            width: 0px !important;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: transparent;
        }


        .trial_card {
            border-radius: 18px;
            background: #FFF;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 13px;

            .MemberBlueImg {
                width: 42px;
                height: 42px;
                object-fit: cover;
                border-radius: 50%;
            }

            .card_profile_wapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
            }

            .name_text {
                color: #333;
                font-family: Inter400;
                font-size: 14px;
            }

            .date_time_wapper {
                display: flex;
                flex-direction: column;

                .date {
                    color: #333;
                    font-family: Inter600;
                    font-size: 12px;
                }

                .time {
                    color: #878787;
                    text-align: center;
                    font-family: Inter500;
                    font-size: 10px;
                }
            }
        }
    }

    .ConfirmSessionBtn {
        border-radius: 42px;
        background: #f0962c;
        color: #F5F5F5;
        text-align: center;
        font-family: Inter600;
        font-size: 14px;
        margin: 1rem;
        width: 90%;
        padding: 14px;
    }

    .Btn__disable {
        opacity: 0.5 !important;
        cursor: default !important;
    }
}

@media screen and (max-width: 720px) {
    .CampBookSession_wrapper {
        .leftside__contant {
            min-width: auto !important;

            .freeTrial_img {
                width: 100% !important;
            }
        }

        .rightSide__Card {
            margin-top: 15px;
            max-height: 220px;
            overflow-y: auto;
        }

        .ConfirmSessionBtn {
            width: 100% !important;
            margin: 20px 0 0 0 !important;
        }
    }
}