.Support__container {
    padding: 30px;
    width: 100%;

    .session__wapper .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
        box-shadow: none !important;
    }

    .session__wapper .css-67l5gl {
        box-shadow: none !important;
    }

    .Button__wapper {
        border-radius: 10px;
        background: #FFF;
        display: flex;
        width: 100%;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-top: 5px;
    }
    .ButtonWapperImg-Arab{
        rotate: 181deg;
    }

    .container__wapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
        overflow-y: scroll;
        padding-right: 20px;

        .img__container {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 200px;
                height: 200px;
                object-fit: contain;
            }
        }

        .contants {
            color: #333;
            font-family: Inter500;
            font-size: 14px;
            margin-left: 27px;
        }

        .paragraph__contant {
            color: #6F6F6F;
            font-family: Inter500;
            font-size: 14px;

            a {
                color: #744FFF;
                font-family: Inter500;
                font-size: 14px;
            }
        }

        .chatBtn {
            background-color: #744FFF;
            color: #FFF;
            font-family: Inter500;
            font-size: 14px;
            padding: 10px 15px;
            border-radius: 12px;
            border: none;
            outline: none;
        }

        .accordion__lable__wapper {
            display: flex;
            flex-direction: row;
            gap: 15px;
            align-items: center;

            img {
                height: 34px;
                width: 34px;
                object-fit: contain;
                user-select: none !important;
                user-drag: none !important;
                -moz-user-select: none !important;
                -webkit-user-drag: none !important;
                -webkit-user-select: none !important;
                -ms-user-select: none !important;
            }

            .text {
                color: #333;
                font-family: Inter500;
                font-size: 16px;
            }
        }
    }

    /* width */
    .container__wapper::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
}

@media screen and (max-width: 450px){
    .Support__container {
        .container__wapper {
            padding-right: 0px !important;
            .contants {
                font-size: 12px !important;
            }
            .paragraph__contant {
                font-size: 12px !important;
    
                a {
                    color: #744FFF;
                    font-family: Inter500;
                    font-size: 12px !important;
                }
            }
            .chatBtn {
                background-color: #744FFF;
                font-size: 12px !important;
            }   
            .accordion__lable__wapper {
                .text{
                    font-size: 14px !important;
                }
            } 
            .img__container img {
                width: 170px !important;
            }
        }

    }
  }
  