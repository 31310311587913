.service_cards_wrapper {
  .service_card {
    margin-right: 12px;
    width: 392px;
    height: 100%;
    cursor: pointer;

    .service_card_container {
      width: 380px;
      height: 100%;
    }

    // .service_card_container_arab{
    //   direction: rtl;
    // }
    /*Crrosal icon rotate*/
    // .navigation_arrow_container_arab{
    //   transform: scaleX(-1);
    // }

    .offers_image {
      width: 100%;
      height: 183px;
      object-fit: cover;
      // border-bottom-right-radius: 20px;
      // border-bottom-left-radius: 20px;
      border-radius: 11.42px;
      margin-bottom: 12px;
    }

    .sevice_card_contents_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .service_card_type {
      font-family: Manrope700;
      font-size: 14px;
      padding: 8px 16px;
      margin-bottom: 12px;
    }

    .school_service_card_type {
      border-radius: 29.463px;
      background: rgba(255, 216, 79, 0.17);
      color: #ffc34f;
      font-family: Manrope700;
      font-size: 14px;
    }

    .university_service_card_type {
      border-radius: 29.463px;
      background: rgba(18, 204, 146, 0.12);
      color: #12cc92;
      font-family: Manrope700;
      font-size: 14px;
    }

    .activities_service_card_type {
      border-radius: 29.463px;
      background: #fff2e1;
      color: #ffae4f;
      font-family: Manrope700;
      font-size: 14px;
    }

    .theme_park_service_card_type {
      border-radius: 29.463px;
      background: rgba(4, 214, 251, 0.1);
      color: #04d6fb;
      font-family: Manrope700;
      font-size: 14px;
    }

    .service_card_title {
      color: #444a53;
      font-family: Inter600;
      font-size: 24px;
      margin-bottom: 6px;
      text-transform: capitalize;
    }

    .service_card_description {
      color: #7588a4;
      font-family: Inter500;
      font-size: 12.25px;
      line-height: 18.02px;
      word-break: break-all;
    }

    .navigation_arrow_container {
      width: 34.746px;
      height: 34.746px;
      flex-shrink: 0;
      background-color: #f0f2f7;
      padding: 8px;
      border-radius: 27px;
      margin-top: 12px;
      cursor: pointer !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dsib_rating {
      color: #444a53;
      font-family: Inter500;
      font-size: 12px;
      margin-top: 6px;
    }

    .ratings_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      margin-top: 6px;
    }

    .rating {
      color: #333;
      font-family: Inter500;
      font-size: 12px;
    }
  }

  .school_explore-all__wapper {
    width: 380px;
    height: 100%;
    min-height: 280px;
    border-radius: 20px;
    background: #FFC812;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .text {
      color: #FFFF97;
      text-align: center;
      font-family: Manrope500;
      font-size: 20.409px;
    }

    .title {
      color: #FFFF97;
      text-align: center;
      font-family: Manrope600;
      font-size: 40.017px;
    }
  }

  .university_explore-all__wapper {
    width: 380px;
    height: 100%;
    min-height: 280px;
    border-radius: 20px;
    background: #12CC92;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .text {
      color: #9CFFFF;
      text-align: center;
      font-family: Manrope500;
      font-size: 20.409px;
    }

    .title {
      color: #9CFFFF;
      text-align: center;
      font-family: Manrope600;
      font-size: 40.017px;
    }
  }

  .activity_explore-all__wapper {
    width: 380px;
    height: 100%;
    min-height: 280px;
    border-radius: 20px;
    background: #FFAE4F;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .text {
      color: #FFFF97;
      text-align: center;
      font-family: Manrope500;
      font-size: 20.409px;
    }

    .title {
      color: #FFFF97;
      text-align: center;
      font-family: Manrope600;
      font-size: 40.017px;
    }
  }

  //carousel navigation icons
  .alice-carousel__prev-btn [data-area]::after {
    content: url("../../Assets/Authentication/previousNavigationArrow.svg") !important;
  }

  .alice-carousel__next-btn [data-area]::after {
    content: url("../../Assets/Authentication/nextNavigationArrow.svg") !important;
  }

  .arrow_nav_tile_contianer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    width: 100%;
  }

  .arrow_navs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
  }

  .arrow_navs_arab {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
  }

  .left_arrow,
  .right_arrow {
    cursor: pointer;
    width: 12px;
  }

  .disable_arrow {
    cursor: default;
    opacity: 0.3;
    color: #867f7f;
    width: 12px;
  }
}

@media (max-width: 550px) {
  .service_cards_wrapper {
    .service_card {
      .service_card_title {
        width: 85%;
      }

      .service_card_description {
        width: 85%;
      }
    }
  }
}