.Universitycheckout-container {
    margin: 20px 15px;

    .tutoImg_wapper {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 4px;

        .checkout-txt {
            font-size: 20px;
            font-weight: 700;
            color: #000000;
        }
    }

    .billingtext {
        color: #12CC92;
        font-size: 18px;
        font-weight: 700;
        padding-left: 12px;
    }

    .billing-container {
        padding: 12px;
        height: 100%;
        border-radius: 20px;
        box-shadow: 0px 4px 15px 0px #B4B4B440;
        background: #FFF;

        .inputfieldss {
            padding: 10px 14px;

            .emailtext {
                font-size: 16px;
                font-weight: 500;
                text-align: left;
                color: #000000;
                width: 100%;
                max-width: 200px;
            }


            .billng_contact_fields {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;

                .inputdesign1 {
                    width: 100%;
                    height: 40px;
                    border-radius: 10px;
                    background: #F1F5FD;
                    border: 0 !important;
                    padding: 3px 15px;
                    outline: none;
                }

                .inputdesign1::placeholder {
                    color: #9BA9C5;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .phone-num {
                margin-top: 1.5rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 12px;

                input {
                    border-radius: 12px;
                    background: #F1F5FD;
                    height: 40px;
                    color: #888;
                    font-size: 14px;
                    border: unset;
                    padding: 12px 12px 12px 44px;
                    width: 100%;
                    box-sizing: border-box;
                }
            }

        }

    }

    .OrderDetails__contants {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;

        .consluting__wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }

        .consluting_Img {
            height: 30px;
            width: 30px;
            object-fit: contain;
        }

        .Order__Details {
            color: #12CC92;
            font-size: 18px;
            font-weight: 700;
        }

        .serice__text {
            color: #3C3C3C;
            font-family: Inter500;
            font-size: 14px;
        }

        .Order__amount {
            color: #333;
            font-size: 16px;
            font-weight: 800;
        }

        .left__contant {
            display: flex;
            flex-direction: column;

            .date {
                color: #12CC92;
                font-size: 12px;
                font-weight: 600;
            }

            .course {
                color: #3C3C3C;
                font-size: 14px;
                font-weight: 600;
                margin-top: 7px;
                margin-bottom: 3px;
            }

            .session__count {
                color: rgba(51, 51, 51, 0.75);
                font-size: 12px;
                font-weight: 500;
            }
        }

        .right__contant {
            color: rgba(51, 51, 51, 0.75);
            font-size: 12px;
            font-weight: 600;
        }
    }

    .OrderDetails__view__more {
        color: #12CC92;
        font-size: 14px;
        font-weight: 700;
        text-align: end;
        padding-right: 20px;
        cursor: pointer;
    }

    .Course__Details__bottom__contant {
        margin: 15px 0px;

        .title {
            color: #333;
            font-size: 12px;
            font-weight: 600;
            padding-left: 20px;
        }

        .bottom__contant__wapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;

            .black__text {
                color: #333;
                font-family: Inter600;
                font-size: 14px;
            }

            .light__text {
                color: rgba(51, 51, 51, 0.75);
                font-family: Inter500;
                font-size: 13px;
            }

            .bottom__contant {
                display: flex;
                align-items: center;
                gap: 10px;

                img {
                    width: 33px;
                    height: 31px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .name {
                    color: #333;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }

    .summary {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: #FFF !important;
        box-shadow: 0px 4px 15px 0px rgba(180, 180, 180, 0.25) !important;
        padding: 10px;

        .Price__detailes__wapper {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 1rem;

            .original-price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 4px 14px;

                .original__price__text {
                    color: rgba(51, 51, 51, 0.75);
                    font-size: 14px;
                    font-weight: 500;
                }

                .discount-text {
                    color: #12CC92;
                    font-size: 14px;
                    font-weight: 500;
                }

                .total__small {
                    color: rgba(0, 0, 0, 0.75);
                    font-size: 14px;
                    font-weight: 700;
                }

                .total__large {
                    color: #333;
                    font-size: 18px;
                    font-weight: 700;
                }
            }
        }

        .summary-btndiv {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .summary__contant {
                display: flex;
                flex-direction: row;
                margin-top: 2.5rem;

                .checkbox {
                    width: 16px;
                    height: 16px;
                    border-radius: 3px;
                    border: 1px solid #12CC92 !important;
                    background: #F7F5FF !important;
                    cursor: pointer;
                }

                .paratext {
                    color: rgba(51, 51, 51, 0.75);
                    font-size: 12px;
                    font-weight: 500;
                }

                .terms__text {
                    color: #12CC92;
                    font-size: 12px;
                    font-weight: 600;
                }
            }

            .proceedBtn {
                width: 359px;
                height: 56px;
                margin-top: 2rem;
                border-radius: 26px;
                background: #12CC92 !important;
                color: #FFF;
                font-size: 16px;
                font-weight: 500;
            }

            .proceedBtn__disable {
                width: 359px;
                height: 56px;
                margin-top: 2rem;
                border-radius: 26px;
                background: #12CC92 !important;
                color: #FFF;
                font-size: 16px;
                font-weight: 500;
                opacity: 0.5;
                cursor: default !important;
            }
        }
    }

}


.University__OrderDetails__Dailoge {
    width: 100% !important;
    min-width: 500px !important;
    height: 100%;
    max-height: 250px;
    overflow: auto;
    overflow-y: scroll;

    .contant__wapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        .Order__Details {
            color: #2396F2;
            font-size: 18px;
            font-weight: 700;
        }

        .Order__amount {
            color: #333;
            font-size: 16px;
            font-weight: 800;
        }

        .left__contant {
            display: flex;
            flex-direction: column;

            .date {
                color: #12CC92;
                font-size: 12px;
                font-weight: 600;
            }

            .course {
                color: #3C3C3C;
                font-size: 14px;
                font-weight: 600;
                margin-top: 7px;
                margin-bottom: 3px;
            }

            .session__count {
                color: rgba(51, 51, 51, 0.75);
                font-size: 12px;
                font-weight: 500;
            }
        }

        .right__contant {
            color: rgba(51, 51, 51, 0.75);
            font-size: 12px;
            font-weight: 600;
        }
    }
}

.bottom__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .backBtn {
        width: 319px;
        height: 50px;
        border-radius: 23px;
        background: #E8F4FF;
        color: #12CC92;
        font-size: 14px;
        font-weight: 600;
    }

    .paymentBtn {
        width: 319px;
        height: 50px;
        border-radius: 23px;
        background: #12CC92;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
    }

}