.InfoWindow__conatiner {
    width: 362px !important;
    height: 147px !important;
}

.Hover__Card {
    display: flex !important;
    max-width: 480px !important;
    // min-width: 250px !important;
    max-height: 135px !important;
    border-radius: 9px !important;
    background: #FFF !important;
    cursor: pointer;

    .profile_image {
        width: 73px !important;
        height: 100px !important;
        object-fit: cover;
    }

    .user__Detailes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center !important;
        padding: 3px 10px;
        gap: 30px;

        .user__name {
            color: #000;
            font-size: 16px;
            font-family: Manrope700;
        }

        .user__icon__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center !important;
            gap: 10px;

            img {
                height: 14px;
                width: 15px;
            }
        }
    }

    .user__Detailes__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 10px;
        gap: 10px;

        .grayIcons {
            width: 10px;
            height: 12px;
        }

        .graytext {
            color: #4E4E4E;
            font-size: 12px;
            font-family: Inter500;
        }
    }

    .user__Detailes__Curriculam {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        padding: 3px 10px;
        gap: 10px;

        .lable {
            color: #4E4E4E;
            font-size: 12px;
            font-family: Inter500;
        }
    }

    .user__Detailes__Course {
        padding: 4px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .Course {
            color: #178FFF;
            font-size: 12px;
            font-family: Inter500;
        }

        .lable {
            color: #4E4E4E;
            font-size: 12px;
            font-family: Inter500;
            max-width: 90px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis !important;
        }

        img {
            width: 12px;
            height: 13px;
        }
    }

    .user__Detailes__rating {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 4px 10px;

        .title {
            color: #178FFF;
            font-size: 16px;
            font-family: Inter500;
        }

        .StarIcon {
            color: #FCB040 !important;
            width: 18px;
            height: 17px;
        }

        .card__title {
            color: #333;
            font-size: 12px;
            font-family: Inter500;
        }
    }
}


.custom-tooltip {
    max-width: 372px !important;
    max-height: 157px !important;
    cursor: pointer;
}

.marker {
    padding: 10px;
    border-radius: 50% !important;

    img {
        width: 30px !important;
        height: 30px !important;
        border-radius: 50% !important;
    }

}

.gm-style-iw {
    margin-top: 1.9rem !important;
}

.gm-style .gm-style-iw-tc {
    display: none !important;
}

// .gm-style {
//     img {
//         border-radius: 50% !important;
//     }
// }

.customMarker {
    position: absolute;
    cursor: pointer;
    background: #424242;
    width: 100px;
    height: 100px;
    /* -width/2 */
    margin-left: -50px;
    /* -height + arrow */
    margin-top: -110px;
    border-radius: 50%;
    padding: 0px;
}

.customMarker:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 40px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: #424242 transparent;
    display: block;
    width: 0;
}

.cutomMarker__icon {
    width: 90px;
    height: 90px;
    border-radius: 90px;
}