.tickets-detail-wrapper {
  margin-bottom: 2rem;
    .ViewTickets {
      &-image {
        height: 120px;
        width: 100%;
        object-fit: cover;
      }
  
      .no-ticket-msg {
        padding: 0px 20px;
      }
      ul {
        padding-left: 20px;
      }
    }
  
    #highlight-card-wrap {
      padding: 10px 30px 10px 38px !important;
    }
  
    .ticket-desc {
      overflow: auto;
    }
  
    #card-wrap {
      background-color: #eeeeee;
      padding: 20px;
      // height: calc(100vh - 190px);
      height: 100%;
    //   overflow-y: scroll;
    }
  
    .Viewtag-input-email {
      text-transform: lowercase;
      background: none;
      border: none;
      font-size: 16px;
      color: #6f6f6f;
      font-weight: 500;
    }
  
    .highlight-list,
    .ticket-event-name {
      background: none;
      border: none;
      font-size: 16px;
      color: #6f6f6f;
      font-weight: 500;
    }
  
    .ticket-event-name {
      text-transform: capitalize;
    }
    .highlight-list {
      text-transform: capitalize;
    }
  
    .ticket-top-view {
      height: 400px;
      background-color: white;
      width: 100%;
       margin-bottom: 30px;
      position: relative;
      border-radius: 15px;
      padding: 20px;
      h5{
        margin-bottom: 15px;
      }
      .top-ticket{
        height:200px;
      }
      .bottom-ticket{
        height: 200px;
      }
      // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  
    .theme-title{
        color: #8a8a8a;
        font-family: Inter400;
        font-size: 14px;
    }
    .theme-value{
        color : #333;
        font-size: 14px;
        font-family: Inter500;
    }

    .right-hole,
    .left-hole {
      height: 50px;
      border-radius: 50%;
  
      background-color: #eeeeee;
      width: 50px;
      box-shadow: none;
    }
  
    .right-hole {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 90%;
      background-color: #eeeeee;
    }
    .left-hole {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 90%;
      background-color: #eeeeee;
      /* left: 0px; */
    }
  
    /* .label-data-wrap {
      display: flex;
      justify-content: flex-start;
    } */
  
    .border-span {
      position: absolute;
      // border-bottom: 0.5px dashed #4D4D4D;
      // border-block-width: 6px;
      top: 55%;
      z-index: 99;
      width: 100%;
      // left: 20px;
      left: 4.5px;
      // border: 0.5px solid #4D4D4D;
      // height: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .border-image {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: c;
    }
  
    .ticket-image {
      height: 130px;
      width: 90%;
      border-radius: 5px;
    }
  
    .title-download{
        display: flex;
        justify-content: space-between;
        .download-icon{
            color: #a3a0a0;
            cursor: pointer;
            // font-size: 18px;
        }
    }
    .ticket-image-wrap {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .ticket-wrap {
      display: flex;
      justify-content: center;
    }
  
    #ticket-values {
      font-weight: 500;
      text-transform: capitalize;
    }
  
    @media (max-width:1024px) {
      #card-wrap {
        background-color: #eeeeee;
        // height: calc(100vh - 190px);
        height: 450px;
        overflow-y: scroll;
      }
    }
    @media (min-width:1440px) {
      #card-wrap {
        background-color: #eeeeee;
        // height: calc(100vh - 190px);
        height: 600px;
        overflow-y: scroll;
      }
    }

  //   @media (max-width:1200px) {
  //       .ticket-top-view {
  //           height: 400px;
  //           background-color: white;
  //           width: 100%;
  //            margin-bottom: 30px;
  //           position: relative;
  //           border-radius: 15px;
  //           padding: 20px;
  //           h5{
  //             margin-bottom: 15px;
  //           }
  //           // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //         }
  // }

}


@media screen and (min-width:767px) and (max-width:992px) {
  .tickets-detail-wrapper {
    .row-overiding-style {
      flex-direction: column !important;

      .col-md-6 {
        width: 90% !important;
      }
    }
  }
}


@media screen and (max-width:992px) {
  .tickets-detail-wrapper {
    .ticket-top-view {
      h5 {
        margin-bottom: 10px !important;
        font-size: 1.1rem !important;
      }
    }

    .theme-value {
      margin-bottom: 0.5rem !important;
    }

    //
    .theme-title,
    .theme-value {
      font-size: 12px !important;
    }

    .highlight-list,
    .ticket-event-name,
    .Viewtag-input-email {
      font-size: 14px !important;
    }
  }
}


@media screen and (min-width:390px) and (max-width:575px) {
  .tickets-detail-wrapper {
    .row-overiding-style {
      display: flex !important;

      .col-md-6 {
        width: 50% !important;
      }
    }
    .theme-value {
      margin-bottom: 1rem !important;
    }
  }
}