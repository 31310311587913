.filter_container {
    display: flex;
    align-items: center;
    /* border: 1px solid gray; */
    border-radius: 10px;
    height: 48px;
    background: #fff;
    width: 100%;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);

    .filter_item:last-child {
        border-right: none;
    }
    

    .filter_item {
        border-right: 1px solid #e5e5e5;
        height: 100%;
        justify-content: center;
        display: flex;
        flex: 1 1;

        .item_button {
            width: 100%;
            justify-content: space-between;
            color: #333333;
            font-size: 12px;
            font-family: Manrope500;
            text-align: start;
            text-transform: capitalize;
            padding: 0px 10px;

            
        }

        .overflow-control {
            white-space: nowrap; /* Prevents text wrapping */
            overflow: hidden;    /* Hides overflowed content */
            text-overflow: ellipsis; /* Adds ellipsis (...) for overflowed content */
        }
    }

    .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
        background: none !important;
    }

    .menu_item:hover {
        background-color: transparent !important;
        /* Override hover background color */
    }

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
        background-color: transparent !important;
    }

    .menu_item {

        .contant__wapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .bobules__wapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
        }

        .title_text {
            color: #333;
            font-family: Inter500;
            font-size: 14px;
        }

        .price_range {
            color: #333;
            font-family: Manrope500;
            font-size: 12px;
        }

        .blue_text {
            color: #339CFE;
            font-family: Inter500;
            font-size: 14px;
        }

        .contant_text {
            display: inline-flex;
            height: 40px;
            padding: 10px 14px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border-radius: 29px;
            cursor: pointer;
            font-family: Manrope500;
            font-size: 12px;
            border: 1px solid #CBDCE1;
            background: #FBFBFB;
            color: #333;
            min-width: 65px;

        }



    }

    .Filter__footer__wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        margin-top: 1rem;

        .applyBtn {
            width: 95px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 26px;
            // background: #178FFF;
            color: #FFF;
            font-family: Inter500;
            font-size: 14px;
        }

        .resetBtn {
            width: 95px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 26px;
            border: 1px solid #CBDCE1;
            color: #333;
            font-family: Inter500;
            font-size: 14px;
        }
    }
}


//**Responsive**//
@media screen and (max-width:991px) {
    .filter_container {
        overflow-x: scroll;
        padding-top: 10px;
        // padding-bottom: 10px;
    .filter_item{ 
        .item_button {
          text-wrap: nowrap;
          .more-fillter-icon{
            margin-right: 10px;
          }
          .MorFillterIcon_arab{
            margin-right: initial;
            margin-left: 10px;
          }
          }
          .ItemButtonLang_arab{
            .css-9tj150-MuiButton-endIcon{
                margin-right: 10px !important;
            }
          }
    }
}
}