.drawe_outlet_wrapper {
  // margin: 65px 40px !important;
  // margin: 5% 1% 1% 1%;
  padding: 5% 1% 0% 1%;
  width: 98%;
  // height: 100vh;
  height: calc(100vh - 61px) !important;
  display: flex;
  justify-content: center;

  .drawer_wrapper {
    // width: 280px;
    width: 20%;
    border-radius: 24px !important;
    border: 1px solid #fff !important;
    background: #fff !important;
    backdrop-filter: blur(6px) !important;
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 10px !important;
    padding: 0px 10px;

    /*hide the sidebar scroll*/
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .profile-layout-swiper {
    .my-swiper-arab {
      direction: ltr;
    }
  }

  .profile_details_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
  }

  .name_actions_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .actions_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .accounts_profile_picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .name {
    color: #333;
    font-family: Manrope700;
    font-size: 16px;
  }

  .edit_profile {
    color: #646464;
    font-family: Manrope600;
    // font-size: 12px;
    font-size: 70%;
    cursor: pointer;
  }

  .add_profile {
    color: #646464;
    font-family: Manrope600;
    font-size: 12px;
    cursor: pointer;
  }

  .profile_contents_pad {
    padding: 20px 12px;
  }

  .accounts_divider {
    background-color: #e4e4e4 !important;
    height: 1px;
    padding: unset !important;
    margin-bottom: 11% !important;
  }

  .outlet_container {
    width: 80%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100%;

    .outlet_container__scroll {
      max-height: 100vh;
      width: 100%;
      overflow-y: scroll;
      height: 100%;
    }


    /*hide the Outlet Scroll*/
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .drawe_outlet_container {
    // width: 100%;
    overflow-x: hidden;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    // gap: 5%;
    padding-top: 24px;
    height: 100%;
    position: relative;
    overflow-y: hidden;

    ::-webkit-scrollbar-thumb {
      background: linear-gradient(98.6deg,
          #A080FF -1.65%,
          #d670f5 116.8%) !important;
      border-radius: 7px !important;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb:hover {
      --background: #000;
      background: purple;
    }
  }

  .breadCamp__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin: 10px 0;

    .home {
      color: #A0A0A0;
      font-family: Manrope600;
      font-size: 14px;
      cursor: pointer;
    }

    .breadCamp__rightIcon {
      font-size: 12px;
      color: #7B58FF !important;
    }

    .BreadCampRightIcon-arab {
      rotate: 179deg;
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .title {
      color: #000;
      font-family: Manrope600;
      font-size: 14px;
    }
  }

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, -6px) !important;
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
  }

  .profile__card__conatiner {
    width: 100%;
    height: auto;
    min-height: 150px;
    border-radius: 22px;
    border: 1px solid rgba(116, 79, 255, 0.13);
    background: #F7F4FF;
    backdrop-filter: blur(5.5px);
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px 12px;
    margin-bottom: 15px;

    .contant__wapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }

    .primary_badge {
      border-radius: 27px;
      background: #744FFF;
      width: fit-content;
      padding: 4px 10px;
      color: #FFF;
      font-family: Manrope700;
      font-size: 10px;
    }

    .select_badge {
      border-radius: 27px;
      background: rgba(116, 79, 255, 0.06);
      width: fit-content;
      padding: 4px 10px;
      color: #3D3D3D;
      font-family: Manrope700;
      font-size: 10px;
      cursor: pointer;
    }

    .profile_Img {
      width: 39.396px;
      height: 39.396px;
      border-radius: 50%;
    }

    .profile_Name {
      color: #000;
      font-family: Manrope700;
      font-size: 12px;
      text-align: center;
    }

    .role {
      color: #744FFF;
      font-family: Inter700;
      font-size: 11px;
    }


  }


  /* width */
  // .Profile__sideBar__container::-webkit-scrollbar {
  //   width: 0px !important;
  // }

  /* Track */
  // ::-webkit-scrollbar-track {
  //   background: transparent;
  // }

  /* Handle */
  // ::-webkit-scrollbar-thumb {
  //   background: red;
  // }

  .Profile__sideBar__container {
    // max-height: 430px;
    width: 100%;
    overflow-y: auto;
    overflow-y: scroll;
    // margin-bottom: 4rem;

    .css-1wvake5 {
      width: initial !important;
      min-width: initial !important;
      border: 1px solid rgba(116, 79, 255, 0.13) !important;
      border-radius: 5px !important;
    }

    // .css-1654oxy >.ps-menu-button{
    //   padding-left: 0px !important;
    //   padding-right: 0px !important;
    // }


    .menu__active {
      // border-left: 2px solid #744FFF;
      border-width: 3px;
      width: 100%;
    }

    .MenuActiveLang_Eng {
      border-left: 2px solid #744FFF;
    }

    .MenuActiveLang_Arab {
      border-right: 2px solid #744FFF;
    }

    .menu__data_wapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      // justify-content: space-between;
      .menu__icon {
        margin-right: 10%;
        height: 15px;
        width: 15px;
        object-fit: contain;
      }

      span {
        width: 70%;
        margin-right: 10%;
        color: #333333;
        font-family: Manrope500;
        // font-size: 14px;
        font-size: 87.5%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        min-width: 60%;
      }

      .rightIcon {
        color: #B6B6B6 !important;
        font-size: 12px !important;
        height: 14px !important;
      }

      .rightIcon-arab {
        rotate: 179deg;
      }
    }

    .menu__data_wapper__active {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      // justify-content: space-between;
      .menu__icon {
        margin-right: 10%;
        height: 15px;
        width: 15px;
        object-fit: contain;
      }

      span {
        width: 70%;
        margin-right: 10%;
        color: #744FFF;
        font-family: Manrope600;
        font-size: 14px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        min-width: 60%;
      }

      .rightIcon {
        color: #744FFF !important;
        font-size: 12px !important;
        height: 14px !important;
      }

      .rightIcon-arab {
        rotate: 179deg;
      }
    }
  }
}




@media (min-width: 1420px) {
  .drawe_outlet_wrapper {
    .Profile__sideBar__container {
      max-height: 85vh !important;
    }
  }
}

//************👋👋Overall Responsive style***************//

/**Icon Centre for mobile view**/
@media screen and (min-width: 360px) and (max-width:700px) {
  .Profile__sideBar__container {

    .menu__data_wapper,
    .menu__data_wapper__active {
      justify-content: center !important;
    }
  }
}

/**right side content need paddind top**/
@media screen and (max-width: 900px) {
  .drawe_outlet_wrapper {
    .outlet_container {
      padding-top: 20px !important;
    }

    .profile_contents_pad {
      padding: 20px 0px !important;

      .profile_details_wrapper {
        display: initial !important;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .drawe_outlet_wrapper {
    .drawer_wrapper {
      width: 25% !important;
    }

    .Profile__sideBar__container {

      .menu__data_wapper,
      .menu__data_wapper__active {
        span {
          display: none !important;
        }

        .css-1t8x7v1>.ps-menu-button {
          padding-right: 5px !important;
        }

        .rightIcon {
          display: none !important;
        }
      }
    }

    .profile_contents_pad {
      padding: 20px 0px !important;

      .profile_details_wrapper {
        display: initial !important;

        .name {
          padding-top: 10px !important;
          font-size: 80% !important;
        }

        .edit_profile {
          font-size: 60%;
        }
      }
    }

    .drawe_outlet_container {
      ::-webkit-scrollbar {
        width: 3px;
      }
    }

    .breadCamp__container {
      margin: 10px 3.5% !important;

      .title {
        font-size: 11px !important;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .breadCamp__container {
      .breadCamp__rightIcon {
        font-size: 10px !important;
      }
    }
  }
}

// @media screen and (max-width: 700px) {
//   .drawe_outlet_wrapper .breadCamp__container{
//     margin: 10px 3.5% !important;
//   }
// }

@media screen and (max-width: 380px) {

  .css-1654oxy>.ps-menu-button,
  .css-1t8x7v1>.ps-menu-button {
    width: 54px !important;
  }

  .drawe_outlet_wrapper .profile__card__conatiner .primary_badge {
    padding: 3px 4px !important;
  }

  .menu_item_lists_arab {
    .ps-menu-button {
      padding-right: 16px !important;
      padding-left: 10px !important;
    }
  }
}

@media screen and (max-width:450px) {
  .drawe_outlet_wrapper {
    .actions_wrap {
      flex-wrap: wrap;
    }
  }
}

/**profile edite add**/
@media screen and (max-width: 992px) {
  .drawe_outlet_wrapper {
    .profile_contents_pad {
      padding: 20px 0px !important;

      .profile_details_wrapper {
        display: initial !important;
      }
    }
  }
}