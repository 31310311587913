.school__list__view {
  .school__list__container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 450px;
  }

  .school__listView__Active {
    cursor: pointer;
    border: 2px solid #eebb12 !important;
  }

  .UniversityCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 60px;
    max-width: 180px;
    background-color: #fff !important;

    img {
      width: 23.122px;
      height: 19.818px;
    }

    .TutorImg__text {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }
  }

  .search_field_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    max-width: 582px;
  }

  .search_field:focus {
    outline: none;
  }

  .search_field_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 14px;
  }

  .search_field {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    font-size: 14px;
    width: 100%;
    height: 46px;
    padding: 14px 15px;
  }

  .search_field::placeholder {
    color: #adb5be;
    font-size: 14px;
    font-family: Inter500;
  }

  .search_button {
    padding: 16px 12px;
    cursor: pointer;
  }

  .search_button_icon_wrapper {
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 48px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .search__autocomplete {
    // width: 77% !important;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    position: relative;
    flex: 1;


    input::placeholder {
      font-size: 14px;
      font-family: Inter500;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 5px !important;
      border-radius: 15px !important;
    }

    .search_button_eng {
      cursor: pointer;
      position: absolute;
      right: 17px;
      top: 15px;
    }

    .search_button_arb {
      cursor: pointer;
      position: absolute;
      left: 17px;
      top: 15px;
    }
  }

  .List__topView__wapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .List__topView__onMap {
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // gap: 10px;
      // border-radius: 15px;
      // background: #f9f9fb;
      // box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
      // padding: 10px 20px;
      // cursor: pointer;
      // margin-right: 45px;
      // width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: fit-content;
      height: 60px;
      border-radius: 15px;
      background: #fff;
      padding: 5px 15px;
      cursor: pointer;
      margin-right: 10px;
      border: 2px solid #fff;

      span {
        color: #000;
        font-size: 16px;
        font-family: Manrope700;
      }
    }
  }

  .school__list__wapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 96%;
    margin: 20px 0;
    padding: 14px;
    border-radius: 14px;
    background: #fff;

    .university__leftside {
      img {
        width: 200px;
        height: 180px;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .university__rightside {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .university__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between !important;

        .name {
          color: #000;
          font-size: 16px;
          font-family: Manrope700;
          cursor: pointer;
        }

        .imageIcon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }

      .university__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }

        .location {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
          max-width: 330px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .university__ranking {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .text__black {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }

        .text__green {
          color: #ffc812;
          font-size: 12px;
          font-family: Inter500;
          max-width: 65px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .university__rating {
        display: flex;
        flex-direction: row;
        gap: 12px;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;

        .text__green {
          color: #FFC812;
          font-size: 12px;
          font-family: Inter600;
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .text__black {
          color: #333333;
          font-size: 12px;
          font-family: Inter500;
        }
      }
    }
  }

  .top__contant__wapper__university {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .top__PageBtn__icons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      cursor: pointer;

      .prev__icons {
        color: rgba(18, 204, 146, 1) !important;
      }

      .next__icons {
        color: rgba(18, 204, 146, 1) !important;
      }

      .icons__disable {
        color: rgba(18, 204, 146, 1) !important;
        opacity: 0.5;
        cursor: default !important;
      }

      .text__disable {
        color: #333;
        font-size: 14px;
        font-weight: 700;
        opacity: 0.5;
        cursor: default !important;
      }

      .prev__text {
        color: #333;
        font-size: 14px;
        font-family: Manrope700;
      }
    }

    .top__close__icons {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .expanded__icons {
        cursor: pointer;
        color: #333333 !important;
        font-size: 16px;
      }

      .close__icons {
        cursor: pointer;
        font-size: 16px;
        color: #333333 !important;
      }
    }
  }

  .top_cover_img_wrapper {
    position: relative;
    max-width: 530px !important;

    .card_bg_img {
      width: 269px;
      height: 150px;
      object-fit: cover;
      border-radius: 12px;
      padding: 0 5px;
    }

    .profileImg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 1);
      object-fit: cover;
      position: absolute;
      left: 20px;
      bottom: -30px;
    }

    .left_arrow {
      position: absolute;
      left: 9px;
      top: 63px;
      z-index: 1000;
      border-radius: 24px;
      background: rgba(240, 240, 240, 0.27);
      backdrop-filter: blur(1.3333333730697632px);
      color: #f0f0f045 !important;
    }

    .right_arrow {
      position: absolute;
      right: 9px;
      top: 63px;
      z-index: 1000;
      border-radius: 24px;
      background: rgba(240, 240, 240, 0.27);
      backdrop-filter: blur(1.3333333730697632px);
      color: #f0f0f045 !important;
    }

    .alice-carousel {
      direction: ltr !important;
    }
  }

  .top_cover_img_wrapper_arb {
    position: relative;
    max-width: 530px !important;

    .card_bg_img {
      width: 269px;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
      padding: 0 5px;
    }

    .profileImg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 1);
      object-fit: cover;
      position: absolute;
      right: 20px;
      bottom: -30px;
    }

    .left_arrow {
      position: absolute;
      left: 9px;
      top: 63px;
      z-index: 1000;
      border-radius: 24px;
      background: rgba(240, 240, 240, 0.27);
      backdrop-filter: blur(1.3333333730697632px);
      color: #f0f0f045 !important;
    }

    .right_arrow {
      position: absolute;
      right: 9px;
      top: 63px;
      z-index: 1000;
      border-radius: 24px;
      background: rgba(240, 240, 240, 0.27);
      backdrop-filter: blur(1.3333333730697632px);
      color: #f0f0f045 !important;
    }

    .alice-carousel {
      direction: rtl !important;
    }
  }

  .card__container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 24px;

    .user__Detailes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 7px 0px;

      .user__name {
        color: #000;
        font-size: 20px;
        font-family: Manrope700;
      }

      .user__icon__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center !important;
        gap: 10px;

        img {
          height: 20px;
          width: 18px;
        }
      }
    }

    .description__contant {
      color: rgb(51, 51, 51);
      font-size: 14px;
      font-family: Inter400;
      padding: 0px 10px;
    }

    .user__Detailes__Education {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 10px;
      gap: 10px;

      .grayIcons {
        width: 12px;
        height: 14px;
      }

      .graytext {
        color: #333;
        font-size: 14px;
        font-family: Inter500;
      }

      .StarIcon {
        color: #fcb040 !important;
        width: 18px;
        height: 17px;
      }
    }

    .user__Detailes__Grade {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .lable {
        color: #4e4e4e;
        font-size: 14px;
        font-family: Inter500;
      }

      img {
        width: 12px;
        height: 16px;
      }
    }

    .user__Detailes__fess {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 7px 10px;
      margin-top: 6px;

      .Avg__Fees {
        color: #333;
        font-size: 14px;
        font-family: Inter500;
      }

      .title {
        color: #178fff;
        font-size: 16px;
        font-family: Manrope600;
      }
    }

    .user__Detailes__Courses {
      padding: 7px 10px;

      .title {
        color: #000;
        font-size: 18px;
        font-family: Manrope700;
      }

      .badge_wrapeer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 6px;
        max-width: 400px;

        .badge__container {
          border: 1px solid #d8d8d8;
          padding: 2px 8px;
          border-radius: 3px;
          margin: 0;
          cursor: pointer;

          span {
            color: #178fff;
            font-size: 12px;
            font-family: Inter600;
          }
        }

        .badge__container-Active {
          border: 1px solid #178fff;
          padding: 2px 8px;
          border-radius: 3px;
          margin: 0;
          cursor: pointer;
          background-color: #178fff;

          span {
            color: #fff;
            font-size: 12px;
            font-family: Inter600;
          }
        }
      }
    }
  }

  .school__list__wapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 96%;
    margin: 20px 0;
    padding: 10px;
    border-radius: 14px;
    background: #fff;

    .university__leftside {
      img {
        width: 200px;
        height: 160px;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .university__rightside {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .university__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between !important;

        .name {
          color: #000;
          font-size: 16px;
          font-family: Manrope700;
          cursor: pointer;
        }

        .imageIcon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            height: 20px;
            width: 18px;
          }
        }
      }

      .university__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }

        .location {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }
      }

      .university__ranking {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .text__black {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }

        .text__green {
          color: #ffc812;
          font-size: 12px;
          font-family: Inter500;
        }
      }

      .university__rating {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .text__contant {
          color: #eebb12;
          font-size: 16px;
          font-family: Inter500;
        }
      }
    }
  }

  .school__listView__container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 350px;
  }

  .schoolListView__rightSide__Pop {
    overflow: auto;
    overflow-y: scroll;
    max-height: 450px;
    border-radius: 16px;
    background: #fff;
    padding: 20px;
    max-width: 594px;
  }

  .animate-slideInRight {
    animation: slideInRight 0.3s forwards;
  }

  .Model__display__Block {
    display: block;
  }

  .Model__display__none {
    display: none;
  }

  @keyframes slideInRight {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  .top__contant__wapper__school {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .top__PageBtn__icons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      cursor: pointer;

      .prev__icons {
        color: rgba(255, 200, 18, 1) !important;
      }

      .next__icons {
        color: rgba(255, 200, 18, 1) !important;
      }

      .icons__disable {
        color: rgba(255, 200, 18, 1) !important;
        opacity: 0.5;
        cursor: default !important;
      }

      .text__disable {
        color: #333;
        font-size: 14px;
        font-weight: 700;
        opacity: 0.5;
        cursor: default !important;
      }

      .prev__text {
        color: #333;
        font-size: 14px;
        font-family: Inter700;
      }
    }

    .top__close__icons {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .expanded__icons {
        cursor: pointer;
        color: #333333 !important;
        font-size: 16px;
      }

      .close__icons {
        cursor: pointer;
        font-size: 16px;
        color: #333333 !important;
      }
    }
  }

  .View__on__map_School_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    padding: 7px 0px;

    .wapperDiv {
      border-radius: 7px;
      background: #faf5e3;
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      img {
        width: 16.217px;
        height: 13.79px;
      }

      span {
        color: #875a04;
        font-size: 12px;
        font-family: Inter600;
      }
    }
  }

  .School__detailed__container__ {
    // padding: 7px 10px;
    // display: flex;
    // flex-direction: column;

    .flexDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
    }

    img {
      width: 20px;
      height: 20px;
    }

    span {
      color: #333;
      font-size: 14px;
      font-family: Inter500;
    }

    .number {
      color: #333;
      font-size: 16px;
      font-family: Manrope700;
      max-width: 120px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .detailed__container__wapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 18px;
    }
  }

  .school__Taps__container__width {
    max-width: 490px !important;

    .css-m2al5c {
      margin-top: 10px !important;
    }
  }

  .css-m2al5c {
    margin-top: 10px !important;
  }

  .school__Taps__container {
    .css-1l4w6pd {
      justify-content: space-around !important;
    }

    .css-19kzrtu {
      padding: 10px 12px !important;
    }

    .school__tap__contant {
      span {
        color: #333;
        font-size: 14px;
        font-family: Manrope600;
      }

      ul {
        list-style: none;
        margin-top: 1rem;
      }

      ul li::before {
        content: "\25A0";
        color: rgba(255, 200, 18, 1);
        font-weight: 800;
        font-size: 0.7em;
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-left: -2em;
        margin-right: 1em;
      }

      li {
        color: #000;
        font-size: 14px;
        font-family: Inter400;
        margin-top: 7px;
      }
    }

    .school__tap__contant_arb {
      span {
        color: #333;
        font-size: 14px;
        font-family: Manrope600;
      }

      ul {
        list-style: none;
        margin-top: 1rem;
      }

      ul li::before {
        content: "\25A0";
        color: rgba(255, 200, 18, 1);
        font-weight: 800;
        font-size: 0.7em;
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-left: 1em;
        margin-right: 1em;
      }

      li {
        color: #000;
        font-size: 14px;
        font-family: Inter400;
        margin-top: 7px;
      }
    }
  }

  .School__Taps {
    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #333 !important;
      text-transform: capitalize !important;
      font-family: Manrope700 !important;
      font-size: 16px !important;
    }

    .css-1mrn996.Mui-selected {
      color: #333 !important;
      text-transform: capitalize !important;
      font-family: Manrope700 !important;
      font-size: 16px !important;
    }

    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
      text-transform: capitalize !important;
      color: #949494 !important;
      font-family: Manrope600 !important;
      font-size: 16px !important;
    }

    .css-axpu1l {
      background-color: rgba(255, 200, 18, 1) !important;
      border-radius: 7px 11px 0px 0px !important;
      height: 5px;
    }

    .css-11yukd5-MuiTabs-indicator {
      background-color: rgba(255, 200, 18, 1) !important;
      border-radius: 7px 11px 0px 0px !important;
      height: 5px;
    }
  }

  .bottom__contant__wapper__school {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    div {
      font-size: 14px;
      font-family: Inter500;
      text-align: left;
    }

    img {
      width: 21px;
      height: 20px;
      object-fit: contain;
    }

    .bottom__contant__left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 14px;
    }

    .bottom__contant__right {
      color: #333;
      font-size: 14px;
      font-family: Manrope700;
    }
  }

  .School__personal__detailes__wapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 4px 10px;

    .detailes__wapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;

      .left__side {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 15px;

        img {
          width: 18px;
          height: 18.001px;
          object-fit: contain;
        }

        span {
          color: #333;
          font-family: Inter500;
          font-size: 14px;
        }
      }

      .link {
        color: #0285bd;
        font-family: Inter400;
        font-size: 14px;
        text-decoration-line: underline;
      }

      .text {
        color: #333;
        font-family: Inter400;
        font-size: 14px;
        text-wrap: wrap;
        text-align: -webkit-right;
        margin-left: 10px;
        // max-width: 300px;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }

      .number {
        color: #0285bd;
        font-family: Inter400;
        font-size: 14px;
      }
    }
  }

  .School__fess__structure__wapper {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 4px 10px;

    .detailes__wapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .left__side {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        img {
          width: 26px;
          height: 26px;
          object-fit: contain;
        }

        span {
          color: #333;
          font-family: Manrope700;
          font-size: 14px;
        }
      }

      .fees__wapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 2px;
      }

      .text {
        color: #333;
        font-family: Inter600;
        font-size: 12px;
      }

      .number {
        color: #333;
        font-family: Inter600;
        font-size: 14px;
      }
    }
  }

  .School__fess__per_Year__wapper {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 4px 10px;

    .detailes__wapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    img {
      width: 34px;
      height: 34px;
      object-fit: contain;
      cursor: pointer;
    }

    .light_text {
      color: #000;
      font-family: Manrope700;
      font-size: 14px;
    }

    .fees__wapper {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 2px;
    }

    .text {
      color: #333;
      font-family: Inter600;
      font-size: 12px;
    }

    .number {
      color: #333;
      font-family: Inter600;
      font-size: 14px;
    }

  }

  .school_join__now_wapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;

    .join__now_Btn {
      width: 100%;
      max-width: 312px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 52px;
      border: 2px solid rgba(23, 143, 255, 0.1);
      background: #efae07;
      color: #f9f9fb;
      font-family: Inter600;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 990px) {
  .school__list__view {
    .schoolListView__rightSide__Pop {
      width: 100% !important;
      max-width: 100% !important;
      max-height: 100% !important;
      height: 100% !important;
    }

    .top_cover_img_wrapper {
      position: relative;
      max-width: 100% !important;
    }

    .school__Taps__container__width {
      max-width: 100% !important;
    }
  }

  .university__description__container .contants {
    max-width: 100% !important;
  }

  .school__listView__container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 100% !important;
    height: 100% !important;
  }
}

@media (max-width: 320px) {
  .school__list__view {
    .search_field_wrapper {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 436px) {
  .school__list__view .School__fess__structure__wapper .detailes__wapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
  }

  .school__list__view .schoolListView__rightSide__Pop {
    padding: 10px;
  }
}

@media screen and (max-width: 1330px) and (min-width: 1080px) {
  .school__list__view {
    .school__listView__container .university__rightside .university__location .location {
      max-width: 180px !important;
    }
  }
}


//mobileView app download
@media (max-width: 500px) {
  .school__listView__container {
    .school__list__wapper {
      display: flex;
      flex-direction: row !important;
      gap: 10px;
      width: 100% !important;
      margin: 10px 0;
      padding: 12px;
      border-radius: 14px;
      background: #fff;

      .university__leftside {
        img {
          width: 100px !important;
          height: 100% !important;
          max-height: 100px !important;
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
      }

      .university__rightside {
        display: flex;
        flex-direction: column;
        gap: 5px !important;
        width: 100%;

        .university__name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between !important;

          .name {
            color: #000;
            font-size: 14px;
            font-family: Manrope700;
            cursor: pointer;
          }

          .imageIcon {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-right: 7px;

            img {
              width: 20px;
              height: 17px;
              cursor: pointer;
            }
          }
        }

        .university__location {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            width: 12px;
          }

          .location {
            color: #333;
            font-size: 12px;
            font-family: Inter500;
            max-width: 180px !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .marginTop {
          margin-top: 0px !important;
        }

        .university__ranking {
          display: none !important;
        }

        .university__rating {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          cursor: pointer;
          gap: 12px;

          .text__green {
            color: #FFC812;
            font-size: 12px;
            font-family: Inter600;
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .text__black {
            color: #333333;
            font-size: 12px;
            font-family: Inter500;
          }
        }
      }
    }
  }
}


@media (min-width: 1420px) {
  .school__list__view {
    margin-top: 1.5rem;

    .school__listView__container {
      max-height: 73vh !important;

      .university__rightside {
        .university__ranking {
          .text__green {
            color: #FFC812;
            font-size: 12px;
            font-family: Inter600;
            max-width: 83%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .schoolListView__rightSide__Pop {
      max-height: 65vh !important;
      max-width: 100% !important;
    }

    .school__Taps__container__width {
      max-width: 100% !important;
    }

    .top_cover_img_wrapper {
      max-width: 100% !important;
    }

  }

  .university__description__container {
    max-width: 100% !important;
  }
}