.Activies_card_carousel_wrapper {
    .Activies_card_wrapper {
        border-radius: 10px;
        background: #fff;
        margin-bottom: 10px;
        margin-right: 10px;
        position: relative;
    }

    .cornorIcon {
        position: absolute;
        top: 0;
        left: 0;
    }

    .card_bg_img {
        width: 186px;
        height: 161px;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
        user-select: none !important;
        // user-drag: none !important;
        -moz-user-select: none !important;
        -webkit-user-drag: none !important;
        -webkit-user-select: none !important;
        -ms-user-select: none !important;
    }

    .Activies_card_text {
        color: #000;
        font-size: 12px;
        font-weight: 700;
        padding: 7px 4px;
    }

}

.Activies_carousel_title {
    color: #333;
    font-family: Inter500;
    font-size: 18px;
}

.arrow_nav_tile_contianer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    width: 100%;
}

.arrow_navs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-right: 10px;
}

.left_arrow,
.right_arrow {
    cursor: pointer;
}

.Activies_card_carousel_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
    width: calc(100% - 15px);
    overflow: hidden;

    .alice-carousel__stage-item {
        width: 200px !important;
    }

    .alice-carousel__wrapper {
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
    }
}

.activity_card_carousel_exploreAll__wapper {
    width: 200px;
    height: 100%;
    min-height: 186px;
    border-radius: 10px;
    padding: 44px 32px;
    background: #FFAE4F;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .text {
        color: #FFFF97;
        text-align: center;
        font-family: Manrope500;
        font-size: 20.409px;
    }

    .title {
        color: #FFFF97;
        text-align: center;
        font-family: Manrope600;
        font-size: 40.017px;
    }
}


.activities__camp_dates {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    gap: 8px;

    .camp_dates__icon {
        height: 16px !important;
        width: 16px !important;
        object-fit: contain;
        // margin-bottom: 12px;
    }
}

.Activies_bottom_nearyou_container {
    margin: 1.5rem 0;

    .activities__list__wapper {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }
}

//*********For Mobile Responsive**********//
@media screen and (max-width: 650px) {
    // .activities__list__wapper {
    //     flex-direction: column !important;

    //     .activities__leftside .profile_img {
    //         width: 100% !important;
    //     }
    // }

    .find-sport-personal-wrap {
        margin-top: 4% !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 1231px) {
    // .activities__list__wapper {
    //     flex-direction: column !important;

    //     .activities__leftside .profile_img {
    //         width: 100% !important;
    //     }
    // }
}

@media screen and (max-width: 720px) {
    .css-19kzrtu{
        padding: 0px  !important;
    }
}