.TutorCourse_Category_Img_wapper {
    border-radius: 17px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(216, 216, 216, 0.25);
    width: auto;
    padding: 10px 15px;
    cursor: pointer;

    img {
        width: 32.16px;
        height: 32.002px;
    }

    span {
        margin-left: 5px;
        color: #333;
        font-size: 12px;
        font-family: Manrope600;
    }
}

.Category_bobules__wapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 17px;
    // align-items: center;
}