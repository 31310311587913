.ActivitiesList__view {
    display: block;
    max-width: 1500px;
    margin: 30px auto 0 auto;
    margin-bottom: 5rem;


    .title_header {
        color: #000 !important;
        font-size: 16px !important;
        font-family: Manrope700 !important;
        margin-bottom: 10px;
    }

    .text_contant {
        font-size: 14px !important;
        font-family: Inter400 !important;
        color: #000 !important;
    }

    .text_contant_bold {
        font-size: 14px !important;
        font-family: Inter600 !important;
        color: #000 !important;
    }

    .description__contant {
        font-size: 14px !important;
        font-family: Inter400 !important;
        color: #000 !important;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
    }

    .css-19kzrtu {
        padding: 0px 10px 0px 0px !important;
    }

    .activities_Img_wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 200px;
        height: 60px;
        border-radius: 15px;
        background: #fff;
        padding: 5px 15px;
        cursor: pointer;

        .activities__text {
            color: #000;
            font-size: 20px;
            font-family: Manrope700;
        }

        .back-img {
            cursor: pointer;
            margin-right: 2px;
        }

        .back-img_arb {
            cursor: pointer;
            margin-right: 2px;
            rotate: 180deg;
        }
    }

    .css-m2al5c {
        margin-top: 0px !important;
    }

    .css-1l4w6pd {
        justify-content: space-evenly !important;
    }

    // .activities_Img_wapper {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     gap: 10px;
    //     border-radius: 15px;
    //     background: #F9F9FB;
    //     box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    //     width: 172px;
    //     height: 60px;
    //     padding: 10px 20px;
    //     cursor: pointer;
    //     // margin-left: 56px;

    //     .activities__text {
    //         color: #000;
    //         font-size: 20px;
    //         font-weight: 700;
    //     }
    // }

    .search_field_wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 14px;
        margin-bottom: 20px;
        // margin-top: 20px;

        .activitiesCard_Filter {
            width: 100px;
            height: 46px;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            padding: 10px;
            gap: 10px;
            border-radius: 15px;
            background: #FFF;
            box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);

            .FilterIcon {
                width: 13px;
                height: 13.949px;
                color: #333333;
                object-fit: contain;
            }

            .Filter__text {
                color: #333;
                font-size: 16px;
                font-family: Inter500;
            }
        }

        .search__autocomplete {
            width: 100% !important;
            // min-width: 282px;
            max-width: 482px !important;
            border-radius: 15px;
            background: #FFF;
            box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
            border: none;
            position: relative;

            input::placeholder {
                // color: #ADB5BE;
                font-size: 14px;
                font-weight: 500;
            }

            .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
                padding: 5px !important;
                border-radius: 15px !important;
            }

            .css-18nc3u2 .MuiOutlinedInput-root {
                padding: 5px 9px !important;
                height: 45px !important;
            }

            .search_button_eng {
                // padding: 8px 12px;
                cursor: pointer;
                position: absolute;
                right: 17px;
                top: 15px;
            }

            .search_button_arb {
                // padding: 8px 12px;
                cursor: pointer;
                position: absolute;
                left: 17px;
                top: 15px;
            }

        }
    }

    .activitiesListView__rightSide__Pop {
        overflow: auto;
        overflow-y: scroll;
        max-height: 450px;
        border-radius: 16px;
        background: #FFF;
        padding: 20px;
        max-width: 594px;
        // margin-top: 13px;
        // margin-top: 80px;
        box-shadow: 7px 4px 26px 0px rgba(144, 144, 144, 0.25);
    }

    .card__container {
        padding: 10px;
        display: flex;
        flex-direction: row;
        margin-top: 35px;
    }
}

.contact_us_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 0.5rem 1.5rem;
    cursor: pointer;

    img {
        height: 32px;
        width: 32px;
        object-fit: contain;
    }

    .text_call {
        color: #F0962C;
        font-size: 16px;
        font-family: Manrope700;
    }

    .number_text {
        color: #333;
        font-family: Inter400;
        font-size: 14px;
    }


}

.activities__list__wapper {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    padding: 10px;
    border-radius: 14px;
    background: #FFF;
    cursor: pointer;
    // margin-top: 10px;
    margin-bottom: 10px;
    border: 2px #fff solid;


    .activities__leftside {
        position: relative;

        .profile_img {
            width: 200px;
            height: 165px;
            object-fit: cover;
            border-radius: 10px;
        }

        .identity_icon {
            position: absolute;
        }
    }

    .activities__rightside {
        display: flex;
        flex-direction: column;
        // gap: 15px;
        padding: 5px 0px;
        width: 100%;

        // justify-content: space-between;
        .activity_top {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .activities__name{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between !important;
            flex-wrap: wrap;
            // margin-bottom: 10px;

            .age_group_wapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
            }

            .age_group_badge {
                border: 2px solid #FCB040;
                border-radius: 12px;
                padding: 1px 6px;
                color: #000;
                font-size: 12px;
                font-family: Inter400;
            }

            .name {
                color: #000;
                font-size: 16px;
                font-family: Manrope700;
            }

            .imageIcon {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                img {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .camp_dates {
            color: #333;
            font-family: Inter500;
            font-size: 12px;
            margin: 8px 0;
        }

        .activities__location {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            margin-bottom: 5px;


            img {
                width: 10px;
                height: 12px;


            }

            .location {
                color: #333;
                font-size: 12px !important;
                font-family: Inter500;
                max-width: 280px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .Slots_activities {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            // margin-bottom: 10px;


            .lable {
                border-radius: 5px;
                background: #FAF3EC !important;
                padding: 3px 10px;
                color: #FCB040;
                font-size: 12px;
                font-weight: 500;

                img {
                    width: 14px;
                    margin-right: 5px;
                }
            }

            .Slots {
                color: #4E4E4E;
                font-size: 12px;
                font-weight: 500;
            }

            .SlotsYes {
                color: #04C270;
                font-size: 12px;
                font-weight: 500;
            }

        }

        .activities__ranking {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .text__black {
                color: #333;
                font-size: 12px;
                font-family: Inter500;
            }

            .text__green {
                color: #F0962C;
                font-size: 12px;
                font-family: Inter500;
            }
        }

        .activities__rating {
            display: flex;
            flex-direction: row;
            align-items: end;
            justify-content: space-between;
            margin-top: 10px;

            .total_class_wapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 7px;
            }

            .total_class_text {
                color: #333;
                font-size: 12px;
                font-family: Inter500;
            }

            .text__contant {
                color: #F0962C;
                font-size: 16px;
                font-family: Inter500;
            }

            .points {
                color: #333;
                font-family: Inter500;
                font-size: 12px;
            }
        }
    }
}

.activities__listView__Active {
    cursor: pointer;
    border: 2px solid #F0962C !important;
}

.activities__listView__container {
    overflow: auto;
    overflow-y: auto;
    max-height: 380px;
    margin-top: 1rem !important;

    .css-m2al5c {
        border-bottom: 1px solid;
        border-color: rgba(0, 0, 0, 0.12);
        margin-top: 1rem !important;
    }
}

.Amenities__container {
    background-color: #fff;
    padding: 0px 20px;

    .Amenities__wapper {
        display: flex;
        flex-direction: row;
        gap: 15px;
        // margin: 15px 0;

        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }

        .text {
            font-size: 14px;
            font-family: Inter400;
            color: #333;
        }
    }
}



.Activities__Taps {
    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: #333 !important;
        text-transform: capitalize !important;
        font-family: Manrope700 !important;
        font-size: 16px !important;
        // width: 50%;
    }

    .css-1mrn996.Mui-selected {
        color: #333 !important;
        text-transform: capitalize !important;
        font-family: Manrope700 !important;
        font-size: 16px !important;
    }

    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
        text-transform: capitalize !important;
        color: #949494 !important;
        font-family: Manrope600 !important;
        font-size: 16px !important;
        // width: 50%;
    }

    .css-axpu1l {
        background-color: #F0962C !important;
        border-radius: 7px 11px 0px 0px !important;
        height: 5px;
        // left: 18% !important;
        // width: 14% !important;
    }

    .css-11yukd5-MuiTabs-indicator {
        background-color: #F0962C !important;
        border-radius: 7px 11px 0px 0px !important;
        height: 5px;
        // left: 18% !important;
        // width: 14% !important;
    }

    .css-1wf8b0h-MuiTabs-flexContainer {
        justify-content: space-evenly !important;
    }
}

@media (min-width: 1420px) {
    .activities__listView__container {
        max-height: 60vh !important;
    }

    .ActivitiesList__view .activitiesListView__rightSide__Pop {
        max-height: 66vh !important;
        max-width: 100% !important;
    }
}

@media (max-width: 990px) {
    .ActivitiesList__view .activitiesListView__rightSide__Pop {
        overflow: auto;
        overflow-y: auto;
        max-height: 100% !important;
        border-radius: 16px;
        background: #FFF;
        padding: 20px;
        max-width: 100% !important;
        box-shadow: 7px 4px 26px 0px rgba(144, 144, 144, 0.25);
    }

    .activities__listView__container {
        width: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        height: 100% !important;
        overflow: hidden;
    }
}

@media screen and (max-width: 720px) {
    .ActivitiesList__view {
        overflow: hidden;
        margin: 0 !important;
        .css-19kzrtu {
            padding: initial !important;
        }
    }

    .Maps__view__listPage {
        width: 100%;
        height: 400px !important;
    }

    .age_group_badge {
        font-size: 10px !important;
        padding: 0px 2px !important;
    }


    .activities__leftside {

        .profile_img {
            width: 100px !important;
            max-height: 100px !important;

        }
    }

    .activities__list__wapper {
        gap: 10px !important;
    }

    .ActivitiesList__view {

        .activitiesListView__rightSide__Pop {
            width: 100% !important;
            max-width: 100% !important;
            max-height: 100% !important;
            height: 100% !important;
        }
    }

    .activities__listView__container {
        width: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        height: 100% !important;
        overflow: hidden;
    }

    .activitiesListView__rightSide__Pop {
        display: none !important;
    }

    .total_class_text {
        display: none !important;
    }

    .camp_dates {
        display: none !important;
    }

    .text__contant {
        font-size: 14px !important;
    }

    .activities__name .name {
        font-size: 14px !important;
    }

    .imageIcon,
    .age_group_wapper {
        gap: 5px !important
    }
}
//*margin adjestment*//
@media screen and (max-width:991px) {
    .ActivitiesList__view {
        .search_field_wrapper {
            margin-bottom:7px;
        }
        .css-19kzrtu {
            padding: initial !important;
        }
    }
}