.cancel_button {
  border-radius: 50px;
  border: 1px solid #744fff;
  color: #744fff;
  font-family: Manrope600;
  font-size: 14px;
  padding: 12px 24px;
}

.action_buttons_wrapper {
  align-self: center;
}

.avatar_images_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 28px;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  width: 60%;
  padding-bottom: 10%;
}

.avatar_image_elements {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
}

.selected_avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile_avatar_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80vh;
  max-height: 600px;
  gap: 40px;
}

.avatar_action_buttons_wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 36px;
}

.avatar_apply_button {
  color: #fff;
  font-family: Manrope700;
  font-size: 14px;
  border-radius: 50px;
  background: #744fff;
  padding: 12px 24px;
  box-sizing: border-box;
  align-self: center;
}

.profile_avatar_dialog_title {
  color: #333;
  font-family: Manrope600;
  font-size: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(98.6deg,
      #bcbbbb -1.65%,
      #bcbbbb 116.8%);
  border-radius: 7px;
}

// .avatar_selection_dialog_wrapper {

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  --background: #000;
  background: #bcbbbb;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

// }

.selected_icon_indicator {
  border-radius: 50%;
  border: 2px solid #fff;
  background: #8e7eff;
  padding: 7px 6px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.avatar_image_container {
  position: relative;
}

.profile_pic {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
}


@media screen and (max-width: 631px) {
  .profile_avatar_dialog_title {
    font-size: 20px !important;
  }
  .profile_avatar_container{
    flex-direction: column !important;
  }
  .profile_pic{
    width: 80px !important;
    height: 80px !important;
  }
  .cancel_button,
  .avatar_apply_button {
    padding: 7px 19px !important;
    font-size: 12px !important;
  }
  .avatar_action_buttons_wrapper{
    margin-top: 23px !important;
  }
  .avatar_images_wrapper{
    width: 100% !important;
    padding-bottom: 22% !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .avatar_image_elements{
    width: 64px !important;
    height: 64px !important;
  }
}
