.TutorHubSession__container {
    border-radius: 10px;
    background: #F4F3F9;

    .Header__wapper {
        border-radius: 10px 10px 0px 0px;
        background: #F4F3F9;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
            width: 22px;
            height: 22px;
            object-fit: contain;
        }

        .title {
            color: #2B2451;
            font-family: Manrope600;
            font-size: 20px;
        }
    }

    .session__scroll {
        max-height: 550px;
        overflow: auto;
        overflow-y: scroll;
        margin-bottom: 2rem;
    }

    /* width */
    .session__scroll::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .session__wapper {
        border-radius: 9px;
        background: #FFF;
        padding: 12px;
        width: 100%;
        margin: 10px 0;

        .row__contant {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;
        }

        .video_call__wapeer {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 34.622px;
            background: #F1ECFF;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            position: relative;
            cursor: pointer;

            img {
                width: 19.002px;
                height: 19.777px;
                object-fit: contain;
            }

            .badeg__pos {
                position: absolute;
                right: 3px;
                top: 2px;
            }
        }

        .disable_style {
            opacity: 0.5;
            cursor: default !important;
        }

        .left__contant {
            display: flex;
            align-items: center;
            gap: 10px;

            .Black__text {
                color: #000;
                font-family: Inter500;
                font-size: 18px;
            }

            .name__text {
                color: #7E7E7E;
                font-family: Inter400;
                font-size: 12px;
            }
        }

        .tutor__img__wapper {
            width: 38px;
            height: 100%;
            min-height: 52px;
            border-radius: 0px;
            background: rgba(209, 233, 255, 0.61);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 23px;
                height: 17px;
                object-fit: contain;
            }
        }

        .activity__img__wapper {
            width: 38px;
            height: 100%;
            min-height: 52px;
            border-radius: 0px;
            background: rgba(255, 242, 227, 1);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 23px;
                height: 17px;
                object-fit: contain;
            }
        }

        .left__light__text {
            color: #8A8A8A;
            font-family: Inter400;
            font-size: 14px;
        }

        .right__black__text {
            color: #333;
            font-family: Inter500;
            font-size: 14px;
        }

        .inprogressBtn {
            width: 110px;
            height: 40px;
            border-radius: 8px;
            background: #F9CE5F;
            color: #000;
            font-family: Manrope700;
            font-size: 14px;
        }

        .inprogressBtn-reject {
            width: 85px;
            height: 40px;
            border-radius: 8px;
            background: #FF7D66;
            color: #fff;
            font-family: Manrope700;
            font-size: 14px;
            margin-right: 10px;
        }

        .inprogressBtn-confirm {
            width: 85px;
            height: 40px;
            border-radius: 8px;
            background: #12CC92;
            color: #fff;
            font-family: Manrope700;
            font-size: 14px;
        }

        .cancelReqBtn {
            width: 100px;
            height: 40px;
            border-radius: 8px;
            background: #FF7D66;
            color: #fff;
            font-family: Manrope700;
            font-size: 14px;
        }

        .CompletedBtn {
            width: 116px;
            height: 40px;
            border-radius: 8px;
            background: #12CC92;
            color: #FFF;
            font-family: Manrope700;
            font-size: 14px;
        }

        .child_profile_img {
            width: 18.698px;
            height: 18.698px;
            border-radius: 50%;
            object-fit: cover;
        }

        .createdBy {
            color: #000;
            font-family: Manrope700;
            font-size: 11px;
        }
        .me-arab{
            margin-right: .5rem !important;
            margin-left: initial !important;
        }
    }
}

.Support__DialogContent___wapper {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 100%;
    gap: 5px;

    .Button__wapper {
        border-radius: 10px;
        background: #FAF9FF;
        display: flex;
        width: 100%;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-top: 5px;
    }

    .accordion__lable__wapper {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        .icon {
            color: #744FFF !important;
        }

        .text {
            color: #333;
            font-family: Inter500;
            font-size: 16px;
        }
    }
}

@media (min-width: 1420px) {
    .TutorHubSession__container {
        .session__scroll {
            max-height: 70vh !important;
        }
    }
}

//******Font-Size*****//
@media screen and (max-width:600px) {
    .TutorHubSession__container {
        .Header__wapper {
            .title {
                font-size: 17px !important;
            }
        }
        .left__contant {
            .Black__text {
                font-size: 15px !important;
            }

            .name__text {
                font-size: 9px !important;
            }
        }
        .left__light__text {
            font-size: 13px !important;
        }
        .right__black__text {
            font-size: 11px !important;
        }
        .inprogressBtn {
            font-size: 11px !important;
            width: 90px !important;
            height:35px !important;
        }
        .cancelReqBtn {
            font-size: 11px !important;
        }
        .CompletedBtn {
            font-size: 11px !important;
        }
        .createdBy {
            font-size: 8px !important;
        }
    
    }
    //
    .Support__DialogContent___wapper {
        .accordion__lable__wapper {
            .text {
                font-size: 13px !important;
            }
        }
    }
}


@media screen and (max-width:460px) {
    .TutorHubSession__container {
    .session__wapper {
        .row__contant {
              flex-direction: column !important;
              margin: 12px 0px !important;
              align-items: initial !important;
        }
        .inprogressBtn {
          margin-bottom: .8rem !important;
        }
    }
}
}