// University__Detaile__view

.University__Detaile__view {
  margin: 0 auto;
  margin-top: 1rem;
  display: block;

  .card_bg_img {
    width: 269px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    padding: 0 5px;
  }

  .tittle-div {
    border-radius: 15px;
    background: #fff;
    width: fit-content;
    padding: 0 10px !important;
    height: 40px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .user__Detailes__Education {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0px;
    gap: 10px;

    .grayIcons {
      width: 12px;
      height: 14px;
    }

    .graytext {
      color: #333;
      font-size: 14px;
      font-family: Inter500;
    }

    .StarIcon {
      color: #fcb040 !important;
      width: 18px;
      height: 17px;
    }
  }

  .user__Detailes__Grade {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    .lable {
      color: #4e4e4e;
      font-size: 14px;
      font-family: Inter500;
    }

    img {
      width: 14px;
      height: 16px;
    }
  }

  .text-tittle {
    color: #000;
    font-size: 20px;
    font-family: Manrope700;
    // margin-left: 6px;
  }

  .text-tittle-english {
    margin-left: 6px;
  }

  .text-tittle-arab {
    margin-right: 6px;
  }

  .tittle-div {
    height: 60px;
  }

  .back-img {
    margin-right: 10px;
    cursor: pointer;
  }

  .back-img_arb {
    cursor: pointer;
    margin-left: 10px;
    rotate: 180deg;
  }

  .unversity-icon {
    margin-right: 5px;
    width: 22px;
    height: 22px;
  }

  .unversity-icon-arab {
    margin-right: initial !important;
    margin-left: 15px !important;
  }

  .tutorlist-conatiner-arab {
    margin-left: 15px;
  }

  .tutorlist-conatiner {
    padding: 25px 35px;
    height: 100%;
    box-shadow: 0px 4px 39px 0px #B2B2B240;
    border-radius: 17px;
    background: #fff;

    .display-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .iconBtn__wapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-top: 17px;

        .iconBtn {
          background-color: transparent;
          border: 1px solid rgba(18, 204, 146, 1) !important;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 28px;
          gap: 3px;
          padding: 5px 5px;
        }

        span {
          font-size: 12px;
          font-family: Inter400;
          color: rgba(18, 204, 146, 1) !important;
        }
      }

      .Book-Slots-btn {
        background: linear-gradient(0deg, #12cc92, #12cc92);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 290px;
        border: none;
        border-radius: 28px;
        height: 50px;
        margin: 17px 0px;
        font-family: Manrope700;
      }
    }

    .courses-div {
      border: 0.4px solid #d8d8d8;
      background: #fafcff;
      padding: 5px 10px;
      border-radius: 7px;
      margin: 0;
      cursor: pointer;

      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
      }

      .coursetxt {
        color: #178fff;
        font-size: 12px;
        font-family: Inter600;
      }
    }

    .courses-Active {
      border: 1px solid #178fff;
      padding: 5px 10px;
      border-radius: 7px;
      margin: 0;
      background-color: #178fff;

      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
      }

      .coursetxt {
        color: #fff;
        font-size: 12px;
        font-family: Inter600;
      }
    }

    .display-div1 {
      width: 45%;
      height: 66px;
    }

    .display-div2 {
      width: 45%;
      height: 66px;
    }

    .slider-img {
      width: 100%;
      height: 140px !important;
      object-fit: cover;
      border-radius: 5px;
    }

    .tutor-image {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
    }

    .img-size {
      width: 14%;
    }

    .text14 {
      color: #000;
      font-size: 18px;
      font-family: Manrope700;
    }

    .text10 {
      color: #929292;
      font-size: 12px;
      font-family: Inter500;
      text-align: center;
    }

    .text11 {
      color: #12cc92;
      font-size: 17px;
      font-family: Manrope700;
    }

    .text12 {
      margin-left: 11px;
      font-family: Inter600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
    }

    .text13 {
      font-family: Inter600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #2396f2;
      white-space: nowrap;
    }

    .select-css {
      border: 0;
    }

    .display-container2 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 20px;
      gap: 14px;
    }

    .userdetails-div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .userdetails-div2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }

    .img-size5 {
      width: 25px;
      height: 17px;
    }

    .img-overall {
      padding: 4px;
      display: flex;
      flex-direction: row;
      gap: 7px;
    }

    .selectcourse-div {
      color: #000;
      font-size: 16px;
      font-family: Manrope700;
      margin: 14px 0;
    }

    .alignment1 {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }

    .display-container5 {
      cursor: none;
      width: 100%;
      display: flex;
    }

    .slider-container {
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    /* Make the first slide visible */
    .slide:first-child {
      display: block;
    }

    .title {
      color: #333;
      font-size: 16px;
      font-family: Manrope700;
    }

    .para-content {
      color: #333;
      font-family: Inter400;
      font-size: 14px;
      width: 90%;
      text-align: justify;
    }

    .custom_cursor {
      display: flex;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 50px;
      height: 50px;
      background: rgba(255, 255, 255, 0);
      backdrop-filter: blur(14.5217px);
      border: 1px solid rgb(255, 255, 255);
      pointer-events: none;
      border-radius: 50%;
      z-index: 1500;
      opacity: 1;
      transition: opacity 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s,
        background 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }

    .alice-carousel__stage {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      transform-style: flat;
      -webkit-transform-style: flat;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }

    .service_card_container {
      width: 250px;
      height: 100%;
    }

    .service_card {
      width: 262px;
      height: 100%;
    }

    .dums-css {
      width: 35%;
    }

    .overall-details {
      display: flex;
      flex-direction: row;
      width: 78%;
      gap: 20%;
      flex-wrap: wrap;
    }
  }

  .founder__contant__wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    span {
      color: #333;
      font-size: 16px;
      font-family: Manrope700;
    }

    div {
      color: #333;
      font-size: 14px;
      font-family: Inter400;
    }
  }

  .bottom__contant__wapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    div {
      font-size: 14px;
      font-family: Inter500;
      text-align: left;
      color: #333333;
    }

    img {
      width: 21px;
      height: 20px;
      object-fit: contain;
    }

    .bottom__contant__left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 14px;
    }

    .bottom__contant__right {
      color: #12cc92;
      font-size: 16px;
      font-family: Inter600;
    }
  }
}


.university__tap__contant {
  span {
    color: #333;
    font-size: 14px;
    font-family: Inter600;
  }

  ul {
    list-style: none;
    margin-top: 1rem;
  }

  ul li::before {
    content: "\25A0";
    color: #12cc92;
    font-weight: 800;
    font-size: 0.7em;
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: -2em;
    margin-right: 1em;
  }

  li {
    font-size: 14px;
    font-family: Inter400;
    text-align: left;
    color: rgba(51, 51, 51, 1);
    margin-top: 7px;
  }
}

.university__tap__contant_arb {
  display: flex;

  span {
    color: #333;
    font-size: 14px;
    font-family: Inter600;
  }

  ul {
    list-style: none;
    margin-top: 1rem;
  }

  ul li::before {
    content: "\25A0";
    color: #12cc92;
    font-weight: 800;
    font-size: 0.7em;
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 1em;
    margin-right: 1em;
  }

  li {
    font-size: 14px;
    font-family: Inter400;
    text-align: left;
    color: rgba(51, 51, 51, 1);
    margin-top: 7px;
  }
}

.MuiTabs-root .css-heg063-MuiTabs-flexContainer {
  background-color: none !important;
}

.Uiversity__Taps {
  .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #333 !important;
    text-transform: capitalize !important;
    font-family: Manrope700 !important;
    font-size: 16px !important;
  }

  .css-1mrn996.Mui-selected {
    color: #333 !important;
    text-transform: capitalize !important;
    font-family: Manrope700 !important;
    font-size: 16px !important;
  }

  .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
    color: #949494 !important;
    font-family: Manrope600 !important;
    font-size: 16px !important;
  }

  .css-axpu1l {
    background-color: #12cc92 !important;
    border-radius: 7px 11px 0px 0px !important;
    height: 5px;
  }

  .css-11yukd5-MuiTabs-indicator {
    background-color: #12cc92 !important;
    border-radius: 7px 11px 0px 0px !important;
    height: 5px;
  }
}

.Uiversity__accordien .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.Uiversity__accordien .css-67l5gl {
  box-shadow: none !important;
}

.Uiversity__accordien .css-1aj41gs {
  box-shadow: none !important;
}

.Uiversity__accordien .css-1086bdv-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.Uiversity__Programs__text {
  color: #333;
  font-family: Manrope700;
  font-size: 20px;
}

.Uiversity__accordien {

  .css-17o5nyn.Mui-expanded {
    margin: 0 !important;
  }

  .css-17o5nyn {
    margin: 0 !important;
  }

  .css-1iji0d4 {
    padding: 0 4px !important;
  }

  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
  }

  .title {
    color: #000;
    font-family: Manrope700;
    font-size: 16px;
  }

  .text {
    color: #000;
    font-family: Manrope600;
    font-size: 12px;
  }

  .green__text {
    color: #12cc92;
    font-size: 14px;
    font-family: Manrope600;
  }

  .detailes__wapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .left__side {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 15px;

    img {
      width: 18px;
      height: 18.001px;
      object-fit: contain;
    }

    span {
      color: #7d7d7d;
      font-family: Inter500;
      font-size: 14px;
    }
  }

  .link {
    color: #0285bd;
    font-family: Inter500;
    font-size: 14px;
    text-decoration-line: underline;
  }

  .text {
    color: #333;
    font-family: Manrope400;
    font-size: 14px;
  }

  .number {
    color: #0285bd;
    font-family: Inter500;
    font-size: 14px;
  }
}

.chat__consultant__btn__wapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  .chat__consultant__btn {
    width: 100%;
    max-width: 312px;
    height: 60px;
    border-radius: 52px;
    border: 2px solid rgba(23, 143, 255, 0.1);
    background: #12cc92;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    outline: none;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }

    span {
      color: #f9f9fb;
      font-family: Inter600;
      font-size: 16px;
      font-weight: 600;
      line-height: 136.523%;
    }
  }
}

.css-1mrn996 {
  text-transform: capitalize !important;
}

//**//
.detailes__scroll__container_university {
  overflow: auto;
  overflow-y: scroll;
  // max-height: 400px;
  height: 100%;
  // margin-left: 15px;
  margin-left: 15px !important;
}

.DetailesScrollContainerArab {
  margin-right: 15px !important;
}

/* width */
.detailes__scroll__container_university::-webkit-scrollbar {
  width: 0px !important;
}

@media (max-width: 1199px) {
  .detailes__scroll__container_university {
    overflow: auto;
    overflow-y: scroll;
    max-height: 100% !important;
    height: 100% !important;
    margin: 10px 0px;
    margin-left: initial !important;
  }
}

@media (max-width: 536px) {
  .University__Detaile__view .tutorlist-conatiner {
    padding: 10px !important;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 14px;
    width: 100%;
    margin-left: 14px !important;

    .display-container .iconBtn__wapper {
      display: flex;
      flex-direction: column;
    }
  }

  .tutorlist-conatiner-arab {
    margin-right: 15px;
    margin-left: initial !important;
  }
}

@media (min-width: 1420px) {
  .University__Detaile__view {
    margin-top: 2rem;
  }
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 32px !important;
}

.css-1iji0d4 {
  min-height: 32px !important;
}