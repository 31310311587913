.top__contant__wapper__school {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  .top__PageBtn__icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;

    .prev__icons {
      color: rgba(255, 200, 18, 1) !important;
      background-color: #faf5e3;
      border-radius: 8px;
    }

    .next__icons {
      color: rgba(255, 200, 18, 1) !important;
      background-color: #faf5e3;
      border-radius: 8px;
    }

    .icons__disable {
      color: rgba(255, 200, 18, 1) !important;
      opacity: 0.5;
      cursor: default !important;
    }

    .text__disable {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      opacity: 0.5;
      cursor: default !important;
    }

    .prev__text {
      color: #333;
      font-size: 14px;
      font-family: Inter700;
    }
  }

  .top__close__icons {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .expanded__icons {
      cursor: pointer;
      color: #333333 !important;
      font-size: 16px;
    }

    .close__icons {
      cursor: pointer;
      font-size: 16px;
      color: #333333 !important;
    }
  }
}

.View__on__map_School_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  padding: 7px 0px;

  .wapperDiv {
    border-radius: 7px;
    background: #faf5e3;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      width: 16.217px;
      height: 13.79px;
    }

    span {
      color: #875a04;
      font-size: 12px;
      font-family: Inter600;
    }
  }
}

// .background_circle_left__School_model {
//   border-radius: 527px;
//   background: radial-gradient(50% 50% at 50% 50%,
//       rgba(255, 207, 48, 0.21) 0%,
//       rgba(208, 220, 255, 0) 100%) !important;
//   width: 100%;
//   max-width: 200px !important;
//   height: 80%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 0;
// }

.background_circle_right__School_model {
  border-radius: 50%;
  background: radial-gradient(#FFCF30 21%, #D0DCFF 0%) !important;
  // height: 80%;
  // width: 100%;
  // max-width: 200px !important;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 70%;
  left: 70%;
  z-index: 0;
  filter: blur(150px);
}

.School__detailed__container {
  max-width: 550px;

  .flexDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .detailed__container__wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  span {
    color: #333;
    font-size: 14px;
    font-family: Inter500;
  }

  .number {
    color: #333;
    font-size: 16px;
    font-family: Manrope700;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.school__Taps__container__width {
  max-width: 530px !important;

  .css-19kzrtu {
    padding: 0px !important;
  }

  .css-1wf8b0h-MuiTabs-flexContainer {
    justify-content: space-around !important;
  }

  .css-1l4w6pd {
    justify-content: space-around !important;
  }
}

.school__Taps__container {
  .css-m2al5c {
    margin-top: 0px !important;
  }

  .css-19kzrtu {
    padding: 0px !important;
  }

  .css-1wf8b0h-MuiTabs-flexContainer {
    justify-content: space-around !important;
  }

  .css-1l4w6pd {
    justify-content: space-around !important;
  }

  .school__tap__contant {
    span {
      color: #333;
      font-size: 14px;
      font-family: Manrope600;
    }

    ul {
      list-style: none;
      margin-top: 1rem;
    }

    ul li::before {
      content: "\25A0";
      color: rgba(255, 200, 18, 1);
      font-weight: 800;
      font-size: 0.7em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: -2em;
      margin-right: 1em;
    }

    li {
      font-size: 14px;
      font-family: Inter400;
      text-align: justify;
      color: #000 !important;
      margin-top: 7px;
    }
  }

  .school__tap__contant_arb {
    span {
      color: #333;
      font-size: 14px;
      font-family: Manrope600;
    }

    ul {
      list-style: none;
      margin-top: 1rem;
    }

    ul li::before {
      content: "\25A0";
      color: rgba(255, 200, 18, 1);
      font-weight: 800;
      font-size: 0.7em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: 1em;
      margin-right: 1em;
    }

    li {
      color: #000;
      font-size: 14px;
      font-family: Inter400;
      margin-top: 7px;
    }
  }
}

.School__Taps {
  .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #333 !important;
    text-transform: capitalize !important;
    font-family: Manrope700 !important;
    font-size: 16px !important;
  }

  .css-1mrn996.Mui-selected {
    color: #333 !important;
    text-transform: capitalize !important;
    font-family: Manrope700 !important;
    font-size: 16px !important;
  }

  .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
    color: #949494 !important;
    font-family: Manrope600 !important;
    font-size: 16px !important;
  }

  .css-axpu1l {
    background-color: rgba(255, 200, 18, 1) !important;
    border-radius: 7px 11px 0px 0px !important;
    height: 5px;
  }

  .css-11yukd5-MuiTabs-indicator {
    background-color: rgba(255, 200, 18, 1) !important;
    border-radius: 7px 11px 0px 0px !important;
    height: 5px;
  }
}

@media (min-width: 1420px) {
  .school__Taps__container__width {
    max-width: 100% !important;
  }

  .School__detailed__container {
    max-width: 100% !important;
  }

  .background_circle_right__School_model {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 50%;
    left: 70%;
    z-index: 0;
    filter: blur(250px);
  }
}

@media (min-width: 420px) {
  .school__Taps__container {
    .css-1wf8b0h-MuiTabs-flexContainer {
      display: flex;
    }
  }
}