.card__container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  .title_header {
    color: #000 !important;
    font-size: 16px !important;
    font-family: Manrope700 !important;
    margin-bottom: 10px;
  }

  .text_contant {
    font-size: 14px !important;
    font-family: Inter400 !important;
    color: #000 !important;
  }

  .text_contant_bold {
    font-size: 14px !important;
    font-family: Inter600 !important;
    color: #000 !important;
  }

  .description__contant {
    font-size: 14px !important;
    font-family: Inter400 !important;
    color: #000 !important;
    margin-top: 0px !important;
    margin-bottom: 15px !important;
  }

  .user__Detailes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px !important;

    .user__name {
      color: #000;
      font-size: 18px;
      font-family: Manrope700;
    }

    .user__icon__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      img {
        height: 17px;
        width: 17px;
      }
    }
  }





  .contact_us_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 10px 0px;
    cursor: pointer;
  }

  .Amenities__container {
    background: none;
    padding: 0px;

    .title {
      margin-bottom: 15px;
    }

    .Amenities__wapper {
      display: flex;
      flex-direction: row;
      gap: 15px;
      margin-bottom: 10px;

      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }

      .text {
        font-size: 14px;
        font-family: Inter400;
        color: #333;
      }
    }
  }

  .user__Detailes__Education_Activities {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;

    .StarIcon {
      color: #fcb040 !important;
      width: 18px;
      height: 17px;
    }

    .graytext {
      color: #000;
      font-size: 14px;
      font-family: Manrope700;
    }
  }

  .user__Detailes__Grade__Activities {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-bottom: 15px;

    img {
      width: 17px;
      height: 17px;
    }

    span {
      color: rgba(255, 174, 79, 1);
      font-size: 14px;
      font-family: Inter500;
    }

    .Grade__Activities {
      background-color: rgba(250, 244, 236, 1) !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 7px 10px;
      gap: 10px;
      border-radius: 8px;
    }

    .View__on__map {
      background-color: rgba(250, 244, 236, 1) !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 7px 10px;
      gap: 10px;
      cursor: pointer;
    }
  }

  .bottom__contant__wapper {
    margin-bottom: 15px;
    margin-top: 0px;

    img {
      width: 18px;
      height: 18px;
      object-fit: cover;
    }

    .bottom__contant__left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;

      // div {
      //   font-size: 14px;
      //   font-family: Inter400 !important;
      //   text-align: left;
      //   color: #000;
      // }
    }
  }

  .user__Detailes__fess__Activities {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin-bottom: 10px;


    .contant_wapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      img {
        width: 18px;
        height: 18px;
        object-fit: cover;
      }
    }

    .Avg__Fees {
      color: #000;
      font-size: 14px;
      font-family: Manrope700;
    }

    .claendar_text {
      color: #333;
      font-size: 14px;
      font-family: Inter500;
    }

    .Price_text {
      color: #333;
      font-size: 14px;
      font-family: Manrope600;
    }

    .title {
      color: #f0962c !important;
      font-size: 16px;
      font-family: Inter600;
    }
  }

  .Facility_needed_Activities {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .title {
      color: #000;
      font-size: 16px;
      font-family: Manrope700;
      margin-bottom: 15px;
    }

    ul {
      list-style: none;
      margin-bottom: 0px;
    }

    ul li::before {
      content: "\25A0";
      color: #f0962c;
      font-weight: 800;
      font-size: 0.7em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: -2em;
      margin-right: 1em;
    }

    li {
      font-size: 14px;
      font-family: Inter400;
      text-align: left;
      color: #333;
      margin-bottom: 10px;
    }
  }

  .Facility_needed_Activities_arb {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .title {
      color: #000;
      font-size: 16px;
      font-family: Manrope700;
      margin-bottom: 15px;
    }

    ul {
      list-style: none;
      margin-bottom: 0px;
    }

    ul li::before {
      content: "\25A0";
      color: #f0962c;
      font-weight: 800;
      font-size: 0.7em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: 1em;
      margin-right: 1em;
    }

    li {
      font-size: 14px;
      font-family: Inter400;
      text-align: left;
      color: #333;
      margin-bottom: 10px;
    }
  }

  .user__Detailes__Activities__bookBtn__container {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 20px;

    .bookBtn {
      border-radius: 52px;
      width: 100%;
      max-width: 312px;
      border: 2px solid rgba(23, 143, 255, 0.1);
      background: #ffae4f;
      color: #f9f9fb;
      font-family: Inter600;
      font-size: 18px;
      padding: 10px 0px;
    }
  }

  .user__Detailes__Activities {
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    // margin-top: 15px;

    .title {
      color: #000;
      font-size: 16px;
      font-family: Manrope700;
      margin-bottom: 15px;
    }

    .badge_wrapeer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 15px;
      max-width: 400px;

      .badge__container {
        border: 1px solid #d8d8d8;
        padding: 10px;
        border-radius: 8px;
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 18px;
          height: 18px;
          object-fit: contain;
        }

        span {
          color: #f0962c;
          font-size: 14px;
          font-family: Inter500;
        }
      }

      .badge__container-Active {
        border: 1px solid #f0962c;
        padding: 10px;
        border-radius: 8px;
        margin: 0;
        cursor: pointer;
        background-color: #f0962c;
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 18px;
          height: 18px;
          object-fit: contain;
        }

        span {
          color: #fff;
          font-size: 14px;
          font-family: Inter500;
        }
      }
    }
  }
}

.top_cover_img_wrapper {
  position: relative;
  max-width: 530px !important;

  .card_bg_img {
    width: 269px;
    height: 200px !important;
    object-fit: cover;
    border-radius: 12px;
    padding: 0 5px;
  }

  .profileImg {
    width: 75px !important;
    height: 75px !important;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 1);
    object-fit: cover;
    position: absolute;
    left: 20px;
    bottom: -30px;
  }

  .left_arrow {
    position: absolute;
    left: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }

  .right_arrow {
    position: absolute;
    right: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }

  .alice-carousel {
    direction: ltr !important;
  }

}

.top_cover_img_wrapper_arb {
  position: relative;
  max-width: 530px !important;

  .card_bg_img {
    width: 269px;
    height: 200px !important;
    object-fit: cover;
    border-radius: 12px;
    padding: 0 5px;
  }

  .profileImg {
    width: 75px !important;
    height: 75px !important;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 1);
    object-fit: cover;
    position: absolute;
    right: 20px;
    bottom: -30px;
  }

  .left_arrow {
    position: absolute;
    left: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }

  .right_arrow {
    position: absolute;
    right: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }

  .alice-carousel {
    direction: rtl !important;
  }
}


// .background_circle_left__Activity_model {
//   border-radius: 527px;
//   background: radial-gradient(50% 50% at 50% 50%,
//       rgba(255, 249, 237, 1) 0%,
//       rgba(252, 193, 106, 0) 100%) !important;
//   width: 100%;
//   max-width: 200px !important;
//   height: 80%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 0;
// }

.background_circle_right__Activity_model {
  border-radius: 50%;
  background: radial-gradient(circle, #FFC812 18%, #FCC16A 0%) !important;
  // height: 80%;
  // width: 100%;
  // max-width: 200px !important;
  position: absolute;
  bottom: 10%;
  right: 0;
  top: 50%;
  left: 80%;
  z-index: 0;
  filter: blur(170px);
}

.MuiBackdrop-root {
  opacity: 0 !important;
}

.Tutor__map__dailog__wrapper {

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
  .css-uhb5lp {
    width: 80vw !important;
    max-width: 900px !important;
    border-radius: 16px !important;
    background: #fff !important;
  }

  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background: rgba(218, 218, 218, 0.68) !important;
    backdrop-filter: blur(9px) !important;
  }
}

.Model__Dialog__container {
  width: 600px !important;
  height: 100% !important;
}

.Activities__description_with {
  max-width: 500px;
  width: 100%;
}

.top__contant__wapper_Activities {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  // min-width: 500px;
  /*not good for tap view list page so comment abo*/

  .top__PageBtn__icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;

    .prev__icons {
      color: rgba(255, 174, 79, 1) !important;
      background: #F4F4F4;
      border-radius: 5px;
    }

    .next__icons {
      color: rgba(255, 174, 79, 1) !important;
      background: #F4F4F4;
      border-radius: 5px;
    }

    .icons__disable {
      color: rgba(255, 174, 79, 1) !important;
      opacity: 0.5;
      cursor: default !important;
      background: #F4F4F4;
      border-radius: 5px;
    }

    .text__disable {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      opacity: 0.5;
      cursor: default !important;
    }

    .prev__text {
      color: #333;
      font-size: 14px;
      font-family: Manrope700;
    }
  }

  .top__close__icons {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .expanded__icons {
      cursor: pointer;
      color: #333333 !important;
      font-size: 16px;
    }

    .close__icons {
      cursor: pointer;
      font-size: 16px;
      color: #333333 !important;
    }
  }
}