.school_user__chat {
    display: flex;
    max-width: 326px;
    width: fit-content;
    padding: 10px 22px;
    align-items: center;
    word-break: break-all !important;
    text-align: justify;
    gap: 10px;
    border-radius: 20px 20px 0px 20px;
    background: #efae07;
    box-shadow: 3px 7px 26px 0px rgba(173, 211, 255, 0.27);
    color: #FFF;
    font-family: Inter500;
    font-size: 12px;
}
.school_user_chat_arab{
    border-radius: 20px 20px 20px 0px !important;
}