// .personal_details_dialog_wrapper {

.personal_details_title_wrapper {
  width: 100%;
}

.personal_details_title {
  color: #333;
  font-family: Manrope700;
  font-size: 28px;
  margin-bottom: 10px;
}

.personal_details_description {
  color: rgba(51, 51, 51, 0.69);
  font-family: Inter400;
  font-size: 14px;
  margin-bottom: 20px;
}

.field_label {
  color: #333;
  font-family: Manrope600;
  font-size: 14px;
  margin-bottom: 12px;
}

.fields_row_container {
  display: flex;
  flex-direction: row;
  gap: 28px;
}

.label_field_error_messages_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  margin-bottom: 12px;
}

.gender-style {
 .Account__drpdown {
  background-color: #f4f3f8;
  border-radius: 10px;
  min-height: 10px !important;
  height: 40px;
  padding-top: 8px;
 }
}

.phone_input_wrapper {
  .login-phone-input {
    border-radius: 20px;
    background: #f4f3f8;
    height: 40px;
    color: #888;
    font-family: Inter400;
    font-size: 14px;
    border: unset;
    padding: 12px 97px 12px 46px;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: #888;
      font-family: Inter400;
      font-size: 14px;
    }

    &:focus {
      outline: unset !important;
      border: unset !important;
    }
  }
  
  .login-phone-input-ar {
    border-radius: 20px;
    background: #f4f3f8;
    height: 40px;
    color: #888;
    font-family: Inter400;
    font-size: 14px;
    border: unset;
    padding: 12px 44px 12px 50px;
    width: 100%;
    box-sizing: border-box;
    direction: rtl;

    .flag-dropdown {
      right: 25px !important;
    }
    &::placeholder {
      color: #888;
      font-family: Inter400;
      font-size: 14px;
    }

    &:focus {
      outline: unset !important;
      border: unset !important;
    }
  }
}

.label_field_error_messages_container {
  input {
    border-radius: 12px;
    background: #f4f3f8;
    height: 40px;
    color: #888;
    font-family: Inter400;
    font-size: 14px;
    border: unset !important;
    padding: 12px 18px;
    box-sizing: border-box;
    width: 100%;

    &::placeholder {
      color: #888;
      font-family: Inter400;
      font-size: 14px;
    }

    &:focus {
      outline: unset !important;
    }
  }
}

//Date picker
.label_date_picker_field_error_messages_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(50% - 16px);

  input {
    background: #f4f3f8 !important;
    height: 40px !important;
    color: #888 !important;
    font-family: Inter400 !important;
    font-size: 14px !important;
    border: unset !important;
    padding: 12px 18px !important;
    box-sizing: border-box !important;
    border-radius: 12px !important;

    &::placeholder {
      color: #888;
      font-family: Inter400;
      font-size: 14px;
    }

    &:focus {
      outline: unset !important;
    }
  }
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border: unset !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background: #f4f3f8;
  border-radius: 12px;
}

// .css-i4bv87-MuiSvgIcon-root {
//   color: #744fff !important;
// }

.signup-account-text-wrap{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.sign_up_button {
  color: #fff;
  font-family: Manrope700;
  font-size: 14px;
  border-radius: 50px;
  background: #744fff;
  padding: 12px 90px;
  box-sizing: border-box;
  align-self: center;
  // margin-top: 16px;
}

.login_navigation {
  color: #1f2027;
  font-family: Inter400;
  font-size: 14px;
  align-self: center;
  margin-top: 12px;
}

.login_navigation_button {
  font-family: Inter600;
  font-size: 14px;
  color: #744fff !important;
  cursor: pointer;
}

.email_login_form_wrapper {
  position: relative;
  z-index: 1000;
}
.errorMessage{
  font-size: 13px;
  color: red;
}

//Background color
.personal_details_dialog_wrapper {

  .MuiDialogContent-root {
    position: relative;
    // overflow-y: hidden;
  }

  .css-ypiqx9-MuiDialogContent-root {
    padding: 34px 48px !important;
  }

  .background_color_wrapper_left {
    position: absolute;
    top: -52px;
    left: -100px;
    width: 350px;
    height: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(116, 79, 255, 0.15) 0%,
        rgba(116, 79, 255, 0) 100%) !important;
    z-index: 1;
  }

  .background_color_wrapper_right {
    position: absolute;
    bottom: 2px;
    right: 0;
    width: 350px;
    height: 50%;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(116, 79, 255, 0.15) 0%,
        rgba(116, 79, 255, 0) 100%) !important;
    z-index: 1;
  }

}


@media screen and (max-width:600px) {
  .field_label,
  .personal_details_description {
    font-size: 0.75rem !important;
  }

  .phone_input_wrapper {
    input {
      border-radius: 5px !important;
      font-size: 0.75rem !important;
  
      &::placeholder {
        font-size: 0.75rem !important;
      }
    }
  }

  .label_field_error_messages_container {
    input {
      font-size: 0.75rem !important;
      border-radius: 5px !important;
  
      &::placeholder {
        font-size: 0.75rem !important;
      }
    }
}
/*Date picker*/
.label_date_picker_field_error_messages_container {
  input {
    font-size: 0.75rem !important;

    &::placeholder {
      font-size: 0.75rem !important;
    }
  }
}
//
.sign_up_button,
.login_navigation,
.login_navigation_button {
  font-size: 0.75rem !important;
}
//
}
.errorMessage{
  font-size: 11px !important;
}
.personal_details_title{
  font-size: 25px !important;
}

@media screen and (max-width:550px) {
  .fields_row_container{
    flex-direction: column !important;
    gap: 0px !important;
  }
  .label_field_error_messages_container,
  .label_date_picker_field_error_messages_container
  {
    width: 100% !important;
  }
  .sign_up_button{
    width: 100% !important;
    padding: 12px 20px !important;
  }
  .login_navigation{
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
