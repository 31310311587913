.LegalAndSecurity__container {
    padding: 30px;

    .Data__wapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 1.5rem;
        width: 92%;

        .title {
            color: #333;
            font-family: Manrope700;
            font-size: 18px;
        }

        .pragraph {
            color: #333;
            font-family: Inter400;
            font-size: 12px;
            text-align: justify;
        }
    }
}

@media (max-width: 600px) {
    .LegalAndSecurity__container {
        padding: 15px 0px 15px 25px !important;
        .Data__wapper {
            .title {
                font-size: 15px !important;
            }
            .pragraph {
                font-size: 10px !important;
            }
        }
    }
}