.calendar_view_container {
    display: block;
    max-width: 1500px;
    margin: 40px auto;

    .fc .fc-button .fc-icon {
        font-size: 15px;
    }

    .role {
        line-height: 10px !important;
    }

    .avatar_pic {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        object-fit: cover;
    }

}

.fc-theme-standard td {
    border: 1px solid #D2D2D2 !important;
}

.fc .fc-scrollgrid-liquid {
    border: none;
}

.fc-daygrid-day-number {
    text-decoration: none;
}

.fc-col-header-cell-cushion {
    text-decoration: none;
    color: #6E6B7B;
    font-size: 12px;
    font-family: Manrope600;

}

.fc-toolbar-title {
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: left;
    font-family: Manrope600;
    color: #50575D;

}

.fc-toolbar-chunk div {
    display: flex !important;
    align-items: center;
}

.fc-toolbar-chunk div>button {
    background: none !important;
    border: none !important;
    outline: none !important;
}

.fc-toolbar-chunk div>button:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.fc-prev-button .fc-button .fc-button-primary {
    background-color: none;
}

.fc-next-button {
    background: none;
}

.fc-icon-chevron-right {
    color: #000;
}

.fc-icon-chevron-left {
    color: #000;
}

.CalendarView__container {
    padding: 20px 25px;
    height: 100%;
    box-shadow: 0px 4px 39px 0px #B2B2B240;
    border-radius: 17px;
    background: #FFF;
    margin-bottom: 4rem;

    .CalendarView__child__select {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
    }

    .CalendarView__child__select_arb {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .slot_wapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        gap: 25px;
        margin-top: 15px;
        margin-bottom: -65px;

        .slot__item {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            .selected_slot {
                width: 18px;
                height: 18px;
                border-radius: 4px;
                background: #F0962C;
            }

            .avilableSlot {
                width: 18px;
                height: 18px;
                border-radius: 4px;
                border: 1.5px solid #F0962C;
                background: rgba(255, 174, 79, 0.12) !important;
            }

            .notAvilableSlot {
                width: 18px;
                height: 18px;
                border-radius: 4px;
                border: 1.5px solid #D2D2D2;
                background: #FAFAFA;
            }

            span {
                font-size: 12px;
                font-weight: 600;
                color: #6E6B7B;
            }
        }
    }
}

/* Custom styling for the cells of the specific date's column */
.custom-day-cell {
    font-size: 14px;
    font-weight: 600;
    color: none !important;
    // border: 1px solid #F8F7FE;
    cursor: pointer;
    background: none !important;
    width: 100%;
}

.custom-day-cell:hover {
    font-size: 14px;
    font-weight: 600;
    // border: 1px solid #F8F7FE;
    color: none !important;
    cursor: pointer;
    background: none !important;
    height: 20px;
    width: 100%;
}

.fc-theme-standard td.custom-background_Activities .fc-daygrid-day-frame {
    border: 1px solid #F0962C !important;
    background: rgba(255, 174, 79, 0.12);
    cursor: pointer;
    border-radius: 5px;
}

.fc-theme-standard td.custom-background_Activities {
    border: none !important;
}

.custom-background_Activities_selecedcheck {
    border: 1px solid #F0962C !important;
    background: #F0962C;
    border-radius: 4px !important;
}

/* Default styling for other cells */
.default-day-cell {
    // width: 100px !important;
    // height: 100px !important;
    margin: 0;
    padding: 0;
    background-color: aqua !important;
}


.Calendar__discount__container__activity {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 17px;
    // margin-bottom: 0.7rem;
    // margin-bottom: 20px;

    .discount__card {
        height: 40px;
        border-radius: 48px;
        background: #FFF5E9;
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
        padding: 0 17px;
        cursor: pointer;

        .discount__Img {
            width: 21.993px;
            height: 21.99px;
            object-fit: contain;
        }

        .Black_text {
            color: #333;
            font-family: Manrope600;
            font-size: 10px;
        }

        .blue__text {
            color: #FFAE4F;
            font-family: Manrope600;
            font-size: 10px;
        }

        .badge_blue {
            color: #FFF5E9;
            font-family: Manrope600;
            font-size: 10px;
            padding: 0px 5px !important;
            padding-bottom: 0 !important;
            border-radius: 8px;
            background: #FFAE4F;
        }
    }

    .discount__card__active {
        height: 40px;
        border-radius: 48px;
        background: #FFAE4F;
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
        padding: 0 17px;
        cursor: pointer;

        .discount__Img {
            width: 21.993px;
            height: 21.99px;
            object-fit: contain;
        }

        .Black_text {
            color: #FFF5E9;
            font-family: Manrope600;
            font-size: 10px;
        }

        .blue__text {
            color: #FFF5E9;
            font-family: Manrope600;
            font-size: 10px;
        }

        .badge_blue {
            color: #FFAE4F;
            font-family: Manrope600;
            font-size: 10px;
            padding: 0px 5px !important;
            padding-bottom: 0 !important;
            border-radius: 8px;
            background: #FFF5E9;
        }
    }
}

.CalendarView__rightside__Activities {
    height: 100%;
    box-shadow: 0px 4px 39px 0px #B2B2B240;
    border-radius: 17px;
    background: #FFF;
    position: relative;

    .top-section {
        padding: 20px 25px;
        height: 100vh;
        overflow: scroll;
        overflow-x: hidden;
    }

    .top-section::-webkit-scrollbar {
        width: 0;
    }


    .CalendarView__datePicker {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        .datePicker {
            max-width: 180px !important;
            background-color: rgba(237, 243, 249, 1) !important;
            border-radius: 28px;
            max-height: 40px !important;
            box-sizing: none !important;
        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
            background-color: rgba(237, 243, 249, 1) !important;
            border-radius: 28px;
            max-height: 40px !important;
            box-sizing: none !important;
        }

        .chosse_time_Conatiner__Active {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border-radius: 35px;
            background: #F0962C;
            cursor: pointer;
            height: 35px;

            img {
                width: 15px;
                height: 15px;
                object-fit: cover;
            }

            span {
                color: #FFF;
                font-size: 12px;
                font-weight: 500;
            }
        }

        .chosse_time_Conatiner {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border-radius: 35px;
            background: #EDF3F9;
            cursor: pointer;

            img {
                width: 13px;
                height: 13px;
                object-fit: cover;
            }

            span {
                color: #333;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    .Timeline__Calendar_container {
        margin-top: 2rem;

        .fc-direction-ltr {
            min-height: 450px;
            height: 100%;
        }
    }

    .Bottom__book__slot__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px -1px 13px 0px rgba(169, 169, 169, 0.25);
        width: 100%;
        // height: 100px;
        height: 20%;
        bottom: 0;
        position: absolute;
        padding: 20px;

        .Book__slot__left {
            display: flex;
            flex-direction: column;
            align-items: start;
            flex: 1;

            .Selected__text {
                color: rgba(51, 51, 51, 1);
                font-size: 12px;
                font-family: Inter500;

            }

            .count__text {
                color: #F0962C;
                font-size: 18px;
                font-family: Inter500;
            }
        }

        .Book__slot__right {
            .Activities__book__slot__btnActivie {
                height: 50px;
                width: 100%;
                min-width: 150px;
                color: rgba(255, 255, 255, 1);
                font-size: 12px;
                border-radius: 50px;
                background: #F0962C;
                opacity: 1;
                font-family: Manrope600;
            }

            .Activities__book__slot__btnInActive {
                height: 50px;
                width: 100%;
                min-width: 150px;
                color: rgba(255, 255, 255, 1);
                font-size: 12px;
                border-radius: 50px;
                background: #F0962C;
                opacity: 0.5;
                cursor: default !important;
                font-family: Manrope600;
            }
        }
    }

    .rightSide__top__Date {
        color: #333;
        font-size: 14px;
        margin-top: 15px;
        font-family: Manrope600;
    }

    .rightSide_slot_container_wapper {
        max-height: 400px;
        overflow: auto;
        overflow-x: hidden;
        margin-top: 1rem;
        margin-bottom: 5px;
    }

    .slot_container_Position {
        display: flex;
        flex-direction: row;
        gap: 15px;

        .DotedLine__wapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            margin: 5px 3px;

            .time {
                color: #000;
                font-size: 12px;
                text-align: left;
                font-family: Inter500;

            }

            .dotted-line {
                width: 1px;
                height: 56px;
                border-left: 2px dotted #B8B8B8;
                stroke-width: 1px;
                stroke: #B8B8B8;
                margin-left: 10px;
            }
        }

        .Slot_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            // padding: 5px;
            padding: 5px 15px;
            margin: 15px 0px;
            border-radius: 8px;
            background-color: rgba(209, 233, 255, 0.42) !important;
            cursor: pointer;
            flex: 1;

            .Slot__activities_img_wapper {
                width: 36.932px;
                height: 60px;
                border-radius: 9.017px;
                background: #FBFDFF;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 21px;
                    height: 18px;
                }
            }

            .Slot__activities_contant_wapper {

                display: flex;
                flex-direction: column;
                width: 100%;

                .Slot_game_name {

                    color: #333;
                    font-size: 12px;
                    font-family: Inter600;
                    display: flex;
                    /* width: 100%; */
                    justify-content: space-between;

                    .slot-checkbox {
                        padding: 0;
                        border: none;
                        outline: none;
                        background: #fff;
                        margin: 0px 10px;
                    }

                }
                .tutor-createTime-wrap{
                    display: flex;
                    justify-content: space-between;
                }
                .Slot_badge {
                    color: #FBFDFF;
                    font-size: 10px;
                    font-weight: 500;
                    border-radius: 5px;
                    background: #F0962C;
                    padding: 1px 3px;
                    width: 100%;
                    max-width: 76px;
                }
                .createdBy_Activity{
                    font-size: 10px;
                    font-weight: 500;
                    color: #F0962C;
                  }
            }
        }
    }
}

.dayCellActivities__selected {
    // color: #FFF;
    // font-size: 14px;
    // font-weight: 600;
    // background-color: #F0962C;
    // padding: 0px 5px;
    // border-radius: 3px;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
}

.dayCellActivities__Unselected,.custom-day-header {
    color: #333;
    font-size: 12px;
    font-weight: 500;
}

.child_choose_calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    gap: 25px;

    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    }

    span {
        color: #333;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        max-width: 200px;
    }
}

.ChildSelectBtn__wapper__Activities {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 2rem;

    .BackBtn {
        width: 133px;
        height: 46px;
        border-radius: 26px;
        background-color: #FFF;
        border: 1px solid #FFAE4F !important;
        color: #FFAE4F;
        font-size: 12px;
        font-weight: 600;
    }

    .OkayBtn {
        width: 133px;
        height: 46px;
        border-radius: 26px;
        background: #FFAE4F;
        color: #FFF;
        font-size: 12px;
        font-weight: 600;
    }
}


.highlighted {
    background-color: #F0962C;
}


.CalendarView__container {

    .fc-highlight {
        background-color: #F0962C;
        // color: white !important;
    }
}

.Activitie__Back__navigateBtn {
    width: 133px;
    height: 46px;
    border-radius: 26px;
    background: #FFAE4F;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
}

@media (min-width: 1420px) {
    .CalendarView__rightside__Activities .rightSide_slot_container_wapper {
        max-height: 67vh !important;
    }

}

@media screen and (max-width: 720px) {
    .calendar_view_container {
        overflow: hidden;
        margin: 0px !important;

        .tutor_calendar_head_breadcam {
            margin: 0px 20px 20px 20px !important;

        }

        .CalendarView__child__select {
            justify-content: center !important;
            margin-top: 15px;
        }

        .Calendar__discount__container__activity {
            overflow: auto;
            flex-wrap: nowrap !important;
            scrollbar-width: none;
            /* Firefox */
            -ms-overflow-style: none;
            /* IE and Edge */
        }

        .Calendar__discount__container__activity::-webkit-scrollbar {
            display: none;
        }

        .Bottom__book__slot__container {
            position: inherit;
        }

        .slot_wapper {
            margin-bottom: 0 !important;
            justify-content: center !important;
        }

    }

    .fc .fc-toolbar.fc-header-toolbar {
        margin: 15px 0px !important;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        min-height: 13px !important;
    }

}