.PaymentHistory__container {
    backdrop-filter: blur(6px);
    width: 100%;
    margin-bottom: 2rem;

    .sort__wapper__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .lable {
            color: #333;
            font-family: Manrope700;
            font-size: 16px;
        }
    }

    .Card__wapper {
        overflow: auto;
        overflow-y: scroll;
        max-height: 550px;

        .card__container {
            border-radius: 9px;
            border: 1px solid #ECECEC;
            background: #FFF;
            padding: 17px;
            display: flex;
            flex-direction: column;

            .top__contant {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 1rem;

                .Black__text {
                    color: #333;
                    font-family: Manrope600;
                    font-size: 16px;
                    width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .name__text {
                    color: #178FFF;
                    font-family: Manrope700;
                    font-size: 12px;
                }
            }

            .row__contant {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 9px 0;
            }

            .left__light__text {
                color: #707070;
                font-family: Inter500;
                font-size: 14px;
            }

            .right__black__text {
                color: #333;
                font-family: Inter500;
                font-size: 14px;
            }

            .img__wapper {
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: contain;
                }
            }

            .bottom__contant {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                // padding: 0 10px;
                padding: 0 0px;
                margin-top: 1rem;

                .left_side__payment_wapper {
                    background-color: rgba(230, 245, 238, 1);
                    border-radius: 24px;
                    padding: 10px 14px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 7px;

                    .icon {
                        color: rgba(17, 190, 107, 1) !important;
                        font-size: 18px !important;
                    }

                    .text {
                        color: #11BE6B;
                        font-family: Manrope700;
                        font-size: 14px;
                        text-transform: capitalize;
                    }
                }

                .amount {
                    color: #17BC89;
                    font-family: Manrope600;
                    font-size: 16px;
                }
            }
        }

        .pagination__container {
            .PaginationContainerLang-Arab {
                .css-wjh20t-MuiPagination-ul {
                    direction: ltr !important;
                }

                .css-nhb8h9 {
                    direction: ltr !important;
                }
            }
        }
    }

    /* width */
    .Card__wapper::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
}


@media (max-width: 900px) {
    .PaymentHistory__container {
        .sort__wapper__container {
            .lable {
                font-size: 81.25% !important;
            }

            .css-lh3xye-MuiFormControl-root {
                .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
                    right: 20px !important;
                }
            }
        }

        .Card__wapper {
            .card__container {
                .top__contant {
                    .Black__text {
                        font-size: 81.25% !important;
                    }

                    .name__text {
                        font-size: 56.25% !important;
                    }
                }

                .left__light__text {
                    font-size: 68.75% !important;
                }

                .right__black__text {
                    font-size: 68.75% !important;
                }

                .bottom__contant {
                    padding: 0px 0px !important;
                    gap: 10px;

                    .left_side__payment_wapper {

                        .icon {
                            font-size: 93.75% !important;
                        }

                        .text {
                            font-size: 68.75% !important;
                        }
                    }

                    .amount {
                        font-size: 81.25% !important;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 360px) {

    //**For Body Content Column**//
    .PaymentHistory__container {
        .Card__wapper {
            .card__container {
                .row__contant {
                    flex-direction: column !important;
                    align-items: flex-start !important;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .PaymentHistory__container {
        overflow-x: hidden !important;

        .sort__wapper__container {
            .css-lh3xye-MuiFormControl-root {
                min-width: 0px !important;

                .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
                    right: 7px !important;
                }
            }

            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
                font-size: 0.8rem !important;
            }

            .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
                width: 0.9rem !important;
            }
        }

        .Card__wapper {
            margin-left: 7px !important;
            overflow-x: hidden !important;
        }
    }
}

//**Responsive For Extra Large🖥️ Screen**//
@media screen and (min-height:860px) {
    .PaymentHistory__container .Card__wapper {
        max-height: 75vh !important;
    }
}