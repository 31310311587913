.childCalender__container {
    width: 100%;
    height: 100%;
    margin-bottom: 9rem;
    overflow: auto;
    overflow-x: hidden;

    .my-claender-view {
        position: relative;

        .slot_wapper {
            position: absolute;
            display: flex;
            flex-direction: row;
            // flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            gap: 25px;
            top: 10%;
            left: 20%;

            .slot__item {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                gap: 10px;

                .today_slot {
                    width: 18px;
                    height: 18px;
                    border-radius: 4px;
                    border: 0.5px solid rgba(255, 220, 40, .15) !important;
                    background: rgba(255, 220, 40, .15) !important;
                }

                .selected_slot {
                    width: 18px;
                    height: 18px;
                    border-radius: 4px;
                    border: 0.5px solid #744FFF;
                    background: #F8F6FF !important;
                }

                .avilableSlot {
                    width: 18px;
                    height: 18px;
                    border-radius: 4px;
                    border: 1px solid #B79FD3 !important;
                    background: #EFF7FF !important;
                }

                .notAvilableSlot {
                    width: 18px;
                    height: 18px;
                    border-radius: 4px;
                    border: 1px solid #D2D2D2 !important;
                }

                span {
                    font-size: 12px;
                    font-weight: 600;
                    color: #6E6B7B;
                }
            }
        }
    }

    .fc-toolbar-chunk .fc-button-group {
        border-radius: 27px;
        background: #F5F2FF;
        padding: 4px 7px !important;
    }

    .fc-button-group {
        button {
            color: #B79FD3;
            font-family: Manrope500;
            font-size: 10px;
            padding: 10px !important;
        }
    }

    .fc-button-group .fc-button-active {
        font-family: Manrope600 !important;
        font-size: 10px;
        color: #5F33E1 !important;
        border-radius: 27px !important;
        background: #FFF !important;
        padding: 10px !important;
    }


    .custom-background_child_calender {
        border-radius: 4px !important;
        border: 1px solid #B79FD3 !important;
        background: #EFF7FF !important;
        cursor: pointer;
    }

    .custom-background_selected_mycalendar {
        border: 1.5px solid #744FFF !important;
        background: #F8F6FF !important;
        border-radius: 4px !important;
    }

    .ChildFulCalender {

        .fc-highlight {
            border: 0.5px solid #744FFF;
            background: #F8F6FF !important;
        }

        .fc-daygrid-day-frame {
            cursor: pointer !important;
        }

    }

    .tutor__EventContent {
        display: flex;
        height: 17px;
        padding: 0px 5px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #1967B0;
        font-family: Inter500;
        font-size: 10px;
    }

    .university__EventContent {
        display: flex;
        height: 17px;
        padding: 0px 5px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #12CC92;
        font-family: Inter500;
        font-size: 10px;
    }

    .activity__EventContent {
        display: flex;
        height: 17px;
        padding: 0px 5px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #DE8926;
        font-family: Inter500;
        font-size: 10px;
    }

    .video_call__wapeer {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F1ECFF;
        width: 28px;
        height: 26px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        img {
            width: 19.002px;
            height: 19.777px;
            object-fit: contain;
        }

        .badeg__pos {
            position: absolute;
            right: 4px;
            top: 0px;
        }
    }

    .disable_style {
        opacity: 0.5;
        cursor: default !important;
    }

    .childCalender__rightSide {
        .slot__select__dropdown_wapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .slot__select__dropdown {
                border-radius: 44px;
                background: #F5F5F5;
                color: #333;
                font-family: Inter500;
                font-size: 12px;
            }
        }

        .slot__detailes__container {
            margin-top: 1rem;

            .title {
                color: #333;
                font-family: Manrope600;
                font-size: 14px;
            }

            .detailes__wapper {
                display: flex;
                flex-direction: column;
                gap: 15px;
                margin-top: 1rem;
                width: 100%;

                .Activitie__container {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    border-radius: 8px;
                    background: #FFEFDC;
                    padding: 7px;

                    .img__wapper {
                        width: 38px;
                        height: 100%;
                        min-height: 70px;
                        border-radius: 10px;
                        background: #FBFDFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 23px;
                            height: 17px;
                            object-fit: contain;
                        }
                    }

                    .right_container {
                        display: flex;
                        flex-direction: column;
                        gap: 7px;
                        width: 100%;
                        padding: 4px 6px;

                        .name__detailes {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            .name {
                                color: #DE8926;
                                font-family: Inter600;
                                font-size: 12px;
                            }
                        }

                        .child_profile_img {
                            width: 17.137px;
                            height: 17.137px;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        .createdBy {
                            color: #DE8926;
                            font-family: Inter500;
                            font-size: 10px;
                        }

                    }
                }

                .Tutor__container {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    border-radius: 8px;
                    background: #D1E9FF;
                    padding: 7px;

                    .img__wapper {
                        width: 38px;
                        height: 100%;
                        min-height: 70px;
                        border-radius: 10px;
                        background: #FBFDFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 23px;
                            height: 17px;
                            object-fit: contain;
                        }
                    }

                    .right_container {
                        display: flex;
                        flex-direction: column;
                        gap: 7px;
                        width: 100%;
                        padding: 4px 6px;

                        .name__detailes {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            .name {
                                color: #1967B0;
                                font-family: Inter600;
                                font-size: 12px;
                            }
                        }

                        .child_profile_img {
                            width: 17.137px;
                            height: 17.137px;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        .createdBy {
                            color: #1967B0;
                            font-family: Inter500;
                            font-size: 10px;
                        }

                    }
                }

                .University__container {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    border-radius: 8px;
                    background: #E8FAF5;
                    padding: 7px;

                    .img__wapper {
                        width: 38px;
                        height: 100%;
                        min-height: 70px;
                        border-radius: 10px;
                        background: #FBFDFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 23px;
                            height: 17px;
                            object-fit: contain;
                        }
                    }

                    .right_container {
                        display: flex;
                        flex-direction: column;
                        gap: 7px;
                        width: 100%;
                        padding: 4px 6px;

                        .name__detailes {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            .name {
                                color: #069B6D;
                                font-family: Inter600;
                                font-size: 12px;
                            }
                        }

                        .child_profile_img {
                            width: 17.137px;
                            height: 17.137px;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        .createdBy {
                            color: #069B6D;
                            font-family: Inter500;
                            font-size: 10px;
                        }

                    }
                }
            }
        }
    }

    .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
        text-transform: capitalize !important;
    }
}

.child-custom-day-header {
    color: #333;
    font-size: 12px;
    font-weight: 500;
  }

///**Style For Mobile Responsive**///
@media screen and (max-width: 991px) {
    .childCalender__container {
        overflow-x: hidden !important;

        .childCalender__rightSide {
            .slot__select__dropdown_wapper {
                margin-top: 24px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .childCalender__container {
        .row {
            margin-left: 0px !important;
        }

        .fc table {
            font-size: 12px !important;
        }
    }

    .childCalender_container_arab {
        .fc table {
            font-size: 12px;
        }
    }
}

//
@media screen and (max-width: 450px) {
    .childCalender__container {
        .childCalender__rightSide .slot__detailes__container .title {
            font-size: 11px !important;
        }

        .row {
            margin-left: 0px !important;

            .fc {
                .fc-toolbar-title {
                    font-size: 14px !important;
                }

                .fc-button .fc-icon {
                    font-size: 0.9em !important;
                }
            }

            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
                font-size: 0.8rem !important;
            }

        }

        .fc-button-group button {
            padding: 5px !important;
        }

        .fc-button-group .fc-button-active {
            padding: 5px !important;
        }

        .fc-toolbar-chunk .fc-button-group {
            padding: 0px 0px !important;
        }
    }
}

@media screen and (min-width: 991px) {
    .childCalender__container {

        .childCalender__rightSide {
            .slot__detailes__container .detailes__wapper {
                width: 100%;
                height: 100%;
                max-height: 580px;
                overflow: auto;
                overflow-x: hidden;
                overflow-y: scroll;
            }

            /* width */
            .slot__detailes__container .detailes__wapper::-webkit-scrollbar {
                width: 0px !important;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: transparent;
            }
        }
    }
}

//*Tutor Chield Calender Responsive 17-05-2024*//
@media screen and (max-width:991px) {
    .childCalender__container .fc .fc-scrollgrid-liquid {
        margin-top: 4rem;
    }
}

@media screen and (max-width:991px) {
    .childCalender__container .childCalender__rightSide {
        margin-top: 4rem !important;
    }
}

@media screen and (max-width:600px) {
    .childCalender__container {
        overflow-x: scroll !important;
        min-width: 400px;

        .my-claender-view {
            .slot_wapper {
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap: 10px;
                left: 3%;
                top: 18%;
            }

            .slot_wapper_arab {
                left: initial !important;
                right: 3% !important;
            }
        }

        .fc .fc-scrollgrid-liquid {
            margin-top: 7rem;
        }

        .childCalender__rightSide {
            margin-top: 7rem !important;

            .slot__select__dropdown_wapper {
                justify-content: center;
            }
        }
    }
}