.schoolCard__RadiusMapKm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 10px;

  .RadiusMapKm__wapper {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50px;
    padding: 10px;
    display: flex;
    gap: 10px;
    cursor: pointer;

    span {
      color: #4e4e4e;
      font-family: Inter500;
      font-size: 14px;
    }
  }

  .RadiusMapKm__wapper__active {
    background-color: rgba(255, 200, 18, 1);
    border-radius: 50px;
    padding: 10px;
    display: flex;
    gap: 10px;
    cursor: pointer;

    span {
      color: #fff;
      font-family: Inter500;
      font-size: 14px;
    }
  }
}

@media screen and (max-width:600px) {
  .schoolCard__RadiusMapKm {
    .RadiusMapKm__wapper {
      span {
        font-size: 12px;
      }
    }

    .RadiusMapKm__wapper__active {
      span {
        font-size: 12px;
      }
    }
  }

}