.card__container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  .user__Detailes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 0px;

    .user__name {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }

    .user__icon__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center !important;
      gap: 10px;

      img {
        height: 20px;
        width: 18px;
      }
    }
  }

  .description__contant {
    color: rgb(51, 51, 51);
    font-size: 14px;
    font-family: Inter400;
  }

  .user__Detailes__Education {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0px;
    gap: 10px;

    .grayIcons {
      width: 12px;
      height: 14px;
    }

    .graytext {
      color: #333;
      font-size: 14px;
      font-family: Inter500;
    }

    .StarIcon {
      color: #fcb040 !important;
      width: 18px;
      height: 17px;
    }
  }

  .user__Detailes__Grade {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .lable {
      color: #4e4e4e;
      font-size: 14px;
      font-family: Inter500;
    }

    img {
      width: 12px;
      height: 16px;
    }
  }

  .user__Detailes__fess {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0px;
    margin-top: 12px;

    .Avg__Fees {
      color: #333;
      font-size: 16px;
      font-weight: Manrope700;
    }

    .title {
      color: #178fff;
      font-size: 16px;
      font-family: Manrope600;
    }
  }

  .user__Detailes__Courses {
    padding: 7px 0px;

    .title {
      color: #000;
      font-size: 18px;
      font-family: Manrope700;
    }

    .badge_wrapeer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 6px;
      max-width: 400px;

      .badge__container {
        border: 1px solid #d8d8d8;
        padding: 2px 8px;
        border-radius: 3px;
        margin: 0;
        cursor: pointer;

        span {
          color: #178fff;
          font-size: 12px;
          font-family: Inter600;
        }
      }

      .badge__container-Active {
        border: 1px solid #178fff;
        padding: 2px 8px;
        border-radius: 3px;
        margin: 0;
        cursor: pointer;
        background-color: #178fff;

        span {
          color: #fff;
          font-size: 12px;
          font-family: Inter600;
        }
      }
    }
  }
}

.top_cover_img_wrapper {
  position: relative;
  max-width: 530px !important;

  .card_bg_img {
    width: 269px;
    height: 150px;
    object-fit: cover;
    border-radius: 12px;
    padding: 0 5px;
  }

  .profileImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 1);
    object-fit: cover;
    position: absolute;
    left: 20px;
    bottom: -30px;
  }

  .left_arrow {
    position: absolute;
    left: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }

  .right_arrow {
    position: absolute;
    right: 9px;
    top: 63px;
    z-index: 1000;
    border-radius: 24px;
    background: rgba(240, 240, 240, 0.27);
    backdrop-filter: blur(1.3333333730697632px);
    color: #f0f0f045 !important;
  }
}

.MuiBackdrop-root {
  opacity: 0 !important;
}

.Tutor__map__dailog__wrapper {

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
  .css-uhb5lp {
    width: 80vw !important;
    max-width: 900px !important;
    border-radius: 16px !important;
    background: #fff !important;
  }

  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background: rgba(218, 218, 218, 0.68) !important;
    backdrop-filter: blur(9px) !important;
  }
}

.Model__Dialog__container {
  width: 600px !important;
  height: 100% !important;
  position: relative;

  .css-1wf8b0h-MuiTabs-flexContainer {
    justify-content: space-around !important;
  }
}

// .background_circle_left__University_model {
//   border-radius: 527px;
//   background: radial-gradient(50% 50% at 50% 50%,
//       rgba(18, 204, 146, 0.17) 0%,
//       rgba(252, 193, 106, 0) 100%) !important;
//   width: 100%;
//   max-width: 200px !important;
//   height: 80%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: -1;
//   filter: blur(250px);
// }

.background_circle_right__University_model {
  border-radius: 50%;
  background: radial-gradient(circle, #12CC92 6%, #12CC92 0%, #FFF 100%);
  // height: 80%;
  // width: 100%;
  // max-width: 200px !important;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 70%;
  left: 80%;
  z-index: 0;
  filter: blur(120px);
}


.top__contant__wapper__university {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  .top__PageBtn__icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;

    .prev__icons {
      color: rgba(18, 204, 146, 1) !important;
      background: #e8f5f1;
      border-radius: 8px;
    }

    .next__icons {
      color: rgba(18, 204, 146, 1) !important;
      background: #e8f5f1;
      border-radius: 8px;
    }

    .icons__disable {
      color: rgba(18, 204, 146, 1) !important;
      opacity: 0.5;
      cursor: default !important;
    }

    .text__disable {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      opacity: 0.5;
      cursor: default !important;
    }

    .prev__text {
      color: #333;
      font-size: 14px;
      font-family: Manrope700;
    }
  }

  .top__close__icons {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .expanded__icons {
      cursor: pointer;
      color: #333333 !important;
      font-size: 16px;
    }

    .close__icons {
      cursor: pointer;
      font-size: 16px;
      color: #333333 !important;
    }
  }
}

.View__on__map__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  padding: 7px 0px;

  .wapperDiv {
    border-radius: 7px;
    background: #e8f5f1;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      width: 16.217px;
      height: 13.79px;
    }

    span {
      color: #0a8962;
      font-size: 12px;
      font-family: Inter600;
    }
  }
}

.University__detailed__container {
  max-width: 500px;

  .flexDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .detailed__container__wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  span {
    color: #333;
    font-size: 14px;
    font-family: Inter500;
  }

  .number {
    color: #12cc92;
    font-size: 16px;
    font-family: Inter600;
  }
}

.university__description__container {
  padding: 7px 0px;
  margin-top: 9px;
  max-width: 500px;

  .title {
    color: #333;
    font-size: 16px;
    font-family: Manrope700;
  }

  .contants {
    color: #333;
    font-size: 14px;
    font-family: Inter400;
    max-width: 450px;
    text-align: justify;
    margin-top: 10px;
  }
}

.university__bottom__contant {
  display: flex;
  flex-direction: column;
  padding: 7px 0px;

  .bottom__contant__wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;

    span {
      color: #333;
      font-size: 16px;
      font-family: Manrope700;
    }

    div {
      color: rgb(51, 51, 51);
      font-size: 14px;
      font-family: Inter400;
    }
  }
}

@media (max-width: 990px) {
  .top_cover_img_wrapper {
    position: relative;
    max-width: 100% !important;
  }
}

@media (min-width: 1420px) {
  .University__detailed__container {
    max-width: 100% !important;
  }

  .background_circle_right__University_model {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 50%;
    left: 80%;
    z-index: 0;
    filter: blur(250px);
  }
}