.Activitiescheckout-container {
    // margin: 20px 15px;
    display: block;
    max-width: 1500px;
    margin: 30px auto;
    margin-bottom: 5rem;

    .billingtext {
        color: #FFAE4F;
        font-size: 18px;
        font-family: Manrope700;
        margin-bottom: 20px;
    }


    .billing-container {
        padding: 20px 30px;
        height: 100%;
        border-radius: 20px;
        box-shadow: 0px 4px 15px 0px #B4B4B440;
        background: white;

        .inputfieldss {
            padding: 0px 15px;

            .emailtext {
                color: #000;
                font-size: 16px;
                width: 100%;
                max-width: 200px;
                font-family: Manrope500;

            }

            .billng_contact_fields {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;

                .inputdesign1 {
                    width: 100%;
                    height: 40px;
                    border-radius: 10px;
                    background: #F1F5FD;
                    border: 0 !important;
                    padding: 3px 15px;
                    outline: none;
                }

                .inputdesign1::placeholder {
                    color: #9BA9C5;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .phone-num {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                margin-top: 1.5rem;

                input {
                    border-radius: 12px;
                    background: #F1F5FD;
                    height: 40px;
                    color: #888;
                    font-size: 14px;
                    border: unset;
                    padding: 12px 12px 12px 44px;
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }

    }

    // .ActivitiesImg_wapper {
    //     display: flex;
    //     align-items: center;
    //     gap: 10px;
    //     margin: 6px;

    //     .checkout-txt {
    //         font-size: 20px;
    //         font-weight: 700;
    //         color: #000000;
    //     }
    // }

    .activities_Img_wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 200px;
        height: 60px;
        border-radius: 15px;
        background: #fff;
        padding: 5px 15px;
        cursor: pointer;

        .activities__text {
            color: #000;
            font-size: 20px;
            font-family: Manrope700;
        }

        .back-img {
            cursor: pointer;
            margin-right: 2px;
        }

        .back-img_arb {
            cursor: pointer;
            margin-right: 2px;
            rotate: 180deg;
        }
    }

    .leftside__contant {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0px 20px;
        // gap: 10px;   

        .freeTrial_img {
            width: 300px;
            height: 170px;
            object-fit: cover;
            border-radius: 8px;
        }

        .age_text {
            color: #f0962c;
            font-family: Inter500;
            font-size: 12px;
            text-align: left;
        }

        .contant_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between !important;
            gap: 14px;
        }

        .title {
            color: #333;
            font-family: Manrope700;
            font-size: 16px;
            text-align: left;
            margin-bottom: 10px;
        }

        .price_text {
            color: #178fff;
            font-family: Manrope700;
            font-size: 18px;
            overflow: hidden;
            white-space: nowrap;
        }

        .descrption {
            color: #333;
            font-family: Inter400;
            font-size: 14px;
            text-align: justify;
            margin-bottom: 15px;
        }

        .date__filed_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            img {
                width: 20px;
                height: 20px;
                object-fit: contain;
                padding: 2px;
                background: rgb(250, 244, 236);
            }

            span {
                color: #333;
                font-family: Inter500;
                font-size: 14px;
                text-align: left;
            }
        }
    }


    .OrderDetails__contants {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .coursetext {
            color: #333333;
            font-size: 14px;
            font-family: Inter600;
        }

        .Order__amount {
            color: #333333;
            font-size: 16px;
            font-family: Manrope800;

        }

        .left__contant {
            display: flex;
            flex-direction: column;

            .date {
                color: #FFAE4F;
                font-size: 12px;
                font-weight: 600;
            }

            .course {
                color: #3C3C3C;
                font-size: 14px;
                font-family: Inter600;
                margin-top: 7px;
                margin-bottom: 3px;
            }

            .session__count {
                color: rgba(51, 51, 51, 0.75);
                font-size: 12px;
                font-weight: 500;
            }
        }

        .right__contant {
            color: #333333BF;
            font-size: 13px;
            font-family: Inter500;
        }
    }

    .OrderDetails__view__more {
        color: #FFAE4F;
        font-size: 14px;
        font-weight: 700;
        text-align: end;
        padding-right: 20px;
        cursor: pointer;
    }

    .Course__Details__bottom__contant {
        // margin: 15px 0px;
        margin: 15px 20px;

        .title {
            color: #333;
            font-size: 12px;
            font-weight: 600;
            padding-left: 20px;
        }

        .bottom__contant__wapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            /* padding: 10px 20px; */
            // margin-left: 30px;
.text-font{
    font-size: 14px;
}
            .bottom__contant {
                display: flex;
                align-items: center;
                gap: 10px;
                .coursetext {
                    color: #333333;
                    font-size: 14px;
                    font-family: Inter600;
                }
                img {
                    width: 33px;
                    height: 31px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .name {
                    color: #333;
                    font-size: 14px;
                    font-weight: 700;
                }
                .Address_select__dropdown   {
                    border-radius: 46px !important;
                    border: 1px solid #efefef !important;
                    padding: 2px 0px !important;
                    margin: 0px 0px !important;
                    height: 52px !important;
                    max-width: 190px !important;  
                }
            }
        }
    }

    .summary {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: #FFF !important;
        box-shadow: 0px 4px 15px 0px rgba(180, 180, 180, 0.25) !important;
        padding: 20px 30px;

        .Price__detailes__wapper {
            display: flex;
            flex-direction: column;
            gap: 15px;
            // margin-top: 1rem;

            .original-price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // padding: 4px 14px;

                .original__price__text {
                    color: rgba(51, 51, 51, 0.75);
                    font-size: 14px;
                    font-family: Inter500;
                }

                .discount-text {
                    color: #FFAE4F;
                    font-size: 14px;
                    font-family: Inter500;
                }

                .original__price__text_amount {
                    color: rgba(51, 51, 51, 0.75);
                    font-size: 14px;
                    font-family: Inter600;
                }

                .discount-text_amount {
                    color: #FFAE4F;
                    font-size: 14px;
                    font-family: Inter600;
                }

                .total__small {
                    color: rgba(0, 0, 0, 0.75);
                    font-size: 14px;
                    font-weight: 700;
                }

                .total__large {
                    color: #333;
                    font-size: 18px;
                    font-family: Manrope700;

                }
            }
        }

        .summary__contant {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            .checkbox {
                width: 16px;
                height: 16px;
                border-radius: 3px !important;
                border: 1px solid #FFAE4F !important;
                background: rgba(255, 174, 79, 0.12) !important;
                cursor: pointer;
            }

            .paratext {
                color: rgba(51, 51, 51, 0.75);
                font-size: 12px;
                font-family: Manrope500;
            }

            .terms__text {
                color: #FFAE4F;
                font-size: 12px;
                font-family: Manrope600;
                margin-left: 5px;
            }
        }

        .summary-btndiv {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;



            .proceedBtn {
                width: 359px;
                height: 56px;
                margin-top: 2rem;
                border-radius: 26px;
                background: #FFAE4F !important;
                color: #FFF;
                font-size: 16px;
                font-family: Inter500;
            }

            .proceedBtn__disable {
                width: 359px;
                height: 56px;
                margin-top: 2rem;
                border-radius: 26px;
                background: #FFAE4F !important;
                color: #FFF;
                font-size: 16px;
                font-family: Inter500;
                opacity: 0.5;
                cursor: default !important;
            }
        }
    }

}

.Activities__OrderDetails__Dailoge {
    width: 100% !important;
    min-width: 500px !important;
    height: 100%;
    // max-height: 250px;
    // overflow: auto;
    // overflow-y: scroll;

    .leftside__contant {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 20px;
        // gap: 10px;
        // margin-bottom: 30px;

        .freeTrial_img {
            width: 300px;
            height: 170px;
            object-fit: cover;
            border-radius: 8px;
        }

        .age_text {
            color: #f0962c;
            font-family: Inter600;
            font-size: 12px;
            text-align: left;
        }

        .contant_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between !important;
            gap: 14px;
            margin-bottom: 10px;
            width: 100%;
        }

        .title {
            color: #333;
            font-family: Inter600;
            font-size: 16px;
            text-align: left;
            margin-bottom: 10px;

        }

        .price_text {
            color: #178fff;
            font-family: Manrope700;
            font-size: 18px;
            overflow: hidden;
            white-space: nowrap;
        }

        .descrption {
            color: #333;
            font-family: Inter400;
            font-size: 14px;
            text-align: justify;
            margin-bottom: 15px;
        }

        .date__filed_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            img {
                width: 24px;
                height: 24px;
                object-fit: contain;
                padding: 2px;
                background: rgb(250, 244, 236);
            }

            span {
                color: #333;
                font-family: Inter400;
                font-size: 14px;
                text-align: left;
            }
        }
    }

    .contant__wapper {
        display: block;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        padding: 10px 15px;

        .Order__Details {
            color: #FFAE4F;
            font-size: 18px;
            font-weight: 700;
        }

        .Order__amount {
            color: #333;
            font-size: 16px;
            font-weight: 800;
        }

        .left__contant {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            .date {
                color: #FFAE4F;
                font-size: 12px;
                font-family: Inter600;
            }

            .course {
                color: #3C3C3C;
                font-size: 14px;
                font-family: Inter600;
                margin-top: 7px;
                margin-bottom: 3px;
            }

            .session__count {
                color: rgba(51, 51, 51, 0.75);
                font-size: 12px;
                font-weight: 500;
            }
        }

        .right__contant {
            color: #333333BF;
            font-size: 13px;
            font-family: Inter500;
        }
    }
}

.Activities__bottom__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;

    .backBtn {
        width: 319px;
        height: 50px;
        border-radius: 23px;
        background: rgba(255, 174, 79, 0.12);
        color: #FFAE4F;
        font-size: 14px;
        font-weight: 600;
    }

    .paymentBtn {
        width: 319px;
        height: 50px;
        border-radius: 23px;
        background: #FFAE4F;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
    }

}

.hr-tag {
    margin: 1rem 20px !important;
}

.mobile__input {
    outline: none;
    padding: 3px 15px;
}

.camp_child_img_text {
    color: #333;
    font-family: Inter400;
    font-size: 14px;
    margin-top: 10px;
}

.camp_child_img_title {
    color: #333;
    font-size: 16px;
    font-weight: Manrope700 !important;
}

@media screen and (max-width: 720px) {
    .Activitiescheckout-container {
        overflow: hidden;
        margin: 0 !important;

        .billing-container {
            padding: 20px !important;

            .emailtext {
                max-width: 120px !important;
            }

            .inputfieldss {
                padding: 0px !important;
            }
        }

        .leftside__contant {
            margin: 0px !important;
        }

        .Course__Details__bottom__contant {
            margin: 0px !important;
        }

        .summary {
            padding: 20px !important;
        }
    }

    .Activities__OrderDetails__Dailoge {
        min-width: auto !important;
    }

    .paymentBtn {
        width: 100% !important;
    }
}