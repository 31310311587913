.tutor_card_carousel_wrapper {
  .tutor_card_wrapper {
    border-radius: 10px;
    background: #fff;
    margin-right: 12px;
  }

  .cover_image_wrap {
    position: relative;
    cursor: pointer;
  }

  .card_bg_img {
    cursor: grab;
    width: 269px;
    height: 100px !important;
    height: auto;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
  }

  .tutor_img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 2px solid #f9f9f9;
    border-radius: 50%;
    position: absolute;
    bottom: -19px;
    left: 20px;
  }

  .tutor_details_wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
    padding: 0px 20px 20px 20px;
  }

  .name_share_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .name_field {
    color: #000;
    font-family: Manrope700;
    font-size: 10.587px;
  }

  .location_exp_mode_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
  }

  .tutor_details_logo_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    white-space: nowrap;

    .tutor_details_logo {
      width: 12.101px;
      height: 9.076px;
      object-fit: contain;
    }
  }

  .location {
    color: #4e4e4e;
    font-family: Inter500;
    font-size: 9.07px;
    max-width: 115px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .share_fav_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    img {
      width: 13.017px;
      height: 11.532px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .charges {
    color: #178fff;
    font-family: Inter700;
    font-size: 14px;
  }

  .ratings {
    color: #333;
    font-family: Inter500;
    font-size: 9.7px;
  }
}

.tutor_carousel_title {
  color: #333;
  font-family: Inter500;
  font-size: 16px;
}

.arrow_nav_tile_contianer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  width: 100%;
}


.tutor_card_carousel_container{
  .arrow_navs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.arrow_navs_english{
  flex-direction: row !important;
}
.arrow_navs_arabic{
  flex-direction:row-reverse !important;
}
}


.left_arrow,
.right_arrow {
  cursor: pointer;
  width: 10px;
}

.disable_arrow {
  cursor: default;
  opacity: 0.3;
  color: #867f7f;
  width: 10px;
}

.tutor_card_carousel_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
  // width: calc(100% - 120px);
  width: 100%;
  overflow: hidden;

  .alice-carousel__stage-item {
    width: 281px !important;
  }

  .alice-carousel__wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    // min-height: 380px;
  }
}

.tutor_card_carousel_exploreAll__wapper {
  width: 100%;
  max-width: 280px;
  height: 218px;
  border-radius: 13px;
  background: #178fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .text {
    color: #fff;
    text-align: center;
    font-family: Manrope500;
    font-size: 20.409px;
  }

  .title {
    color: #fff;
    text-align: center;
    font-family: Manrope600;
    font-size: 40.017px;
  }
}