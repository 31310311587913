.TutorTrialSession_wrapper {
    width: 100%;
    padding: 20px;
    position: relative;

    .leftside__contant {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .freeTrial_img {
            width: 200px;
            height: 230px;
            object-fit: contain;
        }

        .title {
            color: #333;
            text-align: center;
            font-family: Inter600;
            font-size: 28px;
        }

        .descrption {
            color: rgba(51, 51, 51, 0.60);
            text-align: center;
            font-family: Inter500;
            font-size: 12px;
        }
    }

    .rightSide__Card {
        width: 100%;
        padding: 20px;
        border-radius: 13px;
        background: #EBF0F4;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .lable_title {
            color: #333;
            font-family: Manrope600;
            font-size: 14px;
        }

        .scrool__conatiner {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 260px;
            overflow: auto;
            overflow-y: scroll;
        }

        /* width */
        .scrool__conatiner::-webkit-scrollbar {
            width: 0px !important;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: transparent;
        }


        .trial_card {
            border-radius: 18px;
            background: #FFF;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            padding: 13px;

            .MemberBlueImg {
                width: 19px;
                height: 19px;
                object-fit: contain;
            }

            .date_time_wapper {
                display: flex;
                flex-direction: column;

                .date {
                    color: #333;
                    text-align: center;
                    font-family: Inter600;
                    font-size: 12px;
                }

                .time {
                    color: #878787;
                    text-align: center;
                    font-family: Inter500;
                    font-size: 10px;
                }
            }
        }
    }

    .ConfirmSessionBtn {
        border-radius: 42px;
        background: #178FFF;
        color: #F5F5F5;
        text-align: center;
        font-family: Inter600;
        font-size: 14px;
        margin: 1rem;
        width: 90%;
        padding: 14px;
    }

    .Btn__disable {
        opacity: 0.5 !important;
        cursor: default !important;
    }
}


@media screen and (max-width:600px) {
    .TutorTrialSession_wrapper {
        .leftside__contant {
            .freeTrial_img {
                height: 180px;
            }
            .title {
                font-size: 26px;
            }
            .descrption {
                font-size: 10px;
            }
        }
        .rightSide__Card {
            .lable_title {
                font-size: 12px;
            }
            .date_time_wapper {
                .date {
                    font-size: 10px;
                }

                .time {
                    font-size: 8px;
                }
            }
            .ConfirmSessionBtn {
                font-size: 12px;
            }
        }
    }
}