.View__on__map__container_school {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  padding: 7px 0px;

  .wapperDiv {
    border-radius: 7px;
    background: #faf5e3;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      width: 16.217px;
      height: 13.79px;
    }

    span {
      color: #875a04;
      font-size: 12px;
      font-family: Inter600;
    }
  }
}

.text11__school {
  color: #efae07;
  font-size: 17px;
  font-family: Manrope700;
}

.iconBtn__wapper__school {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 17px;

  .iconBtn {
    background-color: transparent;
    border: 1px solid rgba(239, 174, 7, 1) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    gap: 3px;
    padding: 5px 5px;
    outline: none;
  }

  span {
    font-size: 12px;
    font-family: Inter400;
    color: rgba(239, 174, 7, 1) !important;
  }
}

.Book-Slots-btn__school {
  background: linear-gradient(0deg, #efae07, #efae07);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 290px;
  border: none;
  border-radius: 28px;
  height: 50px;
  font-family: Manrope700;
  margin: 17px 0px;
}

.icons-gap {
  padding-right: 75px;
}

.bottom__contant__wapper__school {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  div {
    font-size: 14px;
    font-family: Inter500;
    text-align: left;
  }

  img {
    width: 21px;
    height: 20px;
    object-fit: contain;
  }

  .bottom__contant__left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }

  .bottom__contant__right {
    color: #333;
    font-size: 14px;
    font-family: Manrope700;
    .css-ja5taz-MuiTooltip-tooltip{
      color: red !important;
    }
  }
}

.School_description_contant {
  display: flex;
  flex-direction: column;
  margin-top: 1.4rem;

  .title {
    color: #333;
    font-family: Manrope700;
    font-size: 16px;
  }

  .description-content {
    color: #333;
    font-family: Inter400;
    font-size: 14px;
    width: 80%;
    text-align: justify;
  }
}

.School__personal__detailes__wapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4px 10px;
  margin-top: 10px;

  .detailes__wapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .left__side {
      display: flex;
      flex-direction: row;
      align-items: center;

      gap: 15px;

      img {
        width: 18px;
        height: 18.001px;
        object-fit: contain;
      }

      span {
        color: #333;
        font-family: Inter500;
        font-size: 16px;
      }
    }

    .link {
      color: #0285bd;
      font-family: Inter400;
      font-size: 14px;
      text-decoration-line: underline;
    }

    .text {
      color: rgb(51, 51, 51);
      font-family: Inter400;
      font-size: 14px;
      text-wrap: wrap;
      text-align: -webkit-right;
      // max-width: 300px;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }

    .number {
      color: #0285bd;
      font-family: Inter400;
      font-size: 14px;
    }
  }
}

.School__fess__structure__wapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 4px 10px;
  margin-top: 10px;

  .detailes__wapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left__side {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      img {
        width: 26px;
        height: 26px;
        object-fit: contain;
      }

      span {
        color: #333;
        font-family: Manrope700;
        font-size: 14px;
      }
    }

    .fees__wapper {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 2px;
    }

    .text {
      color: #333;
      font-family: Inter600;
      font-size: 12px;
    }

    .number {
      color: #333;
      font-family: Inter600;
      font-size: 14px;
    }
  }
}

.School__fess__per_Year__wapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 4px 10px;

  .detailes__wapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  img {
    width: 34px;
    height: 34px;
    object-fit: contain;
    cursor: pointer;
  }

  .light_text {
    color: #000;
    font-family: Manrope700;
    font-size: 14px;
  }

  .fees__wapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 2px;
  }

  .text {
    color: #333;
    font-family: Inter600;
    font-size: 12px;
  }

  .number {
    color: #333;
    font-family: Inter600;
    font-size: 14px;
  }
}

.school_join__now_wapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  .join__now_Btn {
    width: 100%;
    max-width: 312px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 52px;
    border: 2px solid rgba(23, 143, 255, 0.1);
    background: #efae07;
    color: #f9f9fb;
    font-family: Inter600;
    font-size: 18px;
    text-transform: uppercase;
  }
}

@media (max-width: 636px) {
  .iconBtn__wapper__school {
    display: flex;
    flex-direction: column;
  }

  .School_description_contant .description-content {
    width: 100%;
  }

  .school_join__now_wapper {
    .join__now_Btn {
      font-size: 16px;
    }
  }
}

@media (max-width: 436px) {
  .School__fess__structure__wapper .detailes__wapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
  }

  .School__personal__detailes__wapper .detailes__wapper .text {
    max-width: 120px;
  }
}
