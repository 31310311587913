.University__dashboard__container {
  font-family: Manrope600 !important;
  margin-top: 1rem;

  .title_container {
    display: flex;
    margin-bottom: 15px;
    gap: 10px;
  }

  .UniversityCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 60px;
    // padding-left: 63px;

    img {
      width: 23.122px;
      height: 19.818px;
    }

    .TutorImg__text {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }
  }

  .search_field_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    max-width: 582px;
  }

  .Dashboard__schoolCard_Img_wapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    width: 151px;
    padding: 0 15px;
    height: 60px;
    // margin-bottom: 10px;


    img {
      width: 23.122px;
      height: 19.818px;
    }

    .text {
      color: #000;
      font-size: 20px;
      font-family: Manrope700;
    }
  }

  .search_field:focus {
    outline: none;
  }

  .search_field_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 14px;
  }

  .search_field {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    font-size: 14px;
    width: 100%;
    height: 46px;
    padding: 14px 15px;
  }

  .search_field::placeholder {
    color: #adb5be;
    font-size: 14px;
    font-family: Inter500;
  }

  .search_button {
    padding: 16px 12px;
    cursor: pointer;
  }

  .search_button_icon_wrapper {
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    height: 48px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .search__autocomplete {
    width: 100% !important;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    border: none;
    position: relative;

    input::placeholder {
      font-size: 14px;
      font-family: Inter500;
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
      padding: 5px !important;
      border-radius: 15px !important;
    }

    .search_button {
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 0;
    }
    .ssearch_button_arab{
      right: initial !important;
      left: 4px;
    }
  }

  .University_map_view {
    border-radius: 12px;
    background: #fff;
    width: 100%;
    height: 330px;
    padding: 7px;
    position: relative;
    margin-top: 5px;
    box-shadow: 0px 4px 18px rgba(187, 187, 187, 0.25) !important;

    .University__viewMap {
      position: absolute;
      right: 18px;
      top: 33px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.45);
      box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
      backdrop-filter: blur(2.5px);
      padding: 10px;
      cursor: pointer;
      z-index: 501;

      span {
        color: #333;
        font-size: 13px;
        font-family: Manrope700;
      }
    }
  }

  .school__list__container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 450px;
  }

  /* width */
  .school__list__container::-webkit-scrollbar {
    width: 0px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .school__list__wapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 96%;
    /* margin: 10px 0; */
    padding: 12px;
    border-radius: 14px;
    background: #fff;
    margin-bottom: 10px;

    .university__leftside {
      img {
        width: 200px;
        height: 160px;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .university__rightside {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .university__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between !important;

        .name {
          color: #000;
          font-size: 16px;
          font-family: Manrope700;
          cursor: pointer;
        }

        .imageIcon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          margin-right: 7px;

          img {
            width: 20px;
            height: 17px;
            cursor: pointer;
          }
        }
      }

      .university__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }

        .location {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
          max-width: 330px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .marginTop {
        margin-top: 15px;
      }

      .university__ranking {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .text__black {
          color: #333333;
          font-size: 12px;
          font-family: Inter500;
        }

        .text__green {
          color: #FFC812;
          font-size: 12px;
          font-family: Inter600;
          max-width: 83px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .university__rating {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        gap: 12px;

        .text__green {
          color: #FFC812;
          font-size: 12px;
          font-family: Inter600;
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .text__black {
          color: #333333;
          font-size: 12px;
          font-family: Inter500;
        }
      }
    }
  }
}

.university__list__container{
  margin-top: 5px;

}

//mobileView app download
@media (max-width: 850px) {
  .University__dashboard__container .school__list__wapper .university__leftside img {
    width: 200px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
  }
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 3px 4px !important;
}

// * Filter__drp   /*
.Filter__drp_top_wapper__school {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  margin: 7px;
  gap: 5px;

  .left__icon {
    background-color: rgba(255, 200, 18, 0.12);
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }

  .title {
    color: #333;
    font-size: 14px;
    font-family: Inter600;
  }
}

.TutorCard_Filter_wapper {
  width: 100px;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  gap: 10px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);

  .FilterIcon {
    width: 13px;
    height: 13.949px;
    color: #333333;
    object-fit: contain;
  }

  .Filter__text {
    color: #333;
    font-size: 16px;
    font-family: Inter500;
  }
}

.Filter__drp__bottom_btn__school {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 5px;

  .resetBtn {
    border-radius: 29px;
    border: 1px solid #eebb12 !important;
    background-color: #fbfbfb;
    color: #eebb12;
    font-size: 12px;
    font-family: Inter400;
    width: 89px;
    height: 36px;
  }

  .applyBtn {
    border-radius: 29px;
    background: #eebb12;
    width: 89px;
    height: 36px;
    color: #fdfeff;
    font-size: 12px;
    font-family: Inter500;
  }
}

.filter_school_rightIcon {
  color: #b6b6b6 !important;
  font-size: 12px !important;
  height: 14px !important;
}

.filterActive_school_rightIcon {
  color: #eebb12 !important;
  font-size: 12px !important;
  height: 14px !important;
}

.filterActive_school {
  color: #eebb12 !important;
}

@media (max-width: 320px) {
  .University__dashboard__container .search_field_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 14px;
  }
}

@media (max-width: 768px) {
  .University__dashboard__container .school__list__wapper .university__rightside {
    .marginTop {
      margin-top: 0px;
    }

    .university__ranking {
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 990px) and (min-width: 500px) {
  .University__dashboard__container .school__list__wapper .university__rightside .university__ranking .text__green {
    color: #FFC812;
    font-size: 12px;
    font-family: Inter600;
    width: 100%;
    max-width: 55%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 990px) and (min-width: 1080px) {
  .University__dashboard__container {
    .school__list__wapper .university__rightside .university__location .location {
      max-width: 180px !important;
    }
  }
}


@media (min-width: 1420px) {
  .University__dashboard__container {
    .University_map_view {
      height: 68vh;
    }

    .university__list__container {
      // max-height: 68vh !important;
      max-height: 78vh;
    }

    .UniversityCard_Img_wapper {

      img {
        width: 26.122px;
        height: 26.818px;
      }

      .TutorImg__text {
        color: #000;
        font-size: 26px;
        font-family: Manrope700;
      }
    }

    .school__list__wapper .university__rightside .university__location .location {
      max-width: 400px !important;
    }

    .school__list__wapper .university__rightside {

      .university__ranking {
        .text__green {
          color: #FFC812;
          font-size: 12px;
          font-family: Inter600;
          max-width: 83%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }


    // .search_field_wrapper {
    //   margin-top: 10px;
    // }

  }
}


//mobileView app download
@media (max-width: 600px) {
  .school__list__wapper {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    width: 100% !important;
    margin: 10px 0;
    padding: 12px;
    border-radius: 14px;
    background: #fff;

    .university__leftside {
      img {
        width: 100px !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .university__rightside {
      display: flex;
      flex-direction: column;
      gap: 5px !important;
      width: 100%;

      .university__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between !important;

        .name {
          color: #000;
          font-size: 14px;
          font-family: Manrope700;
          cursor: pointer;
        }

        .imageIcon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          margin-right: 7px;

          img {
            width: 20px;
            height: 17px;
            cursor: pointer;
          }
        }
      }

      .university__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }

        .location {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
          max-width: 180px !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .marginTop {
        margin-top: 0px !important;
      }

      .university__ranking {
        display: none !important;
      }

      // .university__ranking {
      //   display: flex;
      //   flex-direction: row;
      //   flex-wrap: wrap;
      //   align-items: center;
      //   cursor: pointer;

      //   .text__black {
      //     color: #333333;
      //     font-size: 12px;
      //     font-family: Inter500;
      //   }

      //   .text__green {
      //     color: #FFC812;
      //     font-size: 12px;
      //     font-family: Inter600;
      //     max-width: 100px;
      //     overflow: hidden;
      //     white-space: nowrap;
      //     text-overflow: ellipsis;
      //   }
      // }

      .university__rating {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        gap: 12px;

        .text__green {
          color: #FFC812;
          font-size: 12px;
          font-family: Inter600;
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .text__black {
          color: #333333;
          font-size: 12px;
          font-family: Inter500;
        }
      }
    }
  }
}

