.ThemePark__dashboard__container {

    .Profile__contant__wapper {
        display: flex;
        flex-direction: row;
        gap: 50px;

        .profile__img {
            width: 112px;
            height: 112px;
            border-radius: 50%;
            object-fit: cover;
        }

        .Profile__rightside {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;

            .Profile_name {
                color: #000;
                font-family: Manrope700;
                font-size: 20px;
            }

            .Profile__box__wapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;

                .box__contant {
                    border-radius: 6.95px;
                    background: rgba(4, 214, 251, 0.05);
                    padding: 10px 17px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    img {
                        width: 16px;
                        height: 17px;
                        object-fit: contain;
                    }

                    span {
                        color: #04D6FB;
                        font-family: Inter600;
                        font-size: 14px;
                    }
                }
            }

            .Profile__priceRange__wapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 45px;

                .priceRange__contant {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    img {
                        width: 20px;
                        height: 20px;
                        object-fit: contain;
                    }

                    span {
                        color: #333;
                        font-family: Inter500;
                        font-size: 14px;
                    }
                }

                .Price_text {
                    color: #333;
                    font-family: Inter600;
                    font-size: 16px;
                }
            }

        }
    }

    .Description__wapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 1rem;

        .title {
            color: #333;
            font-family: Manrope700;
            font-size: 16px;
        }

        .description__contant {
            color: #333;
            font-family: Inter400;
            font-size: 14px;
            text-align: justify;
        }
    }

    .Event__curosal__wapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 200px;

        img {
            width: 95%;
            height: 112px;
            object-fit: cover;
            border-radius: 8px;
            cursor: grab;
        }

        span {
            color: #000;
            font-family: Inter600;
            font-size: 14px;
        }
    }

    .Event__curosal__wapper__active {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 200px;

        img {
            width: 95%;
            height: 112px;
            object-fit: cover;
            border-radius: 8px;
            cursor: grab;
            border: 5px solid #04D6FB !important;
        }

        span {
            color: #04D6FB;
            font-family: Inter600;
            font-size: 14px;
        }

    }

    .Highlight__wapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .contant {
            display: flex;
            flex-direction: row;
            gap: 15px;
        }

        .text {
            color: #333;
            font-family: Inter400;
            font-size: 16px;
        }
    }

    .bookTicket__wapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;

        .bookTicketBtn {
            border-radius: 52px;
            border: 2px solid rgba(23, 143, 255, 0.10);
            background: #04D6FB;
            width: fit-content;
            padding: 15px 50px;
            color: #F9F9FB;
            font-family: Inter600;
            font-size: 16px;
            outline: none;
        }
    }
}

.top__contant__wapper__ThemePark {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .top__PageBtn__icons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        cursor: pointer;

        .prev__icons {
            color: rgba(4, 214, 251, 1) !important;
        }

        .next__icons {
            color: rgba(4, 214, 251, 1) !important;
        }

        .icons__disable {
            color: rgba(18, 204, 146, 1) !important;
            opacity: 0.5;
            cursor: default !important;
        }

        .text__disable {
            color: #333;
            font-size: 14px;
            font-weight: 700;
            opacity: 0.5;
            cursor: default !important;
        }

        .prev__text {
            color: #333;
            font-size: 14px;
            font-family: Manrope700;
        }
    }


    .top__close__icons {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .expanded__icons {
            cursor: pointer;
            color: #333333 !important;
            font-size: 16px;
        }

        .close__icons {
            cursor: pointer;
            font-size: 16px;
            color: #333333 !important;
        }
    }

}