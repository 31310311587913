.homePage__footer__container {
    background-color: #F5F4F8;
    padding: 10px 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px 5px 0px 0px;
    //
    // position: fixed;
    // bottom: 0px;
    // width: 100vw;
    // z-index: 1000;

    .footer__nav_links {
        display: flex;
        flex-direction: row;
        align-items: center;

        .link_text {
            color: #636363;
            font-family: Manrope600;
            font-size: 14px;
            cursor: pointer;
            margin-right: 27px;
        }
    }

    .logo__icon {
        cursor: pointer;
    }

    .footer__text {
        color: #636363;
        font-family: Manrope600;
        font-size: 14px;
        margin-left: 20px;
    }
    .footer_text_arab{
        margin-left: initial !important;
        margin-right: 20px !important;
    }
}

@media screen and (max-width:600px) {
    .homePage__footer__container {
        justify-content: space-evenly!important;
        .footer__nav_links {
            margin-top: 7px;
            .link_text {
                font-size: 11px;
            }
        }
        .footer__text {
            font-size: 11px;
        }
    }
}