.Favourites__Taps {
  .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #333 !important;
  }
  

  .css-1mrn996.Mui-selected {
    color: #333 !important;
  }

  .css-axpu1l {
    border-radius: 7px 11px 0px 0px !important;
    background: #744fff !important;
    height: 5px;
  }

  .css-11yukd5-MuiTabs-indicator {
    border-radius: 7px 11px 0px 0px !important;
    background: #744fff !important;
    height: 5px;
  }
}
.Favourites_Taps_arab{
  direction: ltr !important;
}

.FavouritesList__container .MuiTabs-root .css-heg063-MuiTabs-flexContainer {
  background-color: none !important;
}

///*****Parent-Styles-Start*********///
.FavouritesList__container {
  width: 100%;
  margin-bottom: 2rem;
  // width: 90%;

  .header__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 97%;
    margin: 0% 1.5%;
    justify-content: space-between;
    .css-1vqochb{
      width: 70%;
    }
    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root{
      font-size: 12px !important;
      // text-transform: capitalize !important;
    }
    .rightSide__search {
      width: 30%;
      max-width: 180px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      .SettingSearch {
        width: 35px;
        height: 41px;
        box-sizing: border-box;
        border: 1px solid #f2f2f2;
        outline: none;
        border-radius: 6px;
        font-size: 14px;
        background-color: #f2f2f2;
        background-image: url("../../Assets//ProfileSettings//searchIcon.svg");
        background-position: 11px 12px;
        background-repeat: no-repeat;
        padding: 12px 20px 12px 17px;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
        cursor: pointer;
      }
  
      .SettingSearch:focus {
        width: 100%;
        padding: 12px 20px 12px 40px;
      }
    }
  }

  .body-tabpanel-divtag{
     width: 100%;
    .body-tabpanel-ptag{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .FavouritesList__Scroll_wapper {
        width: 100%;

        // height: 100vh;
        // height: auto;
        // max-height: 500px;
        // overflow: auto;
        // overflow-x: hidden;
        // overflow-y: scroll;
      }
    }
  }
  

  /* width */
  .FavouritesList__Scroll_wapper::-webkit-scrollbar {
    width: 0px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .css-m2al5c {
    margin-top: 0px !important;
  }


  

  .parent__contant__wapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5px;
    margin-bottom: 7px;

    img {
      width: 18.698px;
      height: 18.698px;
      border-radius: 50%;
      object-fit: contain;
    }

    span {
      color: #000;
      font-family: Manrope700;
      font-size: 11px;
    }
  }

  // university
  .university__list__wapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0;
    width: 100%;
    padding: 14px;
    border-radius: 9px;
    background: #fff;
    box-shadow: 0px 4px 5px 0px rgba(210, 210, 210, 0.25);
    margin-bottom: 10px;

    .university__leftside {
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .university__rightside {
      display: flex;
      flex-direction: column;
      gap: 9px;
      width: 100%;

      .university__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between !important;

        .name {
          color: #000000;
          font-size: 14px;
          font-family: Manrope700;
        }

        .imageIcon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }

      .university__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }

        .location {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .university__ranking {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .text__black {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }

        .text__green {
          color: #12cc92;
          font-size: 12px;
          font-family: Inter500;
        }
      }

      .university__rating {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        .year {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }

        .StarIcon {
          color: #fcb040 !important;
          font-size: 14px !important;
        }

        .points {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }
      }
    }
  }

  // tutor card
  .Tutor__Favourites__Card {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    margin: 10px 0;
    padding: 14px;
    border-radius: 9px;
    background: #fff;
    box-shadow: 0px 4px 5px 0px rgba(210, 210, 210, 0.25);

    .profile_image {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 10px;
      cursor: pointer;
    }

    .rightside_contant {
      display: flex;
      flex-direction: column;
      width: 100%;

      .user__Detailes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center !important;
        padding: 3px 10px;
        gap: 15px;

        .user__name {
          color: #000;
          font-size: 14px;
          font-family: Manrope700;
        }

        .user__icon__wrapper {
          display: flex;
          flex-direction: row;
          align-items: center !important;
          gap: 10px;

          img {
            height: 14px;
            width: 15px;
            cursor: pointer;
          }
        }
      }

      .user__Detailes__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 10px;
        gap: 10px;

        .grayIcons {
          width: 10px;
          height: 12px;
        }

        .graytext {
          color: #4e4e4e;
          font-size: 12px;
          font-family: Inter500;
        }
      }

      .user__Detailes__Curriculam {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        padding: 3px 10px;
        gap: 10px;

        .lable {
          color: #4e4e4e;
          font-size: 12px;
          font-family: Inter500;
        }
      }

      .user__Detailes__Course {
        padding: 3px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .Course {
          color: #178fff;
          font-size: 12px;
          font-family: Inter500;
        }

        .lable {
          color: #4e4e4e;
          font-size: 12px;
          font-family: Inter500;
        }

        img {
          width: 12px;
          height: 13px;
          cursor: pointer;
        }
      }

      .user__Detailes__rating {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 3px 10px;

        .title {
          color: #178fff;
          font-size: 14px;
          font-family: Inter600;
        }

        .StarIcon {
          color: #fcb040 !important;
          width: 18px;
          height: 17px;
        }

        .card__title {
          color: #333;
          font-size: 12px;
          font-family: Inter500;
        }
      }
    }
  }

  // School card
  .School__Favourites__Card {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    padding: 14px;
    margin: 10px 0;
    border-radius: 9px;
    background: #fff;
    box-shadow: 0px 4px 5px 0px rgba(210, 210, 210, 0.25);

    .profile_image {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 10px;
      cursor: pointer;
    }

    .rightside_contant {
      display: flex;
      flex-direction: column;
      width: 100%;

      .user__Detailes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center !important;
        padding: 3px 10px;
        gap: 15px;

        .user__name {
          color: #000;
          font-size: 14px;
          font-family: Manrope700;
        }

        .user__icon__wrapper {
          display: flex;
          flex-direction: row;
          gap: 10px;

          img {
            height: 14px;
            width: 15px;
            cursor: pointer;
          }
        }
      }

      .user__Detailes__Course {
        padding: 3px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .Course {
          color: #178fff;
          font-size: 12px;
          font-family: Inter500;
        }

        .lable {
          color: #4e4e4e;
          font-size: 12px;
          font-family: Inter500;
        }

        img {
          width: 12px;
          height: 13px;
          cursor: pointer;
        }
      }

      .user__Detailes__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 10px;
        gap: 10px;

        .grayIcons {
          width: 10px;
          height: 12px;
        }

        .lable {
          color: #4e4e4e;
          font-size: 12px;
          font-family: Inter500;
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  // Acrivity card
  .Acrivity__Favourites__Card {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    margin: 10px 0;
    padding: 14px;
    border-radius: 9px;
    background: #fff;
    box-shadow: 0px 4px 5px 0px rgba(210, 210, 210, 0.25);

    .profile_image {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 10px;
      cursor: pointer;
    }

    .rightside_contant {
      display: flex;
      flex-direction: column;
      width: 100%;

      .user__Detailes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center !important;
        padding: 3px 10px;
        gap: 15px;

        .user__name {
          color:#333333;
          font-size: 14px;
          font-family: Manrope700;
        }

        .user__icon__wrapper {
          display: flex;
          flex-direction: row;
          gap: 10px;

          img {
            height: 14px;
            width: 15px;
            cursor: pointer;
          }
        }
      }

      .user__Detailes__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 10px;
        gap: 10px;

        .grayIcons {
          width: 10px;
          height: 12px;
        }

        .graytext {
          color: #4e4e4e;
          font-size: 12px;
          font-family: Inter500;
        }
      }

      .user__Detailes__Slots_activities {
        padding: 2px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: fit-content;
        margin-bottom: -10px !important;

        .lable {
          border-radius: 5px;
          background: #faf3ec !important;
          padding: 6px 10px;
          color: #fcb040;
          font-size: 12px;
          font-weight: 500;
        }

        .Slots {
          color: #4e4e4e;
          font-size: 12px;
          font-weight: 500;
        }

        .SlotsYes {
          color: #04c270;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .user__Detailes__rating_Activities {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 3px 10px;

        .title {
          color: #ffae4f;
          font-size: 12px;
          font-family: Inter500;
        }

        .StarIcon {
          color: #fcb040 !important;
          width: 18px;
          height: 17px;
        }

        .card__title {
          color: #4e4e4e;
          font-family: Inter500;
          font-size: 12px;
        }
      }

      .user__Detailes__rating_themePark {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 3px 10px;

        .title {
          color: #04D6FB;
          font-size: 12px;
          font-family: Inter500;
        }

        .StarIcon {
          color: #04D6FB !important;
          width: 18px;
          height: 17px;
        }

        .card__title {
          color: #4e4e4e;
          font-family: Inter500;
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 1420px) {
  .FavouritesList__container {
    .FavouritesList__Scroll_wapper {
      max-height: 70vh !important;
    }
  }
}

///************Overall responsiuve style*********///

@media (max-width: 700px) {
  .header__container {
    /*TabView and Search wrap*/
    .css-1vqochb{
      width: 90% !important;
    }
    .rightSide__search {
      width: 100% !important;
      justify-content: flex-start !important;
      margin-top: 10px;
      margin-bottom: 0.8rem;
    }
  }
  .Favourites__Taps {
    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
      font-size: 70% !important;
    }
  
    .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
      font-size: 70% !important;
    }
  }
}


//**Below for change font size && **//
@media (max-width: 600px) {
    .FavouritesList__container {
      .header__container {
       margin: 0% 3.5% !important;
      }
      .user__Detailes .user__name {
        font-size: 80% !important;
      }
    }
    .user__Detailes__rating_themePark {
      .title{
        font-size: 80% !important;
      }
    }
    .user__Detailes__rating_Activities .title{
      font-size: 80% !important;
    }

    //////
    .university__name .name{
      font-size: 80% !important;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
    .university__rightside .university__ranking{
      font-size: 60% !important;
    }
    .university__location .location,
    .user__Detailes__location .graytext,
    .user__Detailes__Course .lable{
      font-size: 60% !important;
    }

    ///
    .Tutor__Favourites__Card .rightside_contant .user__Detailes__rating{
      font-size: 75% !important;
    }
    ///
    .drawe_outlet_wrapper .drawe_outlet_container{
      gap: 0% !important;
    }
    .drawe_outlet_wrapper .DraweOutletContainerLang_Arab{
      gap: 15px !important;
    }
  ////
  .FavouritesList__container .university__list__wapper{
    flex-direction: column !important;
    .university__leftside img{
      width: 100% !important;
    }
  }

  .FavouritesList__container .Tutor__Favourites__Card{
    flex-direction: column !important;
  .profile_image{
      width: 100% !important;
    }
  }

  .FavouritesList__container .School__Favourites__Card{
    flex-direction: column !important;
  .profile_image{
    width: 100% !important;
  }
  }

  .FavouritesList__container .Acrivity__Favourites__Card{
    flex-direction: column !important;
   .profile_image{
    width: 100% !important;
   }
  }
}

@media (min-width: 450px) and (max-width: 600px) {
  .FavouritesList__container{
    .university__list__wapper{
      .university__leftside img{
       height: 140px !important;
      }
    }
    .Tutor__Favourites__Card,
    .School__Favourites__Card,
    .School__Favourites__Card,
    .Acrivity__Favourites__Card{
      .profile_image{
        height: 140px !important;
       }
    }
  }
}

