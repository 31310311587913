.University__Detailes__rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;

    .Detailes__rating__contant {
        display: flex;
        gap: 6px;

        span {
            color: #333;
            font-size: 14px;
            font-weight: 500;
        }

        div {
            color: #12CC92;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.profile_image__map_university {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 7px;
}

.School_infowindow__text {
    color: #FCB040;
    font-size: 12px;
    font-weight: 500;
    width: 67px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}



.school_map_user__Detailes__location {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 3px 0px;
    gap: 10px;

    .lable {
        color: #4E4E4E;
        font-size: 12px;
        font-weight: 500;
    }

    .location_text {
        color: #4E4E4E;
        font-size: 12px;
        font-family: Inter500;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.school_map_Hover__Card {
    display: flex !important;
    padding: 5px !important;
    max-width: 300px !important;
    border-radius: 9px !important;
    background: #FFF !important;
    cursor: pointer;
    overflow: hidden;

    .profile_image {
        width: 73px !important;
        height: 100px !important;
        object-fit: cover;
    }

    .user__Detailes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center !important;
        gap: 15px;

        .user__name {
            color: #000;
            font-size: 16px;
            font-family: Manrope700;
        }

        .user__icon__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center !important;
            gap: 10px;

            img {
                height: 14px;
                width: 15px;
            }
        }
    }

    .curriculam_text {
        width: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }


    .school_map_card_over_width {
        width: 100%;
        padding: 3px 0px;
    }

}