.Universitysuccess-container {

    .paymentsuccessful__layout {
        display: flex;
        flex-direction: row !important;
        margin-top: 2.5rem;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 4px 15px 0px rgba(180, 180, 180, 0.25);

        .paymentsuccessful-left {
            width: 30%;
            border-radius: 20px 0px 0px 20px;
            background: #12CC92;

            .alignment3 {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 70%;
            }

            .img-tick {
                height: 70px;
                width: 70px;
                margin-bottom: 1rem;
            }

            .payment-text {
                font-size: 18px;
                font-family: Manrope700;
                color: #FFFFFF;
                text-align: center;
            }
        }

        .paymentsuccessfull-right {
            width: 70%;
            border-radius: 0px 20px 20px 0px;
            background: #FFF;
            padding: 20px 30px;
            background: linear-gradient(to bottom, #FFFFFF 50%, #F6F8FA 50%);

            .topContant {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 2rem;

                .consluting__wapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                }

                .consluting_Img {
                    height: 30px;
                    width: 30px;
                    object-fit: contain;
                }

                .left__contant {
                    color: #3C3C3C;
                    font-family: Inter600;
                    font-size: 14px;
                }

                .right__contant {
                    color: #12CC92;
                    font-size: 16px;
                    font-family: Manrope800;
                }
            }

            .course__wapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 1rem;
                gap: 10px;

                .course-detail-show{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                 .title {
                    color: rgba(51, 51, 51, 0.75);
                    font-size: 12px;
                    font-family: Manrope700;
                }
                .css-i4bv87-MuiSvgIcon-root{ 
                    width: 17px !important;
                    height: 17px !important;
                }
                .Icon {
                    color: #656566 !important;
                    cursor: pointer;
                  }
            
                  .IconInActive {
                    color: #656566 !important;
                    cursor: pointer;
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                  }
                }

                .title {
                    color: rgba(51, 51, 51, 0.75);
                    font-size: 12px;
                    font-family: Manrope700;
                }

                .course__Name {
                    color: #3C3C3C;
                    font-size: 14px;
                    font-family: Inter600;
                }

                .Sessions {
                    color: rgba(51, 51, 51, 0.75);
                    font-size: 12px;
                    font-family: Inter500;
                }

                .profile__wapper {
                    display: flex;
                    flex-direction: row;
                    gap: 14px;
                    align-items: center;
                    margin-top: 10px;

                    img {
                        width: 28px;
                        height: 27px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    .profile__name {
                        font-size: 14px;
                        color:#000000;
                        font-family:Manrope700;
                    }
                }
            }

            .transaction-container {
                margin-top: 2rem;
                padding: 20px 0px;

                .td-text {
                    font-size: 16px;
                    font-family: Inter600;
                    color: #3C3C3C;
                    margin-top: 3rem;
                }

                .transaction-deatiles__wapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 2rem;

                    .ti-container {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        label {
                            color: #3C3C3C;
                            font-size: 14px;
                            font-family: Inter600;
                        }

                        span {
                            color: #3C3C3C;
                            font-size: 14px;
                            font-family: Inter400;
                        }
                    }

                    .t12-container {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        gap: 8px;

                        label {
                            color: #3C3C3C;
                            font-size: 14px;
                            font-family: Inter600;
                        }

                        span {
                            color: #3C3C3C;
                            font-size: 14px;
                            font-family: Inter400;
                        }
                    }
                }

                .display-css {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 2rem;
                    gap: 20px;

                    .btn-share {
                        width: 232px;
                        height: 56px;
                        border-radius: 26px;
                        border: 1px solid #12CC92 !important;
                        background-color: #F6F8FA;
                        color: #333333;
                        font-size: 14px;
                        font-family: Inter600;
                        display: flex;
                        flex-direction: row !important;
                        align-items: center;
                        justify-content: center;
                        gap: 7px;

                        .share__icon {
                            color: #333 !important;
                            font-size: 14px !important;
                        }
                    }

                    .btn-done {
                        width: 232px;
                        height: 56px;
                        border-radius: 26px;
                        background: #12CC92;
                        color: #FFF;
                        font-size: 14px;
                        font-family: Inter600;
                    }
                }
            }
        }
    }
}




/* Show the Pop Data background window has blur css */
.overlay-ModelBackround {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    backdrop-filter: blur(5px);
}


.University__Payment__Success__Pop {

    .Payment__Pop__wapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .text {
            color: #333;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            max-width: 161px;
            margin: 14px 0;
        }
    }

    .Payment__bottom__btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 1rem;

        .backBtn {
            width: 133px;
            height: 46px;
            border-radius: 26px;
            background-color: #FFF;
            border: 1px solid #12CC92 !important;
            color: #12CC92;
            font-size: 12px;
            font-weight: 600;
        }

        .SuccessBtn {
            width: 133px;
            height: 46px;
            border-radius: 26px;
            background: #12CC92;
            color: #FFF;
            font-size: 12px;
            font-weight: 600;
        }
    }
}



//********For mediaquery*****//
@media (max-width: 600px) {
    .Universitysuccess-container {

        .paymentsuccessful__layout {
            display: initial;
            .paymentsuccessful-left{
                width: 100%;
                border-radius: 20px 20px 0px 0px;
                .alignment3 {
                    padding:10px 0px;
                    margin-top: 7%;
                }
            }

            .paymentsuccessfull-right {
                width: 100%;
                border-radius: 0px 0px 20px 20px;
                .transaction-container {
                    .transaction-deatiles__wapper {
                        gap: 20px;
                    }
                }

            }
        }

    }
}