.support__ChatRequest__dailog__box {

    .DialogTitle__wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .title {
            color: #000;
            font-family: Manrope600;
            font-size: 16px;
        }

        .closeBtn {
            color: #C3C3C3;
            font-family: Manrope600;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .DialogContent__wapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        padding: 0 14px;
        min-width: 250px;

        .discription {
            color: #000;
            font-family: Manrope600;
            font-size: 14px;
            text-align: justify;
        }

        .textareaInput {
            border-radius: 16px;
            background: #F4F4F5;
            color: #000;
            font-family: Manrope500;
            font-size: 12px;
            width: 100%;
            height: 100%;
            padding: 12px;
            min-height: 100px;
            outline: none;
            border: none;
        }

        .textareaInput::placeholder {
            color: #B5B5CF;
            font-family: Manrope500;
            font-size: 12px;
        }

        .SubmitBtn {
            border-radius: 16px;
            background: #744FFF;
            color: #FFF;
            font-family: Manrope600;
            font-size: 14px;
            width: 100%;
            padding: 10px;
        }

        .SubmitBtnDisable {
            border-radius: 16px;
            background: #744FFF;
            color: #FFF;
            font-family: Manrope600;
            font-size: 14px;
            width: 100%;
            padding: 10px;
            opacity: 0.5;
            cursor: default !important;
        }
    }
}