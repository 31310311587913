.Activities__Detaile__view {
    display: block;
    max-width: 1500px;
    margin: 40px auto;
    margin-bottom: 5rem;

    .title_header {
        color: #000 !important;
        font-size: 16px !important;
        font-family: Manrope700 !important;
        margin-bottom: 10px;
    }

    .text_contant {
        font-size: 14px !important;
        font-family: Inter400 !important;
        color: #000 !important;
        word-break: break-all;
    }

    .text_contant_bold {
        font-size: 14px !important;
        font-family: Inter600 !important;
        color: #000 !important;
    }

    .description__contant {
        font-size: 14px !important;
        font-family: Inter400 !important;
        color: #000 !important;
        margin-top: 0px !important;
        margin-bottom: 15px !important;
    }

    .contant_wrapper {
        margin-bottom: 20px;
    }

    .card_bg_img {
        width: 269px;
        height: auto;
        object-fit: cover;
        border-radius: 10px;
        padding: 0 5px;
    }

    // .tittle-div {
    //     border-radius: 15px;
    //     max-width: 185px;
    //     height: 60px;
    //     padding: 10px;
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     background: #f9f9fb;
    //     box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.25);
    //     width: 171px;
    // }

    .activities_Img_wapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 200px;
        height: 60px;
        border-radius: 15px;
        background: #fff;
        padding: 5px 15px;
        cursor: pointer;

        .activities__text {
            color: #000;
            font-size: 20px;
            font-family: Manrope700;
        }

        .back-img {
            cursor: pointer;
            margin-right: 2px;
        }

        .back-img_arb {
            cursor: pointer;
            margin-right: 2px;
            rotate: 180deg;
        }
    }


    .user__Detailes__Education {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 7px 0px;
        gap: 10px;

        .grayIcons {
            width: 12px;
            height: 14px;
        }

        .Bluetext {
            color: #F0962C;
            font-size: 14px;
            font-family: Inter500;
        }

        .graytext {
            color: #333;
            font-size: 14px;
            font-family: Inter500;
        }

        .StarIcon {
            color: #FCB040 !important;
            width: 18px;
            height: 17px;
        }
    }

    .user__Detailes__Grade {
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;

        .lable {
            color: #4E4E4E;
            font-size: 14px;
            font-family: Inter500;
        }

        img {
            width: 14px;
            height: 16px;
        }
    }

    // .text-tittle {
    //     font-size: 16px;
    //     font-family: Manrope700;
    //     text-align: left;
    //     margin-left: 4px;
    // }


    // .back-img {
    //     margin-right: 10px;
    //     cursor: pointer;
    // }

    // .tutor-icon {
    //     margin-right: 5px;
    // }

    .View__on__map {
        background-color: rgba(250, 244, 236, 1) !important;
        border-radius: 6.95px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        gap: 10px;
        cursor: pointer;
        max-width: 200px;

        img {
            width: 18px;
            height: 18px;
            transform: rotate(0.183deg);
        }

        span {
            color: #FFAE4F;
            font-family: Inter600;
            font-size: 14px;
        }
    }

    .tutorlist-conatiner {
        padding: 25px;
        height: 100%;
        box-shadow: 0px 4px 39px 0px rgba(178, 178, 178, 0.25);
        border-radius: 17px;
        background: #FFF;

        .display-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // gap: 8px;

            .user__Detailes__Grade__Activities {
                padding: 10px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                img {
                    width: 18px;
                    height: 18px;
                }

                span {
                    color: rgba(255, 174, 79, 1);
                    font-size: 14px;
                    font-family: Inter600;
                }

                .Grade__Activities {
                    background-color: rgba(250, 244, 236, 1) !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    gap: 10px;
                }


            }

            .user__Detailes__Education_Activities {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 5px;
                margin-bottom: 10px;

                .graytext {
                    font-size: 14px;
                    font-family: Inter500;
                    text-align: left;
                    color: rgba(51, 51, 51, 1);
                }

                .StarIcon {
                    color: #FCB040 !important;
                }
            }

            .ClockIcon_detailes {
                width: 18px;
                height: 18px;
                object-fit: contain;
            }

            .iconBtn__wapper {
                display: flex;
                flex-direction: row;
                gap: 7px;
                margin-bottom: 10px;

                .iconBtn {
                    background-color: transparent;
                    border: 1px solid #FFAE4F !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 28px;
                    gap: 5px;
                    padding: 10px 15px;
                    outline: none;
                }

                span {
                    font-size: 12px;
                    font-family: Inter400;
                    color: #FFAE4F !important;
                }
            }

            .Book-Slots-btn {
                background: linear-gradient(0deg, #FFAE4F, #FFAE4F);
                color: #FFF;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 290px;
                border: none;
                outline: none;
                border-radius: 28px;
                height: 50px;
                font-family: Inter600;
                margin: 10px 0px;
                font-size: 16px;
            }
        }

        .courses-div {
            padding: 2px 8px;
            border-radius: 6.95px;
            background: #FBF6F0;
            margin: 0;
            cursor: pointer;
            display: flex;
            padding: 6px;
            justify-content: center;
            align-items: center;
            gap: 6px;

            img {
                width: 28px;
                height: 18px;
                transform: rotate(0.183deg);
                object-fit: contain;
            }

            .coursetxt {
                color: #FFAE4F;
                font-size: 14px;
                font-family: Inter600;
            }
        }

        .courses-Active {
            border-radius: 7px;
            margin: 0;
            background-color: #F0962C;
            display: flex;
            padding: 6px;
            justify-content: center;
            align-items: center;
            gap: 6px;

            img {
                width: 28px;
                height: 18px;
                transform: rotate(0.183deg);
                object-fit: contain;
            }

            .coursetxt {
                color: #fff;
                font-size: 14px;
                font-family: Inter600;
            }
        }

        .Facility_needed_Activities {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            ul {
                list-style: none;
            }

            ul li::before {
                content: "\25A0";
                color: #F0962C;
                font-weight: 800;
                font-size: 0.7em;
                display: inline-block;
                width: 1em;
                height: 1em;
                margin-left: -2em;
                margin-right: 1em;
            }

            li {
                font-size: 14px !important;
                font-family: Inter400 !important;
                color: #000 !important;
                text-align: left;
                margin-bottom: 10px
            }
        }

        .Facility_needed_Activities_arb {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            ul {
                list-style: none;
            }

            ul li::before {
                content: "\25A0";
                color: #F0962C;
                font-weight: 800;
                font-size: 0.7em;
                display: inline-block;
                width: 1em;
                height: 1em;
                margin-left: 1em;
                margin-right: 1em;
            }

            li {
                font-size: 14px !important;
                font-family: Inter400 !important;
                color: #000 !important;
                text-align: left;
                margin-bottom: 10px
            }
        }

        .contact_us_wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            margin: 0px;
            cursor: pointer;
            margin-bottom: 10px;

            img {
                height: 32px;
                width: 32px;
                object-fit: contain;
            }

            .text_call {
                color: #F0962C;
                font-size: 16px;
                font-family: Manrope700;
            }

            .number_text {
                font-size: 14px;
                font-family: Inter400;
                color: #333;
            }


        }

        .display-div1 {
            width: 45%;
            height: 66px;
        }

        .display-div2 {
            width: 45%;
            height: 66px;
        }


        .tutor-image {
            width: 170px;
            height: 170px;
            border-radius: 50%;
            object-fit: cover;
            margin-top: 30px;
            margin-bottom: 15px;
        }

        .img-size {
            width: 14%;
        }

        .text14 {
            color: #000;
            font-size: 20px;
            font-family: Manrope700;

        }

        .text10 {
            font-size: 14px;
            font-family: Inter400;
            margin-bottom: 10px;
            word-break: break-all;
        }

        .text_Price {
            font-size: 16px !important;
            font-family: Manrope600;
            color: #333;
        }

        .text11 {
            color: #F0962C;
            font-size: 17px;
            font-family: Manrope700;
        }

        .text12 {
            font-size: 14px;
            font-family: Inter400;
            margin-bottom: 10px;
        }

        .text13 {
            font-family: Inter600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #2396F2;
            white-space: nowrap;
        }

        .select-css {
            border: 0;
        }

        .display-container2 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 14px;
            margin-bottom: 20px;
        }

        .userdetails-div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .userdetails-div2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
        }

        .img-size5 {
            width: 14px;
            height: 14px;
        }

        .img-overall {
            padding: 4px;
            display: flex;
            flex-direction: row;
            gap: 7px;
        }

        // .selectcourse-div {
        //     color: #000;
        //     font-size: 16px;
        //     font-family: Manrope700;
        //     margin-bottom: 15px;

        // }

        .alignment1 {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }

        .display-container5 {
            position: relative;
            margin-bottom: 20px;

            .card_bg_img {
                width: 269px;
                height: 200px !important;
                object-fit: cover;
                border-radius: 12px;
                padding: 0 5px;
            }

            .left_arrow {
                position: absolute;
                left: 9px;
                top: 63px;
                z-index: 1000;
                border-radius: 24px;
                background: rgba(240, 240, 240, 0.27);
                backdrop-filter: blur(1.3333333730697632px);
                color: #f0f0f045 !important;
            }

            .right_arrow {
                position: absolute;
                right: 9px;
                top: 63px;
                z-index: 1000;
                border-radius: 24px;
                background: rgba(240, 240, 240, 0.27);
                backdrop-filter: blur(1.3333333730697632px);
                color: #f0f0f045 !important;
            }

            .service_card_container {
                width: 240px;
                height: 100%;
            }

            .service_card {
                width: 240px;
                height: 100%;
            }

            .slider-img {
                width: 230px;
                height: 160px !important;
                object-fit: cover;
                border-radius: 5px;
            }

            .alice-carousel {
                direction: ltr !important;
            }
        }

        .display-container5_arb {
            position: relative;
            margin-bottom: 20px;

            .card_bg_img {
                width: 269px;
                height: 200px !important;
                object-fit: cover;
                border-radius: 12px;
                padding: 0 5px;
            }

            .left_arrow {
                position: absolute;
                left: 9px;
                top: 63px;
                z-index: 1000;
                border-radius: 24px;
                background: rgba(240, 240, 240, 0.27);
                backdrop-filter: blur(1.3333333730697632px);
                color: #f0f0f045 !important;
            }

            .right_arrow {
                position: absolute;
                right: 9px;
                top: 63px;
                z-index: 1000;
                border-radius: 24px;
                background: rgba(240, 240, 240, 0.27);
                backdrop-filter: blur(1.3333333730697632px);
                color: #f0f0f045 !important;
            }

            .service_card_container {
                width: 240px;
                height: 100%;
            }

            .service_card {
                width: 240px;
                height: 100%;
            }

            .slider-img {
                width: 230px;
                height: 160px !important;
                object-fit: cover;
                border-radius: 5px;
            }

            .alice-carousel {
                direction: rtl !important;
            }
        }

        // .display-container5 {
        //     cursor: pointer;
        //     margin-bottom: 20px;
        //     width: 100%;
        //     display: flex;
        // }

        .slider-container {
            width: 100%;
            overflow: hidden;
            position: relative;
        }

        /* Make the first slide visible */
        .slide:first-child {
            display: block;
        }

        .title {
            color: #333;
            font-size: 16px;
            font-family: Manrope700;
            margin-bottom: 10px;
        }

        .para-content {
            font-size: 14px !important;
            font-family: Inter500;
            color: #929292;
            margin-bottom: 15px;
        }

        .custom_cursor {
            display: flex;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 50px;
            height: 50px;
            background: rgba(255, 255, 255, 0);
            backdrop-filter: blur(14.5217px);
            border: 1px solid rgb(255, 255, 255);
            pointer-events: none;
            border-radius: 50%;
            z-index: 1500;
            opacity: 1;
            transition: opacity 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s,
                background 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
        }

        .alice-carousel__stage {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            transform-style: flat;
            -webkit-transform-style: flat;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
        }



        .dums-css {
            width: 35%;
        }

        .overall-details {
            display: flex;
            flex-direction: row;
            width: 78%;
            gap: 20%;
            flex-wrap: wrap;
        }
    }

    .bottom__contant__wapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        div {
            font-size: 14px;
            font-family: Inter400;
            text-align: left;
        }

        img {
            width: 18px;
            height: 18px;
            object-fit: cover;
        }

        .bottom__contant__left {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
        }
    }

    .Amenities__container {
        background: none;
        padding: 0px;

        // .title {
        //   margin-bottom: 15px;
        // }

        .Amenities__wapper {
            display: flex;
            flex-direction: row;
            gap: 15px;
            margin: 0px 0px 10px 0px;

            img {
                width: 20px;
                height: 20px;
                object-fit: contain;
            }

            .text {
                font-size: 14px;
                font-family: Inter400;
                color: #333;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .Activities__Detaile__view {
        overflow: hidden;
        margin: 0 !important;

    }
}