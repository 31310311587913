.ThemePark__HomePage {
    // margin-top: 55px;
    background: #f9f9f9;
    user-select: none !important;
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    .banner_swipper {
        .swiper-pagination {
            background: #fff;
            border-radius: 10px;
            justify-content: center;
            left: 50% !important;
            height: 16px;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            transform: translateX(-50%);
            width: auto;
            z-index: 1;
        }

        // .banner_swipper-arab{
        //     .swiper-pagination {
        //         left: initial !important;
        //         right: 26px !important;
        //     }
        // }

        .swiper-pagination-bullet {
            width: 7px !important;
            height: 7px !important;

        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
            color: #8C92A3 !important;
            font-size: 8px !important;
        }

        .swiper-control {
            position: absolute;
            bottom: 16px;
            transform: translateX(-50%);
            left: 50%;
            z-index: 1;
        }

        .swiper-control-arab {

            .swiper-button-prev,
            .swiper-rtl .swiper-button-next {
                left: 9px !important;
                rotate: 182deg !important;
            }

            .swiper-button-next,
            .swiper-rtl .swiper-button-prev {
                rotate: 179deg !important;
                right: 12px !important;
            }
        }

        .swiper-pagination-bullet.swiper-pagination-bullet-active {
            width: 20px !important;
            border-radius: 10px;
        }

        // .swiper-pagination {
        //     padding: 4px;
        //     background: rgb(255, 255, 255);
        //     border-radius: 20px;
        // }

        // .swiper-pagination-bullet.swiper-pagination-bullet-active {
        //     background: #AECED3;
        //     width: 15px !important;
        //     border-radius: 5px;
        //     height: 6px;
        //     bottom: 1px;
        // }

        // .swiper-pagination-bullet{
        //     width: 8px;
        //     height: 8px;
        //     border-radius: 50%;
        // }
    }

    .banner_image {
        width: 100%;
        // height: calc(100vh - 50px);
        object-fit: cover;
        // object-fit: fill;
        height: 80vh;
    }

    .ThemePark__second__conatant {
        position: relative;
        height: 100%;
        width: 100%;

        .container {
            position: relative;
            z-index: 1;
        }

        .background_circle_1 {
            content: "";
            position: absolute;
            top: 0;
            right: 70%;
            bottom: 85%;
            left: 0%;
            z-index: 0;
            border-radius: 50%;
            opacity: 0.5;
            background: radial-gradient(circle, #04D6FB 0, #04D6FB 50%, #fff 100%);
            filter: blur(250px);
        }

        .background_circle_2 {
            content: "";
            position: absolute;
            top: 20%;
            right: 0%;
            bottom: 60%;
            left: 75%;
            z-index: 0;
            border-radius: 50%;
            opacity: 0.5;
            background: radial-gradient(circle, #04D6FB 0, #04D6FB 50%, #fff 100%);
            filter: blur(250px);
        }

        .background_circle_3 {
            content: "";
            position: absolute;
            top: 65%;
            right: 70%;
            bottom: 10%;
            left: 0%;
            z-index: 0;
            border-radius: 50%;
            opacity: 0.5;
            background: radial-gradient(circle, #04D6FB 0, #04D6FB 50%, #fff 100%);
            filter: blur(250px);
        }

        .data__conatiner {
            display: flex;
            align-items: center;
            // justify-content: center;
            margin: 40px auto;
            justify-content: space-between;

            .theme_three_card {
                position: relative;
                width: 32%;
                display: flex;
                flex-direction: column;
                margin-right: 20px;
                height: 540px;
                border-radius: 12px;

                .css-o69gx8-MuiCardMedia-root {
                    height: 100% !important;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .title-heading {
                color: #195871;
                font-family: Manrope700;
                font-size: 41.322px;
                line-height: 56.45px;
                /* margin-top: 15px; */
                position: absolute;
                top: -60px;
            }

            .theme-three-card-arab {
                margin-right: initial !important;
                margin-left: 20px !important;
            }

            .discount {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border-left: 100px solid transparent;
                border-bottom: 100px solid #fff;
                transform: rotate(270deg);
            }

            .discount-label {
                position: absolute;
                top: 12px;
                right: 12px;
                color: #FF4D57;
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;

                img {
                    width: 14px;
                    position: relative;
                    bottom: 1px;
                    margin-left: 2px;
                }

                span {
                    display: block;
                    text-align: end;
                }
            }

            // .theme_three_card::before{
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     width: 0;
            //     height: 0;
            //     border-left: 20px solid transparent; /* Adjust the size of the triangle */
            //     border-bottom: 20px solid #ff0000; /* Set the color of the triangle */

            // }
        }

        .theme_three_card .css-8pbkej-MuiCardContent-root {
            padding: 16px 16px 0px 16px !important;
        }

        .theme_three_card .css-i4bv87-MuiSvgIcon-root {
            color: #DCDCDC !important;
        }

        .card_map_icon {
            // margin-top: -6px;
            font-family: Inter500;
            font-size: 16px;
            color: #B2B4B5;
            margin-bottom: 5px;
            line-height: 25px;

            margin-left: -4px;
            max-width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            svg {
                width: 16px;
                position: relative;
                bottom: 3px;
                margin-right: 5px;
                color: #B2B4B5 !important;
                left: 3px;
            }

        }

        .hover_content {
            font-size: 14px;
            font-family: Inter400;

            .booking_button {
                color: #fff;
                padding: 10px;
                border-radius: 10px;
                background-color: rgba(178, 180, 181, 0.5);
                width: 100%;
            }


            span {
                margin-right: 5px;
                color: #B2B4B5;
            }

            .showmore {
                display: inline-block;
                // margin-left: 5px;

                font-weight: 600;
            }

        }

        .theme_rating_inc {
            font-size: 16px;
            font-family: Inter500;
            display: flex;
            justify-content: space-between;

            .css-1c99szj-MuiRating-icon {
                color: rgba(255, 255, 255, 0.3);
            }

            .theme_card_head {
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 2px;
            }

            .theme-share-like {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                img {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    padding: 5px;
                    border-radius: 50%;
                    background-color: rgba(178, 180, 181, 0.3);

                }
            }

            .rating_count {
                position: relative;
                bottom: 5px;
            }
        }

        @media only screen and (max-width: 700px) {
            // .theme_rating_inc {
            //     display: block !important;
            // }
        }

        @media only screen and (max-width: 768px) {
            .theme_card_head {
                font-size: 0.8rem;
            }

            .theme_three_card {
                width: 100%;
                margin-right: 0;
            }

            .theme_three_card .css-8pbkej-MuiCardContent-root {
                padding: 12px !important;
            }
        }


        .Trending__wapper {
            .left__wapper {
                margin-top: 50px;

                .trending_icon {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 10px;
                    background-color: rgba(2, 219, 238, 0.16);
                    width: fit-content;
                    border-radius: 10px;
                }

                .Trending__text {
                    color: #22606D;
                    font-family: Inter600;
                    font-size: 14px;
                }

                .title {
                    color: #195871;
                    font-family: Manrope700;
                    font-size: 41.322px;
                    line-height: 56.45px;
                    margin-top: 15px;
                }

                .desctiption {
                    color: #333;
                    font-family: Inter500;
                    font-size: 16px;
                    width: 70%;
                    margin-top: 15px;
                    margin-left: 5px;
                }
            }

            .rightSide_card_corosal {
                display: flex;
                flex-direction: column;
                gap: 10px;
                cursor: grab;

                .coverImg {
                    // height: 200px;
                    height: 246px;
                    border-radius: 10px;
                    object-fit: cover;
                    cursor: pointer;
                }

                // .coverImg {
                //     width: 266.259px;
                //     height: 179.602px;
                //     object-fit: cover;
                //     border-radius: 12px;
                // }

                .name {
                    color: #000;
                    font-family: Manrope700;
                    font-size: 20px;
                    max-width: 200px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 28.4px;
                }

                .location__wapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;

                    img {
                        width: 10px;
                        height: 14px;
                    }

                    .location {
                        color: #4E4E4E;
                        font-family: Inter500;
                        font-size: 14px;
                        max-width: 200px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 14px;
                    }
                }

                .rating__wapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 3px;
                    cursor: pointer;

                    svg {
                        position: relative;
                        bottom: 1px;
                    }

                    .number {
                        color: #333;
                        font-family: Inter500;
                        font-size: 12px;
                        line-height: 14px;

                    }

                    .review {
                        color: #7E7C86;
                        font-family: Inter500;
                        font-size: 12px;
                        line-height: 14px;

                    }
                }

                .Amount {
                    color: #01B4D3;
                    font-family: Inter600;
                    font-size: 20px;
                }

            }

            .trending_swipper {
                .swiper-wrapper {
                    padding-bottom: 40px !important;
                }

                .swiper-pagination {
                    text-align: start !important;
                    width: auto !important;
                    left: 15px;
                    height: 23px;
                }

                .swiper-pagination-bullet-active {
                    width: 20px !important;
                    border-radius: 10px !important;
                }

                .swiper-control {
                    position: relative;
                    bottom: 17px;
                    width: 100%;
                }

                .swiper-button-prev {
                    left: 0px;
                }

                .swiper-button-next:after {
                    font-size: 10px !important;
                    margin-left: 15px !important;
                    color: #8C92A3 !important;
                    font-weight: 700 !important;
                }

                .swiper-button-prev:after {
                    font-size: 10px !important;
                    margin-right: 20px !important;
                    color: #8C92A3 !important;
                    font-weight: 700 !important;
                }
            }

            /*For Arabic direction*/
            .banner_swipper-arab {
                .swiper-button-prev {
                    left: 0px;
                    right: 141px;
                }

                .swiper-pagination {
                    left: initial !important;
                    right: 26px !important;
                }
            }


        }
    }

    .splash__container {
        margin: 50px auto;

        .title__wapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            // gap: 10px;

            .title {
                color: #333;
                font-family: Inter500;
                font-size: 24px;
            }

            .arrow__splash {
                color: #333 !important;
                cursor: pointer;
            }

            .arrow-splash-arab {
                rotate: 181deg;
            }
        }

        // Your SCSS code for the splash cards

        .splash__card__wapper {
            display: flex;
            flex-direction: row;
            align-items: start;
            gap: 15px;

            @media (max-width: 768px) {
                // flex-direction: column;
                // align-items: stretch;
            }
        }

        .splash__card__conatiner {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            max-width: 35%;
            cursor: pointer;

            .card__img {
                width: 100% !important;
                height: 200px;
                // width: 240px;
                object-fit: cover;
                border-radius: 10px;
            }

            .card__contant__wapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                width: 100%;
            }

            .text__contant {
                color: #000;
                font-family: Manrope500;
                font-size: 14px;
            }


        }
    }

    .Gallary__container {
        position: relative;

        .container {
            margin: 65px auto;
            position: relative;
            z-index: 1;
        }

        .title {
            color: #000;
            font-family: Manrope700;
            font-size: 32px;
        }

        .Gallary__Wapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            border-radius: 23px;
            margin: 0;
            padding: 0;
            position: relative;

            .layer {
                flex: 1;
                position: relative;
            }


            .Button__wapper {
                border-radius: 50px;
                background: rgba(24, 24, 24, 0.60);
                backdrop-filter: blur(3.5px);
                display: inline-flex;
                padding: 10px 32px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #EBEBEB;
                text-align: center;
                font-family: Manrope700;
                font-size: 20px;
            }

            .Gird1Btn {
                position: absolute;
                left: 5%;
                bottom: 20px;
            }

            .Gird2Btn {
                position: absolute;
                left: 4%;
                top: 37%;
            }

            .Gird3Btn {
                position: absolute;
                left: 4%;
                bottom: 20px;
            }

            .Gird4Btn {
                position: absolute;
                left: 4%;
                top: 37%;
            }

            .Gird5Btn {
                position: absolute;
                left: 4%;
                bottom: 20px;
            }


            .Gird1 {
                height: 600px;
                width: 100%;
                object-fit: cover;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                cursor: pointer;
            }

            .Gird1_arb {
                height: 600px;
                width: 100%;
                object-fit: cover;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                cursor: pointer;
            }

            .Gird2 {
                height: 300px;
                width: 100%;
                object-fit: cover;
                cursor: pointer;
            }

            .Gird3 {
                height: 300px;
                width: 100%;
                object-fit: cover;
                cursor: pointer;
            }

            .Gird4 {
                height: 300px;
                width: 100%;
                object-fit: cover;
                border-top-right-radius: 20px;
                cursor: pointer;
            }

            .Gird4_arb {
                height: 300px;
                width: 100%;
                object-fit: cover;
                border-top-left-radius: 20px;
                cursor: pointer;
            }

            .Gird5 {
                height: 300px;
                width: 100%;
                object-fit: cover;
                border-bottom-right-radius: 20px;
                cursor: pointer;
            }

            .Gird5_arb {
                height: 300px;
                width: 100%;
                object-fit: cover;
                border-bottom-left-radius: 20px;
                cursor: pointer;
            }
        }
    }


    .Review__conatiner {
        margin: 5rem auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;


        .StarIcon {
            font-size: 40px !important;
        }

        .discription {
            color: #536177;
            text-align: center;
            font-family: Manrope600;
            font-size: 23.6px;
            max-width: 70%;
        }

        .review__text {
            color: #536177;
            text-align: center;
            font-family: Manrope400;
            font-size: 23.6px;
        }
    }

    .Review-conatiner-arab {
        .css-15befwp-MuiRating-root {
            text-align: initial !important;
        }
    }

    .womenImg {
        width: calc(100vw - 6px);
        opacity: 0.8;
    }

    .gradient-div {
        position: relative;
        width: 100%;
        height: calc(100vh - 74px);
        /* Set your desired height */
        background-image: url('../../Assets/ThemePark/womenImg-svg.webp');
        background-size: cover;
        background-position: center;
        // background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .gradient-div:before,
    .gradient-div:after {
        content: '';
        /* This is necessary for pseudo-elements */
        position: absolute;
        width: 100%;
        height: 150px;
        /* Set the height of your gradient overlay */
    }

    .gradient-div:before {
        top: 0;
        left: 0;
        // background: linear-gradient(to bottom, #F2F5FC 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.0) 100%);
        background: linear-gradient(to bottom, rgba(249, 249, 249, 1) 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 100%)
    }

    .gradient-div:after {
        bottom: 0;
        left: 0;
        background: linear-gradient(to top, #F2F5FC 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.0) 100%);
    }

    // .gradient-overlay {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 150px; /* Set the height of your gradient overlay */
    //     background: linear-gradient(to bottom, #F2F5FC 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.0) 100%);
    // }

    // .swiper-button-next{
    //     left: 116px !important;
    // }
}


.search-filter-container {
    display: flex;
}

.theme-filter-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.theme-filter {
    border: 1px solid #B8B8B8;
    border-radius: 15px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: absolute;
    // left: 75%;
    cursor: pointer;

    span :first-child {
        margin-right: 5px;
    }
}

/*themepage search field*/
.search-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
    height: 60px;

    .search__autocomplete {
        width: 100% !important;
        max-width: 482px !important;
        border-radius: 15px;
        height: 56px;
        background: #F3F5F5;
        // box-shadow: 0px 16px 20px 0px rgba(210, 210, 210, 0.35);
        border: none;
        position: relative;

        input::placeholder {
            // color: #ADB5BE;
            font-size: 14px;
            font-family: Inter500;
        }

        input {
            padding: 12px 0 !important;
            padding-left: 15px !important;
            padding-right: 35px !important;

        }

        .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
            padding: 5px !important;
            border-radius: 15px !important;
        }

        .search_Icon_button {
            // padding: 8px 12px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 16px;
        }
    }

    .search_box_container {
        position: absolute;
        top: 0;
        margin-top: 45px;

        .auto-complete-container {
            position: relative;
            z-index: 1111111;
            min-width: 516px;
            // max-width: 1000px;
            max-height: 200px;
            height: 100%;
            overflow: auto;
            overflow-y: scroll;
            border-radius: 20px;

            // p {
            //     min-height: 48px;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            // }
        }

        .search_box {
            width: 516px;


        }
    }

    .search_box-arab {
        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
            padding-left: 14px !important;
            // padding-right: 5px !important;
        }

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
            padding-left: 8px !important;
        }
    }


    .celebrationLeft {
        position: absolute;
        left: 0;
        top: 0;
    }

    .celebrationRight {
        position: absolute;
        right: 0;
        top: 0;
    }

    .search-field {
        width: 100%;
    }

    .css-1uwgr7b-MuiTypography-root {
        padding: 10px;
    }


}

/*Style For View More Button*/
.theme-view-more-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E1FCFF;
    // margin: 0px 50px;
    height: 60px;
    border-radius: 16px;
    cursor: pointer;
    transition: background 0.3s ease;

    button {
        color: #195871;
        // font-weight: 600;
        // font-size: 0.9rem;
        font-size: 20px;
        font-family: Manrope700;
        text-transform: none;
        transition: color 0.3s ease;
    }

    &:hover {
        background: #195871;

        button {
            color: #E1FCFF;
        }
    }


    .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
        background-color: transparent !important;
    }
}


@media only screen and (max-width: 700px) {
    // .search-page-container {
    //     display: block;
    //     padding: 0px 10px;
    // }

    .search-page-container {
        .search_box_container {
            margin-top: 70px;
            padding: 0px 20px;
        }

        .celebrationLeft {
            width: 120px;
        }

        .celebrationRight {
            width: 120px;
        }

        .search_box {
            width: 500px !important;
        }

        .auto-complete-container {
            min-width: 500px !important;
        }
    }

    .theme-filter {
        position: inherit;
    }

    .ThemePark__HomePage .ThemePark__second__conatant .data__conatiner {
        display: block;
    }

    .ThemePark__HomePage .ThemePark__second__conatant .data__conatiner .theme_three_card {
        width: 100%;
        margin-bottom: 20px;
    }

    .left__wapper {
        margin-top: 0 !important;
    }

    .ThemePark__HomePage .splash__container .splash__card__wapper {
        display: block;
    }

    .ThemePark__HomePage .Gallary__container .Gallary__Wapper {
        display: block;
    }

    .ThemePark__HomePage .Gallary__container .Gallary__Wapper .layer img {
        margin-top: 20px;
        border-radius: 10px;

    }

    .ThemePark__HomePage .ThemePark__second__conatant .Trending__wapper .swiper-wrapper {
        padding-bottom: 40px !important;
        margin-top: 20px;
    }

    .ThemePark__HomePage .ThemePark__second__conatant .Trending__wapper .swiper-pagination {
        text-align: center;
    }

    .ThemePark__HomePage .banner_image {
        height: 45vh;
    }

    .splash__card__conatiner {
        img {
            width: 100% !important;
        }
    }
}

// .swiper-control{
//     position: absolute;
//     bottom: 20px;
//     left: 20px;
//     width: auto;
//     left: 20px;
// }
// .swiper-button-prev{
//     left: 5px;
// }

@media only screen and (max-width: 500px) {
    .search-page-container {

        .search_box {
            width: 330px !important;
        }

        .auto-complete-container {
            min-width: 330px !important;
        }
    }
}

@media only screen and (max-width: 768px) {

    //**For Slider Icon**//
    .ThemePark__HomePage {
        .swiper-button-next {
            left: 146px !important;
        }

        .ThemePark__second__conatant .Trending__wapper .trending_swipper .swiper-pagination {
            text-align: start !important;
            width: 162px !important;
            height: 23px;
            left: 15px;
            overflow-x: hidden;
            overflow-y: hidden;
        }
    }
}