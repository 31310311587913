.changeEmail-user-verify-button {
    display: flex;
    align-items: center;
    justify-content: center;

    >Button {
        width: 325px;
        height: 46px;
        background-color: #744fff;
        font-size: 14px;
        font-family: Inter700;
        text-transform: capitalize;
        border-radius: 16px;
    }
}

.changeEmail-user-model-timing {
    color: #744fff;
    font-family: Inter500 !important;
    font-size: 12px !important;
    margin-top: 15px;
}

.ExitEmail__wapper_ {
    position: relative;

    .ExitEmail_icon {
        position: absolute;
        bottom: 7px;
        // right: 10px;
        cursor: pointer;
    }
    .ExitEmailIconLang_Eng{
        right: 10px;
    }
    .ExitEmailIconLang_Arab{
        left: 10px;
    }
}




.changeEmail-usert-otp-input {
    width: 50px !important;
    height: 60px;
    margin-left: 10px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #E7E7E7;
    border-radius: 9px;
    background-color: #F4F4F5;


    &:focus {
        border: 2px solid #744fff !important;
        outline: unset !important;
    }
}

.new-email-text_contant {
    font-family: Inter500;
    font-size: 14px;
    color: #333333;
}

.changeEmail-resend_otp {
    cursor: pointer;
    font-family: Inter500;
    font-size: 14px;
    color: #333333;
}

.custom-new-email-wapper {
    width: 100%;
    margin-top: 1.5rem;

    label {
        font-family: Inter500;
        font-size: 12px;
        color: #333333;
        margin-bottom: 5px;
        margin-top: 30px;
    }

    .phone-input-field {
        width: 100%;
        border: 1px solid #E7E7E7;
        border-radius: 9px;
        outline: none;
        padding: 10px 13px;
        padding-left: 43px !important;
        background: #F4F4F5;
        font-size: 12px;
    }

    .email-input-field {
        width: 100%;
        border: 1px solid #E7E7E7;
        border-radius: 9px;
        outline: none;
        padding: 10px 13px;
        background: #F4F4F5;
        font-size: 12px;
    }

    .react-tel-input .selected-flag {
        background: #F4F4F5 !important;
        border: 1px solid #E7E7E7 !important;
    }

    .react-tel-input .selected-flag:hover {
        background: #F4F4F5 !important;
        border: 1px solid #E7E7E7 !important;
        border-radius: 3px !important;
    }

    .error-message {
        color: rgba(255, 0, 0, 0.8196078431);
        font-size: 12px;
        font-family: "Manrope600";
        margin-top: 5px;
        margin-left: 7px;
    }

    .submit-button {
        width: 100%;
        border-radius: 16px;
        background-color: #744fff;
        color: #FFFFFF;
        font-family: Inter700;
        font-size: 14px;
        text-align: center;
        padding: 10px;
        margin-top: 3rem;
    }
}

//*Below Phone Number field icon alignment issue*//
.edite_profile_phone_num{
    .react-tel-input .flag-dropdown{
        height: initial !important;
    }
    .react-tel-input .selected-flag{
        width: initial !important;
        padding: initial !important;
    }
    // .react-tel-input :disabled{
    //     padding-left: 35px !important;
    // }

    // .react-tel-input .selected-flag .flag{
    //     left: 6px !important;
    // }
}

.EditeProfilePhoneNumLan_Eng{
    .react-tel-input :disabled{
        padding-left: 35px !important;
    }
    //
    .react-tel-input .selected-flag .flag{
        left: 6px !important;
    }
}


.EditeProfilePhoneNumLan_Arab{
    .react-tel-input :disabled{
        text-align: end;
        padding-right: 51px;
    }
    //
    .react-tel-input .selected-flag .flag{
        right:28px !important;
    }
}


@media screen and (max-width:600px) {
    .ExitEmailIconLang_Eng{
        bottom: 13px !important;
        right: 4px !important;
      }
    //   .ExitEmailIconLang_Arab{
    //     left: 2px !important;
    //     bottom: 11px !important;
    //   }
}

@media screen and (max-width:480px) {
    .ExitEmail__wapper_ .ExitEmailIconLang_Arab{
        left: 3px !important;
        bottom: 11px !important;
    }
}