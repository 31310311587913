.TutorHubDocuments___container {
  border-radius: 10px;
  background: #f4f3f9;

  .Header__wapper {
    border-radius: 10px 10px 0px 0px;
    background: #f4f3f9;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    img {
      width: 22px;
      height: 22px;
      object-fit: contain;
    }

    .title {
      color: #2b2451;
      font-family: Manrope600;
      font-size: 20px;
    }
  }

  .Show__time {
    color: rgba(51, 51, 51, 0.72);
    text-align: center;
    font-family: Inter500;
    font-size: 12px;
    text-align: left;
    margin: 10px 0;
  }

  .Pdf__container {
    border-radius: 7px;
    background: #fff;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    .left__contant {
      display: flex;
      align-items: center;
      gap: 10px;

      .Black__text {
        color: #333;
        font-family: Inter600;
        font-size: 14px;
      }

      .name__text {
        color: #8d8d8d;
        font-family: Inter500;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .DownloadImg {
      width: 30px;
      height: 30.085px;
      object-fit: contain;
      object-position: center;
      cursor: pointer;
    }
  }

  .Activity__Pdf__container {
    border-radius: 7px;
    background: rgba(255, 242, 227, 1);
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    .left__contant {
      display: flex;
      align-items: center;
      gap: 10px;

      .Black__text {
        color: #333;
        font-family: Inter600;
        font-size: 14px;
      }

      .name__text {
        color: #8d8d8d;
        font-family: Inter500;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .DownloadImg {
      width: 30px;
      height: 30.085px;
      object-fit: contain;
      object-position: center;
      cursor: pointer;
    }
  }
}
