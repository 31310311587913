.Notification__conatiner {
    margin-top: 2rem;
    width: 100%;

    .profile__img {
        width: 49px;
        height: 49px;
        object-fit: contain;
        border-radius: 50%;
    }

    .time {
        color: #878787;
        font-family: Manrope500;
        font-size: 10px;
    }

    .message {
        color: #333;
        font-family: Inter500;
        font-size: 12px;
        text-align: justify;
    }

    .left_side_wapper {
        border-radius: 24px;
        padding: 20px;
        overflow: auto;
        overflow-y: scroll;
        max-height: 570px;

        .list__container {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
            height: auto;
            margin-bottom: 1.5rem;

            .title__wapper {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 10px;

                .text {
                    color: #333;
                    font-family: Manrope700;
                    font-size: 18px;
                }

                .count {
                    color: #744FFF;
                    font-family: Manrope700;
                    font-size: 12px;
                    padding: 3px 6px;
                    background-color: #F3EFFF;
                    fill: #F3EFFF;
                    border-radius: 50%;
                }
            }

            .card__wappper {
                background: #FFF;
                backdrop-filter: blur(18.5px);
                padding: 23px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                width: 100%;
                cursor: pointer;

                .message__wapper {
                    display: flex;
                    flex-direction: column;
                    gap: 13px;
                }
            }

            .card__wappper__active {
                background: #F6F3FF;
                border-radius: 5px;
                backdrop-filter: blur(18.5px);
                padding: 23px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                width: 100%;

                .message__wapper {
                    display: flex;
                    flex-direction: column;
                    gap: 13px;
                }
            }
        }
    }

    /* width */
    .left_side_wapper::-webkit-scrollbar {
        width: 0px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .right_side_wapper {

        .detailed__card {
            border-radius: 24px;
            background: #F8F9FB;
            backdrop-filter: blur(6px);
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            cursor: pointer;
        }

        .bottom_place_wapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .clickBtn {
            color: #744FFF;
            font-family: Manrope700;
            font-size: 14px;
        }
    }

}

@media (min-width: 1420px) {
    .Notification__conatiner {
        .left_side_wapper {
            max-height: 70vh !important;
        }
    }
}


@media screen and (max-width: 600px) {
    .detailed__card {
        margin: 10px !important;
    }
}