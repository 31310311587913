.Themepark_detail_page {
    word-break: break-word;

    .background_circle_1 {
        content: "";
        position: absolute;
        top: 0;
        right: 70%;
        bottom: 85%;
        left: 0%;
        z-index: 0;
        border-radius: 50%;
        opacity: 0.5;
        background: radial-gradient(circle, #04D6FB 0, #04D6FB 50%, #fff 100%);
        filter: blur(250px);
    }

    .background_circle_2 {
        content: "";
        position: absolute;
        top: 35%;
        right: 0%;
        bottom: 30%;
        left: 65%;
        z-index: 0;
        border-radius: 50%;
        opacity: 0.5;
        background: radial-gradient(circle, #04D6FB 0, #04D6FB 50%, #fff 100%);
        filter: blur(250px);
    }
}

.ThemePark__Back__card {
    position: relative;
    z-index: 1;
    border-radius: 15px;
    background: #ffffff;
    width: fit-content;
    // height: 40px;
    // padding: 10px;
    padding-left: 16px;
    width: 243px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3rem;
    cursor: pointer;

    .back-img {
        margin-right: 10px
    }
    .BackImgLang-Arab{
        rotate: 180deg;
    }

    img {
        width: 23.122px;
        height: 19.818px;
    }

    .TutorImg__text {
        color: #000;
        font-size: 20px;
        font-family: Manrope700;
        margin-left: 5px;
    }
}

.ThemePark__detaile_view__conatiner {
    margin-top: 1rem;
    border-radius: 17px;
    background: #FFF;
    padding: 35px 35px 20px 35px;
    box-shadow: 0px 4px 39px 0px rgba(178, 178, 178, 0.25);
    position: relative;
    z-index: 1;

    .Profile__contant__wapper {
        display: flex;
        flex-direction: row;
        gap: 50px;

        .profile__img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            object-fit: cover;
        }

        .Profile__rightside {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;

            .Profile_name__wapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .imageIcon {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                img {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }
            }

            .Profile_name {
                color: #000;
                font-family: Manrope700;
                font-size: 20px;
            }

            .Profile__box__wapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;

                .box__contant {
                    border-radius: 6.95px;
                    background: rgba(4, 214, 251, 0.05);
                    padding: 10px 17px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    margin-left: -13px;

                    img {
                        width: 16px;
                        height: 17px;
                        object-fit: contain;
                    }

                    span {
                        color: #04D6FB;
                        font-family: Inter600;
                        font-size: 14px;
                    }
                }

                .view-on-map {
                    margin-left: 13px;
                }
            }

            .Profile__priceRange__wapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 145px;

                .priceRange__contant {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    img {
                        width: 20px;
                        height: 20px;
                        object-fit: contain;
                    }

                    span {
                        color: #333;
                        font-family: Inter500;
                        font-size: 14px;
                    }
                }

                .detail-star-icon {
                    color: #F4CE5E !important;
                    font-size: 25px !important;

                    >span {
                        border-radius: 50% !important;
                    }
                }

                .Price_text {
                    color: #333;
                    font-family: Inter600;
                    font-size: 16px;
                    margin-left: -8px;
                }
            }

        }
    }

    .Description__wapper {
        display: flex;
        flex-direction: column;
        // gap: 15px;
        margin-top: 20px;

        .title {
            color: #333;
            font-family: Manrope700;
            font-size: 16px;
        }

        .description__contant {
            color: #333;
            font-family: Inter400;
            font-size: 14px;
            text-align: justify;
        }

        //**For Currosal Button**//
        .arrow_nav_tile_contianer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end !important;
            align-items: center;
            // gap: 8px;
            // margin-bottom: 16px;
            width: 100%;
        }

        .arrow_navs {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 18px;
        }
        .ArrowAavsLang-Arab{
            direction: ltr;
        }

        .left_arrow,
        .right_arrow {
            cursor: pointer;
            width: 12px;
        }

        .disable_arrow {
            cursor: default;
            opacity: 0.3;
            color: #867f7f;
            width: 10px;
        }
    }

    .Themepark_full_location {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .detail-location-icon {
            color: #04D6FB !important;
        }

        .detail-location-text {
            color: #333;
            font-family: Inter400;
            font-size: 14px;
        }
    }

    .Event__curosal__wapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 210px;


        img {
            width: 200px;
            height: 140px;
            object-fit: cover;
            border-radius: 8px;
            cursor: pointer;
        }

        span {
            color: #000;
            font-family: Inter600;
            font-size: 14px;
        }
    }

    .Event__curosal__wapper_ticket {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 225px;


        .ticket-card-content {
            width: 220px;
            height: 167px;
            object-fit: cover;
            border-radius: 8px;
            cursor: pointer;
            border: 2px solid #E5E8E9;
            padding: 15px 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;


            .title {
                font-family: Inter600;
                font-size: 14px;
                margin-bottom: 5px;
            }

            .description {
                font-family: Inter400;
                font-size: 13px;
                margin-bottom: 10px;
                color: #797979;
            }

            .price {
                font-size: 14px;
                font-family: Inter600;
                color: #04D6FB;
            }

            .price-line {
                text-decoration: line-through;
                font-size: 13px;
            }

        }

        span {
            color: #000;
            font-family: Inter600;
            font-size: 14px;
        }
    }

    .Event__curosal__wapper__active {

        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 210px;


        img {
            width: 200px;
            height: 140px;
            object-fit: cover;
            border-radius: 8px;
            cursor: pointer;
            // border: 3px solid #04D6FB !important;
        }

        span {
            color: #000;
            font-family: Inter600;
            font-size: 14px;
        }


    }

    .Event__curosal__wapper__active_ticket {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 225px;


        .ticket-card-content {
            width: 220px;
            height: 167px;
            object-fit: cover;
            border-radius: 8px;
            cursor: pointer;
            border: 2px solid #04D6FB !important;
            padding: 24px 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;


            .title {
                font-family: Inter600;
                font-size: 14px;
                margin-bottom: 5px;
            }

            .description {
                font-family: Inter400;
                font-size: 13px;
                margin-bottom: 15px;
                color: #797979;
            }

            .price {
                font-size: 14px;
                font-family: Inter600;
                color: #04D6FB;
            }

            // width: 100%;
            // height: 140px;
            // object-fit: cover;
            // border-radius: 8px;
            // cursor: pointer;
            // font-size: 14px;
            // border: 5px solid #04D6FB !important;
            // padding: 20px 0px 0px 10px;

            // >div:nth-child(1) {
            //     font-size: 14px;
            //     font-weight: 700;
            //     margin-bottom: 5px;
            // }

            // >div:nth-child(2) {
            //     font-size: 9px;
            //     margin-bottom: 15px;
            //     color: #797979;
            // }

            // >div:nth-child(3) {
            //     font-size: 16px;
            //     font-weight: 700;
            //     color: #04D6FB;
            // }

        }

    }

    .more-less-option {
        font-size: 12px !important;
        color: black !important;
        margin-left: 3px !important;
    }

    .Highlight__wapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .contant {
            display: flex;
            flex-direction: row;
            gap: 30px;
        }

        .text {
            color: #333;
            font-family: Inter400;
            font-size: 16px;
        }

        span.text {
            font-size: 14px;
            line-height: 26px;
        }
    }

    .bookTicket__wapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.7rem;

        .bookTicketBtn {
            border-radius: 52px;
            border: 2px solid rgba(23, 143, 255, 0.10);
            background: #04D6FB;
            width: fit-content;
            // padding: 15px 50px;
            color: #F9F9FB;
            font-family: Inter600;
            font-size: 16px;
            outline: none;
            width: 260px;
            height: 60px;
        }
    }

}

/*list-view-catd style*/

.detail_list_theme_three_card {
    margin-top: 50px !important;
    display: flex !important;
    //   gap: 20px !important;
    width: 246px;
    height: 320px;

    .css-1d4hog4-MuiCardContent-root {
        padding: 16px 16px 8px 16px !important;
    }
}



/**For Card Content**/

.detail-list-theme_rating_inc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    width: 219px;
    font-size: 12px !important;
    margin-bottom: 4px;

    .css-1c99szj-MuiRating-icon {
        color: rgba(255, 255, 255, 0.3);
        font-size: 6px;
    }

    .css-15befwp-MuiRating-root {
        font-size: 1.3rem;
        margin-left: -3px;
    }
}

.detail-list-theme_card_head {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 3px;
}

.detail-list-theme-share-like {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
        padding: 3px;
        border-radius: 50%;
        background-color: rgba(178, 180, 181, 0.3);
    }
}

.detail-list-card_map_icon {
    margin-bottom: 2px;
    color: linear-gradient(to right, #808080, #FFFFFF);
    cursor: pointer;

    >div {
        margin-left: -7px;
        font-size: 12px;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        svg {
            width: 18px;
            position: relative;
            bottom: 1px;
            margin-right: 5px;
            color: #B2B4B5 !important;
        }
    }
}

.detail-ticket-button {
    width: 217px;
    height: 31px;

    >button {
        font-size: 12px;
        font-weight: 500;
        width: 217px;
        height: 31px;
        border-radius: 8px;
        font-family: "Inter", sans-serif;
        background-color: #9CA1A6;
        color: #FFFFFF;
    }
}

.detail_list_cart_container {
    background-color: #FFFFFF;
}

.detail_list_cart {
    padding: 30px;

    .alice-carousel__stage-item {
        margin-left: 15px !important;
    }
}

.detail-list-card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 91%;
    padding: 0px 15px;
    margin-bottom: -50px;

    .detail-list-title-l {
        font-size: 20px;
        font-weight: 700;
    }

    .detail-list-title-r {
        font-size: 14px;
    }
}

@media only screen and (max-width: 700px) {
    .ThemePark__detaile_view__conatiner {
        .Profile__contant__wapper {
            display: block;
            text-align: center;

            .Profile__rightside {
                .Profile__priceRange__wapper {
                    justify-content: space-between;
                    gap: 0px;
                }
            }
        }
    }

    .ThemePark__Back__card {
        margin-top: 0px;
    }

}